import React, {FC} from "react";
import styles from "./LanguageSelectModal.module.scss";
import Modal, {ModalProps} from "../Modal/Modal";
import {createLanguageProvider, createLanguageStorage} from "../../../di";
import {Language} from "../../language";
import {Language as LanguageModel} from "../../../api-client";
import {Heading} from "../../common";
import {HeadingType} from "../../common/typography/headings/Heading/Heading";
import {useAppDispatch, useTranslator} from "../../../hooks";
import {set} from "../../../store/language";

const languageProvider = createLanguageProvider();
const languageStorage = createLanguageStorage();

const LanguageSelectModal: FC<ModalProps> = ({ active, onClose }) => {
    const t = useTranslator();
    const dispatch = useAppDispatch();
    const renderLanguages = () => {
        return languageProvider.getLanguages().map((language) => {
            return <Language language={language} onLanguageSelected={handleSelect} key={language.short} />
        });
    }

    const handleSelect = (language: LanguageModel) => {
        dispatch(set(languageProvider.getByShort(language.short)));
        languageStorage.setPreferredLanguage(language.short);
    }

    return (
        <Modal active={active} onClose={onClose} className={styles.language_select_modal}>
            <Heading headingType={HeadingType.H2} className={styles.language_select_modal__heading}>
                { t("language_select") }
            </Heading>
            <ul className={styles.language_select_modal__list}>
                { renderLanguages() }
            </ul>
        </Modal>
    );
}

export default LanguageSelectModal;