import {Translations} from "../../types";

export const my_detailed_project: Translations = {
    "my_detailed_project.placeholder": "Նման նախագիծ չի գտնվել",
    "my_detailed_project.schedule": "Վճարումների ժամանակացույց",
    "my_detailed_project.schedule.repayment": "Մարման ժամանակացույց",
    "my_detailed_project.schedule.not_found": "Վճարումների ժամանակացույցը հասանելի չէ",
    "my_detailed_project.schedule.preliminary": "Վճարման նախնական ժամանակացույց",
    "my_detailed_project.schedule.date": "ամսաթիվը",
    "my_detailed_project.schedule.sum": "Վճարման չափը",
    "my_detailed_project.schedule.principal": "Հիմնական պարտքը",
    "my_detailed_project.schedule.percents": "Հետաքրքրություն",
    "my_detailed_project.schedule.unexpected": "Ծրագրի անսպասելի տեսակ",
    "my_detailed_project.aside.requested": "Պահանջվող գումարը",
    "my_detailed_project.aside.not_listed": "Նշված չէ",
    "my_detailed_project.aside.not_listed.masculine": "Նշված չէ",
    "my_detailed_project.aside.rate": "Հայտ",
    "my_detailed_project.aside.term": "Ժամկետ",
    "my_detailed_project.aside.continue": "Շարունակեք լրացնել",
    "my_detailed_project.aside.collected": "Վճարված",
    "my_detailed_project.aside.sum": "Վարկի գումար",
    "my_detailed_project.aside.payout_sum": "Վճարման գումարը",
    "my_detailed_project.aside.fundraising_collected": "Հավաքված",
    "my_detailed_project.aside.target": "Թիրախ",
    "my_detailed_project.aside.make_payment": "Կատարել վճարում",
    "my_detailed_project.aside.tax": "վճարել հարկը",
    "my_detailed_project.status.review": "Դիտարկվող հայտ",
    "my_detailed_project.status.review_approved": "Դիմումը հաստատվել է հանձնաժողովի կողմից",
    "my_detailed_project.status.review_rejected_first": "Դիմումը մերժվել է",
    "my_detailed_project.status.review_rejected_second": "Այս պահին Ձեր դիմումը չի կարող հաստատվել, քանի որ ընկերության " +
        "ընթացիկ գործունեության մասին հայտարարություններում բավարար տեղեկատվություն չկա։",
    "my_detailed_project.status.final_review": "Դիմումը վերջնական վերանայման փուլում է",
    "my_detailed_project.status.final_review_approved": "Վարկային կոմիտեի կողմից վերահաստատված հայտը",
    "my_detailed_project.status.final_review_rejected": "Վարկային կոմիտեն մերժել է վարկ տրամադրել կրկնակի ստուգմամբ",
    "my_detailed_project.status.fundraising": "Նպատակին հասնելուց հետո վարկի միջոցները կուղարկվեն ձեր ընթացիկ հաշվին: " +
        "Վճարման ճշգրիտ ժամանակացույցը կձևավորվի այն բանից հետո, երբ միջոցները կուղարկվեն ձեր հաշվին:",
    "my_detailed_project.status.finished": "Հավաքածուն ավարտված է",
    "my_detailed_project.status.unknown": "Ծրագրի անհայտ կարգավիճակը, պարզաբանման համար դիմեք ադմինիստրատորին",

    "my_detailed_project.schedule.monthly": "Ամիսը մեկ",
    "my_detailed_project.schedule.monthly_twice": "Ամսական 2 անգամ",
    "my_detailed_project.schedule.monthly_thrice": "ամիսը 3 անգամ",
    "my_detailed_project.schedule.monthly_four_times": "ամիսը 4 անգամ",
    "my_detailed_project.schedule.quarterly": "Քառորդը մեկ անգամ",
    "my_detailed_project.schedule.at_the_end": "Նախագծի ավարտին",
}