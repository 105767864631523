import {ApiClientInterface} from "./clients";
import {DayRepayment} from "./models";

export class DayRepaymentProvider {
    constructor(private readonly apiClient: ApiClientInterface, private readonly signal: AbortSignal) {}

    public async getByDate(date: Date): Promise<Array<DayRepayment>> {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const sMonth = month < 10 ? "0" + month : month;
        const day = date.getDate();
        const sDay = day < 10 ? "0" + day : day;
        return this.apiClient.get(`/${year}-${sMonth}-${sDay}`, undefined, this.signal);
    }
}