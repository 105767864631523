import React, {FC, ReactNode, useMemo, useState} from "react";
import {Phone} from "../../../../api-client";
import {useEffectOnUpdate, useErrorCallback, useProfileData, useTranslator} from "../../../../hooks";
import './ConfirmSmsCodeForm.scoped.scss';
import formatPhone from "../../../../formatters/formatPhone";
import {InputSmsCode} from "../../inputs";
import {Loader} from "../index";
import LoaderContainer from "../../../loading/LoaderContainer";
import {SMS_CODE_LENGTH} from "../../../../utils";

type Props = {
    sendCallback: (code: string) => any;
    resendCallback: any;
    successCallback: any;
    phone?: Phone,
    header?: ReactNode
}

const ConfirmSmsCodeForm: FC<Props> = (
    {
        sendCallback,
        resendCallback,
        successCallback,
        phone,
        header= "confirm_phone.header"
    }
) => {
    const t = useTranslator();
    const handleError = useErrorCallback();
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);

    const isFilled = useMemo((): boolean => {
        return code.length === SMS_CODE_LENGTH;
    }, [code]);

    useEffectOnUpdate(() => {
        if (isFilled) {
            (async () => {
                await sendCode();
            })()
        }
    }, [isFilled]);

    const resendCode = async () => {
        setLoading(true);
        try {
            await resendCallback();
        } catch (error: any) {
            await handleError(error);
        } finally {
            setLoading(false);
        }
    }

    const sendCode = async () => {
        setLoading(true);
        try {
            const result = await sendCallback(code);
            await successCallback(result);
        } catch (error: any) {
            await handleError(error);
        } finally {
            setLoading(false);
        }
    }

    const profileData = useProfileData();
    const actualPhone = phone ?? profileData.phone!;
    return (
        <div className="sms-code-form">
            <div className="sms-code-form__header">
                { typeof header === "string" ? t(header) : header }
            </div>
            <div className="sms-code-form__text">
                { t('confirm_phone.message') } { formatPhone(actualPhone) }
            </div>
            <div className="sms-code-form__input">
                { loading && <LoaderContainer>
                    <Loader />
                </LoaderContainer> }
                <InputSmsCode code={code} onInput={setCode} />
            </div>
            <div className="sms-code-form__timer">
                { t("confirm_phone.time", { time: "0:29" }) }
            </div>
        </div>
    );
}

export default ConfirmSmsCodeForm;