import {useTranslator} from "../../useTranslator";
import {Errors as ValidationErrors} from "../../../utils";
import {FullName} from "../../../api-client";

export type PersonalDataErrors = {
    firstName?: ValidationErrors;
    middleName?: ValidationErrors;
    lastName?: ValidationErrors;
    birthday?: ValidationErrors;
}

export const usePersonalInfoValidation = (data: { fullName: FullName, birthday: Date }) => {
    const t = useTranslator();
    return (initialErrors: PersonalDataErrors) => {
        if (data.fullName.firstName === "") {
            initialErrors.firstName = [t(
                "errors.field_required",
                { field: t("errors.fields.name"), type: t("errors.field_required.neuter") }
            )];
        }
        if (data.fullName.lastName === "") {
            initialErrors.lastName = [t(
                "errors.field_required",
                { field: t("errors.fields.last_name"), type: t("errors.field_required.feminine") }
            )];
        }
        return initialErrors;
    }
}