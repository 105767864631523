import React, {useContext} from "react";
import {GuarantorContext} from "../GuarantorContextProvider";
import {Heading, PrimaryHeading} from "../../../common";
import {HeadingType} from "../../../common/typography/headings/Heading/Heading";
import {useTranslator} from "../../../../hooks";

const GuarantorStatus = () => {
    const t = useTranslator();
    const {guarantor} = useContext(GuarantorContext);
    if (guarantor === undefined) {
        return <div className="identification-status">
            <PrimaryHeading>
                <Heading headingType={HeadingType.H1} className="identification-status__heading">
                    { t("guarantor.survey_not_found") }
                </Heading>
            </PrimaryHeading>
        </div>
    }
    return <div className="identification-status">
        <PrimaryHeading>
            <Heading headingType={HeadingType.H1} className="identification-status__heading">
                { t("guarantor.status") }
            </Heading>
        </PrimaryHeading>
        <div className="identification-status__text">
            { t("guarantor.awaiting_administrator") }
        </div>
    </div>
}

export default GuarantorStatus;