import {useMemo, useState} from "react";
import {ANALYTICS_PERIOD, AnalyticsFilter, AnalyticsPeriod, AnalyticsType} from "../../api-client";

export const useAnalyticsFilter = () => {
    const [selectedYear, setSelectedYear] = useState<AnalyticsPeriod>(ANALYTICS_PERIOD.TwelveMonths);
    const [types, setTypes] = useState<Array<AnalyticsType>>([]);
    const filter: AnalyticsFilter = useMemo(() => {
        return {
            period: selectedYear,
            type: types.length !== 0 ? types[0].toString() : undefined
        }
    }, [selectedYear, types]);

    return {
        selectedYear,
        setSelectedYear,
        types,
        setTypes,
        filter
    };
}