import React, {FC, ReactNode, useId, useMemo, useRef, useState} from "react";
import {formatAmount} from "../../../../formatters";
import './InputMoney.scoped.scss';
import {Money} from "../../../../api-client";
import {useEffectOnUpdate} from "../../../../hooks";
import {InputLabel} from "../../form";
import {createMoneyFactory} from "../../../../di";
import {cn, resultIf} from "../../../../utils";

interface Props {
    money: Money;
    onMoneyChanged: (money: Money) => void;
    boundaries?: {
        min?: number
        max?: number
    };
    text: ReactNode;
    error: boolean;
    disabled?: boolean;
}

const moneyFactory = createMoneyFactory();

const InputMoney: FC<Props> = (
    {
        money,
        onMoneyChanged,
        boundaries,
        text,
        error,
        disabled,
        ...props
    }) => {
    const inputElement = useRef<HTMLInputElement>(null);
    const [prevPosition, setPrevPosition] = useState(0);

    const inputValue = useMemo((): string => {
        let amount;
        let value = money.amount.toString();
        let sanitizedValue =
            value || value.length === 0 ? value.toString().replace(/\D+/g, "") : "";
        if (sanitizedValue.length > 0) {
            if (sanitizedValue.length > 15) {
                sanitizedValue = sanitizedValue.slice(0, 15);
            }
            const parsedAmount = parseInt(sanitizedValue);
            if (!isNaN(parsedAmount)) {
                amount = parsedAmount;

                if (boundaries !== undefined) {
                    const { min, max } = boundaries;
                    if (min && amount < min) {
                        amount = min;
                    }
                    if (max && amount > max) {
                        amount = max;
                    }
                }
            }
        }
        if (amount !== undefined) {
            return formatAmount(amount > 100 ? amount / 100 : amount);
        }
        return "";
    }, [money]);

    useEffectOnUpdate(() => {
        if (inputElement.current) {
            const currentPosition = inputElement.current.selectionStart!;
            if (currentPosition > prevPosition) {
                inputElement.current.selectionStart = inputElement.current.selectionEnd
                    = currentPosition - prevPosition >= 2
                    ? prevPosition
                    : currentPosition - 1;
            } else if (currentPosition === prevPosition) {
                inputElement.current.selectionStart = inputElement.current.selectionEnd = prevPosition;
            }
        }
    }, [money]);

    const updateValue = (value: string) => {
        if (value.length === 0) {
            onMoneyChanged(moneyFactory.createMoney());
            return;
        }
        if (value.indexOf(".") !== -1) {
            const numberOfPennies = value.split(".")[1].length;
            if (numberOfPennies < 2) {
                value = updatePennies(value);
            } else if (numberOfPennies >= 3) {
                value = value.slice(0, value.length - 1);
            }
        }
        const sanitizedValue = value.replace(/\D/g, '');
        const numberValue = parseInt(sanitizedValue);
        setPrevPosition(inputElement.current!.selectionStart!);
        onMoneyChanged(new Money(numberValue, money.currencyCode));
    }

    const updatePennies = (amount: string): string => {
        let currentPosition = inputElement.current!.selectionStart;
        if (currentPosition === null) {
            currentPosition = 0;
        }
        if (amount.length === currentPosition) {
            return `${amount}0`;
        }
        return amount.slice(0, currentPosition) + "0" + amount.slice(currentPosition);
    }

    const id = useId();

    return (
        <div className="input-money__container">
            <InputLabel id={id} text={text} />
            <input
                {...props}
                id={id}
                type="text"
                className={cn("input-money", resultIf(error, "input-money--error"))}
                ref={inputElement}
                value={inputValue}
                onInput={e => updateValue((e.target as HTMLInputElement).value)}
                disabled={disabled}
            />
        </div>
    );
}

export default InputMoney;