import {ApiClientInterface} from "./clients";
import {EmailResult, RegistrationConfirmation} from "./models";

export class VerificationManager {
    constructor(private readonly apiClient: ApiClientInterface) {}

    public async requestPhoneConfirmation(userUuid: string): Promise<RegistrationConfirmation> {
        return this.apiClient.post("/request", {userUuid});
    }

    public async confirmPhone(userUuid: string, confirmationCode: string): Promise<void> {
        return this.apiClient.post("/confirm", {userUuid, confirmationCode});
    }

    public async verifyEmail(verificationCode: string): Promise<EmailResult> {
        return this.apiClient.post("/verify-email", {verificationCode});
    }
}