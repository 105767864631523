import React from "react";
import styles from "./RegisterLender.module.scss";
import RegisterContainer from "../../../components/pages/register/RegisterContainer";
import LenderRegistrationForm from "../../../components/pages/register/LenderRegistrationForm";

const RegisterLender = () => {
    return (
        <div className={styles.register_lender}>
            <RegisterContainer>
                <LenderRegistrationForm />
            </RegisterContainer>
        </div>
    );
}

export default RegisterLender;