import React from "react";
import {CommonBlock} from "../../../../common/blocks";
import styles from "./FundraisingProjectWarning.module.scss";
import InformationSvg from "../../../../svg/InformationSvg";
import {CommonText} from "../../../../common";
import {ColorDictionary, getCssVariable} from "../../../../../utils";
import {useTranslator} from "../../../../../hooks";

const FundraisingProjectWarning = () => {
    const t = useTranslator();
    return (
        <CommonBlock className={styles.fundraising_project_module}>
            <CommonBlock.Section underscore={false}>
                <InformationSvg color={getCssVariable(ColorDictionary.PRIMARY_BLUE)} />
                <CommonText>
                    { t("detailed_project.invest.fundraising_warning") }
                </CommonText>
            </CommonBlock.Section>
        </CommonBlock>
    );
}

export default FundraisingProjectWarning;