import {Translations} from "../../types";

export const finances: Translations = {
    "finances": "Финансы",
    "finances.schedule": "График выплат",
    "finances.repayment.date": "Дата",
    "finances.repayment.amount": "Сумма платежа",
    "finances.repayment.debt": "Основной долг",
    "finances.repayment.percents": "Проценты",
    "finances.no_data": "Выплаты не найдены",
    "finances.calendar": "Календарь выплат",
    "finances.calendar.no_payments": "В этот день нет ни одной выплаты",
    "finances.calendar.payment.id": "ID заёма",
    "finances.calendar.payment.sum": "Сумма инвестиции",
    "finances.calendar.payment.rate": "Ставка",
    "finances.calendar.payment.month": "Месяц",
    "finances.calendar.payment.date": "Дата платежа",
    "finances.calendar.payment.payment_sum": "Сумма платежа",
}