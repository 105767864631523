import {Translations} from "../../types";

export const common: Translations = {
    "common.surname": "Ազգանունը",
    "common.name": "Անուն",
    "common.parent_name": "Ազգանունը",
    "common.birthday": "Ծննդյան ամսաթիվ",
    "common.email": "Էլ",
    "common.phone": "Հեռախոսահամար",
    "common.passport.series": "Սերիա",
    "common.passport.number": "Թիվ",
    "common.passport.issued_by": "Թողարկվել է",
    "common.passport.department_code": "Բաժանմունքի կոդը",
    "common.passport.issued_date": "թողարկման ամսաթիվը",
    "common.passport.registration_address": "Գրանցման հասցեն",
    "common.passport.face_photo": "Էջ լուսանկարով",
    "common.passport.registration_photo": "Էջ գրանցմամբ",
    "common.passport.passport_selfie": "Սելֆի անձնագրով",
    "common.lender": "Վարկատու",
    "common.borrower": "Վարկառու",
    "common.password": "Գաղտնաբառ",
    "common.back": "Վերադառնալ",
    "common.individual": "անհատական",
    "common.entrepreneur": "ձեռնարկատեր",
    "common.legal_entity": "իրավաբանական անձ",
    "common.please_wait": "Խնդրում ենք սպասել...",
    "common.loading": "Բեռնվում է...",
    "common.reg_number": "Գրանցման համարը",
    "common.company_reg_number": "կանոն. ընկերության համարը",
    "common.continue": "Շարունակել",
    "common.kpp": "LLC",
    "common.bik": "BIK",
    "common.from": "Սկսած",
    "common.to": "նախքան",
    "common.chart_mln": "մի",
    "common.chart_tho": "հազ",
    "common.submit": "Հաստատել",
    "common.delete": "Ջնջել",
    "common.no_translation": "«:lang» թարգմանությունը չի գտնվել",

    "common.euro": "եվրո",
    "common.ruble": "Ռուբլի",
    "common.dram": "դրամ",
}