import React, {FC, LiHTMLAttributes} from "react";
import CommonListEntry from "../../../common/blocks/CommonListEntry";
import {cn} from "../../../../utils";
import styles from "./CommonOffer.module.scss";

const CommonOffer: FC<LiHTMLAttributes<HTMLLIElement>> = ({ ...props }) => {
    return (
        <CommonListEntry {...props} className={cn(styles.common_offer, props.className)}>
            { props.children }
        </CommonListEntry>
    );
}

export default CommonOffer;