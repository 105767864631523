import React from "react";
import {SvgComponent} from "../SvgComponent";
import SvgContainer from "../SvgContainer";
import {SvgContainerSize} from "../SvgContainer/SvgContainer";
import {ColorDictionary, getCssVariable} from "../../../utils";

const RejectedSvg: SvgComponent = ({ color= getCssVariable(ColorDictionary.ERROR) }) => {
    return (
        <SvgContainer size={SvgContainerSize.Small}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1575_37266)">
                    <circle cx="8" cy="8" r="7" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.8281 5.17188L7.9997 8.0003L5.17127 10.8287" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.8281 10.8281L7.9997 7.9997L5.17127 5.17127" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_1575_37266">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

        </SvgContainer>
    );
}

export default RejectedSvg;