import React from "react";
import './HeaderNavigation.scoped.scss';
import ProjectLink from "../../../common/links/ProjectLink";
import {RouteDictionary} from "../../../../utils";
import {PublicLink} from "../../../common";
import {useTranslator} from "../../../../hooks";

const HeaderNavigation = () => {
    const t = useTranslator();
    return (
        <nav className="header-navigation">
            <ul className="header-navigation__list">
                <li className="header-navigation__element">
                    <ProjectLink
                        className="header-navigation__link"
                        to={RouteDictionary.ARCHIVE}
                    >
                        { t("header.archive") }
                    </ProjectLink>
                </li>
                <li className="header-navigation__element">
                    <PublicLink
                        className="header-navigation__link"
                        url="/page/how-it-works"
                    >
                        { t("header.how_it_works") }
                    </PublicLink>
                </li>
                <li className="header-navigation__element">
                    <PublicLink
                        className="header-navigation__link"
                        url="/page/faq"
                    >
                        { t("header.faq") }
                    </PublicLink>
                </li>
            </ul>
        </nav>
    );
}

export default HeaderNavigation;