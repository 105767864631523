import React, {FC, InputHTMLAttributes, ReactNode, useId} from "react";
import {Phone} from "../../../../api-client";
import './InputPhone.scoped.scss';
import {cn, resultIf} from "../../../../utils";
import {InputLabel} from "../../form";
import InputMask  from "react-input-mask";

export interface InputPhoneProps extends InputHTMLAttributes<HTMLInputElement> {
    phone: Phone;
    onPhoneInput: (phone: Phone) => void;
    text: ReactNode;
    error: boolean;
}

const InputPhone: FC<InputPhoneProps> = ({ phone, onPhoneInput, text, error, ...props }) => {
    const changeCallback = (number: string) => {
        const formattedNumber = number.replace(/[^0-9]/g, '');
        const newPhone = new Phone(phone.countryCode, formattedNumber);
        onPhoneInput(newPhone);
    }
    const id = useId();
    return (
        <div className="input-phone">
            <InputLabel id={id} text={text} />
            <div className={cn("input-phone__inner", resultIf(error, "input-phone__inner--error"))}>
                <div className="input-phone__code">
                    +{ phone.countryCode }
                </div>
                <div className="input-phone__container">
                    <InputMask
                        {...props}
                        mask="(999) 999-99-99"
                        id={id}
                        className={cn("input-phone__input", props.className)}
                        placeholder="(812) 335-04-20"
                        value={phone.number}
                        onChange={(event) => changeCallback(event.target.value)}
                    />
                </div>
            </div>
        </div>
    );
}

export default InputPhone;