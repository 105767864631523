import React from "react";
import {Route, Routes} from "react-router-dom";
import FundingRequest from "../../../views/borrower/request/FundingRequest";
import BorrowerRequestDashboard from "../../../views/borrower/BorrowerRequestDashboard";

const BorrowerRequestRoutes = () => {
    return (
        <Routes>
            <Route element={<BorrowerRequestDashboard />}>
                <Route index element={<FundingRequest />} />
                <Route path=":uuid" element={<FundingRequest />} />
            </Route>
        </Routes>
    );
}

export default BorrowerRequestRoutes;