import React, {FC, PropsWithChildren} from "react";
import "./SvgContainer.scoped.scss";
import {cn} from "../../../utils";

export enum SvgContainerSize {
    Small = "svg-container--small",
    Medium = "svg-container--medium",
    Large = "svg-container--large"
}

interface Props {
    size?: SvgContainerSize;
}

const SvgContainer: FC<PropsWithChildren<Props>> = ({ size= SvgContainerSize.Medium, children }) => {
    return (
        <div className={cn("svg-container", size)}>
            { children }
        </div>
    );
}

export default SvgContainer;