import React, {FC} from "react";
import {ANALYTICS_TYPE, AnalyticsType} from "../../../../api-client";
import AnalyticsMultiSelect from "../AnalyticsMultiSelect";
import {useTranslator} from "../../../../hooks";

interface Props {
    selected: Array<AnalyticsType>;
    setSelected: (selected: Array<AnalyticsType>) => void;
}

interface TypeObject {
    type: AnalyticsType;
    translation: string;
}

const typesObjects: Array<TypeObject> = [
    { type: ANALYTICS_TYPE.BuyBack, translation: "analytics.user.type.buyback" }
];

const AnalyticsTypeSelect: FC<Props> = ({ selected, setSelected }) => {
    const t = useTranslator();
    const mappedSelected = typesObjects.filter((entry) => {
        return selected.includes(entry.type);
    });

    const handleSelect = (selected: Array<TypeObject>) => {
        setSelected(selected.map((entry) => entry.type));
    }

    return (
        <AnalyticsMultiSelect
            onSelected={handleSelect}
            options={typesObjects}
            selectedOptions={mappedSelected}
            renderOption={(option) => t(option.translation)}
        />
    );
}

export default AnalyticsTypeSelect;