import React, {AllHTMLAttributes, ElementType, FC} from "react";
import styles from "./CommonBlockExtension.module.scss";
import {cn, resultIf} from "../../../../../../utils";

export interface CommonBlockProps extends AllHTMLAttributes<HTMLElement> {
    tag?: ElementType;
    underscore?: boolean;
}

const CommonBlockExtension: FC<CommonBlockProps> = (
    {
        tag: Tag= "div",
        underscore= true,
        children,
        ...props
    }) => {
    return (
        <Tag
            {...props}
            className={cn(
                styles.common_block_extension,
                resultIf(underscore, styles.common_block_extension__underscored),
                props.className
            )}
        >
            { children }
        </Tag>
    );
}

export default CommonBlockExtension;