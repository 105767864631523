import React, {FC, PropsWithChildren, ReactNode, useEffect, useRef} from "react";
import './OptionBox.scoped.scss';
import {Nullable} from "../../../../utils";
import {OptionBoxContent, OptionBoxHead} from "./composables";
import OptionBoxContextProvider, {OptionBoxContextType} from "./OptionBoxContext";
import {useToggle} from "../../../../hooks";

export type OptionBoxProps<T> = {
    options: Array<T>;
    selectedOption: Nullable<T>;
    renderOption?: (option: T) => ReactNode | undefined;
}

type OptionBoxExtensions = {
    Head: typeof OptionBoxHead;
    Content: typeof OptionBoxContent;
}

const OptionBox: FC<PropsWithChildren<OptionBoxProps<any>>>
    & OptionBoxExtensions = <T, >(
        {
            options,
            selectedOption,
            renderOption,
            children
        }: PropsWithChildren<OptionBoxProps<T>>
) => {
    const [active, toggle, setActive] = useToggle();

    const value: OptionBoxContextType<T> = {
        options: options,
        selectedOption: selectedOption,
        render: renderOption !== undefined ? renderOption : (option: T) => option as unknown as ReactNode,
        active: active,
        toggle: toggle
    }

    const elem = useRef(null);

    useEffect(() => {
        const handleClick = (e: MouseEvent): void => {
            if (e.target instanceof HTMLElement) {
                let element: Nullable<HTMLElement> = e.target;
                while (element && element !== elem.current) {
                    element = element.parentElement;
                }
                if (element === null) {
                    setActive(false);
                }
            }
        }

        const handleKeyPress = function (e: KeyboardEvent) {
            if (active && e.key === 'Escape') {
                setActive(false);
            }
        }
        if (active) {
            window.addEventListener('click', handleClick);
            window.addEventListener('keydown', handleKeyPress);
        }
        return () => {
            window.removeEventListener('click', handleClick);
            window.removeEventListener('keydown', handleKeyPress);
        }
    }, [active]);

    return (
        <OptionBoxContextProvider props={value}>
            <div className="option-box" ref={elem}>
                { children }
            </div>
        </OptionBoxContextProvider>
    );
}

OptionBox.Head = OptionBoxHead;
OptionBox.Content = OptionBoxContent;

export default OptionBox;