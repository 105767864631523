import React, {FC, PropsWithChildren} from "react";
import {useMountAnimation} from "../../../../../hooks";
import {cn, resultIf} from "../../../../../utils";
import './DetailedProjectSubPage.scoped.scss';

const DetailedProjectSubPage: FC<PropsWithChildren> = ({ children }) => {
    const mounted = useMountAnimation();
    return (
        <div className={cn("detailed-project-sub-page", resultIf(mounted, "mounted"))}>
            { children }
        </div>
    );
}

export default DetailedProjectSubPage;