import React from "react";
import SvgContainer from "./SvgContainer";
import {SvgContainerSize} from "./SvgContainer/SvgContainer";

const DocumentSvg = () => {
    return (
        <SvgContainer size={SvgContainerSize.Small}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 0.75H12C12.6904 0.75 13.25 1.30964 13.25 2V10.6893L8.68934 15.25H4C3.30964 15.25 2.75 14.6904 2.75 14V2C2.75 1.30964 3.30964 0.75 4 0.75Z" stroke="#262B39" strokeWidth="1.5" strokeLinejoin="round"/>
                <path d="M6 4H10" stroke="#262B39" strokeWidth="1.5" strokeLinecap="round"/>
                <path d="M6 7H10" stroke="#262B39" strokeWidth="1.5" strokeLinecap="round"/>
            </svg>
        </SvgContainer>
    );
}

export default DocumentSvg;