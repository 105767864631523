import React, {FC, PropsWithChildren} from "react";
import CommonBlockExtension from "../CommonBlockExtension";
import {CommonBlockProps} from "../CommonBlockExtension/CommonBlockExtension";

const CommonBlockSection: FC<PropsWithChildren<CommonBlockProps>> = ({ children, ...props }) => {
    return (
        <CommonBlockExtension {...props} tag="section">
            { children }
        </CommonBlockExtension>
    );
}

export default CommonBlockSection;