import React, {FC, useMemo} from "react";
import './DetailedProjectAnalyticsList.scoped.scss';
import {Money} from "../../../../common";
import {useMountAnimation, useTranslator} from "../../../../../hooks";
import {cn, resultIf} from "../../../../../utils";
import {
    NetCreditTurnoverMonth,
    NetCreditTurnoverPeriod
} from "../../../../../api-client";
import DetailedProjectTurnoverPeriods from "../DetailedProjectTurnoverPeriods";
import {getMonthTranslation} from "../../../../../i18n";

interface Props {
    months: Array<NetCreditTurnoverMonth>;
    periods: Array<NetCreditTurnoverPeriod>;
}

const chunkSize = 6;

const DetailedProjectAnalyticsList: FC<Props> = ({ months, periods }) => {
    const t = useTranslator();
    const mounted = useMountAnimation();
    const chunks = useMemo(() => {
        const res: Array<Array<NetCreditTurnoverMonth>> = [];
        for (let i = 0; i < months.length; i+= chunkSize) {
            res.push(months.slice(i, i + chunkSize));
        }
        return res;
    }, [months]);
    const renderChunks = () => {
        return chunks.map((chunk, index) => {
            return <div className="detailed-project-analytics-list__columns" key={index}>
                <>
                    {chunk.map((month, index) => {
                        return <div className="detailed-project-analytics-list__column" key={index}>
                            <div className="detailed-project-analytics-list__column-header">
                                { t(getMonthTranslation(month.month)) }
                            </div>
                            <div>
                                <Money money={month.sum} withCurrency={false} />
                            </div>
                        </div>
                    })}
                </>
            </div>
        })
    }
    return (
        <div className={cn("detailed-project-analytics-list", resultIf(mounted, "mounted"))}>
            <DetailedProjectTurnoverPeriods periods={periods} />
            <div className="detailed-project-analytics-list__table">
                { renderChunks() }
            </div>
        </div>
    )
}

export default DetailedProjectAnalyticsList;