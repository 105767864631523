import {useAppDispatch} from "./store";
import {useNavigateByName} from "./useNavigateByName";
import {useEffect} from "react";
import {createAuthTokenManager} from "../di";
import {clear} from "../store/profile";
import {RouteDictionary} from "../utils";

export const useLogout = (callback?: () => void) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigateByName();
    useEffect(() => {
        createAuthTokenManager().remove();
        dispatch(clear());
        callback && callback();
    }, []);
}