import React, {FC} from "react";
import logoImage from "../../../../assets/images/another-placeholder-logo.svg";
import Image from "../Image";
import {ImageProps} from "../Image/Image";

const LogoImage: FC<ImageProps> = ({ ...props }) => {
    return (
        <Image {...props} src={logoImage} alt="Logo image" />
    );
}

export default LogoImage;