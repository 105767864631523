import React, {FC} from "react";
import {PassportPhotos} from "../../../../../api-client";
import "./IdentificationPassportFiles.scoped.scss";
import firstPassportPlaceholder from "../../../../../assets/images/first-passport-placeholder.svg";
import secondPassportPlaceholder from "../../../../../assets/images/second-passport-placeholder.svg";
import thirdPassportPlaceholder from "../../../../../assets/images/third-passport-placeholder.svg";
import {Errors as ValidationErrors} from "../../../../../utils";
import {InputErrors} from "../../../form";
import IdentificationPassportUpload from "../IdentificationPassportUpload";
import {useTranslator} from "../../../../../hooks";

export type PassportPhotoErrors = {
    firstPhotos?: ValidationErrors;
    secondPhotos?: ValidationErrors;
    selfiePhotos?: ValidationErrors;
}

interface Props {
    photos: PassportPhotos;
    setPhotos: (photos: PassportPhotos) => void;
    errors: PassportPhotoErrors;
}

const IdentificationPassportFiles: FC<Props> = ({photos, setPhotos, errors}) => {
    const t = useTranslator();
    return (
        <div className="identification-passport-files">
            <div className="identification-passport-files__header">
                { t("identification.passport_files") }
            </div>
            <div className="identification-passport-files__text">
                { t("identification.passport_files.disclaimer") }
            </div>
            <div className="identification-passport-files__uploads">
                <div>
                    <IdentificationPassportUpload
                        photos={photos.firstPhotos}
                        setPhotos={(newPhotos) => {setPhotos({...photos, firstPhotos: newPhotos})}}
                        name={ t("common.passport.face_photo") }
                        placeholder={firstPassportPlaceholder}
                        error={errors.firstPhotos !== undefined}
                    />
                    { errors.firstPhotos && <InputErrors errors={errors.firstPhotos} /> }
                </div>
                <div>
                    <IdentificationPassportUpload
                        photos={photos.secondPhotos}
                        setPhotos={(newPhotos) => {setPhotos({...photos, secondPhotos: newPhotos})}}
                        name={ t("common.passport.registration_photo") }
                        placeholder={secondPassportPlaceholder}
                        error={errors.secondPhotos !== undefined}
                    />
                    { errors.secondPhotos && <InputErrors errors={errors.secondPhotos} /> }
                </div>
                <div>
                    <IdentificationPassportUpload
                        photos={photos.selfiePhotos}
                        setPhotos={(newPhotos) => {setPhotos({...photos, selfiePhotos: newPhotos})}}
                        name={ t("common.passport.passport_selfie") }
                        placeholder={thirdPassportPlaceholder}
                        error={errors.selfiePhotos !== undefined}
                    />
                    { errors.selfiePhotos && <InputErrors errors={errors.selfiePhotos} /> }
                </div>
            </div>
        </div>
    );
}

export default IdentificationPassportFiles;