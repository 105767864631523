import React, {FC, useEffect, useState} from "react";
import {ModalProps} from "../Modal/Modal";
import {ConfirmSmsCodeForm} from "../../common";
import {Modal} from "../index";
import {useErrorCallback, useProfileData} from "../../../hooks";
import {createVerificationManager} from "../../../di";
import {PhoneConfirmation} from "../../../api-client";

interface Props extends ModalProps {
    onSuccess: () => void;
}

const PhoneConfirmationModal: FC<Props> = ({ active, onClose, onSuccess }) => {
    const handleError = useErrorCallback();
    const profileData = useProfileData();
    const [confirmation, setConfirmation] = useState<PhoneConfirmation | null>(null);

    useEffect(() => {
        (async () => {
            try {
                const manager = await createVerificationManager();
                const confirmation = await manager.requestPhoneConfirmation(profileData.uuid!);
                setConfirmation(confirmation);
            } catch (error: any) {
                await handleError(error);
            }
        })();
    }, []);

    const handleResend = async () => {
        console.log(123);
    }

    const handleSend = async (verificationCode: string): Promise<void> => {
        const manager = await createVerificationManager();
        await manager.confirmPhone(profileData.uuid!, verificationCode);
    }

    return (
        <Modal active={active} onClose={onClose}>
            <ConfirmSmsCodeForm
                sendCallback={handleSend}
                resendCallback={handleResend}
                successCallback={onSuccess}
            />
        </Modal>
    );
}

export default PhoneConfirmationModal;