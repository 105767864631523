import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {IdentificationManager} from "../IdentificationManager";

export class IdentificationManagerFactory {
    private readonly apiClientFactory: ApiClientFactoryInterface;

    constructor(baseUrl: string, headers: Headers) {
        this.apiClientFactory = new ApiClientFactory(
            `${baseUrl}/api/v1/identification`,
            headers
        )
    }

    public createIdentificationManager(authToken: string): IdentificationManager {
        return new IdentificationManager(this.apiClientFactory.createAuthorizedClient(authToken));
    }
}