import React from "react";
import "./AnalyticsMultiSelect.scoped.scss";
import {MultiSelect} from "../../../common";
import {MultiSelectProps} from "../../../common/selects/MultiSelect/MultiSelect";
import {MultiSelectContext} from "../../../common/selects/MultiSelect/MultiSelectContext";
import DropdownOpener from "../../../common/utils/DropdownOpener";
import DropdownDownSvg from "../../../svg/dropdown/DropdownDownSvg";
import {ColorDictionary, getCssVariable} from "../../../../utils";
import {useTranslator} from "../../../../hooks";

interface Props<T> extends MultiSelectProps<T> {
    onSelected: (selected: Array<T>) => void;
}

const AnalyticsMultiSelect = <T, >({ options, selectedOptions, onSelected, renderOption }: Props<T>) => {
    const t = useTranslator();
    const renderAnalyticsOption = (option: T) => {
        return <div className="analytics_multi_select__option">
            { renderOption !== undefined ? renderOption(option) : option + "" }
        </div>
    }
    return (
        <div className="analytics_multi_select">
            <MultiSelect options={options} selectedOptions={selectedOptions} renderOption={renderAnalyticsOption}>
                <MultiSelect.Head>
                    <MultiSelectContext.Consumer>
                        {({ active }) => <>
                            <div className="analytics_multi_select__head">
                                <span>
                                    { t("analytics.user.type") }
                                </span>
                                <DropdownOpener active={active}>
                                    <DropdownDownSvg color={getCssVariable(ColorDictionary.WHITE)} />
                                </DropdownOpener>
                            </div>
                        </>}
                    </MultiSelectContext.Consumer>
                </MultiSelect.Head>
                <MultiSelect.Content onSelected={onSelected} className="analytics_multi_select__content" />
            </MultiSelect>
        </div>
    );
}

export default AnalyticsMultiSelect;