import React, {createContext, PropsWithChildren} from "react";
import {FundingRequest as FundingRequestModel, FundingRequest} from "../../../api-client";
import {createRequestSumViewedStorage} from "../../../di";

export enum FundingRequestStep {
    UploadDocuments = 0,
    CountAvailableSum = 1,
    FillSurvey = 2,
    ChooseLoanOption = 3,
    SignDocuments = 4
}

export interface FundingRequestContextType {
    step: FundingRequestStep;
    setStep: (step: FundingRequestStep) => void;
    request: FundingRequest | null;
    setRequest: (request: FundingRequest) => void;
}

type Props = {
    props: FundingRequestContextType;
}

export const resolveFundingRequestStep = (request: FundingRequestModel | null): FundingRequestStep => {
    const storage = createRequestSumViewedStorage();
    if (request === null) {
        return FundingRequestStep.UploadDocuments;
    }
    if (request.desiredMoney !== undefined) {
        return FundingRequestStep.SignDocuments;
    }
    if (request.companyUuid !== undefined && request.approvedSum !== undefined) {
        return FundingRequestStep.ChooseLoanOption;
    }
    if (request.approvedSum !== undefined && storage.get(request)) {
        return FundingRequestStep.FillSurvey;
    }
    if (request.financialStatements.length !== 0) {
        return FundingRequestStep.CountAvailableSum;
    }
    return FundingRequestStep.UploadDocuments;
}

export const FundingRequestContext = createContext<FundingRequestContextType>({
    step: FundingRequestStep.UploadDocuments,
    setStep: () => {},
    request: null,
    setRequest: () => {}
});

function FundingRequestContextProvider(props: PropsWithChildren<Props>) {
    const { children, ...value } = props;
    return <FundingRequestContext.Provider value={value.props}>
        { children }
    </FundingRequestContext.Provider>
}

export default FundingRequestContextProvider;