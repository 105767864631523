import React, {SyntheticEvent, useContext, useEffect, useMemo, useState} from "react";
import './IntroductionIdentification.scoped.scss';
import {
    Button,
    Checkbox,
    DatePicker,
    Heading,
    IdentificationPassportFiles, InputErrors, Loader,
    PrimaryButton,
    PrimaryHeading
} from "../../../common";
import {HeadingType} from "../../../common/typography/headings/Heading/Heading";
import {CommonInput} from "../../../common/inputs";
import {BorrowerCompanyRepresentative, CompanyMoneySource, CompanyReputationRating} from "../../../../api-client";
import {
    cn,
    Errors as ValidationErrors,
    resultIf
} from "../../../../utils";
import {
    useCompanyInfo,
    useEffectOnUpdate,
    useErrorCallback,
    usePassport,
    usePassportPhotos,
    useProfileData,
    useToggle, useFullName, useTranslator
} from "../../../../hooks";
import {PrimaryButtonColor} from "../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import IdentificationPassport from "../../../common/utils/identification/IdentificationPassport/IdentificationPassport";
import {
    PassportPhotoErrors
} from "../../../common/utils/identification/IdentificationPassportFiles/IdentificationPassportFiles";
import {
    createBorrowerCompanyManager
} from "../../../../di";
import {FundingRequestContext, FundingRequestStep} from "../../funding-request/FundingRequestContext";
import {formatApiDate} from "../../../../formatters";
import IntroductionCompany from "../IntroductionCompany";
import LoaderContainer from "../../../loading/LoaderContainer";
import {
    CompanyInfoErrors,
    PassportErrors,
    PersonalDataErrors, useCompanyInfoValidation,
    usePassportValidation,
    usePersonalInfoValidation
} from "../../../../hooks/data/validation";

type FormErrors = PersonalDataErrors & {
    regNumber?: ValidationErrors;
    phone?: ValidationErrors;
} & PassportErrors & PassportPhotoErrors & CompanyInfoErrors;

const IntroductionIdentification = () => {
    const t = useTranslator();
    const profileData = useProfileData();
    const handleError = useErrorCallback();
    const { setStep, request, setRequest } = useContext(FundingRequestContext);
    const [fullName, setFullName] = useFullName();
    const [birthday, setBirthday] = useState<Date>(new Date());

    const [passport, setPassport] = usePassport();
    const [passportPhotos, setPassportPhotos] = usePassportPhotos();

    useEffectOnUpdate(() => {
        setPassport({
            ...passport,
            firstPhotos: passportPhotos.firstPhotos.map((file) => file.url),
            secondPhotos: passportPhotos.secondPhotos.map((file) => file.url),
            selfiePhotos: passportPhotos.selfiePhotos.map((file) => file.url),
        });
    }, [passportPhotos]);

    const representative = useMemo((): BorrowerCompanyRepresentative => {
        return {
            fullName,
            birthday: formatApiDate(birthday),
            passport
        }
    }, [fullName, birthday, passport]);

    const [companyInfo, setCompanyInfo] = useCompanyInfo(profileData.name!);

    const [noCrime, toggleNoCrime] = useToggle();
    const [noNegative, toggleNoNegative] = useToggle();
    const [loading, setLoading] = useState(false);
    const [personalDataAgree, togglePersonalDataAgree] = useToggle();
    const [dataLoading, setDataLoading] = useState(true);
    const [initialInput, setInitialInput] = useState(true);

    const [errors, setErrors] = useState<FormErrors>({});

    const validatePersonalInfo = usePersonalInfoValidation({ fullName, birthday });
    const validatePassport = usePassportValidation({ passport });
    const validateCompanyInfo = useCompanyInfoValidation({ companyInfo })

    useEffect(() => {
        (async () => {
            try {
                const manager = await createBorrowerCompanyManager();
                const result = await manager.getUserCompany(profileData.uuid!);
                if (result !== null) {
                    setFullName(result.representative.fullName);
                    setBirthday(new Date(result.representative.birthday));
                    setPassport(result.representative.passport);
                    setCompanyInfo(result.data);
                }
            } catch (error: any) {
                await handleError(error);
            } finally {
                setDataLoading(false);
            }
        })();
    }, [profileData]);

    const validateForm = (): boolean => {
        const newErrors: FormErrors = {};
        validatePersonalInfo(newErrors);
        validatePassport(newErrors);
        validateCompanyInfo(newErrors);
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    useEffectOnUpdate(() => {
        setInitialInput(false);
        validateForm();
    }, [representative, companyInfo]);

    const handleSubmit = async (event: SyntheticEvent) => {
        event.preventDefault();
        if (validateForm()) {
            setLoading(true);
            try {
                const manager = await createBorrowerCompanyManager();
                const result = await manager.create(companyInfo, representative);
                setRequest({
                    ...request,
                    uuid: request!.uuid,
                    projectStatus: request!.projectStatus,
                    financialStatements: request!.financialStatements,
                    confirmed: request!.confirmed,
                    companyUuid: result.uuid
                });
                setStep(FundingRequestStep.ChooseLoanOption);
            } catch (error: any) {
                await handleError(error);
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <div className="funding-request-identification">
            <PrimaryHeading>
                <Heading headingType={HeadingType.H1} className="funding-request__heading funding-request-identification__heading">
                    { t("funding_request.survey") }
                </Heading>
            </PrimaryHeading>
            <div className="funding-request__text">
                { t("funding_request.survey.fill") }
            </div>
            { dataLoading ? <LoaderContainer>
                <Loader />
            </LoaderContainer> : <form className="funding-request-identification__form" onSubmit={handleSubmit}>
                <div className="funding-request-identification__block">
                    <div className="funding-request-identification__subheader">
                        { t("funding_request.survey.personal_data") }
                    </div>
                    <div>
                        <CommonInput
                            value={fullName.lastName}
                            onChange={(value: string) => setFullName({...fullName, lastName: value})}
                            text={t("common.surname")}
                            error={errors.lastName !== undefined}
                        />
                        { errors.lastName && <InputErrors errors={errors.lastName} /> }
                    </div>
                    <div>
                        <CommonInput
                            value={fullName.firstName}
                            onChange={(value: string) => setFullName({...fullName, firstName: value})}
                            text={t("common.name")}
                            error={errors.firstName !== undefined}
                        />
                        { errors.firstName && <InputErrors errors={errors.firstName} /> }
                    </div>
                    <div>
                        <CommonInput
                            value={fullName.middleName}
                            onChange={(value: string) => setFullName({...fullName, middleName: value})}
                            text={t("common.parent_name")}
                            error={errors.lastName !== undefined}
                        />
                        { errors.lastName && <InputErrors errors={errors.lastName} /> }
                    </div>
                    <div>
                        <DatePicker
                            value={birthday}
                            onSelected={setBirthday}
                            text={t("common.birthday")}
                        />
                    </div>
                </div>
                <IdentificationPassport
                    passport={passport}
                    setPassport={setPassport}
                    errors={errors}
                />
                <IntroductionCompany
                    needLoading={!dataLoading && companyInfo.registrationNumber === ""}
                    companyInfo={companyInfo}
                    setCompanyInfo={setCompanyInfo}
                    errors={errors}
                />
                <IdentificationPassportFiles
                    photos={passportPhotos}
                    setPhotos={setPassportPhotos}
                    errors={errors}
                />
                <div className="funding-request-identification__rating">
                    <div>
                        <div className="funding-request-identification__subheader">
                            { t("funding_request.survey.mark") }
                        </div>
                        <div className="funding-request__text">
                            { t("funding_request.survey.mail_for_reviews") }&nbsp;
                            <a href="#" className="funding-request-identification__link">sample@gmail.com</a>
                        </div>
                    </div>
                    <div className="funding-request-identification__list">
                        <div
                            className={cn(
                                "funding-request-identification__element",
                                resultIf(companyInfo.reputationRating === CompanyReputationRating.Positive, "active")
                            )}
                            onClick={() => setCompanyInfo({ ...companyInfo, reputationRating: CompanyReputationRating.Positive })}
                        >
                            { t("funding_request.survey.reputation.positive") }
                        </div>
                        <div
                            className={cn(
                                "funding-request-identification__element",
                                resultIf(companyInfo.reputationRating === CompanyReputationRating.Negative, "active")
                            )}
                            onClick={() => setCompanyInfo({ ...companyInfo, reputationRating: CompanyReputationRating.Negative })}
                        >
                            { t("funding_request.survey.reputation.negative") }
                        </div>
                        <div
                            className={cn(
                                "funding-request-identification__element",
                                resultIf(companyInfo.reputationRating === CompanyReputationRating.NoRating, "active")
                            )}
                            onClick={() => setCompanyInfo({ ...companyInfo, reputationRating: CompanyReputationRating.NoRating })}
                        >
                            { t("funding_request.survey.reputation.no_reputation") }
                        </div>
                    </div>
                    <div className="funding-request-identification__checkboxes">
                        <Checkbox checked={noCrime} onChange={toggleNoCrime}>
                            { t("funding_request.survey.reputation.no_crime") }
                        </Checkbox>
                        <Checkbox checked={noNegative} onChange={toggleNoNegative}>
                            { t("funding_request.survey.reputation.no_negative") }
                        </Checkbox>
                    </div>
                </div>
                <div className="funding-request-identification__source">
                    <div className="funding-request-identification__subheader">
                        { t("funding_request.survey.reputation.money_source") }
                    </div>
                    <div className="funding-request-identification__list">
                        <div
                            className={cn(
                                "funding-request-identification__element",
                                resultIf(companyInfo.moneySource === CompanyMoneySource.Economic, "active")
                            )}
                            onClick={() => setCompanyInfo({ ...companyInfo, moneySource: CompanyMoneySource.Economic })}
                        >
                            { t("funding_request.survey.reputation.money_source.economic") }
                        </div>
                        <div
                            className={cn(
                                "funding-request-identification__element",
                                resultIf(companyInfo.moneySource === CompanyMoneySource.Borrower, "active")
                            )}
                            onClick={() => setCompanyInfo({ ...companyInfo, moneySource: CompanyMoneySource.Borrower })}
                        >
                            { t("funding_request.survey.reputation.money_source.borrowed") }
                        </div>
                        <div
                            className={cn(
                                "funding-request-identification__element",
                                resultIf(companyInfo.moneySource === CompanyMoneySource.Other, "active")
                            )}
                            onClick={() => setCompanyInfo({ ...companyInfo, moneySource: CompanyMoneySource.Other })}
                        >
                            { t("funding_request.survey.reputation.money_source.other") }
                        </div>
                    </div>
                </div>
                <div className="funding-request-identification__approve">
                    <div className="funding-request-identification__subheader">
                        { t("funding_request.survey.reputation.confirm") }
                    </div>
                    <Checkbox checked={personalDataAgree} onChange={togglePersonalDataAgree}>
                        { t("funding_request.survey.reputation.confirmation.first") }&nbsp;
                        <a href="#" className="funding-request-identification__link">
                            { t("funding_request.survey.reputation.confirmation.second") }
                        </a>
                    </Checkbox>
                </div>
                { loading && <div>
                    { t("common.loading") }
                </div> }
                <div>
                    <PrimaryButton
                        expanded
                        color={initialInput || request?.companyUuid !== undefined
                            ? PrimaryButtonColor.GRAY
                            : PrimaryButtonColor.BLUE}
                    >
                        <Button type="submit" disabled={initialInput || request?.companyUuid !== undefined}>
                            { t("funding_request.survey.send") }
                        </Button>
                    </PrimaryButton>
                </div>
            </form> }
        </div>
    );
}

export default IntroductionIdentification;