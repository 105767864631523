import {Translations} from "../../types";

export const selling_confirmation: Translations = {
    "selling_confirmation": "Sell loan ID :id",
    "selling_confirmation.commission": "Sales commission - :amount% of the loan amount.",
    "selling_confirmation.paid": "Paid",
    "selling_confirmation.amount": "Amount",
    "selling_confirmation.policy_agree": "I agree with ",
    "selling_confirmation.policy_agree_link": "loan sale policy",
    "selling_confirmation.investment.sum": "Investment amount",
    "selling_confirmation.investment.rate": "Rate",
    "selling_confirmation.investment.income": "Income",
    "selling_confirmation.investment.term": "Term",
    "selling_confirmation.investment.earn_without_commission": "Without a commission, you will receive",
    "selling_confirmation.sell": "Put up for sale",
}