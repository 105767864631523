import {Translations} from "../../types";

export const auth: Translations = {
    "auth.login": "Login",
    "auth.login.enter": "Enter",
    "auth.login.forgot_password": "Forgot password?",
    "auth.create_account": "Create new account",
    "auth.register": "Register",
    "auth.register.have_account": "I already have an account",
    "auth.register_lender": "Register new lender",
    "auth.register.password": "Password (:length or more letters)",
    "auth.register.confirm_password": "Confirm password",
    "auth.register_lender.information_exchange_agree": "I agree to information exchange",
    "auth.register_lender.platform_interaction_rules": "I agree to platform interaction rules",
    "auth.register_borrower": "Register new borrower",
    "auth.register_borrower.as": "Register as",
    "auth.register_borrower.data": "Account data",
    "auth.register_borrower.agree_with": "Agree with",
    "auth.register_borrower.platform_rules": "platform rules",
    "auth.register_borrower.register_as": "register as a general director of a company or an individual entrepreneur",
    "auth.register_borrower.familiar_with": "Familiar with",
    "auth.register_borrower.processing_policy": "policy of processing personal data",
}