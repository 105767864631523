import {Translations} from "../../types";

export const navigation: Translations = {
    "navigation.new_investments": "Новые займы",
    "navigation.my_investments": "Мои инвестиции",
    "navigation.analytics": "Аналитика",
    "navigation.finances": "Финансы",
    "navigation.profile": "Профиль",
    "navigation.my_loans": "Мои займы",
    "navigation.history": "История",
    "navigation.contact_us": "Связаться с нами",
    "navigation.logout": "Выйти"
}