const months = [
    "date.january",
    "date.february",
    "date.march",
    "date.april",
    "date.may",
    "date.june",
    "date.july",
    "date.august",
    "date.september",
    "date.october",
    "date.november",
    "date.december"
];

export const getMonthTranslation = (date: Date): string => {
    return months[new Date(date).getMonth()];
}