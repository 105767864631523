import React, {AllHTMLAttributes, FC, PropsWithChildren} from "react";
import {cn} from "../../../../../utils";
import {DatatableRow, DatatableText} from "./extensions";
import './DetailedProjectDatatable.scoped.scss';

interface Props extends AllHTMLAttributes<HTMLDivElement> {}

interface Extensions {
    Row: typeof DatatableRow;
    Text: typeof DatatableText
}

const DetailedProjectDatatable: FC<PropsWithChildren<Props>> & Extensions = ({ children, ...props }) => {
    return (
        <div {...props} className={cn("detailed-project-datatable", props.className)}>
            { children }
        </div>
    );
}

DetailedProjectDatatable.Row = DatatableRow;
DetailedProjectDatatable.Text = DatatableText;

export default DetailedProjectDatatable;