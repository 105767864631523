import React from "react";
import {defaultSvgColor} from "../SvgProps";
import {SvgComponent} from "../SvgComponent";
import SvgContainer from "../SvgContainer";

const AnalyticsSvg: SvgComponent = ({ color = defaultSvgColor }) => {
    return (
        <SvgContainer>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.38688 0.167081C7.38688 0.0686501 7.30255 -0.00903491 7.20461 0.000854208C3.15826 0.409448 0 3.82588 0 7.97996C0 12.4093 3.59069 16 8.02004 16C10.246 16 12.2603 15.0928 13.7129 13.629C13.7823 13.5591 13.7747 13.4449 13.698 13.383L7.62205 8.4724C7.47331 8.35219 7.38688 8.1712 7.38688 7.97996V0.167081Z" fill={color} />
                <path d="M14.4947 12.3986C14.5713 12.4605 14.6845 12.4441 14.7383 12.3617C15.4213 11.3165 15.8672 10.1018 15.9991 8.79499C16.009 8.69705 15.9313 8.61312 15.8329 8.61312H10.2883C10.1291 8.61312 10.0584 8.81322 10.1822 8.91328L14.4947 12.3986Z" fill={color} />
                <path d="M15.8329 7.34679C15.9313 7.34679 16.009 7.2625 15.9991 7.16457C15.6176 3.38631 12.6137 0.382351 8.83542 0.00084984C8.73749 -0.00903874 8.6532 0.0686503 8.6532 0.167081V7.09353C8.6532 7.2334 8.76659 7.34679 8.90646 7.34679H15.8329Z" fill={color} />
            </svg>
        </SvgContainer>
    );
}

export default AnalyticsSvg;