import React, {FC, PropsWithChildren} from "react";
import styles from "./RegisterContainer.module.scss";

const RegisterContainer: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className={styles.register_container}>
            { children }
        </div>
    );
}

export default RegisterContainer;