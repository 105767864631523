import React, {lazy} from "react";
import {Outlet, Route, Routes} from "react-router-dom";
import MyActiveProjects from "../../../pages/my-projects/active/MyActiveProjects";
import MyFundraisingProjects from "../../../pages/my-projects/fundraising/MyFundraisingProjects";
import MyRefundedProjects from "../../../pages/my-projects/refunded/MyRefundedProjects";
import MyModeratedProjects from "../../../pages/my-projects/moderated/MyModeratedProjects";
import Loadable from "../../../loading/Loadable";
import MyProject from "../../../../views/borrower/statistics/MyProject";

const LazyMyLoans = Loadable(lazy(() => import("../../../../views/borrower/statistics/MyLoans")));

const MyLoansRoutes = () => {
    return (
        <>
            <Routes>
                <Route element={<LazyMyLoans />}>
                    <Route index element={<MyActiveProjects />} />
                    <Route path="active" element={<MyActiveProjects />} />
                    <Route path="fundraising" element={<MyFundraisingProjects />} />
                    <Route path="refunded" element={<MyRefundedProjects />} />
                    <Route path="moderated" element={<MyModeratedProjects />} />
                </Route>
                <Route path=":uuid" element={<MyProject />} />
            </Routes>
            <Outlet />
        </>
    );
}

export default MyLoansRoutes;