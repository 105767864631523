import {ApiClientInterface} from "./clients";
import {
    DesiredLoan,
    FundingRequest,
    UuidResult,
    FundingRequestConfirmation,
    PaymentSchedule
} from "./models";

export class ProjectRequestManager {
    constructor(private readonly apiClient: ApiClientInterface, private readonly signal?: AbortSignal) {}

    public async getFundingRequest(uuid: string): Promise<FundingRequest | null> {
        return this.apiClient.get(`/${uuid}`);
    }

    public async getUsersFirst(): Promise<FundingRequest | null> {
        return this.apiClient.get("/first");
    }

    public async create(statements: Array<string>): Promise<UuidResult> {
        return this.apiClient.post("", {statements});
    }

    public async setLoan(
        uuid: string,
        desiredLoan: DesiredLoan
    ): Promise<void> {
        return this.apiClient.post(`/${uuid}/loan`, {desiredLoan});
    }

    public async getPreliminaryPaymentSchedule(
        uuid: string,
        desiredLoan: DesiredLoan
    ): Promise<PaymentSchedule> {
        return this.apiClient.post(`/${uuid}/schedule`, {uuid, desiredLoan}, this.signal);
    }

    public async setDocuments(uuid: string, documents: Array<string>): Promise<void> {
        return this.apiClient.post(`/${uuid}/documents`, {documents});
    }

    public async request(uuid: string): Promise<FundingRequestConfirmation> {
        return this.apiClient.post(`/${uuid}/request`);
    }

    public async confirm(uuid: string, code: string): Promise<void> {
        return this.apiClient.post(`/${uuid}/confirm`, {code});
    }
}