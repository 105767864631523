import React, {FC, PropsWithChildren, useEffect, useState} from "react";
import LenderNavigation from "../../components/layout/lender/LenderNavigation";
import {useNavigateByName, useProfileData} from "../../hooks";
import {RouteDictionary} from "../../utils";
import Container from "../../components/layout/common/Container";
import Content from "../../components/layout/common/Content";
import Header from "../../components/layout/common/Header";
import MainGrid from "../../components/layout/common/MainGrid";
import Main from "../../components/layout/common/Main";
import {Loader} from "../../components/common";

const LayoutLenderCabinet: FC<PropsWithChildren> = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const profileData = useProfileData();
    const navigate = useNavigateByName();
    useEffect(() => {
        if (!profileData.lenderVerified) {
            navigate(RouteDictionary.IDENTIFICATION);
            return;
        }
        setLoading(false);
        return;
    }, []);
    return (
        <>
            { loading ? <Loader /> : <Container>
                <Content>
                    <Header />
                    <MainGrid>
                        <LenderNavigation />
                        <Main>
                            { children }
                        </Main>
                    </MainGrid>
                </Content>
            </Container> }
        </>
    );
}

export default LayoutLenderCabinet;