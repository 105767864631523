import React, {FC, ReactNode} from "react";
import {CommonSubText, Money} from "../../common";
import ProgressBar from "../ProgressBar";
import {Money as MoneyModel} from "../../../api-client";
import styles from "./MoneyProgress.module.scss";
import {cn} from "../../../utils";

interface MilePoint {
    name: ReactNode;
    money: MoneyModel;
}

interface Props {
    firstPoint: MilePoint;
    secondPoint: MilePoint;
    showPercent?: boolean;
}

const MoneyProgress: FC<Props> = ({ firstPoint, secondPoint , showPercent= true}) => {
    const filledPercent = (): number => {
        return Math.floor((firstPoint.money.amount / secondPoint.money.amount * 100) * 100) / 100;
    }
    return (
        <div className={styles.money_progress}>
            <div className={styles.money_progress__top}>
                <div className={styles.money_progress__container}>
                    <CommonSubText small>
                        {firstPoint.name}{ showPercent && ` (${filledPercent()} %)` }
                    </CommonSubText>
                    <div className={styles.money_progress__money}>
                        <Money money={firstPoint.money}/>
                    </div>
                </div>
                <div className={cn(styles.money_progress__right, styles.money_progress__container)}>
                    <CommonSubText small>
                        {secondPoint.name}
                    </CommonSubText>
                    <div className={styles.money_progress__money}>
                        <Money money={secondPoint.money} />
                    </div>
                </div>
            </div>
            <div className={styles.money_progress__progress}>
                <ProgressBar filled={filledPercent()} />
            </div>
        </div>
    );
}

export default MoneyProgress;