import React, {createContext, PropsWithChildren} from "react";

export type ModalContextType = {
    onClose: () => void;
}

type Props = {
    props: ModalContextType;
}

export const ModalContext = createContext<ModalContextType>({
    onClose: () => {}
});

function ModalContextProvider(props: PropsWithChildren<Props>) {
    const { children, ...value } = props;
    return <ModalContext.Provider value={value.props}>
        { children }
    </ModalContext.Provider>
}

export default ModalContextProvider;