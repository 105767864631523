import React, {FC, useMemo} from "react";
import {PaymentScheduleType} from "../../../../api-client";
import {Money} from "../../../common";
import {Money as MoneyModel} from "../../../../api-client/models/common";
import {countMoneyPercent} from "../../../../utils";
import {useTranslator} from "../../../../hooks";
import {resolveMonthTranslation} from "../../../../i18n";

interface Props {
    sum: MoneyModel;
    initialTerm: string | number;
    scheduleType?: PaymentScheduleType;
}

const requestScheduleTypeTranslations: Record<PaymentScheduleType, string> = {
    [PaymentScheduleType.MONTHLY_PAYMENTS]: "funding_request.loan_options.terms.term.monthly",
    [PaymentScheduleType.MONTHLY_TWO_PAYMENTS]: "funding_request.loan_options.terms.term.twice_a_month",
    [PaymentScheduleType.MONTHLY_THREE_PAYMENTS]: "funding_request.loan_options.terms.term.thrice_a_month",
    [PaymentScheduleType.MONTHLY_FOUR_PAYMENTS]: "funding_request.loan_options.terms.term.four_times_a_month",
    [PaymentScheduleType.QUARTERLY_PAYMENTS]: "funding_request.loan_options.terms.term.quarterly",
    [PaymentScheduleType.PAYMENT_AT_THE_END]: "funding_request.loan_options.terms.term.at_the_end",
}

const FundingRequestLoanConditions: FC<Props> = ({ sum, initialTerm, scheduleType }) => {
    const t = useTranslator();
    const commission = useMemo((): MoneyModel => {
        return countMoneyPercent(sum, 4);
    }, [sum]);

    const finalSum = useMemo(() => {
        return new MoneyModel(sum.amount - commission.amount, sum.currencyCode);
    }, [sum, commission]);
    return (
        <div>
            <div className="funding-request-options__subheader">
                { t("funding_request.loan_options.terms") }
            </div>
            <ul className="funding-request__list">
                <li className="funding-request__element">
                    <div className="funding-request-options__condition">
                        <div className="funding-request-options__text">
                            { t("funding_request.loan_options.terms.sum") }
                        </div>
                        <Money money={sum} />
                    </div>
                </li>
                <li className="funding-request__element">
                    <div className="funding-request-options__condition">
                        <div className="funding-request-options__text">
                            { t("funding_request.loan_options.terms.commission", { percent: "4" }) }
                        </div>
                        <div className="funding-request-options__value">
                            <Money money={commission} />
                        </div>
                    </div>
                </li>
                <li className="funding-request__element">
                    <div className="funding-request-options__condition">
                        <div className="funding-request-options__text">
                            { t("funding_request.loan_options.terms.final_sum") }
                        </div>
                        <div className="funding-request-options__value">
                            <Money money={finalSum} />
                        </div>
                    </div>
                </li>
                <li className="funding-request__element">
                    <div className="funding-request-options__condition">
                        <div className="funding-request-options__text">
                            { t("funding_request.loan_options.terms.schedule") }
                        </div>
                        <div className="funding-request-options__value">
                            { scheduleType !== undefined
                                ? t(requestScheduleTypeTranslations[scheduleType])
                                : t("funding_request.loan_options.terms.term.not_listed") }
                        </div>
                    </div>
                </li>
                <li className="funding-request__element">
                    <div className="funding-request-options__condition">
                        <div className="funding-request-options__text">
                            { t("funding_request.loan_options.terms.term") }
                        </div>
                        <div className="funding-request-options__value">
                            { initialTerm } { t(resolveMonthTranslation(+initialTerm)) }
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default FundingRequestLoanConditions;