import React, {FC, PropsWithChildren} from "react";
import './LayoutBorrowerIntroduction.scoped.scss';

const LayoutBorrowerIntroduction: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="layout-borrower-introduction">
            { children }
        </div>
    );
}

export default LayoutBorrowerIntroduction;