import React, {FC} from "react";
import './RoundProgressBar.scoped.scss';

interface Props {
    size?: number;
    strokeWidth?: number;
    percentage: number;
}

const RoundProgressBar: FC<Props> = ({ size = 106, strokeWidth = 8, percentage }) => {
    const viewBox = `0 0 ${size} ${size}`;
    const radius = (size - strokeWidth) / 2;
    const dashArray = Math.PI * 2 * radius;
    const dashOffset = dashArray - dashArray * percentage / 100;
    return (
        <svg width={size} height={size} viewBox={viewBox} className="round-progress-bar">
            <circle
                className="round-progress-bar__background"
                cx={size / 2}
                cy={size / 2}
                r={radius}
                strokeWidth={`${strokeWidth}px`}
            />
            <circle
                className="round-progress-bar__progress"
                cx={size / 2}
                cy={size / 2}
                r={radius}
                strokeWidth={`${strokeWidth}px`}
                transform={`rotate(-90 ${size / 2} ${size / 2})`}
                style={{
                    strokeDasharray: dashArray,
                    strokeDashoffset: dashOffset
                }}
            />
            <text
                className="round-progress-bar__text"
                x="50%"
                y="50%"
                dy=".3em"
                textAnchor="middle"
                fill="#004aff"
            >
                {`${percentage}%`}
            </text>
        </svg>
    );
}

export default RoundProgressBar;