import React, {FC, PropsWithChildren} from "react";
import styles from "./DetailedProjectInvestBlocks.module.scss";

const DetailedProjectInvestBlocks: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className={styles.detailed_project_invest_blocks}>
            { children }
        </div>
    );
}

export default DetailedProjectInvestBlocks;