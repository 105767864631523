import React, {FC, PropsWithChildren, ReactNode} from "react";
import "./CommonAsideLink.scoped.scss";
import {RouteDictionary} from "../../../../../../utils";
import {CommonAsideElement} from "../index";
import {ProjectLink} from "../../../../../common";
import {ProjectLinkProps} from "../../../../../common/links/ProjectLink/ProjectLink";

interface Props extends ProjectLinkProps {
    icon: ReactNode;
}

const CommonAsideLink: FC<PropsWithChildren<Props>> = ({ to, icon, children, ...props}) => {
    return (
        <CommonAsideElement>
            <ProjectLink {...props} to={to} className="common-aside-link">
                <div className="common-aside-link__inner">
                    <div className="common-aside-link__icon">
                        { icon }
                    </div>
                    <span>
                    { children }
                </span>
                </div>
            </ProjectLink>
        </CommonAsideElement>
    );
}

export default CommonAsideLink;