export enum FileSizeType {
    Kilobytes = "Кб",
    Megabytes = "Мб",
}

type FileSizeResponse = {
    size: number | string,
    type: FileSizeType
}

export const formatFileSize = (bytes: number): FileSizeResponse => {
    const kilo = bytes / 1024;
    const mega = kilo / 1024;
    if (mega * 10 < 1) {
        return { size: kilo.toFixed(2), type: FileSizeType.Kilobytes };
    }
    return { size: mega.toFixed(2), type: FileSizeType.Megabytes };
}