import React, {FC} from "react";
import {MyFundraisingProject as MyFundraisingProjectModel} from "../../../../../api-client";
import styles from "./MyFundraisingProject.module.scss";
import MyProject from "../../MyProject";
import {CommonSubText, CommonText, Money, ProjectLink} from "../../../../common";
import {createMoneyFactory} from "../../../../../di";
import {RouteDictionary} from "../../../../../utils";
import {formatDate} from "../../../../../formatters";
import {loansScheduleTypeTranslations} from "../../../../../views/borrower/statistics/MyLoans/MyLoans";
import {useTranslator} from "../../../../../hooks";

interface Props {
    project: MyFundraisingProjectModel;
}

const moneyFactory = createMoneyFactory();

const MyFundraisingProject: FC<Props> = ({ project }) => {
    const t = useTranslator();
    return (
        <MyProject small className={styles.my_fundraising_project}>
            <ProjectLink to={RouteDictionary.MY_PROJECT} params={{uuid: project.uuid}}>
                <MyProject.Column className={styles.my_fundraising_project__id}>
                    <CommonText>
                        { project.paymentCode ?? t("my_loans.not_listed") }
                    </CommonText>
                </MyProject.Column>
                <MyProject.Column className={styles.my_fundraising_project__target}>
                    <CommonText>
                        <Money money={project.targetSum} />
                    </CommonText>
                </MyProject.Column>
                <MyProject.Column className={styles.my_fundraising_project__collected}>
                    { project.collectedSum !== undefined ? <>
                        <CommonText>
                            <Money money={project.collectedSum} />
                        </CommonText>
                        <CommonSubText small>
                            { (project.collectedSum.amount / project.targetSum.amount) * 100 }%
                        </CommonSubText>
                    </> : <CommonText>
                        <Money money={moneyFactory.createMoney()} />
                    </CommonText> }
                </MyProject.Column>
                <MyProject.Column className={styles.my_fundraising_project__rate}>
                    <CommonText>
                        { project.interestRate }%
                    </CommonText>
                </MyProject.Column>
                <MyProject.Column className={styles.my_fundraising_project__date}>
                    <CommonText>
                        { formatDate(project.createdAt) }
                    </CommonText>
                </MyProject.Column>
                <MyProject.Column className={styles.my_fundraising_project__schedule}>
                    <CommonText>
                        { project.scheduleType ? t(loansScheduleTypeTranslations[project.scheduleType]) : t("my_loans.not_listed") }
                    </CommonText>
                </MyProject.Column>
            </ProjectLink>
        </MyProject>
    );
}

export default MyFundraisingProject;