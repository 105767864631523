import React, {FC} from "react";
import {SvgProps} from "../SvgProps";
import {defaultStatSvgColor} from "./defaultStatSvgColor";
import SvgContainer from "../SvgContainer";
import {SvgContainerSize} from "../SvgContainer/SvgContainer";

const CurrencySvg: FC<SvgProps> = ({ color = defaultStatSvgColor }) => {
    return (
        <SvgContainer size={SvgContainerSize.Small}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.55556 13L6.55556 10.5M6.55556 8L6.55556 3.01087C6.55556 3.00487 6.56042
                    3 6.56642 3L9.5 3C10.8807 3 12 4.11929 12 5.5V5.5C12 6.88071 10.8807 8 9.5
                    8L6.55556 8ZM6.55556 8L6.55556 10.5M6.55556 10.5L9.66667 10.5L5 10.5"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    stroke={color}
                />
            </svg>
        </SvgContainer>
    );
}

export default CurrencySvg;