import React, {FC, PropsWithChildren} from "react";
import './MainGrid.scoped.scss';
import {cn, resultIf} from "../../../../utils";

interface Props {
    subpage?: boolean;
}

const MainGrid: FC<PropsWithChildren<Props>> = ({ children, subpage = false }) => {
    return (
        <div className={cn("main-grid", resultIf(subpage, "main-grid--subpage"))}>
            { children }
        </div>
    )
}

export default MainGrid;