import React from "react";
import MyProjectStatus from "../MyProjectStatus";
import ClockSvg from "../../../../../svg/stats/ClockSvg";
import {ColorDictionary, getCssVariable} from "../../../../../../utils";
import {CommonText} from "../../../../../common";
import {useTranslator} from "../../../../../../hooks";

const MyProjectFinalReviewStatus = () => {
    const t = useTranslator();
    return (
        <MyProjectStatus>
            <ClockSvg color={getCssVariable(ColorDictionary.ADDITIONAL_ORANGE)} />
            <CommonText>
                { t("my_detailed_project.status.final_review") }
            </CommonText>
        </MyProjectStatus>
    );
}

export default MyProjectFinalReviewStatus;