import React, {useContext} from "react";
import BarChart from "../../../../../chart/BarChart";
import {AnalyticsUserContext} from "../../AnalyticsUserContext";
import moment from "moment";
import AnalyticsLoanHeader from "../../../rows/loan/AnalyticsLoanHeader";
import AnalyticsLoan from "../../../rows/loan/AnalyticsLoan";
import AnalyticsRows from "../../../rows/AnalyticsRows";
import {useTranslator} from "../../../../../../hooks";
import AnalyticsTable from "../../../AnalyticsTable";

const AnalyticsUserVolume = () => {
    const t = useTranslator();
    const loans = useContext(AnalyticsUserContext).analytics?.loans;
    if (loans === undefined) {
        return <div>Analytics is undefined (somehow)</div>
    }

    const chartData = loans.map((entry) => {
        return entry.totalSum.amount / 100;
    });

    const chartHeadings = loans.map((entry) => {
        const momentDate = moment(entry.date);
        return [momentDate.format("MMM"), momentDate.format("YYYY")];
    });

    const renderRows = () => {
        return loans.map((loan, index) => {
            return <AnalyticsLoan loan={loan} key={index} />
        })
    }

    return (
        <AnalyticsRows>
            <div className="user-analytics__chart">
                <BarChart
                    data={chartData}
                    labels={chartHeadings}
                    stepSize={10000000}
                    ticksFunc={(tickValue) => `${Math.floor(+tickValue / 1000000)} ${t("common.chart_mln")}`}
                />
            </div>
            <AnalyticsTable>
                <AnalyticsLoanHeader />
                <ul>
                    { renderRows() }
                </ul>
            </AnalyticsTable>
        </AnalyticsRows>
    );
}

export default AnalyticsUserVolume;