import React from "react";
import {SvgComponent} from "./SvgComponent";
import {defaultSvgColor} from "./SvgProps";
import SvgContainer from "./SvgContainer";

const ChatSvg: SvgComponent = ({ color = defaultSvgColor }) => {
    return (
        <SvgContainer>
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.82333 11.2099C-0.357547 5.85685 3.58059 0 9.36086 0H9.68229C13.9999 0 17.4999 3.50008 17.4999 7.81765C17.4999 12.6404 13.5903 16.55 8.76757 16.55H0.947294C0.735122 16.55 0.546054 16.4161 0.475635 16.2159C0.405217 16.0158 0.468784 15.793 0.634212 15.6602L2.6057 14.0769C2.77811 13.9384 2.8391 13.7032 2.75567 13.4984L1.82333 11.2099ZM5.24994 9.5C5.94029 9.5 6.49994 8.94036 6.49994 8.25C6.49994 7.55964 5.94029 7 5.24994 7C4.55958 7 3.99994 7.55964 3.99994 8.25C3.99994 8.94036 4.55958 9.5 5.24994 9.5ZM10.4999 8.25C10.4999 8.94036 9.9403 9.5 9.24994 9.5C8.55958 9.5 7.99994 8.94036 7.99994 8.25C7.99994 7.55964 8.55958 7 9.24994 7C9.9403 7 10.4999 7.55964 10.4999 8.25ZM13.2499 9.5C13.9403 9.5 14.4999 8.94036 14.4999 8.25C14.4999 7.55964 13.9403 7 13.2499 7C12.5596 7 11.9999 7.55964 11.9999 8.25C11.9999 8.94036 12.5596 9.5 13.2499 9.5Z"
                    fill={color}
                />
            </svg>
        </SvgContainer>
    );
}

export default ChatSvg;