export default {
    get publicBaseUrl(): string {
        let url =  process.env.REACT_APP_PUBLIC_BASE_URL || ""
        if (!url) {
            url = window.location.protocol + "//" + window.location.host.replace(/^(\w+\.)/, '');
        }
        return url;
    },
    get apiBaseUrl(): string {
        return process.env.REACT_APP_API_BASE_URL || "";
    },
}