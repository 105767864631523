import {Translations} from "../../types";

export const identification: Translations = {
    "identification": "Application for investor status",
    "identification.disclaimer": "Fill out the form to complete registration.",
    "identification.personal_data": "Personal data",
    "identification.request": "Your request",
    "identification.status": "Request status",
    "identification.your_status": "Your request status",
    "identification.phone_number": "Phone number",
    "identification.phone_confirmed": "Phone confirmed",
    "identification.passport_data": "Passport data",
    "identification.passport_files": "Identification",
    "identification.passport_files.disclaimer": "Upload the specified photos or scans of documents that will be required for identification. " +
        "The recommended resolution is at least 1200×800px up to 10 mb.",
    "identification.confirmation": "Confirmation of entered data",
    "identification.agree": "I agree",
    "identification.promotion_conditions": "with conditions to promote investment",
    "identification.data_policy": "with personal data processing policy",
    "identification.send": "Send request",
    "identification.draft": "You request is being checked by the administrator...",
}