import React, {FC} from "react";
import {Payment} from "../../../api-client";
import RejectedSvg from "../../svg/status/RejectedSvg";
import ApprovedSvg from "../../svg/status/ApprovedSvg";
import PendingSvg from "../../svg/status/PendingSvg";

interface Props {
    payment: Payment;
}

const PaymentStatus: FC<Props> = ({ payment }) => {
    if (payment.overdue) {
        return <RejectedSvg />
    }
    if (payment.paid) {
        return <ApprovedSvg />
    }
    return <PendingSvg />
}

export default PaymentStatus;