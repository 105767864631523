import {NamedUrl} from "./types";
import {lenderRoutes} from "./routes/lenderRoutes";
import {authRoutes} from "./routes/authRoutes";
import {borrowerRoutes} from "./routes/borrowerRoutes";
import {userRoutes} from "./routes/userRoutes";

export class RouteProvider {
    getUrls(): Array<NamedUrl> {
        return [
            ...this.withPrefix("auth", [...authRoutes]),
            ...this.withPrefix("lender", [...lenderRoutes]),
            ...this.withPrefix("borrower", [...borrowerRoutes]),
            ...userRoutes,
        ];
    }

    private withPrefix(prefix: string, urls: Array<NamedUrl>): Array<NamedUrl> {
        return urls.map((url) => {
            return { ...url, url: `/${prefix}${url.url}` }
        });
    }
}