import React, {useContext} from "react";
import {MyProjectContext} from "../MyProjectContext";
import MyModeratedProjectSchedule from "./MyModeratedProjectSchedule";
import {
    DetailedMyActiveProject,
    DetailedMyFundraisingProject,
    DetailedMyModeratedProject
} from "../../../../../api-client";
import MyActiveProjectSchedule from "./MyActiveProjectSchedule";
import MyFundraisingProjectSchedule from "./MyFundraisingProjectSchedule";
import {useTranslator} from "../../../../../hooks";


const MyProjectScheduleResolver = () => {
    const t = useTranslator();
    const project = useContext(MyProjectContext).project;
    if (project.moderated !== undefined) {
        return <MyModeratedProjectSchedule project={project as DetailedMyModeratedProject} />
    }
    if (project.active !== undefined) {
        return <MyActiveProjectSchedule project={project as DetailedMyActiveProject} />
    }
    if (project.fundraising !== undefined) {
        return <MyFundraisingProjectSchedule project={project as DetailedMyFundraisingProject} />
    }
    return (
        <div>
            { t("my_detailed_project.schedule.unexpected") }
        </div>
    );
}

export default MyProjectScheduleResolver;