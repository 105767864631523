import React, {Children, FC, PropsWithChildren} from "react";

interface Props {
    step: number;
}

const MultiStepForm: FC<PropsWithChildren<Props>> = ({ step, children }) => {
    const childrenArray = Children.toArray(children);

    const childToRender = () => {
        return childrenArray[step];
    }

    return (
        <>
            { childToRender() }
        </>
    );
}

export default MultiStepForm;