import React, {useContext} from "react";
import './DetailedProjectReputation.scoped.scss';
import {
    DetailedProjectSubBlocks,
    DetailedProjectSubPage
} from "../../index";
import {DetailedProjectContext} from "../../DetailedProjectContext";
import DetailedProjectPlaceholder from "../../common/DetailedProjectPlaceholder";
import DetailedProjectCreditHistory from "../DetailedProjectCreditHistory";
import DetailedProjectLoanPortfolio from "../DetailedProjectLoanPortfolio";
import DetailedProjectArbitrations from "../DetailedProjectArbitrations";
import DetailedProjectProceedingHistory from "../DetailedProjectProceedingHistory";

const DetailedProjectReputation = () => {
    const { reputation } = useContext(DetailedProjectContext).project;
    if (reputation === undefined) {
        return <DetailedProjectPlaceholder />
    }
    return (
        <DetailedProjectSubPage>
            <DetailedProjectSubBlocks>
                <DetailedProjectCreditHistory creditHistory={reputation.creditHistory} />
                <DetailedProjectLoanPortfolio loanPortfolio={reputation.loanPortfolio} />
                <DetailedProjectArbitrations arbitrations={reputation.arbitrations} />
                <DetailedProjectProceedingHistory proceedingHistory={reputation.proceedingHistory} />
            </DetailedProjectSubBlocks>
        </DetailedProjectSubPage>
    );
}

export default DetailedProjectReputation;
