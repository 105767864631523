import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";
import DetailedProjectRoutes from "./cabinet/DetailedProjectRoutes";
import LenderCabinetDashboard from "../../../views/lender/LenderCabinetDashboard";
import MyInvestmentRoutes from "./cabinet/MyInvestmentRoutes";
import Loadable from "../../loading/Loadable";
import AnalyticsRoutes from "./cabinet/AnalyticsRoutes";
import LenderCabinetOther from "../../../views/lender/LenderCabinetOther";

const LazyFinances = Loadable(lazy(() => import("../../../views/lender/statistics/Finances")));
const LazyArchive = Loadable(lazy(() => import("../../../views/lender/market/Archive")));
const LazyMarket = Loadable(lazy(() => import("../../../views/lender/market/Market")));
const LazyDeposit = Loadable(lazy(() => import("../../../views/lender/money/Deposit")));
const LazyWithdraw = Loadable(lazy(() => import("../../../views/lender/money/Withdraw")));
const LazyProfile = Loadable(lazy(() => import("../../../views/lender/user/Profile")));

const LenderCabinetRoutes = () => {
    return (
        <Routes>
            <Route element={<LenderCabinetDashboard />}>
                <Route path="market" element={<LazyMarket />} />
                <Route element={<LenderCabinetOther />}>
                    <Route index element={<AnalyticsRoutes />} />
                    <Route path="analytics/*" element={<AnalyticsRoutes />} />
                    <Route path="my-investments/*" element={<MyInvestmentRoutes />} />
                    <Route path="finances" element={<LazyFinances />} />
                    <Route path="market/:uuid/*" element={<DetailedProjectRoutes />} />
                    <Route path="archive" element={<LazyArchive />} />
                    <Route path="deposit" element={<LazyDeposit />} />
                    <Route path="withdraw" element={<LazyWithdraw />} />
                    <Route path="profile" element={<LazyProfile />} />
                </Route>
            </Route>
        </Routes>
    );
}

export default LenderCabinetRoutes