import {ApiClientInterface} from "./clients";
import {InvestmentCancelConfirmation} from "./models";

export class CancelInvestmentManager {
    constructor(private readonly apiClient: ApiClientInterface) {}

    public async requestCancelCode(investmentUuid: string): Promise<InvestmentCancelConfirmation> {
        return this.apiClient.post(`/${investmentUuid}/request`);
    }

    public async confirmCancelCode(investmentUuid: string, code: string): Promise<void> {
        return this.apiClient.post(`/${investmentUuid}/confirm`, { code });
    }
}