import {configureStore} from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from 'redux-thunk';

import profileReducer from "./profile";
import overlayReducer from "./overlay";
import myMoneyReducer from "./myMoney";
import languageReducer from "./language";

const reducers = combineReducers({
    profile: profileReducer,
    overlay: overlayReducer,
    myMoney: myMoneyReducer,
    language: languageReducer
});

const store = configureStore({
    reducer: reducers,
    middleware: [thunk]
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;