export enum RouteDictionary {
    //RegisterAuth routes
    REGISTER = "register",
    REGISTER_LENDER = "register-lender",
    REGISTER_BORROWER = "register-borrower",
    LOGIN = "login",
    REDIRECT_AFTER_AUTH = "redirect-after-auth",
    FORGOT_PASSWORD = "forgot-password",
    VERIFICATION = "verification",
    LOGOUT = "logout",

    // RegisterAuth routes for specific users
    CONFIRM_EMAIL = "confirm-email",
    CONFIRM_PHONE = "confirm-phone",

    // Lender routes
    DEPOSIT = "deposit",
    WITHDRAW = "withdraw",
    MARKET = "market",
    DETAILED_PROJECT = "detailed-project",
    DETAILED_PROJECT_INVESTMENT = "detailed-project-investment",
    INVESTMENT_CONFIRMATION = "investment-confirmation",
    SELL_CONFIRMATION = "sell-confirmation",
    MY_INVESTMENTS = "my-investments",
    MY_INVESTMENTS_LOAN_PAYMENT = "my-investments-active",
    MY_INVESTMENTS_FUNDRAISING = "my-investments-fundraising",
    MY_INVESTMENTS_REFUNDED = "my-investments-refunded",
    MY_INVESTMENTS_DEFAULT = "my-investments-default",
    MY_INVESTMENTS_SELLING = "my-investments-selling",
    ANALYTICS = "analytics",
    ANALYTICS_USER = "analytics-user",
    ANALYTICS_USER_VOLUME = "analytics-user-volume",
    ANALYTICS_USER_PROFIT = "analytics-user-profit",
    ANALYTICS_USER_DEFAULTS = "analytics-user-defaults",
    ANALYTICS_USER_PENALTIES = "analytics-user-penalties",
    ANALYTICS_PLATFORM = "analytics-platform",
    ANALYTICS_PLATFORM_VOLUME = "analytics-platform-volume",
    ANALYTICS_PLATFORM_PROFIT = "analytics-platform-profit",
    ANALYTICS_PLATFORM_DEFAULTS = "analytics-platform-defaults",
    ANALYTICS_PLATFORM_PENALTIES = "analytics-platform-penalties",
    ANALYTICS_MKK = "analytics-mkk",
    ANALYTICS_MKK_VOLUME = "analytics-mkk-volume",
    ANALYTICS_MKK_PROFIT = "analytics-mkk-profit",
    ANALYTICS_MKK_DEFAULTS = "analytics-mkk-defaults",
    ANALYTICS_MKK_PENALTIES = "analytics-mkk-penalties",
    FINANCES = "finances",
    IDENTIFICATION = "identification",
    ARCHIVE = "archive",
    LENDER_PROFILE = "lender-profile",

    // Borrower routes
    INTRODUCTION = "introduction",
    FUNDING_REQUEST = "funding-request",
    PROJECT_REQUEST = "project-request",
    MY_PROJECTS = "my-projects",
    MY_PROJECTS_ACTIVE = "my-projects-active",
    MY_PROJECTS_FUNDRAISING = "my-projects-fundraising",
    MY_PROJECTS_REFUNDED = "my-projects-refunded",
    MY_PROJECTS_MODERATED = "my-projects-moderated",
    MY_PROJECT = "my-project",
    MY_HISTORY = "my-history",
    BORROWER_PROFILE = "borrower-profile",

    // Other pages
    // TODO: REMOVE CONTACT US, WILL BE REPLACED WITH CHAT
    CONTACT_US = "contact-us",
    DYNAMIC_PAGE = "dynamic-page"
}