import React from "react";
import './ForgotPassword.scoped.scss';

const ForgotPassword = () => {
    return (
        <>
            Forgot password page
        </>
    );
}

export default ForgotPassword;