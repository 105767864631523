import React, {PropsWithChildren} from "react";
import "./InfoPageLink.scoped.scss";
import {ClassNameDecorator} from "../../../../decorators";
import {ClassInjector} from "../../../../injectors";

const InfoPageLink: ClassNameDecorator<PropsWithChildren> = ({ children, className }) => {
    return (
        <ClassInjector
            classNames="info-page-link"
            oldClassName={className}
        >
            { children }
        </ClassInjector>
    );
}

export default InfoPageLink;