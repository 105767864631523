import React, {FC, PropsWithChildren} from "react";
import styles from "./MyProjectStatus.module.scss";
import {CommonBlock} from "../../../../../common/blocks";

const MyProjectStatus: FC<PropsWithChildren> = ({ children }) => {
    return (
        <CommonBlock.Section underscore={false}>
            <div className={styles.my_project_status}>
                { children }
            </div>
        </CommonBlock.Section>
    );
}

export default MyProjectStatus;