import {ObjectValues} from "../../utils";

export const ANALYTICS_PERIOD = {
    TwelveMonths: "TwelveMonths",
    SixMonths: "SixMonths",
    ThreeMonths: "ThreeMonths",
    FirstYearBefore: "FirstYearBefore",
    SecondYearBefore: "SecondYearBefore",
    ThirdYearBefore: "ThirdYearBefore"
} as const;

export type AnalyticsPeriod = ObjectValues<typeof ANALYTICS_PERIOD>;