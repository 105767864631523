import React, {FC} from "react";
import styles from "./LoanPaymentInvestment.module.scss";
import {LoanPaymentProjectInvestment} from "../../../../../api-client";
import {Button, CommonSubText, CommonText, Money, SecondaryButton} from "../../../../common";
import {formatDate} from "../../../../../formatters";
import {SecondaryButtonColor} from "../../../../common/buttons/decorators/SecondaryButton/SecondaryButton";
import CommonInvestment from "../../CommonInvestment";
import {useTranslator} from "../../../../../hooks";
import {DynamicTranslation} from "../../../../language";

interface Props {
    investment: LoanPaymentProjectInvestment;
}

const LoanPaymentInvestment: FC<Props> = ({ investment }) => {
    const t = useTranslator();
    return (
        <CommonInvestment
            className={styles.loan_payment_investment}
            linkTo={{ projectUuid: investment.project.uuid, investmentUuid: investment.investments[0].uuid }}
        >
            <div className={styles.loan_payment_investment__name}>
                <CommonSubText small>
                    ID { investment.project.paymentCode }
                </CommonSubText>
                <CommonText>
                    <DynamicTranslation translations={ investment.project.name } />
                </CommonText>
            </div>
            <div className={styles.loan_payment_investment__rate}>
                <CommonText>
                    { investment.project.interestRate }%
                </CommonText>
            </div>
            <div className={styles.loan_payment_investment__date}>
                <CommonText>
                    { investment.nextRepayment !== undefined && formatDate(investment.nextRepayment.date) }
                </CommonText>
            </div>
            <div className={styles.loan_payment_investment__return}>
                <CommonText>
                    { formatDate(new Date(investment.investments[0].createdAt)) }
                </CommonText>
            </div>
            <div className={styles.loan_payment_investment__sum}>
                <CommonText>
                    <Money money={investment.investedSum} />
                </CommonText>
            </div>
            <div className={styles.loan_payment_investment__paid}>
                <CommonText>
                    <Money money={investment.investedSum} />
                </CommonText>
            </div>
            <div className={styles.loan_payment_investment__actions}>
                <SecondaryButton color={SecondaryButtonColor.BLUE}>
                    <Button
                        className={styles.loan_payment_investment__button}
                    >
                        { t("my_investments.loan.sell") }
                    </Button>
                </SecondaryButton>
            </div>
        </CommonInvestment>
    );
}

export default LoanPaymentInvestment;