import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {ProjectManager} from "../ProjectManager";

export class ProjectManagerFactory {
    private readonly apiClientFactory: ApiClientFactoryInterface;

    constructor(baseUrl: string, headers: Headers) {
        this.apiClientFactory = new ApiClientFactory(
            `${baseUrl}/api/v1/project`,
            headers
        );
    }

    public createProjectManager(authToken: string): ProjectManager {
        return new ProjectManager(this.apiClientFactory.createAuthorizedClient(authToken));
    }
}