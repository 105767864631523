import React, {AnchorHTMLAttributes, FC, PropsWithChildren} from "react";
import appConfig from "../../../../configs/app";

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
    url: string;
}

const PublicLink: FC<PropsWithChildren<Props>> = ({ url, children, ...props }) => {
    const publicUrl = `${appConfig.publicBaseUrl}${url}`;
    return (
        <a {...props} href={publicUrl}>
            { children }
        </a>
    );
}

export default PublicLink;