import {Translations} from "../../types";

export const investment_confirmation: Translations = {
    "investment_confirmation": "Ներդրումներ կատարեք վարկի մեջ",
    "investment_confirmation.sum": "Դիմումի գումարը",
    "investment_confirmation.term": "Ժամկետ",
    "investment_confirmation.rate": "Հայտ",
    "investment_confirmation.create": "Ստեղծվում է հաստատում...",
    "investment_confirmation.balance": "Հաշվեկշիռ",
    "investment_confirmation.input": "Ներդրման գումարը (400 :currency մինչև 1,000,000 :currency)",
    "investment_confirmation.final_sum": "Վճարման գումարը` ելնելով եկամտից",
    "investment_confirmation.invest": "Ներդրումներ",
    "investment_confirmation.confirm": "Սեղմելով «Ներդրում»՝ ես հասկանում եմ, որ վարկի մեջ ներդրումներ կատարելը խիստ ռիսկային է:",
    "investment_confirmation.preliminary": "Վճարման նախնական ժամանակացույց",
    "investment_confirmation.generating_schedule": "Գծապատկերի ստեղծում...",
}