import {Translations} from "../../types";

export const borrower_profile: Translations = {
    "borrower_profile.account_data": "Данные аккаунта",
    "borrower_profile.company_data": "Информация о компании",
    "borrower_profile.company_data.disclaimer": "При изменении данных компании, потребуется обновление выписки. На время проверки данных, функционал платформы будет недоступен.",
    "borrower_profile.company_data.phone": "Телефон",
    "borrower_profile.company_data.description": "Подробное описание текущей деятельности",
    "borrower_profile.company_data.business_form": "Форма ведения бизнеса",
    "borrower_profile.company_data.activity": "Деятельность заказчиков",
    "borrower_profile.company_data.address": "Фактический адрес ведения бизнеса",
    "borrower_profile.company_data.documents": "Документы",
    "borrower_profile.company_data.passport_photo": "Разворот паспорта с фотографией",
    "borrower_profile.company_data.passport_address": "Разворот паспорта с пропиской",
    "borrower_profile.company_data.passport_selfie": "Селфи с паспортом",
}