import React, {useContext} from "react";
import MoneyProgress from "../../../../progress/MoneyProgress";
import {Button, PrimaryButton} from "../../../../common";
import {PrimaryButtonColor} from "../../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {useToggle, useTranslator} from "../../../../../hooks";
import {DetailedProjectContext} from "../../DetailedProjectContext";
import CancelInvestmentModal from "../../../../modals/CancelInvestmentModal";
import DetailedProjectInvestmentStat from "../../common/DetailedProjectInvestmentStat";
import {CommonBlock} from "../../../../common/blocks";

const FundraisingProjectInvestmentInfo = () => {
    const t = useTranslator();
    const { project, investment } = useContext(DetailedProjectContext);
    const [cancelModalOpened, toggleModal] = useToggle();
    return (
        <>
            <CommonBlock.Header>
                <MoneyProgress
                    firstPoint={{ name: t("detailed_project.invest.collected"), money: project.general.investedSum }}
                    secondPoint={{ name: t("detailed_project.invest.target"), money: project.general.loanSum }}
                />
            </CommonBlock.Header>
            <CommonBlock.Section underscore={false}>
                <DetailedProjectInvestmentStat />
                <div className="detailed-project-invest__submit">
                    <PrimaryButton expanded color={PrimaryButtonColor.GRAY}>
                        <Button onClick={toggleModal}>
                            { t("detailed_project.invest.investment_cancel") }
                        </Button>
                    </PrimaryButton>
                </div>
            </CommonBlock.Section>
            <CancelInvestmentModal
                active={cancelModalOpened}
                onClose={toggleModal}
                investment={investment!}
            />
        </>
    );
}

export default FundraisingProjectInvestmentInfo;