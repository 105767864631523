import {Translations} from "../../types";

export const borrower_profile: Translations = {
    "borrower_profile.account_data": "Հաշվի մանրամասներ",
    "borrower_profile.company_data": "Ընկերության տվյալներ",
    "borrower_profile.company_data.disclaimer": "Եթե ընկերության տվյալները փոխվեն, ապա քաղվածքը պետք է թարմացվի: Տվյալների ստուգման պահին հարթակի ֆունկցիոնալությունն անհասանելի կլինի:",
    "borrower_profile.company_data.phone": "Հեռախոս",
    "borrower_profile.company_data.description": "Ընթացիկ գործունեության մանրամասն նկարագրությունը",
    "borrower_profile.company_data.business_form": "Բիզնեսի ձև",
    "borrower_profile.company_data.activity": "Հաճախորդների գործունեություն",
    "borrower_profile.company_data.address": "Փաստացի բիզնես հասցե",
    "borrower_profile.company_data.documents": "Փաստաթղթեր",
    "borrower_profile.company_data.passport_photo": "Անձնագրային էջ՝ լուսանկարով",
    "borrower_profile.company_data.passport_address": "Անձնագրային էջ հասցեով",
    "borrower_profile.company_data.passport_selfie": "Սելֆի անձնագրով"
}