import React, {FC, PropsWithChildren} from "react";
import styles from "./AnalyticsTable.module.scss";

const AnalyticsTable: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className={styles.analytics_table}>
            { children }
        </div>
    );
}

export default AnalyticsTable;