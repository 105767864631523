import {Translations} from "../../types";

export const navigation: Translations = {
    "navigation.new_investments": "New investments",
    "navigation.my_investments": "My investments",
    "navigation.analytics": "Analytics",
    "navigation.finances": "Finances",
    "navigation.profile": "Profile",
    "navigation.my_loans": "My loans",
    "navigation.history": "History",
    "navigation.contact_us": "Contact us",
    "navigation.logout": "Logout"
}