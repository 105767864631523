import React, {createContext, PropsWithChildren} from "react";
import {DaDataCompany} from "../../../../../../api-client/models/company";
import {Phone} from "../../../../../../api-client";

export enum BorrowerRegistrationStep {
    EnterCompany = 0,
    EnterCredentials = 1
}

export interface BorrowerRegistrationInput {
    company?: DaDataCompany;
    phone: Phone;
    email: string;
    password: string;
    confirmPassword: string;
    rulesAgree: boolean;
    policyAgree: boolean;
}

export interface BorrowerRegistrationContextType {
    step: BorrowerRegistrationStep;
    setStep: (step: BorrowerRegistrationStep) => void;
    input: BorrowerRegistrationInput;
    setInput: (input: BorrowerRegistrationInput) => void;
}

type Props = {
    props: BorrowerRegistrationContextType
}

export const BorrowerRegistrationContext = createContext<BorrowerRegistrationContextType>({
    step: BorrowerRegistrationStep.EnterCompany,
    setStep: () => {},
    input: {
        phone: new Phone("374", ""),
        email: "",
        password: "",
        confirmPassword: "",
        rulesAgree: false,
        policyAgree: false
    },
    setInput: () => {}
});

function BorrowerRegistrationContextProvider(props: PropsWithChildren<Props>) {
    const { children, ...value } = props;
    return <BorrowerRegistrationContext.Provider value={value.props}>
        { children }
    </BorrowerRegistrationContext.Provider>
}

export default BorrowerRegistrationContextProvider;