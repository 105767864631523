import React, {FC} from "react";
import {useMountAnimation} from "../../../../../hooks";
import {formatFullName} from "../../../../../formatters";
import {ProjectInvestor} from "../../../../../api-client/models/project/detailed-project";
import "./DetailedProjectPeriodDistribution.scoped.scss";
import {cn, resultIf} from "../../../../../utils";
import DetailedProjectProgress from "../../common/DetailedProjectProgress";

interface Props {
    investors: Array<ProjectInvestor>;
}

const DetailedProjectPeriodDistribution: FC<Props> = ({ investors }) => {
    const mounted = useMountAnimation();
    const renderInvestorsDistribution = () => {
        return investors.map((investor, index) => {
            return <DetailedProjectProgress
                percent={investor.share}
                text={formatFullName(investor.fullName, true)}
                key={index}
            />
        });
    }
    return (
        <div className={cn("detailed-project-period-distribution", resultIf(mounted, "mounted"))}>
            { renderInvestorsDistribution() }
        </div>
    );
}

export default DetailedProjectPeriodDistribution;