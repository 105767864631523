import React, {useEffect, useState} from "react";
import {Navigate, Outlet} from "react-router-dom";
import {createAuthTokenManager, createRouteUrlProvider} from "../../../di";
import {RouteDictionary} from "../../../utils";
import {Loader} from "../../common";

const AuthProtectedRouteGroup = () => {
    const routeProvider = createRouteUrlProvider();
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
    useEffect(() => {
        (async () => {
            setIsLoggedIn(await createAuthTokenManager().has());
        })();
    }, []);
    if (isLoggedIn === null) {
        return <Loader />;
    }
    return (
        isLoggedIn ? <Outlet /> : <Navigate to={routeProvider.getByName(RouteDictionary.LOGIN)} />
    );
}

export default AuthProtectedRouteGroup;