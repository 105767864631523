import React, {useContext} from "react";
import CommonAside from "../../../layout/common/CommonAside";
import {FundingRequestContext, FundingRequestStep} from "../../funding-request/FundingRequestContext";
import {useTranslator} from "../../../../hooks";

const IntroductionAside = () => {
    const t = useTranslator();
    const { step, setStep, request } = useContext(FundingRequestContext);
    const projectRejected = request !== null && request.rejectionReason !== null;
    return (
        <CommonAside>
            <CommonAside.Step
                index={1}
                text={ t("funding_request.navigation.upload_documents") }
                active={step === FundingRequestStep.UploadDocuments}
                onClick={() => setStep(FundingRequestStep.UploadDocuments)}
                finished={request !== null && request.financialStatements.length !== 0}
            />
            <CommonAside.Step
                index={2}
                text={ t("funding_request.navigation.count_available") }
                active={step === FundingRequestStep.CountAvailableSum}
                onClick={
                    request !== null && request.financialStatements.length !== 0
                        ? () => setStep(FundingRequestStep.CountAvailableSum)
                        : undefined
                }
                finished={request !== null && request.approvedSum !== undefined}
            />
            { !projectRejected && <>
                <CommonAside.Step
                    index={3}
                    text={ t("funding_request.navigation.survey") }
                    active={step === FundingRequestStep.FillSurvey}
                    onClick={
                        request !== null && request.approvedSum !== undefined
                            ? () => setStep(FundingRequestStep.FillSurvey)
                            : undefined
                    }
                    finished={request !== null && request.companyUuid !== undefined}
                />
                <CommonAside.Step
                    index={4}
                    text={ t("funding_request.navigation.loan_options") }
                    active={step === FundingRequestStep.ChooseLoanOption}
                    onClick={request !== null && request.companyUuid !== undefined
                        ? () => setStep(FundingRequestStep.ChooseLoanOption)
                        : undefined}
                    finished={request !== null && request.desiredMoney !== undefined}
                />
                <CommonAside.Step
                    index={5}
                    text={ t("funding_request.navigation.sign_documents") }
                    active={step === FundingRequestStep.SignDocuments}
                    onClick={request !== null && request.desiredMoney !== undefined
                        ? () => setStep(FundingRequestStep.SignDocuments)
                        : undefined}
                />
            </> }
        </CommonAside>
    );
}

export default IntroductionAside;