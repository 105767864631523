export enum AccountBalanceName {
    TangibleNonCurrentAssets = "tangible_non_current_assets", // Материальные внеоборотные активы
    IntangibleFinancialAndOther = "intangible_financial_and_other", // Нематериальные финансовые и другие
    Stocks = "stocks", // Запасы
    FinancialAndOtherCurrentAssets = "financial_and_other_current_assets", // Финансовые и другие оборотные активы
    BalanceActive = "balance_active", // Баланс актив
    CapitalAndReserves = "capital_and_reserves", // Капитал и резервы
    LongTermBorrowings = "long_term_borrowings", // Долгосрочные заемные средства
    ShortTermBorrowings = "short_term_borrowings", // Краткосрочные заемные средства
    AccountsPayable = "accounts_payable", // Кредиторская задолженность
    OtherCurrentLiabilities = "other_current_liabilities", // Другие краткосрочные обязательства
    BalancePassive = "balance_passive" // Баланс пассив
}

export const accountBalancesActive: Array<AccountBalanceName> = [
    AccountBalanceName.TangibleNonCurrentAssets,
    AccountBalanceName.IntangibleFinancialAndOther,
    AccountBalanceName.Stocks,
    AccountBalanceName.FinancialAndOtherCurrentAssets,
    AccountBalanceName.BalanceActive,
]

export const accountBalancesPassive: Array<AccountBalanceName> = [
    AccountBalanceName.CapitalAndReserves,
    AccountBalanceName.LongTermBorrowings,
    AccountBalanceName.ShortTermBorrowings,
    AccountBalanceName.AccountsPayable,
    AccountBalanceName.OtherCurrentLiabilities,
    AccountBalanceName.BalancePassive,
]