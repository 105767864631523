import React, {FC, useState} from "react";
import Modal, {ModalProps} from "../Modal/Modal";
import {useEffectOnUpdate, useErrorCallback, useNavigateByName, useTranslator} from "../../../hooks";
import {Investment, InvestmentCancelConfirmation} from "../../../api-client";
import {createCancelInvestmentManager} from "../../../di";
import {ConfirmSmsCodeForm, Loader} from "../../common";
import {RouteDictionary} from "../../../utils";

interface Props extends ModalProps {
    investment: Investment;
}

const CancelInvestmentModal: FC<Props> = ({ active, onClose, investment, ...props }) => {
    const t = useTranslator();
    const handleError = useErrorCallback();
    const [confirmation, setConfirmation] = useState<InvestmentCancelConfirmation | null>(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigateByName();

    useEffectOnUpdate(() => {
        if (active) {
            (async () => {
                try {
                    const manager = await createCancelInvestmentManager();
                    const result = await manager.requestCancelCode(investment.uuid);
                    setConfirmation(result);
                } catch (error: any) {
                    await handleError(error);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [active]);

    const handleSend = async (code: string) => {
        const manager = await createCancelInvestmentManager();
        await manager.confirmCancelCode(investment.uuid, code);
    }

    const handleResend = async () => {
        console.log("ADD RESEND LOGIC");
    }

    const handleSuccess = async () => {
        await navigate(RouteDictionary.MY_INVESTMENTS_FUNDRAISING);
    }

    return (
        <Modal active={active} onClose={onClose}>
            { loading ? <Loader /> : <ConfirmSmsCodeForm
                sendCallback={handleSend}
                resendCallback={handleResend}
                successCallback={handleSuccess}
            /> }
        </Modal>
    );
}

export default CancelInvestmentModal;