import {Translations} from "../../types";

export const deposit: Translations = {
    "deposit": "Deposit",
    "deposit.id_assigned": "You have been assigned an ID",
    "deposit.terms": "It is possible to make investments on the platform only if there are free funds on an individual account",
    "deposit.transfer": "Replenishment of funds is carried out exclusively by transferring funds from a bank account owned by the investor to the nominal account of the Platform Operator",
    "deposit.requisites": "Requisites",
    "deposit.reg_number": "Registration number",
    "deposit.account": "C/a",
    "deposit.bik": "BIC",
    "deposit.correspondent": "Correspondent account",
    "deposit.purpose": "Payment purpose",
    "deposit.agreement": "Replenishment of the nominal account under the agreement dated :date, ID 160499, VAT free",
    "deposit.qr": "QR-code",
    "deposit.camera": "Point your smartphone camera",
}