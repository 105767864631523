import React, {useEffect, useState} from "react";
import {useAppDispatch, useCurrentLanguage, useEffectOnUpdate} from "../../hooks";
import {createLanguageResolver} from "../../di";
import {set} from "../../store/language";
import {Outlet} from "react-router-dom";
import {Loader} from "../../components/common";
import {sleep} from "../../utils";

const InitialLayout = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();
    useEffect(() => {
        const languageResolver = createLanguageResolver();
        dispatch(set(languageResolver.resolveUserLanguage()));
        setLoading(false);
    }, []);
    return (
        <>
            { loading ? <Loader /> : <Outlet /> }
        </>
    );
}

export default InitialLayout;