import {Translations} from "../../types";

export const borrower_profile: Translations = {
    "borrower_profile.account_data": "Account data",
    "borrower_profile.company_data": "Company info",
    "borrower_profile.company_data.disclaimer": "If the company data changes, the statement will need to be updated. At the time of data verification, the functionality of the platform will be unavailable.",
    "borrower_profile.company_data.phone": "Phone",
    "borrower_profile.company_data.description": "Detailed description of current activities",
    "borrower_profile.company_data.business_form": "Business form",
    "borrower_profile.company_data.activity": "Customer activities",
    "borrower_profile.company_data.address": "Actual business address",
    "borrower_profile.company_data.documents": "Documents",
    "borrower_profile.company_data.passport_photo": "Passport page with photo",
    "borrower_profile.company_data.passport_address": "Passport page with address",
    "borrower_profile.company_data.passport_selfie": "Selfie with passport"
}