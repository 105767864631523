import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";
import {LenderCabinetRoutes, LenderIdentificationRoutes} from "../lender";
import {EmailVerifiedRouteGroup} from "../groups";
import {LenderDashboard} from "../../../views";
import Loadable from "../../loading/Loadable";

const LazyConfirmEmail = Loadable(lazy(() => import("../../../views/lender/confirmation/ConfirmEmail")));

const LenderRoutes = () => {
    return (
        <>
            <Routes>
                <Route element={<LenderDashboard />}>
                    <Route path="confirm-email" element={<LazyConfirmEmail />} />
                    <Route element={<EmailVerifiedRouteGroup />}>
                        <Route path="identification/*" element={<LenderIdentificationRoutes />} />
                        <Route path="/*" element={<LenderCabinetRoutes />} />
                    </Route>
                </Route>
            </Routes>
        </>
    );
}

export default LenderRoutes;