import React from "react";
import styles from "./AnalyticsDefaultHeader.module.scss";
import {CommonSubText} from "../../../../../common";
import {useTranslator} from "../../../../../../hooks";

const AnalyticsDefaultHeader = () => {
    const t = useTranslator();
    return (
        <div className={styles.analytics_default_header}>
            <CommonSubText small>
                { t("analytics.user.default.header.month") }
            </CommonSubText>
            <CommonSubText small>
                { t("analytics.user.default.header.all") }
            </CommonSubText>
            <CommonSubText small>
                { t("analytics.user.default.header.returned") }
            </CommonSubText>
            <CommonSubText small>
                { t("analytics.user.default.header.defaulted") }
            </CommonSubText>
        </div>
    );
}

export default AnalyticsDefaultHeader;