import React, {useEffect, useState} from "react";
import {useNavigateByName, useProfileData} from "../../../hooks";
import {Outlet} from "react-router-dom";
import {RouteDictionary} from "../../../utils";

const EmailVerifiedRouteGroup = () => {
    const navigate = useNavigateByName();
    const [loading, setLoading] = useState(true);
    const profileData = useProfileData();
    useEffect(() => {
        if (!profileData.emailVerified) {
            navigate(RouteDictionary.CONFIRM_EMAIL);
            return;
        }
        setLoading(false);
        return;
    }, [profileData]);
    return (
        loading ? <div>Проверка эл. почты...</div> : <Outlet />
    );
}

export default EmailVerifiedRouteGroup;