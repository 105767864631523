import React from "react";
import {Outlet} from "react-router-dom";
import LayoutBorrowerRequest from "../../layouts/LayoutBorrowerRequest";

const BorrowerRequestDashboard = () => {
    return (
        <LayoutBorrowerRequest>
            <Outlet />
        </LayoutBorrowerRequest>
    );
}

export default BorrowerRequestDashboard;