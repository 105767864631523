import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {RepaymentProvider} from "../RepaymentProvider";
import {CancelToken} from "axios";

export class RepaymentProviderFactory {
    private readonly apiClientFactory: ApiClientFactoryInterface;

    constructor(baseUrl: string, headers: Headers) {
        this.apiClientFactory = new ApiClientFactory(
            `${baseUrl}/api/v1/repayment`,
            headers
        );
    }

    public createRepaymentProvider(authToken: string, signal: AbortSignal): RepaymentProvider {
        return new RepaymentProvider(this.apiClientFactory.createAuthorizedClient(authToken), signal);
    }
}