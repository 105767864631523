import React, {FC, PropsWithChildren} from "react";
import {Heading, SecondaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";

const ConfirmationBlockHeading: FC<PropsWithChildren> = ({ children }) => {
    return (
        <SecondaryHeading>
            <Heading headingType={HeadingType.H1} className="confirmation-block__heading">
                { children }
            </Heading>
        </SecondaryHeading>
    );
}

export default ConfirmationBlockHeading;