import {Translations} from "../../types";

export const market: Translations = {
    "market.primary": "IPO շուկա",
    "market.secondary": "Երկրորդային առաջարկի շուկա",
    "market.primary.company": "Ընկերություն",
    "market.primary.sum": "Գումար",
    "market.primary.rate": "Հայտ",
    "market.primary.term": "Ժամկետ",
    "market.primary.schedule": "Մարման ժամանակացույց",
    "market.primary.collected": "Հավաքված",
    "market.primary.invest": "Ներդրումներ",
    "market.primary.filters": "Զտիչներ",
    "market.primary.filters.term": "Վարկի ժամկետը, օրերը",
    "market.primary.filters.submit": "Դիմել",
    "market.primary.filters.reset": "Վերականգնել",
    "market.primary.schedule.monthly": "Ամսական",
    "market.primary.schedule.monthly_twice": "Ամիսը երկու անգամ",
    "market.primary.schedule.monthly_thrice": "Ամսական երեք անգամ",
    "market.primary.schedule.monthly_four_times": "Ամսական չորս անգամ",
    "market.primary.schedule.quarterly": "Եռամսյակային",
    "market.primary.schedule.at_the_end": "Ժամանակահատվածի վերջում"
}