import React, {FC} from "react";
import Image from "../Image";
import {ImageProps} from "../Image/Image";
import {cn} from "../../../../utils";
import './Icon.scoped.scss';

export enum IconSize {
    SMALL = "icon--small",
    MEDIUM = "icon--medium",
}

interface Props extends ImageProps {
    src: string;
    size?: IconSize;
}

const Icon: FC<Props> = ({ src, size, ...props }) => {
    return (
        <Image
            {...props}
            src={src}
            className={cn("icon", size)}
        />
    );
}

export default Icon;