import React from "react";
import {SvgComponent} from "./SvgComponent";
import {defaultSvgColorDark} from "./SvgProps";
import SvgContainer from "./SvgContainer";
import {SvgContainerSize} from "./SvgContainer/SvgContainer";

const FilterSvg: SvgComponent = ({ color = defaultSvgColorDark }) => {
    return (
        <SvgContainer size={SvgContainerSize.Small}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.5 5L7.5 5" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
                <path d="M13.5 11L8.5 11" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
                <path d="M11.5 5H13.5" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
                <path d="M4.5 11H2.5" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
                <circle cx="9.5" cy="5" r="2" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
                <circle r="2" transform="matrix(-1 0 0 1 6.5 11)" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
            </svg>
        </SvgContainer>
    );
}

export default FilterSvg;