import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {MyMoneyProvider} from "../MyMoneyProvider";

export class MyMoneyProviderFactory {
    private readonly apiClientFactory: ApiClientFactoryInterface;

    constructor(baseUrl: string, headers: Headers) {
        this.apiClientFactory = new ApiClientFactory(
            `${baseUrl}/api/v1/my-money`,
            headers
        )
    }

    createMoneyProvider(authToken: string, signal: AbortSignal): MyMoneyProvider {
        return new MyMoneyProvider(this.apiClientFactory.createAuthorizedClient(authToken), signal);
    }
}