import React, {FC} from "react";
import './IdentificationPassport.scoped.scss';
import {Passport} from "../../../../../api-client";
import {CommonInput} from "../../../inputs";
import {Errors as ValidationErrors} from "../../../../../utils";
import {InputErrors} from "../../../form";
import {DatePicker} from "../../../selects";
import {formatApiDate} from "../../../../../formatters";
import {useTranslator} from "../../../../../hooks";
import {PassportErrors} from "../../../../../hooks/data/validation";

interface Props {
    passport: Passport;
    setPassport: (passport: Passport) => void;
    errors: PassportErrors
}

const IdentificationPassport: FC<Props> = ({ passport, setPassport, errors }) => {
    const t = useTranslator();
    return (
        <div className="identification-passport">
            <div className="identification-passport__header">
                { t("identification.passport_data") }
            </div>
            <div>
                <CommonInput
                    value={passport.series}
                    onChange={(value: string) => setPassport({...passport, series: value})}
                    text={ t("common.passport.series") }
                    error={errors.series !== undefined}
                />
                { errors.series && <InputErrors errors={errors.series} /> }
            </div>
            <div>
                <CommonInput
                    value={passport.number}
                    onChange={(value: string) => setPassport({...passport, number: value})}
                    text={ t("common.passport.number") }
                    error={errors.number !== undefined}
                />
                { errors.number && <InputErrors errors={errors.number} /> }
            </div>
            <div className="identification-passport--full">
                <CommonInput
                    value={passport.issuedBy}
                    onChange={(value: string) => setPassport({...passport, issuedBy: value})}
                    text={ t("common.passport.issued_by") }
                    error={errors.issuedBy !== undefined}
                />
                { errors.issuedBy && <InputErrors errors={errors.issuedBy} /> }
            </div>
            <div>
                <CommonInput
                    value={passport.departmentCode}
                    onChange={(value: string) => setPassport({...passport, departmentCode: value})}
                    text={ t("common.passport.department_code") }
                    error={errors.departmentCode !== undefined}
                />
                { errors.departmentCode && <InputErrors errors={errors.departmentCode} /> }
            </div>
            <div>
                <DatePicker
                    value={new Date(passport.issuedDate)}
                    onSelected={(date) => setPassport({ ...passport, issuedDate: formatApiDate(date) })}
                    text={ t("common.passport.issued_date") }
                />
                { errors.issuedDate && <InputErrors errors={errors.issuedDate} /> }
            </div>
            <div className="identification-passport--full">
                <CommonInput
                    value={passport.registrationAddress}
                    onChange={(value: string) => setPassport({...passport, registrationAddress: value})}
                    text={t("common.passport.registration_address")}
                    error={errors.registrationAddress !== undefined}
                />
                { errors.registrationAddress && <InputErrors errors={errors.registrationAddress} /> }
            </div>
        </div>
    );
}

export default IdentificationPassport;