import React from "react";
import {Route, Routes} from "react-router-dom";
import LenderIdentificationDashboard from "../../../views/lender/LenderIdentificationDashboard";
import Identification from "../../../views/lender/identification/Identification";

const LenderIdentificationRoutes = () => {
    return (
        <Routes>
            <Route element={<LenderIdentificationDashboard />}>
                <Route index element={<Identification />}/>
            </Route>
        </Routes>
    );
}

export default LenderIdentificationRoutes