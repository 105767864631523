import React, {AllHTMLAttributes, FC, LiHTMLAttributes, PropsWithChildren} from "react";
import {cn, resultIf} from "../../../../utils";
import styles from "./MyProject.module.scss";
import MyProjectColumn from "../MyProjectColumn";
import CommonListEntry from "../../../common/blocks/CommonListEntry";

interface Props extends LiHTMLAttributes<HTMLLIElement> {
    small?: boolean;
}

type MyProjectExtensions = {
    Column: typeof MyProjectColumn;
}

const MyProject: FC<Props> & MyProjectExtensions = ({ small = false, ...props }) => {
    return (
        <CommonListEntry {...props} className={cn(styles.my_project, resultIf(small, styles.my_project___small), props.className)}>
            { props.children }
        </CommonListEntry>
    );
}

MyProject.Column = MyProjectColumn;

export default MyProject;