import {CompanyInfo} from "../../../api-client";
import {useTranslator} from "../../useTranslator";
import {Errors as ValidationErrors, validatePhone} from "../../../utils";

export type CompanyInfoErrors = {
    registrationNumber?: ValidationErrors;
    kpp?: ValidationErrors;
    companyPhone?: ValidationErrors;
    description?: ValidationErrors;
    businessForm?: ValidationErrors;
    activity?: ValidationErrors;
    address?: ValidationErrors;
    siteUrls?: ValidationErrors;
    reputationRating?: ValidationErrors;
    moneySource?: ValidationErrors;
}

export const useCompanyInfoValidation = (data: { companyInfo: CompanyInfo }) => {
    const t = useTranslator();
    return (initialErrors: CompanyInfoErrors) => {
        if (data.companyInfo.registrationNumber === "") {
            initialErrors.registrationNumber = [t(
                "errors.field_required",
                { field: t("errors.fields.company.reg_number"), type: t("errors.field_required.masculine") }
            )];
        } else if (isNaN(parseInt(data.companyInfo.registrationNumber))) {
            initialErrors.registrationNumber = [t(
                "errors.not_number",
                { field: t("errors.fields.company.reg_number"), type: t("errors.field_required.masculine") }
            )];
        }
        if (data.companyInfo.kpp === "") {
            initialErrors.kpp = [t(
                "errors.field_required",
                { field: t("errors.fields.company.kpp"), type: t("errors.field_required.masculine") }
            )];
        } else if (isNaN(parseInt(data.companyInfo.kpp))) {
            initialErrors.kpp = [t(
                "errors.not_number",
                { field: t("errors.fields.company.kpp"), type: t("errors.field_required.masculine") }
            )];
        }
        if (!validatePhone(data.companyInfo.phone)) {
            initialErrors.companyPhone = [t("errors.wrong_phone")];
        }
        if (data.companyInfo.description === "") {
            initialErrors.description = [t(
                "errors.field_required",
                { field: t("errors.fields.company.description"), type: t("errors.field_required.neuter") }
            )];
        }
        if (data.companyInfo.businessForm === "") {
            initialErrors.businessForm = [t(
                "errors.field_required",
                { field: t("errors.fields.company.business_form"), type: t("errors.field_required.feminine") }
            )];
        }
        if (data.companyInfo.activity === "") {
            initialErrors.activity = [t(
                "errors.field_required",
                { field: t("errors.fields.company.activity"), type: t("errors.field_required.feminine") }
            )];
        }
        if (data.companyInfo.address === "") {
            initialErrors.address = [t(
                "errors.field_required",
                { field: t("errors.fields.company.address"), type: t("errors.field_required.masculine") }
            )];
        }
        if (data.companyInfo.siteUrls.length > 1 && data.companyInfo.siteUrls.some((url) => url === "")) {
            initialErrors.siteUrls = [t(
                "errors.fields_required",
                { field: t("errors.fields.company.site_urls") }
            )];
        }
        return initialErrors;
    }
}