import React, {useContext, useMemo, useState} from "react";
import "./MyInvestmentsRefunded.module.scss";
import {useErrorCallback, useTranslator} from "../../../../../hooks";
import {MyInvestmentsContext} from "../../../../../views/lender/statistics/MyInvestments/MyInvestmentsContext";
import {RefundedProjectInvestment, SortingDirection} from "../../../../../api-client";
import {cn, DataCollection, MyInvestmentsFilter} from "../../../../../utils";
import {createInvestmentManager} from "../../../../../di";
import RefundedInvestment from "../RefundedInvestment";
import styles from "./MyInvestmentsRefunded.module.scss";
import {Pagination, PaginationContent, PaginationHeader, PaginationPaginator} from "../../../../pagination";
import commonStyles from "../../../../../views/lender/statistics/MyInvestments/MyInvestments.module.scss";
import {CommonSubText} from "../../../../common";
import SortingDirectionBlock from "../../../../common/utils/SortingDirectionBlock";

const MyInvestmentsRefunded = () => {
    const t = useTranslator();
    const filter = useContext(MyInvestmentsContext).filter;
    const [loanAmountSorting, setLoanAmountSorting] = useState<SortingDirection | undefined>(undefined);
    const [interestRateSorting, setInterestRateSorting] = useState<SortingDirection | undefined>(undefined);
    const [createdAtSorting, setCreatedAtSorting] = useState<SortingDirection | undefined>(undefined);

    const investmentsFilter = useMemo((): MyInvestmentsFilter => {
        return {
            ...filter,
            loanAmountSorting,
            interestRateSorting,
            createdAtSorting
        }
    }, [filter, loanAmountSorting, interestRateSorting, createdAtSorting]);

    const handleError = useErrorCallback();
    const [loading, setLoading] = useState(false);
    const [investments, setInvestments] =
        useState<DataCollection<RefundedProjectInvestment>>(new DataCollection());

    const loadData = async (page: number, perPage: number, filter?: MyInvestmentsFilter) => {
        setLoading(true);
        try {
            const manager = await createInvestmentManager();
            const collection = await manager.getListRefundedOwned(page, perPage, filter);
            const investments = new DataCollection<RefundedProjectInvestment>();
            investments.setCollection(collection);
            setInvestments(investments);
        } catch (error: any) {
            await handleError(error);
        } finally {
            setLoading(false);
        }
    }

    const renderInvestments = useMemo(() => {
        return investments.items.map((investment) => {
            return <RefundedInvestment investment={investment} key={investment.project.uuid} />
        });
    }, [investments]);

    return (
        <div className={styles.refunded_investments}>
            <Pagination
                onLoad={loadData}
                count={investments.count}
                totalCount={investments.totalCount}
                filterObject={filter}
            >
                <PaginationHeader className={cn(styles.refunded_investments__header, commonStyles.my_investments__header)}>
                    <CommonSubText small>
                        { t("my_investments.loan.company") }
                    </CommonSubText>
                    <SortingDirectionBlock
                        direction={investmentsFilter.interestRateSorting}
                        handleChange={setInterestRateSorting}
                    >
                        { t("my_investments.loan.rate") }
                    </SortingDirectionBlock>
                    <SortingDirectionBlock
                        direction={investmentsFilter.createdAtSorting}
                        handleChange={setCreatedAtSorting}
                    >
                        { t("my_investments.loan.date") }
                    </SortingDirectionBlock>
                    <SortingDirectionBlock
                        direction={investmentsFilter.loanAmountSorting}
                        handleChange={setLoanAmountSorting}
                    >
                        { t("my_investments.loan.sum") }
                    </SortingDirectionBlock>
                </PaginationHeader>
                <PaginationContent loading={loading}>
                    <div className={commonStyles.my_investments__content}>
                        <ul className={commonStyles.my_investments__list}>
                            { renderInvestments }
                        </ul>
                    </div>
                </PaginationContent>
                <PaginationPaginator />
            </Pagination>
        </div>
    );
}

export default MyInvestmentsRefunded;