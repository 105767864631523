import React, {FC, InputHTMLAttributes, ReactNode, useId} from "react";
import {cn, resultIf} from "../../../../utils";
import "./InputPassword.scoped.scss";
import {useToggle} from "../../../../hooks";
import {InputLabel} from "../../form";
import HideSvg from "../../../svg/password/HideSvg";
import VisibleSvg from "../../../svg/password/VisibleSvg";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    password: string;
    onChangePassword: (value: string) => void;
    text: ReactNode;
    error: boolean;
}

const InputPassword: FC<Props> = ({ password, onChangePassword, text, error, ...props }) => {
    const id = useId();
    const [visible, toggleVisible] = useToggle();
    const onInput = (event: any) => {
        onChangePassword(event.target.value);
    };
    return (
        <div className="input-password">
            <InputLabel id={id} text={text} />
            <div className={cn("input-password__inner", resultIf(error, "input-password__inner--error"))}>
                <div className="input-password__left">
                    <input
                        {...props}
                        type={visible ? "text" : "password"}
                        id={id}
                        className={cn("input-password__input", props.className)}
                        value={password}
                        onChange={onInput}
                    />
                </div>
                <div className="input-password__eye" onClick={toggleVisible}>
                    { visible ? <HideSvg /> : <VisibleSvg /> }
                </div>
            </div>
        </div>
    );
}

export default InputPassword;