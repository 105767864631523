import {FullName} from "../../api-client";
import {useMemo, useState} from "react";

export const useFullName = (initial?: FullName): [FullName, (fullName: FullName) => void] => {
    const [firstName, setFirstName] = useState(initial?.firstName ?? "");
    const [middleName, setMiddleName] = useState<string | undefined>(initial?.middleName ?? "");
    const [lastName, setLastName] = useState(initial?.lastName ?? "");

    const fullName = useMemo((): FullName => {
        return {
            firstName,
            middleName,
            lastName
        }
    }, [firstName, middleName, lastName]);

    const setFullName = (fullName: FullName): void => {
        setFirstName(fullName.firstName);
        setMiddleName(fullName.middleName);
        setLastName(fullName.lastName);
    }

    return [
        fullName,
        setFullName
    ];
}