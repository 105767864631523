import React, {ButtonHTMLAttributes, FC} from "react";
import {cn, resultIf} from "../../../../utils";
import ButtonInner from "./extensions/ButtonInner";
import './Button.scoped.scss';
import {Loader} from "../../utils";
import {LOADER_COLOR} from "../../../loading/Loader/Loader";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    disabled?: boolean | undefined;
    loading?: boolean;
}

interface ButtonExtensions {
    Inner: typeof ButtonInner;
}

const Button: FC<ButtonProps> & ButtonExtensions = (
    {
        children,
        disabled= false,
        loading= false,
        ...props
    }) => {
    const renderChildren = () => {
        if (loading) {
            return <div className="button__loader">
                <Loader small color={LOADER_COLOR.WHITE} />
            </div>;
        }
        return children;
    }
    return (
        <button
            {...props}
            className={cn(
                "button",
                props.className,
                resultIf(disabled || loading, "button--disabled")
            )}
        >
            { renderChildren() }
        </button>
    );
}

Button.Inner = ButtonInner;

export default Button;