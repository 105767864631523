import React from "react";
import './Deposit.scoped.scss';
import ContentContainer from "../../../../components/layout/common/ContentContainer";
import Section from "../../../../components/layout/common/Section";
import {CommonSubText, CommonText, Heading, PrimaryHeading} from "../../../../components/common";
import {HeadingType} from "../../../../components/common/typography/headings/Heading/Heading";
import {CommonBlock} from "../../../../components/common/blocks";
import StatusBlock from "../../../../components/common/utils/StatusBlock";
import {StatusBlockType} from "../../../../components/common/utils/StatusBlock/StatusBlock";
import {useProfileData, useTranslator} from "../../../../hooks";
import QrCode from "../../../../components/common/utils/QrCode";
import {CURRENT_COMPANY_REQUISITES} from "../../../../utils";
import moment from "moment";

const Deposit = () => {
    const t = useTranslator();
    const profileData = useProfileData();
    return (
        <div className="deposit">
            <ContentContainer>
                <Section className="deposit__section">
                    <PrimaryHeading>
                        <Heading headingType={HeadingType.H1}>
                            { t("deposit") }
                        </Heading>
                    </PrimaryHeading>
                    <div className="deposit__container">
                        <CommonBlock>
                            <CommonBlock.Header>
                                <div className="deposit__status">
                                    <StatusBlock type={StatusBlockType.Success} text={`${t("deposit.id_assigned")} ${profileData.paymentCode}`} />
                                </div>
                                <div className="deposit__text">
                                    <div>
                                        { t("deposit.terms") }
                                    </div>
                                    <div>
                                        { t("deposit.transfer") }
                                    </div>
                                </div>
                            </CommonBlock.Header>
                            <CommonBlock.Section>
                                <CommonText large>
                                    { t("deposit.requisites") }
                                </CommonText>
                                <ul className="deposit__list">
                                    <li>
                                        ООО &quot;{ CURRENT_COMPANY_REQUISITES.name }&quot;
                                    </li>
                                    <li>
                                        { t("deposit.reg_number") } { CURRENT_COMPANY_REQUISITES.regNumber }
                                    </li>
                                    <li>
                                        { t("common.kpp") } { CURRENT_COMPANY_REQUISITES.kpp }
                                    </li>
                                    <li>
                                        { t("deposit.account") } { CURRENT_COMPANY_REQUISITES.account } в АО &quot;{ CURRENT_COMPANY_REQUISITES.bank }&quot;
                                    </li>
                                    <li>
                                        { t("deposit.bik") } { CURRENT_COMPANY_REQUISITES.bik }
                                    </li>
                                    <li>
                                        { t("deposit.correspondent") } { CURRENT_COMPANY_REQUISITES.correspondent }
                                    </li>
                                    <li>
                                        <b>{ t("deposit.purpose") }: </b>{ t(
                                        "deposit.agreement",
                                        { date: moment(new Date()).format("dd.mm.YYYY") }
                                    ) }
                                    </li>
                                </ul>
                            </CommonBlock.Section>
                            <CommonBlock.Footer>
                                <div className="deposit__qr">
                                    <QrCode />
                                    <CommonText large>
                                        { t("deposit.qr") }
                                    </CommonText>
                                    <CommonSubText>
                                        { t("deposit.camera") }
                                    </CommonSubText>
                                </div>
                            </CommonBlock.Footer>
                        </CommonBlock>
                    </div>
                </Section>
            </ContentContainer>
        </div>
    )
}

export default Deposit;