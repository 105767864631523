import React, {FC, PropsWithChildren, ReactNode} from "react";
import Content from "../../components/layout/common/Content";
import Overlay from "../../components/layout/common/Overlay";
import ModalContainer from "../../components/layout/common/ModalContainer";

interface Props {
    header: ReactNode;
    footer: ReactNode;
}

const LayoutAuth: FC<PropsWithChildren<Props>> = ({ children, header, footer }) => {
    return (
        <Content auth>
            <Overlay />
            { header }
            { children }
            { footer }
            <ModalContainer />
        </Content>
    );
}

export default LayoutAuth;