import React, {FC} from "react";
import styles from "./MyModeratedProject.module.scss";
import {CommonText, Money, ProjectLink} from "../../../../common";
import {formatDate} from "../../../../../formatters";
import {MyModeratedProject as MyModeratedProjectModel, ProjectStatus} from "../../../../../api-client";
import MyProject from "../../MyProject";
import {RouteDictionary} from "../../../../../utils";
import {loansScheduleTypeTranslations} from "../../../../../views/borrower/statistics/MyLoans/MyLoans";
import {useTranslator} from "../../../../../hooks";

interface Props {
    project: MyModeratedProjectModel;
}

const translateProjectStatus = (status: ProjectStatus): string => {
    if (status === ProjectStatus.REVIEW) {
        return "my_loans.status.review";
    }
    if (status === ProjectStatus.REVIEW_APPROVED) {
        return "my_loans.status.review_approved";
    }
    if (status === ProjectStatus.REVIEW_REJECTED) {
        return "my_loans.status.review_rejected";
    }
    if (status === ProjectStatus.FINAL_REVIEW) {
        return "my_loans.status.final_review";
    }
    if (status === ProjectStatus.FINAL_REVIEW_APPROVED) {
        return "my_loans.status.final_review_approved";
    }
    if (status === ProjectStatus.FINAL_REVIEW_REJECTED) {
        return "my_loans.status.final_review_rejected";
    }
    return "";
}

const MyModeratedProject: FC<Props> = ({ project }) => {
    const t = useTranslator();
    return (
        <MyProject className={styles.my_moderated_project}>
            <ProjectLink to={RouteDictionary.MY_PROJECT} params={{ uuid: project.uuid }}>
                <MyProject.Column className={styles.my_moderated_project__id}>
                    <CommonText>
                        { project.paymentCode ?? t("my_loans.not_listed") }
                    </CommonText>
                </MyProject.Column>
                <MyProject.Column className={styles.my_moderated_project__target}>
                    <CommonText>
                        { project.targetSum !== undefined
                            ? <Money money={project.targetSum} />
                            : <div>{ t("my_loans.not_listed.feminine") }</div>
                        }
                    </CommonText>
                </MyProject.Column>
                <MyProject.Column className={styles.my_moderated_project__rate}>
                    <CommonText>
                        { project.interestRate ? `${project.interestRate}%` : t("my_loans.not_listed") }
                    </CommonText>
                </MyProject.Column>
                <MyProject.Column className={styles.my_moderated_project__date}>
                    <CommonText>
                        { formatDate(project.createdAt) }
                    </CommonText>
                </MyProject.Column>
                <MyProject.Column className={styles.my_moderated_project__schedule}>
                    <CommonText>
                        { project.scheduleType ? t(loansScheduleTypeTranslations[project.scheduleType]) : t("my_loans.not_listed") }
                    </CommonText>
                </MyProject.Column>
                <MyProject.Column className={styles.my_moderated_project__actions}>
                    <CommonText>
                        { t(translateProjectStatus(project.status)) }
                    </CommonText>
                </MyProject.Column>
            </ProjectLink>
        </MyProject>
    );
}

export default MyModeratedProject;