import React, {FC} from "react";
import {DetailedMyFundraisingProject} from "../../../../../../api-client";
import {CommonBlock} from "../../../../../common/blocks";
import CommonPropertiesList from "../../../../../common/blocks/CommonPropertiesList";
import {CommonSubText, CommonText, Money} from "../../../../../common";
import {myProjectScheduleTranslations} from "../MyProjectAside/MyProjectAside";
import MyProjectStatusResolver from "../../status/MyProjectStatusResolver";
import MoneyProgress from "../../../../../progress/MoneyProgress";
import {useTranslator} from "../../../../../../hooks";
import {resolveMonthTranslation} from "../../../../../../i18n";

interface Props {
    project: DetailedMyFundraisingProject;
}

const MyFundraisingProjectAside: FC<Props> = ({ project }) => {
    const t = useTranslator();
    return (
        <>
            <CommonBlock>
                <CommonBlock.Header>
                    <MoneyProgress
                        firstPoint={{ name: t("my_detailed_project.aside.fundraising_collected"), money: project.fundraising.collectedSum }}
                        secondPoint={{ name: t("my_detailed_project.aside.target"), money: project.fundraising.loan.sum }}
                    />
                </CommonBlock.Header>
                <CommonBlock.Section underscore={false}>
                    <CommonPropertiesList>
                        <CommonPropertiesList.Element>
                            <CommonSubText>
                                { t("my_detailed_project.aside.rate") }
                            </CommonSubText>
                            <CommonText>
                                <span>{project.fundraising.loan.interestRate}%</span>
                            </CommonText>
                        </CommonPropertiesList.Element>
                        <CommonPropertiesList.Element>
                            <CommonSubText>
                                { t("my_detailed_project.aside.payout_sum") }
                            </CommonSubText>
                            <CommonText>
                                <Money money={project.fundraising.loan.sum} />
                            </CommonText>
                        </CommonPropertiesList.Element>
                        <CommonPropertiesList.Element>
                            <CommonSubText>
                                { t("my_detailed_project.aside.term") }
                            </CommonSubText>
                            <CommonText>
                                <span>{project.fundraising.loan.initialTerm} { t(resolveMonthTranslation(project.fundraising.loan.interestRate)) }</span>
                            </CommonText>
                        </CommonPropertiesList.Element>
                        <CommonPropertiesList.Element>
                            <CommonSubText>
                                { t("my_detailed_project.schedule.repayment") }
                            </CommonSubText>
                            <CommonText>
                                { t(myProjectScheduleTranslations[project.fundraising.paymentSchedule.scheduleType]) }
                            </CommonText>
                        </CommonPropertiesList.Element>
                    </CommonPropertiesList>
                </CommonBlock.Section>
            </CommonBlock>
            <CommonBlock>
                <MyProjectStatusResolver status={project.status} />
            </CommonBlock>
        </>
    );
}

export default MyFundraisingProjectAside;