import React from "react";
import {CommonBlock} from "../../../../common/blocks";
import styles from "./AnalyticsPlatformStatistics.module.scss";
import {CommonSubText, CommonText, Heading, Money, TertiaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import {createMoneyFactory} from "../../../../../di";
import {useTranslator} from "../../../../../hooks";

const moneyFactory = createMoneyFactory();

const AnalyticsPlatformStatistics = () => {
    const t = useTranslator();
    return (
        <section className={styles.analytics_platform_statistics}>
            <CommonBlock>
                <CommonBlock.Section underscore={false}>
                    <TertiaryHeading>
                        <Heading headingType={HeadingType.H2} className={styles.analytics_platform_statistics__header}>
                            { t("analytics.platform_statistics") }
                        </Heading>
                    </TertiaryHeading>
                    <ul className={styles.analytics_platform_statistics__list}>
                        <li className={styles.analytics_platform_statistics__element}>
                            <div>
                                <CommonSubText>
                                    { t("analytics.platform_statistics.issued_first") }
                                </CommonSubText>
                                <CommonSubText>
                                    { t("analytics.platform_statistics.issued_second") }
                                </CommonSubText>
                            </div>
                            <div className={styles.analytics_platform_statistics__text}>
                                <Money money={moneyFactory.createMoney(2000000000)} />
                            </div>
                        </li>
                        <li className={styles.analytics_platform_statistics__element}>
                            <CommonSubText>
                                { t("analytics.platform_statistics.number_of_loans") }
                            </CommonSubText>
                            <CommonText>
                                23
                            </CommonText>
                        </li>
                        <li className={styles.analytics_platform_statistics__element}>
                            <CommonSubText>
                                { t("analytics.platform_statistics.number_of_borrowers") }
                            </CommonSubText>
                            <CommonText>
                                30
                            </CommonText>
                        </li>
                        <li className={styles.analytics_platform_statistics__element}>
                            <CommonSubText>
                                { t("analytics.platform_statistics.number_of_lenders") }
                            </CommonSubText>
                            <CommonText>
                                1200
                            </CommonText>
                        </li>
                        <li className={styles.analytics_platform_statistics__element}>
                            <div>
                                <CommonSubText>
                                    { t("analytics.platform_statistics.paid_first") }
                                </CommonSubText>
                                <CommonSubText>
                                    { t("analytics.platform_statistics.paid_second") }
                                </CommonSubText>
                            </div>
                            <div className={styles.analytics_platform_statistics__text}>
                                <Money money={moneyFactory.createMoney(1200000000)} />
                            </div>
                        </li>
                        <li className={styles.analytics_platform_statistics__element}>
                            <CommonSubText>
                                { t("analytics.platform_statistics.yield") }
                            </CommonSubText>
                            <CommonText>
                                26%
                            </CommonText>
                        </li>
                    </ul>
                </CommonBlock.Section>
            </CommonBlock>
        </section>
    );
}

export default AnalyticsPlatformStatistics;