import React from "react";
import {SvgComponent} from "./SvgComponent";
import SvgContainer from "./SvgContainer";
import {SvgContainerSize} from "./SvgContainer/SvgContainer";
import {defaultSvgColorDark} from "./SvgProps";

const ListSvg: SvgComponent = ({ color= defaultSvgColorDark }) => {
    return (
        <SvgContainer size={SvgContainerSize.Small}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 5.5L14 5.5" stroke={color} strokeWidth="2.5" strokeLinecap="round"/>
                <path d="M2 5.5L3 5.5" stroke={color} strokeWidth="2.5" strokeLinecap="round"/>
                <path d="M7 10.5L14 10.5" stroke={color} strokeWidth="2.5" strokeLinecap="round"/>
                <path d="M2 10.5L3 10.5" stroke={color} strokeWidth="2.5" strokeLinecap="round"/>
            </svg>
        </SvgContainer>
    );
}

export default ListSvg