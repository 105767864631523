import React from "react";
import styles from "./RegisterBorrower.module.scss";
import RegisterContainer from "../../../components/pages/register/RegisterContainer";
import BorrowerRegistration from "../../../components/pages/register/borrower/BorrowerRegistration";

const RegisterBorrower = () => {
    return (
        <div className={styles.register_borrower}>
            <RegisterContainer>
                <BorrowerRegistration />
            </RegisterContainer>
        </div>
    );
}

export default RegisterBorrower;