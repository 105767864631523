import {LanguageStorage} from "./LanguageStorage";
import {LanguageProvider} from "./LanguageProvider";
import {invariant} from "../utils";
import {Language} from "../api-client";

export class LanguageResolver {
    constructor(private readonly storage: LanguageStorage, private readonly provider: LanguageProvider) {}

    public resolveUserLanguage(): Language {
        const storedLanguage = this.storage.getPreferredLanguage();
        if (storedLanguage !== null) {
            return this.provider.getByShort(this.formatLanguageCode(storedLanguage));
        }
        const browserLanguage = this.formatLanguageCode(window.navigator.language);
        if (this.provider.getSupportedLanguageCodes().includes(browserLanguage)) {
            return this.provider.getByShort(browserLanguage);
        }
        return this.provider.getDefaultLanguage();
    }

    private formatLanguageCode(lang: string): string {
        return lang.toLowerCase().substring(0, 2);
    }
}