import React, {FC} from "react";
import styles from "./MarketCount.module.scss";
import {resolveOfferTranslation} from "../../../../i18n/wordCases/OffersWordCase";
import {useTranslator} from "../../../../hooks";

interface Props {
    count: number;
}

const MarketCount: FC<Props> = ({ count }) => {
    const t = useTranslator();
    return (
        <div className={styles.market_count}>
            {count} { t(resolveOfferTranslation(count)) }
        </div>
    );
}

export default MarketCount;