import React, {AllHTMLAttributes, FC} from "react";
import useBodyOverflow from "../../../../hooks/useBodyOverflow";
import styles from "./CommonConfirmationSubPage.module.scss";
import {cn} from "../../../../utils";
import {Button} from "../../buttons";
import CloseButton from "../../buttons/decorators/CloseButton";
import {InvestmentLanguageSelect} from "../../../language";

interface Props extends AllHTMLAttributes<HTMLDivElement> {
    closePage: () => void;
}

const CommonConfirmationSubPage: FC<Props> = ({ closePage, ...props }) => {
    useBodyOverflow();
    return (
        <div {...props} className={cn(styles.common_confirmation_sub_page, props.className)}>
            <div className={styles.common_confirmation_sub_page__close}>
                <CloseButton>
                    <Button onClick={closePage} />
                </CloseButton>
            </div>
            <div className={styles.common_confirmation_sub_page__content}>
                { props.children }
            </div>
            <InvestmentLanguageSelect />
        </div>
    );
}

export default CommonConfirmationSubPage;