import React, {AllHTMLAttributes, FC, PropsWithChildren} from "react";
import styles from "./CommonText.module.scss";
import {cn, resultIf} from "../../../../utils";

interface Props extends AllHTMLAttributes<HTMLDivElement> {
    large?: boolean;
}

const CommonText: FC<PropsWithChildren<Props>> = ({ large = false, children, ...props }) => {
    return (
        <div className={cn(styles.common_text, resultIf(large, styles.common_text___large), props.className)}>
            { children }
        </div>
    );
}

export default CommonText;