import React, {FC, useContext, useMemo, useState} from "react";
import {GuarantorContext, GuarantorStep} from "../GuarantorContextProvider";
import {
    Button,
    Checkbox,
    DatePicker,
    Heading,
    IdentificationPassportFiles,
    InputErrors, Loader,
    PrimaryButton,
    PrimaryHeading
} from "../../../common";
import {HeadingType} from "../../../common/typography/headings/Heading/Heading";
import {CommonInput} from "../../../common/inputs";
import IdentificationPassport from "../../../common/utils/identification/IdentificationPassport";
import {PrimaryButtonColor} from "../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {
    useEffectOnUpdate,
    useErrorCallback,
    usePassport,
    usePassportPhotos,
    useToggle,
    useTranslator
} from "../../../../hooks";
import {
    PassportPhotoErrors
} from "../../../common/utils/identification/IdentificationPassportFiles/IdentificationPassportFiles";
import {FullName, GuarantorInput} from "../../../../api-client";
import {createGuarantorManager} from "../../../../di";
import {formatApiDate} from "../../../../formatters";
import {PassportErrors, PersonalDataErrors} from "../../../../hooks/data/validation";

type FormErrors = PersonalDataErrors & PassportErrors & PassportPhotoErrors;

interface Props {
    uuid: string;
}

const GuarantorForm: FC<Props> = ({ uuid }) => {
    const t = useTranslator();
    const context = useContext(GuarantorContext);
    const handleError = useErrorCallback();
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [birthday, setBirthday] = useState<Date>(new Date());

    const fullName = useMemo((): FullName => {
        return {
            firstName,
            middleName,
            lastName
        }
    }, [firstName, middleName, lastName]);

    const [passport, setPassport] = usePassport(context.guarantor?.passport);
    const [passportPhotos, setPassportPhotos] = usePassportPhotos();
    const [conditionsAgree, toggleConditions] = useToggle();
    const [errors, setErrors] = useState<FormErrors>({});
    const [loading, setLoading] = useState(false);

    useEffectOnUpdate(() => {
        setPassport({
            ...passport,
            firstPhotos: passportPhotos.firstPhotos.map((file) => file.url),
            secondPhotos: passportPhotos.secondPhotos.map((file) => file.url),
            selfiePhotos: passportPhotos.selfiePhotos.map((file) => file.url),
        });
    }, [passportPhotos]);

    const formattedBirthday = useMemo(() => {
        return formatApiDate(birthday);
    }, [birthday]);

    const guarantorInput = useMemo((): GuarantorInput => {
        return {
            fullName,
            birthday: formattedBirthday,
            passport
        }
    }, [fullName, birthday, passport]);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const manager = createGuarantorManager();
            await manager.updateGuarantor(uuid, guarantorInput);
            context.setGuarantor({ uuid: uuid,...guarantorInput, birthday: new Date(formattedBirthday), signedAt: undefined });
            context.setStep(GuarantorStep.CheckStatus);
        } catch (error: any) {
            await handleError(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="funding-request-identification">
            <PrimaryHeading>
                <Heading headingType={HeadingType.H1} className="funding-request__heading funding-request-identification__heading">
                    { t("guarantor") }
                </Heading>
            </PrimaryHeading>
            <form className="funding-request-identification__form" onSubmit={handleSubmit}>
                <div className="funding-request-identification__block">
                    <div className="funding-request-identification__subheader">
                        { t("guarantor.personal_data") }
                    </div>
                    <div>
                        <CommonInput
                            value={lastName}
                            onChange={setLastName}
                            text={ t("guarantor.personal_data.last_name") }
                            error={errors.lastName !== undefined}
                        />
                        { errors.lastName && <InputErrors errors={errors.lastName} /> }
                    </div>
                    <div>
                        <CommonInput
                            value={firstName}
                            onChange={setFirstName}
                            text={ t("guarantor.personal_data.first_name") }
                            error={false}
                        />
                        { errors.firstName && <InputErrors errors={errors.firstName} /> }
                    </div>
                    <div>
                        <CommonInput
                            value={middleName}
                            onChange={setMiddleName}
                            text={ t("guarantor.personal_data.middle_name") }
                            error={false}
                        />
                        { errors.lastName && <InputErrors errors={errors.lastName} /> }
                    </div>
                    <div>
                        <DatePicker
                            value={birthday}
                            onSelected={setBirthday}
                            text={ t("guarantor.personal_data.birthday") }
                        />
                    </div>
                </div>
                <IdentificationPassport
                    passport={passport}
                    setPassport={setPassport}
                    errors={errors}
                />
                <IdentificationPassportFiles
                    photos={passportPhotos}
                    setPhotos={setPassportPhotos}
                    errors={errors}
                />
                <div className="funding-request-identification__approve">
                    <div className="funding-request-identification__subheader">
                        { t("guarantor.confirmation") }
                    </div>
                    <Checkbox checked={conditionsAgree} onChange={toggleConditions}>
                        { t("guarantor.confirmation.first") } <a href="#" className="funding-request-identification__link">{ t("guarantor.confirmation.second") }</a>
                    </Checkbox>
                </div>
                { loading && <div>
                    <Loader />
                </div> }
                <div>
                    <PrimaryButton expanded color={PrimaryButtonColor.BLUE}>
                        <Button type="submit" onClick={handleSubmit}>
                            { t("guarantor.send") }
                        </Button>
                    </PrimaryButton>
                </div>
            </form>
        </div>
    );
}

export default GuarantorForm;