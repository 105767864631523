import {ApiClientInterface} from "./clients";
import {
    Money,
    UuidResult,
    InvestmentSmsConfirmation,
    InvestmentConfirmation,
    Collection,
    FundRaisingProjectInvestment,
    LoanPaymentProjectInvestment,
    PaymentSchedule,
    RefundedProjectInvestment,
    InvestmentCancelConfirmation, ProjectInvestment, Investment
} from "./models";
import {
    DefaultInvestmentsFilter,
    LoanPaymentInvestmentsFilter,
    MyFundraisingInvestmentsFilter,
    RefundedInvestmentsFilter, SellingInvestmentsFilter
} from "../utils";
import buildQuery from "../utils/router/buildQuery";

export class InvestmentManager {
    constructor(private readonly apiClient: ApiClientInterface) {}

    public async getDetailedInvestment(uuid: string): Promise<Investment> {
        return this.apiClient.get(`/${uuid}`);
    }

    public async getListFundraisingOwned(
        page: number,
        perPage: number,
        filter?: MyFundraisingInvestmentsFilter
    ): Promise<Collection<FundRaisingProjectInvestment>> {
        const query = buildQuery({ page, perPage, filter });
        return this.apiClient.get(`/fund-raising/owned?${query}`);
    }

    public async getListLoanPaymentOwned(
        page: number,
        perPage: number,
        filter?: LoanPaymentInvestmentsFilter
    ): Promise<Collection<LoanPaymentProjectInvestment>> {
        const query = buildQuery({ page, perPage, filter });
        return this.apiClient.get(`/loan-payment/owned?${query}`);
    }

    public async getListRefundedOwned(
        page: number,
        perPage: number,
        filter?: RefundedInvestmentsFilter
    ): Promise<Collection<RefundedProjectInvestment>> {
        const query = buildQuery({ page, perPage, filter });
        return this.apiClient.get(`/refunded/owned?${query}`);
    }

    public async getListDefaultOwned(
        page: number,
        perPage: number,
        filter?: DefaultInvestmentsFilter
    ): Promise<Collection<RefundedProjectInvestment>> {
        const query = buildQuery({ page, perPage, filter });
        return this.apiClient.get(`/default/owned?${query}`);
    }

    public async getListSellingOwned(
        page: number,
        perPage: number,
        filter?: SellingInvestmentsFilter
    ): Promise<Collection<RefundedProjectInvestment>> {
        const query = buildQuery({ page, perPage, filter });
        return this.apiClient.get(`/selling/owned?${query}`);
    }

    public async createInvestmentConfirmation(projectUuid: string, money: Money): Promise<UuidResult>{
        return this.apiClient.post("/confirmation", {projectUuid, money});
    }

    public async getPreliminarySchedule(projectUuid: string, sum: Money): Promise<PaymentSchedule> {
        return this.apiClient.post(`/${projectUuid}/preliminary`, {sum});
    }

    public async requestCode(confirmationUuid: string): Promise<InvestmentSmsConfirmation> {
        return this.apiClient.post(`/confirmation/${confirmationUuid}/request`);
    }

    public async confirmCode(confirmationUuid: string, smsCode: string): Promise<void> {
        return this.apiClient.post(`/confirmation/${confirmationUuid}/confirm`, {smsCode});
    }

    public async createInvestment(confirmationUuid: string): Promise<UuidResult> {
        return this.apiClient.post("", {confirmationUuid});
    }
}