import React, {AllHTMLAttributes, FC, PropsWithChildren} from "react";
import styles from "./MyProjectColumn.module.scss";
import {cn} from "../../../../utils";

interface Props extends AllHTMLAttributes<HTMLDivElement> {}

const MyProjectColumn: FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
    return <div {...props} className={cn(styles.my_project_column, props.className)}>
        { children }
    </div>
}

export default MyProjectColumn;