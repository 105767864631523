import React, {useContext} from "react";
import {AnalyticsUserContext} from "../../AnalyticsUserContext";
import AnalyticsRows from "../../../rows/AnalyticsRows";
import AnalyticsProfitabilityHeader from "../../../rows/profitability/AnalyticsProfitabilityHeader";
import AnalyticsProfitability from "../../../rows/profitability/AnalyticsProfitability";
import BarChart from "../../../../../chart/BarChart";
import moment from "moment/moment";

const AnalyticsUserProfit = () => {
    const profitabilities = useContext(AnalyticsUserContext).analytics?.profitabilities;
    if (profitabilities === undefined) {
        return <div>Analytics is undefined (somehow)</div>
    }

    const renderProfitabilities = () => {
        return profitabilities.map((profitability, index) => {
            return <AnalyticsProfitability profitability={profitability} key={index} />
        });
    }

    const chartData = profitabilities.map((entry) => {
        return entry.irr;
    });
    const chartHeadings = profitabilities.map((entry) => {
        const momentDate = moment(entry.date);
        return [momentDate.format("MMM"), momentDate.format("YYYY")];
    });

    return (
        <AnalyticsRows>
            <div className="user-analytics__chart">
                <BarChart
                    data={chartData}
                    labels={chartHeadings}
                    stepSize={25}
                    ticksFunc={(tickValue) => `${Math.floor(+tickValue)} %`}
                />
            </div>
            <AnalyticsProfitabilityHeader />
            <ul>
                { renderProfitabilities() }
            </ul>
        </AnalyticsRows>
    );
}

export default AnalyticsUserProfit;