import React, {useContext} from "react";
import SecondaryNavigation from "../../../layout/common/SecondaryNavigation";
import "./FundingRequestNavigation.scoped.scss";
import {AsLink, Button} from "../../../common";
import {FundingRequestContext, FundingRequestStep} from "../FundingRequestContext";
import {cn, resultIf, RouteDictionary} from "../../../../utils";
import SecondaryNavigationLink from "../../../layout/common/SecondaryNavigation/extensions/SecondaryNavigationLink";
import BigCloseSvg from "../../../svg/BigCloseSvg";
import {useNavigateByName, useTranslator} from "../../../../hooks";
import {ProjectStatus} from "../../../../api-client";

const FundingRequestNavigation = () => {
    const t = useTranslator();
    const navigate = useNavigateByName();

    const handleClose = () => {
        navigate(RouteDictionary.MY_PROJECTS);
    }

    const { step, setStep, request } = useContext(FundingRequestContext);
    const projectRejected = request!== null && request.rejectionReason !== undefined && request.rejectionReason !== null;
    return (
        <SecondaryNavigation>
            <SecondaryNavigation.List>
                <SecondaryNavigation.Link>
                    <AsLink>
                        <Button
                            className={cn(resultIf(step === FundingRequestStep.UploadDocuments, "active"))}
                            onClick={() => setStep(FundingRequestStep.UploadDocuments)}
                        >
                            01 { t("funding_request.navigation.upload_documents") }
                        </Button>
                    </AsLink>
                </SecondaryNavigation.Link>
                <SecondaryNavigation.Link>
                    <AsLink>
                        <Button
                            className={cn(resultIf(step === FundingRequestStep.CountAvailableSum, "active"))}
                            onClick={() => setStep(FundingRequestStep.CountAvailableSum)}
                            disabled={request === null}
                        >
                            02 { t("funding_request.navigation.count_available") }
                        </Button>
                    </AsLink>
                </SecondaryNavigation.Link>
                { !projectRejected && <>
                    <SecondaryNavigation.Link>
                        <AsLink>
                            <Button
                                className={cn(resultIf(step === FundingRequestStep.ChooseLoanOption, "active"))}
                                onClick={() => setStep(FundingRequestStep.ChooseLoanOption)}
                                disabled={request === null || request.projectStatus === ProjectStatus.REVIEW}
                            >
                                03 { t("funding_request.navigation.loan_options") }
                            </Button>
                        </AsLink>
                    </SecondaryNavigation.Link>
                    <SecondaryNavigationLink>
                        <AsLink>
                            <Button
                                className={cn(resultIf(step === FundingRequestStep.SignDocuments, "active"))}
                                onClick={() => setStep(FundingRequestStep.SignDocuments)}
                                disabled={request === null || request.desiredMoney === undefined}
                            >
                                04 { t("funding_request.navigation.sign_documents") }
                            </Button>
                        </AsLink>
                    </SecondaryNavigationLink>
                </> }
            </SecondaryNavigation.List>
            <div className="funding-request-navigation__close">
                <AsLink>
                    <Button onClick={handleClose}>
                        <BigCloseSvg />
                    </Button>
                </AsLink>
            </div>
        </SecondaryNavigation>
    );
}

export default FundingRequestNavigation;