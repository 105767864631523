import React from "react";
import {CURRENT_CURRENCY_SYMBOL} from "../../configs";

const CurrentCurrency = () => {
    return (
        <>
            { CURRENT_CURRENCY_SYMBOL() }
        </>
    );
}

export default React.memo(CurrentCurrency);