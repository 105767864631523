import React from "react";
import {defaultSvgColor} from "../SvgProps";
import {SvgComponent} from "../SvgComponent";
import SvgContainer from "../SvgContainer";

const LogoutSvg: SvgComponent = ({ color = defaultSvgColor }) => {
    return (
        <SvgContainer>
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 1.25C5 1.66421 4.66421 2 4.25 2L2.25 2C2.11193 2 2 2.11193 2 2.25L2 14.25C2 14.3881 2.11193 14.5 2.25 14.5L4.25 14.5C4.66421 14.5 5 14.8358 5 15.25C5 15.6642 4.66421 16 4.25 16L2.25 16C1.2835 16 0.499999 15.2165 0.499999 14.25L0.5 2.25C0.5 1.2835 1.2835 0.499999 2.25 0.499999L4.25 0.499999C4.66421 0.499999 5 0.835786 5 1.25Z" fill={color} />
                <path d="M17.5 6.98795C17.5 6.47356 17.1069 6.04447 16.5945 5.99955C14.8417 5.84591 13.0804 5.81881 11.3242 5.91838C11.3105 5.67139 11.2947 5.4245 11.2767 5.17773L11.1681 3.68822C11.1263 3.11392 10.4859 2.79288 10.0007 3.10293C8.33859 4.16497 6.89366 5.53343 5.74291 7.13538L5.31424 7.73212C5.09191 8.04162 5.09191 8.45849 5.31424 8.76799L5.74291 9.36473C6.89366 10.9667 8.33859 12.3351 10.0007 13.3972C10.4859 13.7072 11.1263 13.3862 11.1681 12.8119L11.2767 11.3224C11.2947 11.0756 11.3105 10.8287 11.3242 10.5817C13.0804 10.6813 14.8417 10.6542 16.5945 10.5006C17.1069 10.4556 17.5 10.0265 17.5 9.51216L17.5 6.98795Z" fill={color} />
            </svg>
        </SvgContainer>
    );
}

export default LogoutSvg;