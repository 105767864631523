import {Translations} from "../../types";

export const profile: Translations = {
    "profile": "Profile",
    "profile.edit": "Edit",
    "profile.cancel": "Cancel",
    "profile.account": "Account data",
    "profile.passport": "Passport data",
    "profile.passport.disclaimer": "When changing passport data, additional identification will be required." +
        "At this time, the functionality of the platform will be unavailable.",
    "profile.documents": "Documents",
    "profile.save": "Save the changes",
}