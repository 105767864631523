import {ApiClientInterface} from "./clients";
import {BorrowerProjectsCount, Collection, MyActiveProject, MyFundraisingProject, MyModeratedProject, DetailedMyProject} from "./models";
import buildQuery from "../utils/router/buildQuery";
import {
    MyActiveProjectsFilter,
    MyFundraisingProjectsFilter,
    MyModeratedProjectsFilter,
    MyRefundedProjectsFilter
} from "../utils";

export class MyProjectsProvider {
    constructor(private readonly apiClient: ApiClientInterface, private readonly abortSignal: AbortSignal) {}

    public async getProjectsCount(): Promise<BorrowerProjectsCount> {
        return this.apiClient.get("/count");
    }

    public async getOne(uuid: string): Promise<DetailedMyProject> {
        return this.apiClient.get(`/${uuid}`);
    }

    public async getActiveList(page: number, perPage: number, filter: MyActiveProjectsFilter): Promise<Collection<MyActiveProject>> {
        const query = buildQuery({page, perPage, filter});
        return this.apiClient.get(`/active?${query}`, {}, this.abortSignal);
    }

    public async getFundraisingList(page: number, perPage: number, filter: MyFundraisingProjectsFilter): Promise<Collection<MyFundraisingProject>> {
        const query = buildQuery({page, perPage, filter});
        return this.apiClient.get(`/fundraising?${query}`, {}, this.abortSignal);
    }

    public async getRefundedList(page: number, perPage: number, filter: MyRefundedProjectsFilter) {
        const query = buildQuery({page, perPage, filter});
        return this.apiClient.get(`/refunded?${query}`, {}, this.abortSignal);
    }

    public async getModeratedList(page: number, perPage: number, filter: MyModeratedProjectsFilter): Promise<Collection<MyModeratedProject>> {
        const query = buildQuery({ page, perPage, filter });
        return this.apiClient.get(`/moderated?${query}`);
    }
}