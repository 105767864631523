import React, {useEffect, useState} from "react";
import "./MyLoans.scoped.scss";
import Section from "../../../../components/layout/common/Section";
import {Heading, PrimaryButton, PrimaryHeading, ProjectLink} from "../../../../components/common";
import {HeadingType} from "../../../../components/common/typography/headings/Heading/Heading";
import {Outlet} from "react-router-dom";
import MyProjectsNavigation from "../../../../components/pages/my-projects/layout/MyProjectsNavigation";
import {PrimaryButtonColor} from "../../../../components/common/buttons/decorators/PrimaryButton/PrimaryButton";
import {RouteDictionary} from "../../../../utils";
import {BorrowerProjectsCount, PaymentScheduleType} from "../../../../api-client";
import {useErrorCallback, useTranslator} from "../../../../hooks";
import {createMyProjectsProvider} from "../../../../di";

export const loansScheduleTypeTranslations: Record<PaymentScheduleType, string> = {
    [PaymentScheduleType.MONTHLY_PAYMENTS]: "my_loans.schedule.monthly",
    [PaymentScheduleType.MONTHLY_TWO_PAYMENTS]: "my_loans.schedule.monthly_twice",
    [PaymentScheduleType.MONTHLY_THREE_PAYMENTS]: "my_loans.schedule.monthly_thrice",
    [PaymentScheduleType.MONTHLY_FOUR_PAYMENTS]: "my_loans.schedule.monthly_four_times",
    [PaymentScheduleType.QUARTERLY_PAYMENTS]: "my_loans.schedule.monthly_quarterly",
    [PaymentScheduleType.PAYMENT_AT_THE_END]: "my_loans.schedule.monthly_at_the_end",
}

const MyLoans = () => {
    const t = useTranslator();
    const handleError = useErrorCallback();
    const [projectsCount, setProjectsCount] = useState<BorrowerProjectsCount | undefined>(undefined);
    useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            try {
                const provider = await createMyProjectsProvider(abortController.signal);
                const results = await provider.getProjectsCount();
                setProjectsCount(results);
            } catch (error: any) {
                await handleError(error);
            }
        })();
        return () => {
            abortController.abort();
        }
    }, []);
    return (
        <div className="my-loans">
            <Section className="my-loans__section">
                <PrimaryHeading>
                    <Heading headingType={HeadingType.H1} className="my-loans__header">
                        { t("my_loans") }
                    </Heading>
                </PrimaryHeading>
                <div className="my-loans__navigation">
                    <MyProjectsNavigation projectsCount={projectsCount} />
                </div>
                <Outlet />
            </Section>
            <div className="my-loans__new">
                <PrimaryButton color={PrimaryButtonColor.BLUE}>
                    <ProjectLink to={RouteDictionary.FUNDING_REQUEST}>
                        { t("my_loans.new") }
                    </ProjectLink>
                </PrimaryButton>
            </div>
        </div>
    );
}

export default MyLoans;