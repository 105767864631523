import React, {FC} from "react";
import {ProjectStatus} from "../../../../../api-client";
import MyProjectStatus from "./MyProjectStatus";
import MyProjectReviewStatus from "./MyProjectReviewStatus";
import MyProjectReviewApprovedStatus from "./MyProjectReviewApprovedStatus";
import MyProjectReviewRejectedStatus from "./MyProjectReviewRejectedStatus";
import MyProjectFinalReviewStatus from "./MyProjectFinalReviewStatus";
import MyProjectFinalReviewApprovedStatus from "./MyProjectFinalReviewApprovedStatus";
import MyProjectFinalReviewRejectedStatus from "./MyProjectFinalReviewRejectedStatus";
import MyProjectFundraisingStatus from "./MyProjectFundraisingStatus/MyProjectFundraisingStatus";
import MyProjectFinishedStatus from "./MyProjectFinishedStatus";
import {useTranslator} from "../../../../../hooks";

interface Props {
    status: ProjectStatus;
}

const MyProjectStatusResolver: FC<Props> = ({ status }) => {
    const t = useTranslator();
    if (status === ProjectStatus.REVIEW) {
        return <MyProjectReviewStatus />
    }
    if (status === ProjectStatus.REVIEW_APPROVED) {
        return <MyProjectReviewApprovedStatus />
    }
    if (status === ProjectStatus.REVIEW_REJECTED) {
        return <MyProjectReviewRejectedStatus />
    }
    if (status === ProjectStatus.FINAL_REVIEW) {
        return <MyProjectFinalReviewStatus />
    }
    if (status === ProjectStatus.FINAL_REVIEW_APPROVED) {
        return <MyProjectFinalReviewApprovedStatus />
    }
    if (status === ProjectStatus.FINAL_REVIEW_REJECTED) {
        return <MyProjectFinalReviewRejectedStatus />
    }
    if (status === ProjectStatus.FUND_RAISING) {
        return <MyProjectFundraisingStatus />
    }
    if (status === ProjectStatus.FINISHED) {
        return <MyProjectFinishedStatus />
    }
    return (
        <MyProjectStatus>
            { t("my_detailed_project.status.unknown") }
        </MyProjectStatus>
    );
}

export default MyProjectStatusResolver;