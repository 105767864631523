import React from "react";
import {SvgComponent} from "../SvgComponent";
import SvgContainer from "../SvgContainer";
import {SvgContainerSize} from "../SvgContainer/SvgContainer";
import {defaultStatSvgColor} from "./defaultStatSvgColor";

const ArrowDownSvg: SvgComponent = ({ color= defaultStatSvgColor }) => {
    return (
        <SvgContainer size={SvgContainerSize.Small}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1 3.25L5.5 8.25L8.3827 5.527L15 12.75M15 12.75L9 12.25M15 12.75V6.75"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </SvgContainer>
    );
}

export default ArrowDownSvg;