import {ApiClientInterface} from "./clients";
import {ArchivedProject, Collection, FundRaisingProject, MyActiveProject, MyFundraisingProject} from "./models";
import {DetailedProject} from "./models";
import buildQuery from "../utils/router/buildQuery";
import {FundraisingProjectsFilter} from "../utils";

export class ProjectManager {
    constructor(private readonly apiClient: ApiClientInterface) {}

    getDetailedProject(uuid: string): Promise<DetailedProject> {
        return this.apiClient.get(`/${uuid}`);
    }

    getFundRaisingProjects(page: number, perPage: number, filter?: FundraisingProjectsFilter): Promise<Collection<FundRaisingProject>> {
        const query = buildQuery({ page, perPage, filter });
        return this.apiClient.get(`/fund-raising?${query}`);
    }

    getArchivedProjects(page: number, perPage: number): Promise<Collection<ArchivedProject>> {
        const query = buildQuery({ page, perPage });
        return this.apiClient.get(`/archived?${query}`);
    }
}