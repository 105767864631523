import {Translations} from "../../types";

export const investment_confirmation: Translations = {
    "investment_confirmation": "Инвестировать в заём",
    "investment_confirmation.sum": "Сумма заявки",
    "investment_confirmation.term": "Срок",
    "investment_confirmation.rate": "Ставка",
    "investment_confirmation.create": "Создаем подтверждение...",
    "investment_confirmation.balance": "Баланс",
    "investment_confirmation.input": "Сумма инвестиции (от 400 :currency до 1 000 000 :currency)",
    "investment_confirmation.final_sum": "Сумма выплаты с учетом дохода",
    "investment_confirmation.invest": "Инвестировать",
    "investment_confirmation.confirm": "Нажимая «Инвестировать» я понимаю, что инвестирование в займ является высокорискованным.",
    "investment_confirmation.preliminary": "Предварительный график платежей",
    "investment_confirmation.generating_schedule": "Генерируем график..."
}