import React, {FC} from "react";
import {UploadedFile} from "../../../../api-client";
import './FundingRequestFile.scoped.scss';
import CloseSvg from "../../../svg/CloseSvg";

interface Props {
    file: UploadedFile;
    onRemoved: () => void;
}

const FundingRequestFile: FC<Props> = ({ file, onRemoved }) => {
    const formatFilename = (): string => {
        const { name } = file;
        if (name.length > 30) {
            return name.substring(0, 15) + "..." + name.substring(name.length - 6);
        }
        return name;
    }
    return (
        <div className="funding-request-file">
            <span className="funding-request-file__name">
                { formatFilename() }
            </span>
            <div className="funding-request-file__close" onClick={onRemoved}>
                <CloseSvg />
            </div>
        </div>
    );
}

const MemoizedFundingRequestFile = React.memo(FundingRequestFile);

export default MemoizedFundingRequestFile;