import {ProjectsFilter} from "./ProjectsFilter";

export const compareProjectsFilter = (prev?: ProjectsFilter, curr?: ProjectsFilter) => {
    if (prev && curr) {
        return prev.loanAmountFrom === curr.loanAmountFrom &&
            prev.loanAmountTo === curr.loanAmountTo &&
            prev.interestRateFrom === curr.interestRateFrom &&
            prev.interestRateTo === curr.interestRateTo &&
            prev.initialTermFrom === curr.initialTermFrom &&
            prev.initialTermTo === curr.initialTermTo &&
            prev.loanAmountSorting === curr.loanAmountSorting &&
            prev.interestRateSorting === curr.interestRateSorting &&
            prev.initialTermSorting === curr.initialTermSorting &&
            prev.paymentScheduleType === curr.paymentScheduleType;
    }
    return prev === curr;
}