import React, {FC} from "react";
import {matchPath, useLocation} from "react-router-dom";
import Tabs from "../../../../layout/common/Tabs";
import {ProjectLink} from "../../../../common";
import {RouteDictionary} from "../../../../../utils";
import {BorrowerProjectsCount} from "../../../../../api-client";
import {useTranslator} from "../../../../../hooks";

interface Props {
    projectsCount: BorrowerProjectsCount | undefined;
}

const MyProjectsNavigation: FC<Props> = ({ projectsCount }) => {
    const t = useTranslator();
    const { pathname } = useLocation();
    return (
        <>
            { projectsCount === undefined ? <div>{ t("common.loading") }</div> : <Tabs>
                <ProjectLink
                    to={RouteDictionary.MY_PROJECTS_ACTIVE}
                    explicitActive={matchPath("borrower/my-projects", pathname) !== null}
                >
                    { t("my_loans.navigation.active") } ({projectsCount.activeCount})
                </ProjectLink>
                <ProjectLink to={RouteDictionary.MY_PROJECTS_FUNDRAISING}>
                    { t("my_loans.navigation.fundraising") } ({projectsCount.fundraisingCount})
                </ProjectLink>
                <ProjectLink to={RouteDictionary.MY_PROJECTS_REFUNDED}>
                    { t("my_loans.navigation.returned") } ({projectsCount.refundedCount})
                </ProjectLink>
                <ProjectLink to={RouteDictionary.MY_PROJECTS_MODERATED}>
                    { t("my_loans.navigation.moderated") } ({projectsCount.moderatedCount})
                </ProjectLink>
            </Tabs> }
        </>
    );
}

export default MyProjectsNavigation;