import React, {FC} from "react";
import "./DetailedProjectTurnoverPeriods.scoped.scss";
import {Period, NetCreditTurnoverPeriod, Money} from "../../../../../api-client";
import {useTranslator} from "../../../../../hooks";

interface Props {
    periods: Array<NetCreditTurnoverPeriod>
}

const translatePeriod: Record<Period, string> = {
    [Period.OneMonth]: "detailed_project.turnover_period.month",
    [Period.ThreeMonths]: "detailed_project.turnover_period.three_months",
    [Period.SixMonths]: "detailed_project.turnover_period.six_months",
    [Period.TwelveMonths]: "detailed_project.turnover_period.twelve_months",
    [Period.AllTime]: "detailed_project.turnover_period.all_time",
}

const DetailedProjectTurnoverPeriods: FC<Props> = ({ periods }) => {
    const t = useTranslator();

    const convertAmount = (money: Money) => {
        const multiplier = 100;
        if (money.amount < Math.pow(10, 6) - 1) {
            return `${(money.amount / (1000 * multiplier)).toFixed(1)} ${t("common.chart_tho")}.`;
        }
        return `${(money.amount / (1000000 * multiplier)).toFixed(1)} ${t("common.chart_mln")}.`;
    }

    const renderShorts = () => {
        return periods.map((turnoverPeriod) => {
            return <div key={turnoverPeriod.period} className="detailed-project-analytics-short">
                <div className="detailed-project-analytics-short__text">
                    { convertAmount(turnoverPeriod.averageSum) }
                </div>
                <div className="detailed-project-analytics-short__subtext">
                    { t(translatePeriod[turnoverPeriod.period]) }
                </div>
            </div>
        })
    }
    return (
        <div className="detailed-project-analytics-shorts">
            { renderShorts() }
        </div>
    );
}

export default DetailedProjectTurnoverPeriods;