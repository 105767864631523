export enum ColorDictionary {
    WHITE = "--white-color",
    BLACK = "--black-color",
    ERROR = "--error-color",
    PRIMARY_GRAY = "--primary-gray-color",
    SECONDARY_GRAY = "--secondary-gray-color",
    STROKE_GRAY = "--stroke-gray-color",
    PRIMARY_BLUE = "--primary-blue-color",
    PRIMARY_DARK = "--primary-dark-color",
    SECONDARY_DARK = "--secondary-dark-color",
    ADDITIONAL_DARK = "--additional-dark-color",
    PRIMARY_GREEN = "--primary-green-color",
    SECONDARY_GREEN = "--secondary-green-color",
    ADDITIONAL_GREEN = "--additional-green-color",
    ADDITIONAL_ORANGE = "--additional-orange-color"
}