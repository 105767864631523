import React, {useMemo, useState} from "react";
import styles from "./LenderRegistrationForm.module.scss";
import registerStyles from "../register.module.scss";
import {cn, Errors as ValidationErrors, resultIf, RouteDictionary} from "../../../../utils";
import {LenderType} from "../../../../api-client";
import {
    useCurrentLanguage,
    useEffectOnUpdate,
    useErrorCallback,
    useNavigateByName,
    useToggle,
    useTranslator
} from "../../../../hooks";
import {createRegistrationManager} from "../../../../di";
import {Button, Checkbox, Heading, InputErrors, PrimaryButton, PrimaryHeading, PublicLink} from "../../../common";
import {PrimaryButtonColor} from "../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {HeadingType} from "../../../common/typography/headings/Heading/Heading";
import {CommonInput, InputPassword} from "../../../common/inputs";

interface RegisterInput {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    type: LenderType | undefined;
    rulesAgree: boolean;
    policyAgree: boolean;
}

type FormErrors = {
    name?: ValidationErrors;
    email?: ValidationErrors;
    password?: ValidationErrors;
    confirmPassword?: ValidationErrors;
    type?: ValidationErrors;
    rulesAgree?: ValidationErrors;
    policyAgree?: ValidationErrors;
}

const LenderRegistrationForm = () => {
    const t = useTranslator();
    const currentLanguage = useCurrentLanguage();
    const handleError = useErrorCallback();
    const navigate = useNavigateByName();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [type, setType] = useState<LenderType | undefined>(undefined);
    const [rulesAgree, toggleRules] = useToggle(false);
    const [policyAgree, togglePolicy] = useToggle(false);
    const [loading, setLoading] = useState(false);

    const registerInput = useMemo((): RegisterInput => {
        return {
            name,
            email,
            password,
            confirmPassword,
            type,
            rulesAgree,
            policyAgree
        }
    }, [name, email, password, confirmPassword, type, rulesAgree, policyAgree]);

    const [errors, setErrors] = useState<FormErrors>({});

    const validateForm = (): boolean => {
        const newErrors: FormErrors = {};
        if (registerInput.name === "") {
            newErrors.name = [t(
                "errors.field_required",
                { field: t("errors.fields.name"), type: t("errors.field_required.neuter") }
            )];
        }
        if (registerInput.email === "") {
            newErrors.email = [t(
                "errors.field_required",
                { field: t("errors.fields.email"), type: t("errors.field_required.feminine") }
            )];
        }
        if (registerInput.password === "") {
            newErrors.password = [t(
                "errors.field_required",
                { field: t("errors.fields.password"), type: t("errors.field_required.masculine") }
            )];
        }
        if (registerInput.confirmPassword === "") {
            newErrors.confirmPassword = [t("errors.confirm_password")];
        } else if (registerInput.confirmPassword !== registerInput.password) {
            newErrors.confirmPassword = [t("errors.passwords_do_not_match")];
        }
        if (registerInput.type === undefined) {
            newErrors.type = [t(
                "errors.field_required",
                { field: t("errors.fields.register_type"), type: t("errors.field_required.masculine") }
            )];
        }
        if (!registerInput.rulesAgree) {
            newErrors.rulesAgree = [
                t("errors.accept_something",
                    { document: t("errors.fields.platform_rules").toLowerCase() }
                )
            ];
        }
        if (!registerInput.policyAgree) {
            newErrors.policyAgree = [
                t("errors.accept_something",
                    { document: t("errors.fields.privacy_policy").toLowerCase() }
                )
            ];
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const [initialInput, setInitialInput] = useState(true);

    useEffectOnUpdate(() => {
        if (!initialInput) {
            validateForm();
        }
    }, [registerInput, currentLanguage]);

    const handleSubmit = async () => {
        if (validateForm()) {
            setLoading(true);
            try {
                const manager = await createRegistrationManager();
                await manager.registerLender({
                    name: name,
                    email: email,
                    password: password,
                    type: type!
                });
                navigate(RouteDictionary.LOGIN);
            } catch (error: any) {
                await handleError(error);
            } finally {
                setLoading(false);
            }
        }
        setInitialInput(false);
    }

    return (
        <div>
            <PrimaryHeading>
                <Heading headingType={HeadingType.H1} className={registerStyles.register__heading}>
                    { t("auth.register_lender") }
                </Heading>
            </PrimaryHeading>
            <div className={styles.register_lender}>
                <div className={styles.register_lender__inputs}>
                    <div>
                        <CommonInput
                            value={name}
                            onChange={setName}
                            id="name"
                            text={t("common.name")}
                            error={false}
                        />
                        { errors.name && <InputErrors errors={errors.name} /> }
                    </div>
                    <div>
                        <CommonInput
                            value={email}
                            onChange={setEmail}
                            id="email"
                            text={t("common.email")}
                            error={false}
                        />
                        { errors.email && <InputErrors errors={errors.email} /> }
                    </div>
                    <div>
                        <InputPassword
                            password={password}
                            onChangePassword={setPassword}
                            id="password"
                            text={t(
                                "auth.register.password",
                                { length: "6" }
                            )}
                            error={false}
                        />
                        { errors.password && <InputErrors errors={errors.password} /> }
                    </div>
                    <div>
                        <InputPassword
                            password={confirmPassword}
                            onChangePassword={setConfirmPassword}
                            id="confirm-password"
                            text={t("auth.register.confirm_password")}
                            error={false}
                        />
                        { errors.confirmPassword && <InputErrors errors={errors.confirmPassword} /> }
                    </div>
                </div>
                <div className={styles.register_lender__types}>
                    <div
                        className={cn(
                            styles.register_lender__type,
                            resultIf(type === LenderType.INDIVIDUAL, styles.register_lender__type___active)
                        )}
                        onClick={() => setType(LenderType.INDIVIDUAL)}
                    >
                        { t("common.individual") }
                    </div>
                    <div
                        className={cn(
                            styles.register_lender__type,
                            resultIf(type === LenderType.ENTREPRENEUR, styles.register_lender__type___active)
                        )}
                        onClick={() => setType(LenderType.ENTREPRENEUR)}
                    >
                        { t("common.entrepreneur") }
                    </div>
                    <div
                        className={cn(
                            styles.register_lender__type,
                            resultIf(type === LenderType.LEGAL_ENTITY, styles.register_lender__type___active)
                        )}
                        onClick={() => setType(LenderType.LEGAL_ENTITY)}
                    >
                        { t("common.legal_entity") }
                    </div>
                </div>
                { errors.type && <InputErrors errors={errors.type} /> }
                <div className={styles.register_lender__checkboxes}>
                    <Checkbox id="interaction" checked={policyAgree} onChange={togglePolicy}>
                        <PublicLink className={styles.register_lender__link} target="_blank" url="/page/privacy-policy">
                            { t("auth.register_lender.information_exchange_agree") }
                        </PublicLink>
                    </Checkbox>
                    { errors.policyAgree && <InputErrors errors={errors.policyAgree} /> }
                    <Checkbox id="rules" checked={rulesAgree} onChange={toggleRules}>
                        <PublicLink className={styles.register_lender__link} target="_blank" url="/page/privacy-policy">
                            { t("auth.register_lender.platform_interaction_rules") }
                        </PublicLink>
                    </Checkbox>
                    { errors.rulesAgree && <InputErrors errors={errors.rulesAgree} /> }
                </div>
                <div>
                    <PrimaryButton expanded color={PrimaryButtonColor.BLUE}>
                        <Button onClick={handleSubmit} loading={loading}>
                            { t("auth.create_account") }
                        </Button>
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
}

export default LenderRegistrationForm;