import React, {useContext, useState} from "react";
import styles from "./SellConfirmation.module.scss";
import CommonConfirmationSubPage from "../../../../components/common/blocks/CommonConfirmationSubPage";
import {
    useErrorCallback,
    useGoBack,
    useNavigateByName,
    useProfileData,
    useToggle,
    useTranslator
} from "../../../../hooks";
import {useParams} from "react-router-dom";
import {createMoneyFactory} from "../../../../di";
import {
    Button,
    Checkbox,
    Heading,
    Money,
    PrimaryButton,
    PrimaryHeading,
    PublicLink
} from "../../../../components/common";
import {HeadingType} from "../../../../components/common/typography/headings/Heading/Heading";
import {DetailedProjectContext} from "../../../../components/pages/detailed-project/DetailedProjectContext";
import LargeText from "../../../../components/common/typography/LargeText";
import {commissions} from "../../../../configs";
import {CommonBlock} from "../../../../components/common/blocks";
import MoneyProgress from "../../../../components/progress/MoneyProgress";
import CommonPropertiesList from "../../../../components/common/blocks/CommonPropertiesList";
import MediumText from "../../../../components/common/typography/MediumText";
import {addPenniesToAmount} from "../../../../utils";
import {resolveDayTranslation} from "../../../../i18n";
import {PrimaryButtonColor} from "../../../../components/common/buttons/decorators/PrimaryButton/PrimaryButton";

const moneyFactory = createMoneyFactory();

const mockIncome = 150000;

const SellConfirmation = () => {
    const { project, investment } = useContext(DetailedProjectContext);
    const closePage = useGoBack();
    const t = useTranslator();
    const navigateByName = useNavigateByName();
    const profileData = useProfileData();
    const handleError = useErrorCallback();
    const { uuid, investmentUuid } = useParams();
    const [loading, setLoading] = useState(false);

    const [policyAgree, togglePolicyAgree] = useToggle();

    const income = () => {
        return mockIncome;
    }

    const finalSumAmount = (): number => {
        return Math.round((investment!.money.amount - investment!.money.amount / 100 * 4));
    }

    return (
        <CommonConfirmationSubPage closePage={closePage}>
            <PrimaryHeading>
                <Heading headingType={HeadingType.H1} className={styles.sell_confirmation__heading}>
                    { t("selling_confirmation", { id: project.paymentCode ?? "" }) }
                </Heading>
            </PrimaryHeading>
            <LargeText tag="p" className={styles.sell_confirmation__commission}>
                { t("selling_confirmation.commission", { amount: `${commissions.sell_investment}` }) }
            </LargeText>
            <CommonBlock>
                <CommonBlock.Header>
                    <MoneyProgress
                        firstPoint={{ name: t("selling_confirmation.paid"), money: project.general.investedSum }}
                        secondPoint={{ name: t("selling_confirmation.amount"), money: project.general.loanSum }}
                    />
                </CommonBlock.Header>
                <CommonBlock.Section>
                    <CommonPropertiesList>
                        <CommonPropertiesList.Element>
                            <MediumText>
                                { t("selling_confirmation.investment.sum") }
                            </MediumText>
                            <MediumText accent>
                                <Money money={investment!.money} />
                            </MediumText>
                        </CommonPropertiesList.Element>
                        <CommonPropertiesList.Element>
                            <MediumText>
                                { t("selling_confirmation.investment.rate") }
                            </MediumText>
                            <MediumText accent>
                                { project.general.interestRate } %
                            </MediumText>
                        </CommonPropertiesList.Element>
                        <CommonPropertiesList.Element>
                            <MediumText>
                                { t("selling_confirmation.investment.income") }
                            </MediumText>
                            <MediumText accent>
                                <Money money={moneyFactory.createMoney(addPenniesToAmount(income()))} />
                            </MediumText>
                        </CommonPropertiesList.Element>
                        <CommonPropertiesList.Element>
                            <MediumText>
                                { t("selling_confirmation.investment.term") }
                            </MediumText>
                            <MediumText accent>
                                { `${project.general.initialTerm} ${t(resolveDayTranslation(project.general.initialTerm))}` }
                            </MediumText>
                        </CommonPropertiesList.Element>
                    </CommonPropertiesList>
                </CommonBlock.Section>
                <CommonBlock.Section>
                    <CommonPropertiesList>
                        <CommonPropertiesList.Element>
                            <MediumText>
                                { t("selling_confirmation.investment.earn_without_commission") }
                            </MediumText>
                            <Money
                                money={moneyFactory.createMoney(finalSumAmount())}
                                className={styles.sell_confirmation__commission_amount}
                                withPennies
                            />
                        </CommonPropertiesList.Element>
                    </CommonPropertiesList>
                </CommonBlock.Section>
                <CommonBlock.Footer>
                    <div className={styles.sell_confirmation__footer}>
                        <Checkbox checked={policyAgree} onChange={togglePolicyAgree}>
                            { t("selling_confirmation.policy_agree") }<PublicLink
                            url="/page/sell-loan-policy"
                            className={styles.sell_confirmation__link}
                        >
                            { t("selling_confirmation.policy_agree_link") }
                        </PublicLink>
                        </Checkbox>
                        <PrimaryButton color={PrimaryButtonColor.BLUE} expanded>
                            <Button>
                                { t("selling_confirmation.sell") }
                            </Button>
                        </PrimaryButton>
                    </div>
                </CommonBlock.Footer>
            </CommonBlock>
        </CommonConfirmationSubPage>
    );
}

export default SellConfirmation;