import React, {FC, PropsWithChildren, useEffect, useState} from "react";
import {useNavigateByName, useProfileData} from "../../hooks";
import {RouteDictionary} from "../../utils";
import Overlay from "../../components/layout/common/Overlay";
import ModalContainer from "../../components/layout/common/ModalContainer";

const LayoutLender: FC<PropsWithChildren> = ({ children }) => {
    const navigate = useNavigateByName();
    const profileData = useProfileData();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!profileData.isLender) {
            navigate(RouteDictionary.LOGIN);
            return;
        }
        setLoading(false);
        return;
    }, []);

    return (
        <>
            { loading ? <div>Проверяем ваши данные инвестора...</div> : <>
                <Overlay />
                { children }
                <ModalContainer />
            </> }
        </>
    );
}

export default LayoutLender;