import React from "react";
import MyProjectStatus from "../MyProjectStatus";
import RejectedSvg from "../../../../../svg/status/RejectedSvg";
import {CommonText} from "../../../../../common";
import MediumText from "../../../../../common/typography/MediumText";
import {useTranslator} from "../../../../../../hooks";

const MyProjectFinalReviewRejectedStatus = () => {
    const t = useTranslator();
    return (
        <MyProjectStatus>
            <RejectedSvg />
            <CommonText>
                { t("my_detailed_project.status.review_rejected_first") }
            </CommonText>
            <MediumText>
                { t("my_detailed_project.status.final_review_rejected") }
            </MediumText>
        </MyProjectStatus>
    );
}

export default MyProjectFinalReviewRejectedStatus;