import React from "react";
import Tabs from "../../../../layout/common/Tabs";
import {ProjectLink} from "../../../../common";
import {RouteDictionary} from "../../../../../utils";
import {matchPath, useLocation} from "react-router-dom";

const AnalyticsMkkNavigation = () => {
    const { pathname } = useLocation();
    return (
        <div className="analytics-user-navigation">
            <Tabs>
                <ProjectLink
                    to={RouteDictionary.ANALYTICS_MKK_VOLUME}
                    explicitActive={
                        matchPath('lender/analytics', pathname) !== null ||
                        matchPath('lender/analytics/mkk', pathname) !== null
                    }
                >
                    Объем выданных займов
                </ProjectLink>
                <ProjectLink
                    to={RouteDictionary.ANALYTICS_MKK_PROFIT}
                >
                    Доходность
                </ProjectLink>
                <ProjectLink
                    to={RouteDictionary.ANALYTICS_MKK_DEFAULTS}
                >
                    Дефолты
                </ProjectLink>
                <ProjectLink
                    to={RouteDictionary.ANALYTICS_MKK_PENALTIES}
                >
                    Взыскания
                </ProjectLink>
            </Tabs>
        </div>
    );
}

export default AnalyticsMkkNavigation;