import React, {useMemo, useState} from "react";
import {useErrorCallback, useToggle, useTranslator} from "../../../../../hooks";
import {cn, compareOffersFilter, DataCollection, SellingOffersFilter} from "../../../../../utils";
import {SellingOffer} from "../../../../../api-client";
import {createOfferProvider} from "../../../../../di";
import MarketHeader from "../../../../layout/lender/MarketHeader";
import MarketHeading from "../../../../layout/lender/MarketHeading";
import {AsLink, Button, Heading, SecondaryButton, SecondaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import MarketCount from "../../../../layout/lender/MarketCount";
import MarketButtons from "../../../../layout/lender/MarketButtons";
import {SecondaryButtonColor} from "../../../../common/buttons/decorators/SecondaryButton/SecondaryButton";
import {SortingSvg} from "../../../../svg";
import FilterSvg from "../../../../svg/FilterSvg";
import {Pagination, PaginationContent, PaginationHeader, PaginationPaginator} from "../../../../pagination";
import SortingDirectionBlock from "../../../../common/utils/SortingDirectionBlock";
import ProjectsFilterModal from "../../../../modals/ProjectsFilterModal";
import styles from "./SecondaryMarket.module.scss";
import SecondaryMarketOffer from "../SecondaryMarketOffer";

const defaultFilter: SellingOffersFilter = {};

const SecondaryMarket = () => {
    const t = useTranslator();
    const handleError = useErrorCallback();
    const [loading, setLoading] = useState(false);
    const [offers, setOffers] =
        useState<DataCollection<SellingOffer>>(new DataCollection());
    const [filterModalOpened, toggleFilterModalOpened] = useToggle();

    const [offersFilter, setOffersFilter] = useState<SellingOffersFilter>(defaultFilter);

    const loadData = async (page: number, perPage: number, filter?: SellingOffersFilter, signal?: AbortSignal): Promise<void> => {
        setLoading(true);
        try {
            const offerProvider = await createOfferProvider(signal);
            const offers = await offerProvider.getOffers(page, perPage, filter);
            const dataCollection = new DataCollection<SellingOffer>();
            dataCollection.setCollection(offers);
            setOffers(dataCollection);
        } catch (error: any) {
            await handleError(error);
        } finally {
            setLoading(false);
        }
    }

    const renderOffers = useMemo(() => {
        return offers.items.map((offer) => {
            return <SecondaryMarketOffer offer={offer} key={offer.uuid} />
        });
    }, [offers]);

    return (
        <div className={styles.secondary_market}>
            <MarketHeader>
                <MarketHeading>
                    <SecondaryHeading smaller>
                        <Heading headingType={HeadingType.H2}>
                            { t("market.secondary") }
                        </Heading>
                    </SecondaryHeading>
                    { !loading && <MarketCount count={offers.totalCount} /> }
                </MarketHeading>
                <MarketButtons>
                    <SecondaryButton color={SecondaryButtonColor.GRAY}>
                        <AsLink>
                            <Button
                                onClick={toggleFilterModalOpened}
                                className={cn(styles.secondary_market__button, styles.secondary_market__button___sorting)}
                            >
                                <span>
                                    { t("market.primary.filters") }
                                </span>
                                <SortingSvg color={filterModalOpened ? "#262B39" : "#ffffff"} />
                            </Button>
                        </AsLink>
                    </SecondaryButton>
                    <SecondaryButton color={SecondaryButtonColor.GRAY}>
                        <AsLink>
                            <Button onClick={toggleFilterModalOpened} className={styles.secondary_market__button}>
                                <span>
                                    { t("market.primary.filters") }
                                </span>
                                <FilterSvg />
                            </Button>
                        </AsLink>
                    </SecondaryButton>
                </MarketButtons>
            </MarketHeader>
            <Pagination
                onLoad={loadData}
                count={offers.count}
                totalCount={offers.totalCount}
                filterObject={offersFilter}
                initialChangeFilter={(value: Partial<SellingOffersFilter>) => setOffersFilter({...offersFilter, ...value})}
                config={{ filterParam: "offers_filter", pageParam: "offers_page", perPageParam: "offers_per_page" }}
                compareFilters={compareOffersFilter}
            >
                <PaginationContent loading={loading}>
                    <PaginationHeader className="primary-market__list-header">
                        <div>
                            { t("market.primary.company") }
                        </div>
                        <SortingDirectionBlock
                            direction={offersFilter.loanAmountSorting}
                            handleChange={(direction) => setOffersFilter({...offersFilter, loanAmountSorting: direction})}
                        >
                            { t("market.primary.sum") }
                        </SortingDirectionBlock>
                        <SortingDirectionBlock
                            direction={offersFilter.interestRateSorting}
                            handleChange={(direction) => setOffersFilter({...offersFilter, interestRateSorting: direction})}
                        >
                            { t("market.primary.rate") }
                        </SortingDirectionBlock>
                        <SortingDirectionBlock
                            direction={offersFilter.initialTermSorting}
                            handleChange={(direction) => setOffersFilter({...offersFilter, initialTermSorting: direction})}
                        >
                            { t("market.primary.term") }
                        </SortingDirectionBlock>
                        <div>
                            { t("market.primary.schedule") }
                        </div>
                        <div>
                            { t("market.primary.collected") }
                        </div>
                    </PaginationHeader>
                    <div className="market__content">
                        <ul className="market__list">
                            { renderOffers }
                        </ul>
                    </div>
                </PaginationContent>
                <PaginationPaginator />
            </Pagination>
            <ProjectsFilterModal
                filter={offersFilter}
                onFilterChange={setOffersFilter}
                active={filterModalOpened}
                onClose={toggleFilterModalOpened}
            />
        </div>
    );
}

export default SecondaryMarket;