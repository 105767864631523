import {ApiClientInterface} from "./clients";
import {
    EntrepreneurIdentification,
    IndividualIdentification,
    LegalEntityIdentification
} from "./models";
import {TIdentification} from "./types";

export class IdentificationManager {
    constructor(private readonly apiClient: ApiClientInterface) {}

    public async getLatest(): Promise<TIdentification | undefined> {
        return this.apiClient.get("");
    }

    //TODO: FIND A WAY TO REMOVE TS IGNORE
    public async getProfile(): Promise<TIdentification> {
        const identification = this.getLatest();
        if (identification === undefined) {
            throw new Error("Identification must be present");
        }
        // @ts-ignore
        return identification;
    }

    public async createIndividualIdentification(identification: IndividualIdentification): Promise<void> {
        return this.apiClient.post("/individual", {identification});
    }

    public async createEntrepreneurIdentification(identification: EntrepreneurIdentification): Promise<void> {
        return this.apiClient.post("/entrepreneur", {identification});
    }

    public async createLegalEntityIdentification(identification: LegalEntityIdentification): Promise<void> {
        return this.apiClient.post("/legal-entity", {identification});
    }
}