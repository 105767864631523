import React, {useMemo, useState} from "react";
import styles from "./MyRefundedProjects.module.scss";
import {DataCollection, MyRefundedProjectsFilter} from "../../../../../utils";
import {useErrorCallback, useTranslator} from "../../../../../hooks";
import {MyRefundedProject as MyRefundedProjectModel} from "../../../../../api-client";
import {createMyProjectsProvider} from "../../../../../di";
import MyRefundedProject from "../MyRefundedProject";
import {Pagination, PaginationContent, PaginationHeader, PaginationPaginator} from "../../../../pagination";
import SortingDirectionBlock from "../../../../common/utils/SortingDirectionBlock/SortingDirectionBlock";

const defaultFilter: MyRefundedProjectsFilter = {};

const MyRefundedProjects = () => {
    const t = useTranslator();
    const handleError = useErrorCallback();
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState<MyRefundedProjectsFilter>(defaultFilter);
    const [projects, setProjects] =
        useState<DataCollection<MyRefundedProjectModel>>(new DataCollection<MyRefundedProjectModel>());

    const loadData = async (
        page: number,
        perPage: number,
        filter: MyRefundedProjectsFilter | undefined,
        signal: AbortSignal
    ): Promise<void> => {
        setLoading(true);
        try {
            const myProjectsProvider = await createMyProjectsProvider(signal);
            const results = await myProjectsProvider.getRefundedList(page, perPage, filter ?? defaultFilter);
            const collection = new DataCollection<MyRefundedProjectModel>();
            collection.setCollection(results);
            setProjects(collection);
        } catch (error: any) {
            await handleError(error);
        } finally {
            setLoading(false);
        }
    }

    const renderProjects = useMemo(() => {
        return projects.items.map((project) => {
            return <MyRefundedProject key={project.uuid} project={project} />
        })
    }, [projects]);

    return (
        <div className={styles.my_refunded_projects}>
            <Pagination
                onLoad={loadData}
                count={projects.count}
                totalCount={projects.totalCount}
                filterObject={filter}
                initialChangeFilter={(value: Partial<MyRefundedProjectsFilter>) => setFilter({...filter, ...value})}
            >
                <PaginationHeader className={styles.my_refunded_projects__list_header}>
                    <div>
                        { t("my_loans.id") }
                    </div>
                    <SortingDirectionBlock
                        direction={filter.targetSumSorting}
                        handleChange={(direction) => setFilter((filter) => { return {...filter, targetSumSorting: direction} })}
                    >
                        { t("my_loans.target") }
                    </SortingDirectionBlock>
                    <SortingDirectionBlock
                        direction={filter.interestRateSorting}
                        handleChange={(direction) => setFilter((filter) => { return {...filter, interestRateSorting: direction} })}
                    >
                        { t("my_loans.rate") }
                    </SortingDirectionBlock>
                    <SortingDirectionBlock
                        direction={filter.createdAtSorting}
                        handleChange={(direction) => setFilter((filter) => { return {...filter, createdAtSorting: direction} })}
                    >
                        { t("my_loans.created_at") }
                    </SortingDirectionBlock>
                    <div>
                        { t("my_loans.schedule_payments") }
                    </div>
                </PaginationHeader>
                <PaginationContent loading={loading}>
                    <div className="market__content">
                        <ul className="market__list">
                            { renderProjects }
                        </ul>
                    </div>
                </PaginationContent>
                <PaginationPaginator />
            </Pagination>
        </div>
    );
}

export default MyRefundedProjects;