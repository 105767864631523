export class LanguageStorage {
    private readonly storageKey = "lang";

    constructor(private readonly storage: Storage) {}

    public setPreferredLanguage(language: string): void {
        this.storage.setItem(this.storageKey, language);
    }

    public getPreferredLanguage(): string | null {
        return this.storage.getItem(this.storageKey);
    }
}