import React, {FC, PropsWithChildren} from "react";
import CommonBlockExtension from "../CommonBlockExtension";

const CommonBlockHeader: FC<PropsWithChildren> = ({ children }) => {
    return (
        <CommonBlockExtension tag="header">
            { children }
        </CommonBlockExtension>
    );
}

export default CommonBlockHeader;