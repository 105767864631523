import React from "react";
import {useLocation} from "react-router-dom";
import {getQueryObject} from "../../../../utils";
import {DETAILED_PROJECT_TAB, TabQuery} from "../layout/DetailedProjectNavigation/DetailedProjectNavigation";
import {DetailedProjectAnalytics, DetailedProjectReputation, DetailedProjectSummary} from "../index";
import DetailedProjectDocuments from "../documents/DetailedProjectDocuments";
import DetailedProjectOffers from "../offers/DetailedProjectOffers";

const DetailedProjectPageResolver = () => {
    const search = useLocation().search;
    const tab = getQueryObject<TabQuery>(search).tab;
    if (tab === DETAILED_PROJECT_TAB.SUMMARY) {
        return <DetailedProjectSummary />
    }
    if (tab === DETAILED_PROJECT_TAB.ANALYTICS) {
        return <DetailedProjectAnalytics />
    }
    if (tab === DETAILED_PROJECT_TAB.REPUTATION) {
        return <DetailedProjectReputation />
    }
    if (tab === DETAILED_PROJECT_TAB.DOCUMENTS) {
        return <DetailedProjectDocuments />
    }
    if (tab === DETAILED_PROJECT_TAB.OFFERS) {
        return <DetailedProjectOffers />
    }
    return <DetailedProjectSummary />
}

export default DetailedProjectPageResolver;