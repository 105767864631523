import React, {InputHTMLAttributes, ReactNode, useId} from "react";
import './Checkbox.scoped.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    children: ReactNode;
    checked: boolean;
    onChange: () => void;
}

const Checkbox = ({ children, ...props }: Props) => {
    const id = useId();
    return (
        <div className="custom-checkbox">
            <input {...props} type="checkbox" className="custom-checkbox__input" id={id} />
            <label htmlFor={id} className="custom-checkbox__label">
                { children }
            </label>
        </div>
    );
}

export default Checkbox;