import React, {FC, PropsWithChildren} from "react";
import Section from "../../common/Section";
import styles from "./MarketSection.module.scss";

const MarketSection: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Section className={styles.market_section}>
            { children }
        </Section>
    )
}

export default MarketSection;