import React, {FC, PropsWithChildren} from "react";
import styles from "./MarketHeader.module.scss";

const MarketHeader: FC<PropsWithChildren> = ({ children }) => {
    return (
        <header className={styles.market_header}>
            { children }
        </header>
    );
}

export default MarketHeader;