import React, {FC} from "react";
import {DetailedMyModeratedProject} from "../../../../../../api-client";
import PaymentsSchedule from "../../../../../payment-schedule/PaymentsSchedule";
import MyProjectScheduleHeading from "../MyProjectScheduleHeading";
import {useTranslator} from "../../../../../../hooks";

interface Props {
    project: DetailedMyModeratedProject;
}

const MyModeratedProjectSchedule: FC<Props> = ({ project }) => {
    const t = useTranslator();
    if (project.moderated.paymentSchedule === undefined) {
        return (
            <MyProjectScheduleHeading>
                { t("my_detailed_project.schedule.not_found") }
            </MyProjectScheduleHeading>
        );
    }
    return (
        <>
            <MyProjectScheduleHeading>
                { t("my_detailed_project.schedule.preliminary") }
            </MyProjectScheduleHeading>
            <PaymentsSchedule schedule={project.moderated.paymentSchedule} />
        </>
    );
}

export default MyModeratedProjectSchedule;