import React, {AllHTMLAttributes, FC, PropsWithChildren, useEffect, useRef, useState} from "react";
import './Modal.scoped.scss';
import {useAppDispatch, useEffectOnUpdate, useOverlayState} from "../../../hooks";
import {set} from "../../../store/overlay";
import {cn} from "../../../utils";
import {createPortal} from "react-dom";
import ModalContextProvider, {ModalContextType} from "./ModalContext";
import {CSSTransition} from "react-transition-group";

export interface ModalProps extends AllHTMLAttributes<HTMLDivElement> {
    active: boolean;
    onClose: () => void;
}

const Modal: FC<PropsWithChildren<ModalProps>> = ({ active, onClose, children, ...props }) => {
    const dispatch = useAppDispatch();
    const isOverlayActive = useOverlayState();
    const elRef = useRef<HTMLElement | null>(null);
    const [domReady, setDomReady] = useState(false);

    if (!elRef.current) {
        elRef.current = document.getElementById("modal-container");
    }

    useEffectOnUpdate(() => {
        if (!isOverlayActive && active) {
            onClose();
        }
    }, [isOverlayActive]);

    useEffect(() => {
        dispatch(set(active));
    }, [active]);

    const value: ModalContextType = {
        onClose: onClose
    }

    useEffect(() => {
        setDomReady(true);
    }, []);

    return (
        <>
            { domReady && createPortal(<>
                <CSSTransition
                    in={active}
                    timeout={500}
                    classNames="my-modal-"
                    mountOnEnter
                    unmountOnExit
                >
                    <div {...props} className={cn("my-modal", props.className)}>
                        <ModalContextProvider props={value}>
                            <div className="my-modal__close" onClick={onClose}></div>
                            <div className="my-modal__content">
                                { children }
                            </div>
                        </ModalContextProvider>
                    </div>
                </CSSTransition>
            </>, elRef.current!) }
        </>
    );
}

export default Modal;