import React, {useEffect} from "react";
import styles from "./Verification.module.scss";
import {useParams} from "react-router-dom";
import {useErrorCallback, useNavigateByName} from "../../../hooks";
import {createVerificationManager} from "../../../di";
import {RouteDictionary} from "../../../utils";
import LoaderContainer from "../../../components/loading/LoaderContainer";
import {Loader} from "../../../components/common";

const Verification = () => {
    const { code } = useParams();
    const navigate = useNavigateByName();
    const handleError = useErrorCallback();
    useEffect(() => {
        if (code !== undefined) {
            (async () => {
                try {
                    const manager = await createVerificationManager(false);
                    const result = await manager.verifyEmail(code);
                    if (result.verified) {
                        navigate(RouteDictionary.LOGIN);
                    }
                } catch (error: any) {
                    await handleError(error);
                    navigate(RouteDictionary.LOGIN);
                }
            })();
        }
    }, []);
    return (
        <div className={styles.verification_loading}>
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        </div>
    );
}

export default Verification;