import {useAppDispatch} from "./store";
import {Dispatch, SetStateAction, useState} from "react";
import {useEffectOnUpdate} from "./useEffectOnUpdate";
import {set} from "../store/overlay";

export const useModalState = (initial: boolean = false): [boolean, Dispatch<SetStateAction<boolean>>] => {
    const dispatch = useAppDispatch();
    const [opened, setOpened] = useState(initial);
    useEffectOnUpdate(() => {
        dispatch(set(opened));
    }, [opened]);

    return [opened, setOpened];
}