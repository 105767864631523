import React, {FC} from "react";
import {LoanPortfolio} from "../../../../../api-client";
import {Heading, SecondaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import {DetailedProjectSubBlock} from "../../index";
import DetailedProjectProgress from "../../common/DetailedProjectProgress";
import "./DetailedProjectLoanPortfolio.scoped.scss";
import {useTranslator} from "../../../../../hooks";

interface Props {
    loanPortfolio: LoanPortfolio;
}

const DetailedProjectLoanPortfolio: FC<Props> = ({ loanPortfolio }) => {
    const t = useTranslator();
    return (
        <DetailedProjectSubBlock>
            <SecondaryHeading smaller>
                <Heading headingType={HeadingType.H2}>
                    { t("detailed_project.portfolio") }
                </Heading>
            </SecondaryHeading>
            <div className="detailed-project-loan-portfolio">
                <DetailedProjectProgress
                    className="detailed-project-loan-portfolio__progress"
                    percent={loanPortfolio.mortgage}
                    text={ t("detailed_project.portfolio.mortgage") }
                />
                <DetailedProjectProgress
                    className="detailed-project-loan-portfolio__progress"
                    percent={loanPortfolio.leasing}
                    text={ t("detailed_project.portfolio.leasing") }
                />
                <DetailedProjectProgress
                    className="detailed-project-loan-portfolio__progress"
                    percent={loanPortfolio.bankLoan}
                    text={ t("detailed_project.portfolio.bank_credit") }
                />
                <DetailedProjectProgress
                    className="detailed-project-loan-portfolio__progress"
                    percent={loanPortfolio.crowdLending}
                    text={ t("detailed_project.portfolio.crowdlending") }
                />
            </div>
        </DetailedProjectSubBlock>
    );
}

export default DetailedProjectLoanPortfolio;