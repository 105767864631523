import {NamedUrl} from "../types";
import {RouteDictionary} from "./RouteDictionary";

export const lenderRoutes: Array<NamedUrl> = [
    {
        name: RouteDictionary.CONFIRM_EMAIL,
        url: "/confirm-email"
    },
    {
        name: RouteDictionary.LENDER_PROFILE,
        url: "/profile"
    },
    {
        name: RouteDictionary.MARKET,
        url: "/market"
    },
    {
        name: RouteDictionary.DETAILED_PROJECT,
        url: "/market/:uuid"
    },
    {
        name: RouteDictionary.INVESTMENT_CONFIRMATION,
        url: "/market/:uuid/confirmation"
    },
    {
        name: RouteDictionary.DETAILED_PROJECT_INVESTMENT,
        url: "/market/:uuid/investment/:investmentUuid"
    },
    {
        name: RouteDictionary.SELL_CONFIRMATION,
        url: "/market/:uuid/investment/:investmentUuid/sell"
    },
    {
        name: RouteDictionary.MY_INVESTMENTS,
        url: "/my-investments"
    },
    {
        name: RouteDictionary.MY_INVESTMENTS_LOAN_PAYMENT,
        url: "/my-investments/loan-payment"
    },
    {
        name: RouteDictionary.MY_INVESTMENTS_FUNDRAISING,
        url: "/my-investments/fundraising"
    },
    {
        name: RouteDictionary.MY_INVESTMENTS_REFUNDED,
        url: "/my-investments/refunded"
    },
    {
        name: RouteDictionary.MY_INVESTMENTS_DEFAULT,
        url: "/my-investments/default"
    },
    {
        name: RouteDictionary.MY_INVESTMENTS_SELLING,
        url: "/my-investments/selling"
    },
    {
        name: RouteDictionary.ANALYTICS,
        url: "/analytics"
    },
    {
        name: RouteDictionary.ANALYTICS_USER,
        url: "/analytics/user"
    },
    {
        name: RouteDictionary.ANALYTICS_USER_VOLUME,
        url: "/analytics/user/volume"
    },
    {
        name: RouteDictionary.ANALYTICS_USER_PROFIT,
        url: "/analytics/user/profit"
    },
    {
        name: RouteDictionary.ANALYTICS_USER_DEFAULTS,
        url: "/analytics/user/defaults"
    },
    {
        name: RouteDictionary.ANALYTICS_USER_PENALTIES,
        url: "/analytics/user/penalties"
    },
    {
        name: RouteDictionary.ANALYTICS_PLATFORM,
        url: "/analytics/platform"
    },
    {
        name: RouteDictionary.ANALYTICS_PLATFORM_VOLUME,
        url: "/analytics/platform/volume"
    },
    {
        name: RouteDictionary.ANALYTICS_PLATFORM_PROFIT,
        url: "/analytics/platform/profit"
    },
    {
        name: RouteDictionary.ANALYTICS_PLATFORM_DEFAULTS,
        url: "/analytics/platform/defaults"
    },
    {
        name: RouteDictionary.ANALYTICS_PLATFORM_PENALTIES,
        url: "/analytics/platform/penalties"
    },
    {
        name: RouteDictionary.ANALYTICS_MKK,
        url: "/analytics/mkk"
    },
    {
        name: RouteDictionary.ANALYTICS_MKK_VOLUME,
        url: "/analytics/mkk/volume"
    },
    {
        name: RouteDictionary.ANALYTICS_MKK_PROFIT,
        url: "/analytics/mkk/profit"
    },
    {
        name: RouteDictionary.ANALYTICS_MKK_DEFAULTS,
        url: "/analytics/mkk/defaults"
    },
    {
        name: RouteDictionary.ANALYTICS_MKK_PENALTIES,
        url: "/analytics/mkk/penalties"
    },
    {
        name: RouteDictionary.FINANCES,
        url: "/finances"
    },
    {
        name: RouteDictionary.ARCHIVE,
        url: "/archive"
    },
    {
        name: RouteDictionary.DEPOSIT,
        url: "/deposit"
    },
    {
        name: RouteDictionary.WITHDRAW,
        url: "/withdraw"
    },
    {
        name: RouteDictionary.IDENTIFICATION,
        url: "/identification"
    }
]