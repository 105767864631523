import {useNavigate} from "react-router-dom";
import {createRouteUrlProvider} from "../di";
import {QueryObject, RouteDictionary, UrlParamType} from "../utils";

export const useNavigateByName = () => {
    let navigate = useNavigate();
    const provider = createRouteUrlProvider();
    return (name: RouteDictionary, params?: UrlParamType, queryObject?: QueryObject) => {
        return navigate(provider.getByName(name, params, queryObject));
    };
}