import React, {FC} from "react";
import {AnalyticsPenalty} from "../../../../../../api-client";
import styles from "./AnalyticsPenaltyPercent.module.scss";
import {getMonthTranslation} from "../../../../../../i18n/utils";
import {useTranslator} from "../../../../../../hooks";

interface Props {
    penalty: AnalyticsPenalty;
}

const AnalyticsPenaltyPercent: FC<Props> = ({ penalty }) => {
    const t = useTranslator();
    return (
        <li className={styles.analytics_penalty_percent}>
            <div>
                { t(getMonthTranslation(penalty.date)) }
            </div>
            <div>
                { penalty.returned.percent + penalty.inDefault.percent }%
            </div>
            <div>
                { penalty.returned.percent }%
            </div>
            <div>
                { penalty.inDefault.percent }%
            </div>
        </li>
    );
}

export default AnalyticsPenaltyPercent;