import React, {AllHTMLAttributes, FC, PropsWithChildren} from "react";
import {cn} from "../../../../utils";
import "./CommonStatsContainer.scoped.scss";

interface Props extends AllHTMLAttributes<HTMLDivElement> {}

const CommonStatsContainer: FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
    return (
        <div {...props} className={cn("common-stats-container", props.className)}>
            { children }
        </div>
    );
}

export default CommonStatsContainer;