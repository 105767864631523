import React from "react";
import {useCurrentLanguage, useTranslator} from "../../../hooks";
import {Translation} from "../../../api-client";

interface Props {
    translations: Array<Translation>;
}

const DynamicTranslation = ({ translations }: Props) => {
    const t = useTranslator();
    const currentLanguage = useCurrentLanguage();
    const validTranslations = translations.filter((translation) =>
        translation.languageCode.toLowerCase() === currentLanguage!.short.toLowerCase()
    );
    if (validTranslations.length === 0) {
        return <>{ t("common.no_translation", { lang: currentLanguage!.short }) }</>;
    }
    return <>
        { validTranslations[0].text }
    </>;
}

export default DynamicTranslation;