import React, {FC, useContext, useEffect} from "react";
import styles from './IntroductionAvailableSum.module.scss';
import greenApproved from "../../../../assets/images/icons/approved-green-icon.svg";
import {Button, Heading, Image, Money, PrimaryButton, PrimaryHeading} from "../../../common";
import {HeadingType} from "../../../common/typography/headings/Heading/Heading";
import {PrimaryButtonColor} from "../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {ArrowRight} from "../../../svg";
import {FundingRequestContext} from "../../funding-request/FundingRequestContext";
import {ColorDictionary, getCssVariable} from "../../../../utils";
import {useTranslator} from "../../../../hooks";
import LargeText from "../../../common/typography/LargeText";
import {createRequestSumViewedStorage} from "../../../../di";

interface Props {
    onNextStep: () => void;
}

const IntroductionAvailableSum: FC<Props> = ({ onNextStep }) => {
    const t = useTranslator();
    const {request} = useContext(FundingRequestContext);
    if (request === null) {
        return <div>You shouldn&apos;t be here...</div>
    }

    const isRejected = () => {
        return request.rejectionReason !== undefined && request.rejectionReason !== null;
    }

    useEffect(() => {
        if (request.approvedSum !== undefined) {
            const storage = createRequestSumViewedStorage();
            storage.set(request);
        }
    }, []);

    const renderHeading = (): string => {
        if (isRejected()) {
            return t("funding_request.available_sum.declined");
        }
        if (request.approvedSum === undefined) {
            return t("funding_request.available_sum.pending");
        }
        return t("funding_request.available_sum.success");
    }

    if (isRejected()) {
        return <div className={styles.funding_request_available}>
            <PrimaryHeading>
                <Heading headingType={HeadingType.H1} className="funding-request__heading">
                    { renderHeading() }
                </Heading>
            </PrimaryHeading>
            <LargeText>
                { t("funding_request.available_sum.preliminary_declined") }
            </LargeText>
        </div>
    }

    return (
        <div className={styles.funding_request_available}>
            { request.approvedSum !== undefined && <div className={styles.funding_request_available__approved}>
                <Image src={greenApproved} />
            </div> }
            <PrimaryHeading>
                <Heading headingType={HeadingType.H1} className="funding-request__heading">
                    { renderHeading() }
                </Heading>
            </PrimaryHeading>
            <div className={styles.funding_request_available__texts}>
                { request.approvedSum !== undefined ? <>
                    <LargeText>
                        { t("funding_request.available_sum.approved_more_first") }
                    </LargeText>
                    <LargeText>
                        { t("funding_request.available_sum.approved_more_second") }
                    </LargeText>
                </> : <>
                    <LargeText>
                        { t("funding_request.available_sum.pending_more") }
                    </LargeText>
                </> }
            </div>
            { request.approvedSum !== undefined && <div className={styles.funding_request_available__block}>
                <div>
                    <div className={styles.funding_request_available__sum}>
                        <Money money={request.approvedSum} />
                    </div>
                    <div className={styles.funding_request_available__sub}>
                        { t("funding_request.available_sum.preliminary_approved") }
                    </div>
                </div>
                <div>
                    <PrimaryButton color={PrimaryButtonColor.BLUE}>
                        <Button onClick={onNextStep}>
                            <div className={styles.funding_request_available__submit}>
                                <span>
                                    { t("common.continue") }
                                </span>
                                <ArrowRight color={getCssVariable(ColorDictionary.WHITE)} />
                            </div>
                        </Button>
                    </PrimaryButton>
                </div>
            </div> }
        </div>
    );
}

export default IntroductionAvailableSum;