import React, {FC, PropsWithChildren} from "react";
import "./LayoutBorrowerRequest.scoped.scss";
import {BorrowerLanguageSelect} from "../../components/language";

const LayoutBorrowerRequest: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="layout-borrower-request">
            { children }
            <BorrowerLanguageSelect />
        </div>
    );
}

export default LayoutBorrowerRequest;