import React from "react";
import ProjectNavigate from "../../components/navigation/ProjectNavigate";
import {RouteDictionary} from "../../utils";

const IndexPage = () => {
    return (
        <ProjectNavigate to={RouteDictionary.LOGIN} />
    );
}

export default IndexPage;