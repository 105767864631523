import React, {FC} from "react";
import "./DetailedProjectCreditHistory.scoped.scss";
import {CreditHistory} from "../../../../../api-client";
import {CommonSubText, Heading, Money, SecondaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import StatusBlock from "../../../../common/utils/StatusBlock";
import {StatusBlockType} from "../../../../common/utils/StatusBlock/StatusBlock";
import {DetailedProjectDatatable, DetailedProjectSubBlock} from "../../index";
import {useTranslator} from "../../../../../hooks";

interface Props {
    creditHistory: CreditHistory;
}

const DetailedProjectCreditHistory: FC<Props> = ({ creditHistory }) => {
    const t = useTranslator();
    return (
        <DetailedProjectSubBlock>
            <div className="detailed-project-reputation__header">
                <SecondaryHeading smaller>
                    <Heading headingType={HeadingType.H2}>
                        { t("detailed_project.reputation.credit_history") }
                    </Heading>
                </SecondaryHeading>
                { creditHistory.currentDebtLoad.amount === 0
                    && <StatusBlock type={StatusBlockType.Success} text={t("detailed_project.reputation.no_delay")} />
                }
            </div>
            <DetailedProjectDatatable className="detailed-project-credits-datatable">
                <DetailedProjectDatatable.Row>
                    <CommonSubText>
                        { t("detailed_project.reputation.debt_load") }
                    </CommonSubText>
                    <DetailedProjectDatatable.Text>
                        <Money money={creditHistory.currentDebtLoad} />
                    </DetailedProjectDatatable.Text>
                </DetailedProjectDatatable.Row>
                <DetailedProjectDatatable.Row>
                    <CommonSubText>
                        { t("detailed_project.reputation.delays_30_days_12_months") }
                    </CommonSubText>
                    <DetailedProjectDatatable.Text>
                        { creditHistory.delaysUpTo30daysLast12Months ?? 0 }
                    </DetailedProjectDatatable.Text>
                </DetailedProjectDatatable.Row>
                <DetailedProjectDatatable.Row>
                    <CommonSubText>
                        { t("detailed_project.reputation.max_borrower_sum") }
                    </CommonSubText>
                    <DetailedProjectDatatable.Text>
                        <Money money={creditHistory.maximumSuccessfulLoan} />
                    </DetailedProjectDatatable.Text>
                </DetailedProjectDatatable.Row>
                <DetailedProjectDatatable.Row>
                    <CommonSubText>
                        { t("detailed_project.reputation.last_month_loans_number") }
                    </CommonSubText>
                    <DetailedProjectDatatable.Text>
                        { creditHistory.loansLastMonth ?? 0 }
                    </DetailedProjectDatatable.Text>
                </DetailedProjectDatatable.Row>
                <DetailedProjectDatatable.Row>
                    <CommonSubText>
                        { t("detailed_project.reputation.microcredits_3_months") }
                    </CommonSubText>
                    <DetailedProjectDatatable.Text>
                        { creditHistory.microCreditsLast3Months ?? 0 }
                    </DetailedProjectDatatable.Text>
                </DetailedProjectDatatable.Row>
            </DetailedProjectDatatable>
        </DetailedProjectSubBlock>
    );
}

export default DetailedProjectCreditHistory;