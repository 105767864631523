import React, {createContext, PropsWithChildren} from "react";
import {Analytics} from "../../../../api-client";

export type AnalyticsUserContextType = {
    analytics: Analytics | undefined;
}

type Props = {
    props: AnalyticsUserContextType;
}

export const AnalyticsUserContext = createContext<AnalyticsUserContextType>({
    analytics: undefined
});

function AnalyticsUserContextProvider(props: PropsWithChildren<Props>) {
    const { children, ...value } = props;
    return <AnalyticsUserContext.Provider value={value.props}>
        { children }
    </AnalyticsUserContext.Provider>
}

export default AnalyticsUserContextProvider;