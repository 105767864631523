import React from "react";
import {SvgComponent} from "../SvgComponent";
import SvgContainer from "../SvgContainer";
import {SvgContainerSize} from "../SvgContainer/SvgContainer";
import {defaultStatSvgColor} from "./defaultStatSvgColor";

const PercentSvg: SvgComponent = ({ color= defaultStatSvgColor }) => {
    return (
        <SvgContainer size={SvgContainerSize.Small}>
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.83301 12.5L12.833 3.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <circle cx="4.83301" cy="4.5" r="1.5" fill={color} />
                <circle cx="11.833" cy="11.5" r="1.5" fill={color} />
            </svg>
        </SvgContainer>
    );
}

export default PercentSvg