import React from "react";
import {Route, Routes} from "react-router-dom";
import BorrowerDashboard from "../../../views/borrower/BorrowerDashboard";
import BorrowerRequestRoutes from "../borrower/BorrowerRequestRoutes";
import BorrowerCabinetRoutes from "../borrower/BorrowerCabinetRoutes";
import {PhoneVerifiedRouteGroup} from "../groups";
import ConfirmPhone from "../../../views/borrower/confirmation/ConfirmPhone";
import Introduction from "../../../views/borrower/request/Introduction";
import BorrowerIntroductionDashboard from "../../../views/borrower/BorrowerIntroductionDashboard";
import IntroductionCompletedRouteGroup from "../groups/IntroductionCompletedRouteGroup";

const BorrowerRoutes = () => {
    return (
        <Routes>
            <Route element={<BorrowerDashboard />}>
                <Route path="confirm-phone" element={<ConfirmPhone />} />
                <Route element={<PhoneVerifiedRouteGroup />}>
                    <Route element={<BorrowerIntroductionDashboard />}>
                        <Route path="introduction" element={<Introduction />} />
                    </Route>
                    <Route element={<IntroductionCompletedRouteGroup />}>
                        <Route path="/*" element={<BorrowerCabinetRoutes />} />
                        <Route path="request/*" element={<BorrowerRequestRoutes />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}

export default BorrowerRoutes;