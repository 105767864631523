import React, {FC, useContext, useState} from "react";
import styles from "./BorrowerRegistrationForm.module.scss";
import registerStyles from "../../register.module.scss";
import {BorrowerRegistrationContext} from "./context/BorrowerRegistrationContext";
import {Errors as ValidationErrors, validatePhone} from "../../../../../utils";
import {useEffectOnUpdate, useErrorCallback, useTranslator} from "../../../../../hooks";
import {Button, Checkbox, Heading, InputErrors, PrimaryButton, PrimaryHeading, PublicLink} from "../../../../common";
import {PrimaryButtonColor} from "../../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {CommonInput, InputPassword, InputPhone} from "../../../../common/inputs";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";

interface Props {
    onSubmit: () => void;
}

type FormErrors = {
    phone?: ValidationErrors;
    email?: ValidationErrors;
    password?: ValidationErrors;
    confirmPassword?: ValidationErrors;
    policyAgree?: ValidationErrors;
    rulesAgree?: ValidationErrors;
}

const BorrowerRegistrationForm: FC<Props> = ({ onSubmit }) => {
    const t = useTranslator();
    const handleError = useErrorCallback();
    const { input, setInput } = useContext(BorrowerRegistrationContext);
    const [errors, setErrors] = useState<FormErrors>({});
    const [loading, setLoading] = useState(false);
    const [initialSubmit, setInitialSubmit] = useState(true);

    const validateForm = (): boolean => {
        const newErrors: FormErrors = {};
        if (input.phone.number === "") {
            newErrors.phone = [t(
                "errors.field_required",
                { field: t("errors.fields.phone"), type: t("errors.field_required.masculine") }
            )];
        } else if (!validatePhone(input.phone)) {
            newErrors.phone = [t(
                "errors.incorrect_input",
                { field: t("errors.fields.phone"), type: t("errors.input.masculine") }
            )];
        }
        if (input.email === "") {
            newErrors.email = [t(
                "errors.field_required",
                { field: t("errors.fields.email"), type: t("errors.field_required.feminine") }
            )];
        }
        if (input.password === "") {
            newErrors.password = [t(
                "errors.field_required",
                { field: t("errors.fields.password"), type: t("errors.field_required.masculine") }
            )];
        } else if (input.password !== input.confirmPassword) {
            newErrors.confirmPassword = [t(
                "errors.passwords_do_not_match"
            )];
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    useEffectOnUpdate(() => {
        if (!initialSubmit) {
            validateForm();
        }
    }, [initialSubmit, input]);

    const canSubmit = (): boolean => {
        return Object.keys(errors).length === 0;
    }

    const handleSubmit = async () => {
        if (validateForm()) {
            setLoading(true);
            try {
                await onSubmit();
            } catch (error: any) {
                await handleError(error);
            } finally {
                setLoading(false);
            }
        }
        setInitialSubmit(false);
    }

    const togglePolicy = () => {
        const inverted = !input.policyAgree;
        setInput({...input, policyAgree: inverted});
    }

    const toggleRules = () => {
        const inverted = !input.rulesAgree;
        setInput({...input, rulesAgree: inverted});
    }

    return (
        <div className={styles.contact_info_form}>
            <PrimaryHeading>
                <Heading className={registerStyles.register__heading} headingType={HeadingType.H1}>
                    { t("auth.register_borrower.data") }
                </Heading>
            </PrimaryHeading>
            <div className={styles.contact_info_form__inputs}>
                <div>
                    <InputPhone
                        phone={input.phone}
                        onPhoneInput={(phone) => {setInput({...input, phone: phone})}}
                        text={ t("common.phone") }
                        id="phone"
                        error={errors.phone !== undefined}
                    />
                    { errors.phone && <InputErrors errors={errors.phone} /> }
                </div>
                <div>
                    <CommonInput
                        value={input.email}
                        onChange={(email) => {setInput({...input, email: email})}}
                        id="email"
                        text={ t("common.email") }
                        error={errors.email !== undefined}
                    />
                    { errors.email && <InputErrors errors={errors.email} /> }
                </div>
                <div>
                    <InputPassword
                        password={input.password}
                        onChangePassword={(password) => {setInput({...input, password: password})}}
                        id="password"
                        text={t(
                            "auth.register.password",
                            { length: "6" }
                        )}
                        error={errors.password !== undefined}
                    />
                    { errors.password && <InputErrors errors={errors.password} /> }
                </div>
                <div>
                    <InputPassword
                        password={input.confirmPassword}
                        onChangePassword={(password) => {setInput({...input, confirmPassword: password})}}
                        id="confirm-password"
                        text={t("auth.register.confirm_password")}
                        error={errors.confirmPassword !== undefined}
                    />
                    { errors.confirmPassword && <InputErrors errors={errors.confirmPassword} /> }
                </div>
            </div>
            <div>
                <Checkbox id="policy-agree" checked={input.policyAgree} onChange={togglePolicy}>
                    <div>{ t("auth.register_borrower.agree_with") } <PublicLink className={styles.contact_info_form__link} url="/page/privacy-policy">
                        { t("auth.register_borrower.processing_policy") }
                    </PublicLink>
                    </div>
                </Checkbox>
                { errors.policyAgree && <InputErrors errors={errors.policyAgree} /> }
            </div>
            <div>
                <Checkbox id="rules-agree" checked={input.rulesAgree} onChange={toggleRules}>
                    <div>{ t("auth.register_borrower.familiar_with") } <PublicLink className={styles.contact_info_form__link} url="/page/platform-rules">
                        { t("auth.register_borrower.platform_rules") }
                    </PublicLink>,&nbsp;
                        { t("auth.register_borrower.register_as") }
                    </div>
                </Checkbox>
                { errors.rulesAgree && <InputErrors errors={errors.rulesAgree} /> }
            </div>
            <div>
                <PrimaryButton expanded color={PrimaryButtonColor.BLUE}>
                    <Button disabled={!canSubmit()} onClick={handleSubmit} loading={loading}>
                        { t("auth.create_account") }
                    </Button>
                </PrimaryButton>
            </div>
        </div>
    );
}

export default BorrowerRegistrationForm;