import React, {FC, PropsWithChildren} from "react";
import "./SecondaryNavigationList.scoped.scss";

const SecondaryNavigationList: FC<PropsWithChildren> = ({ children }) => {
    return (
        <ul className="secondary-navigation-list">
            { children }
        </ul>
    );
}

export default SecondaryNavigationList;