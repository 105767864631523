import React, {FC} from "react";
import styles from "./AnalyticsDefaultSum.module.scss";
import {AnalyticsPenalty} from "../../../../../../api-client";
import {Money} from "../../../../../common";
import sumMoney from "../../../../../../utils/money/sumMoney";
import {useTranslator} from "../../../../../../hooks";
import {getMonthTranslation} from "../../../../../../i18n/utils";

interface Props {
    penalty: AnalyticsPenalty;
}

const AnalyticsDefaultSum: FC<Props> = ({ penalty }) => {
    const t = useTranslator();
    return (
        <li className={styles.analytics_default_sum}>
            <div>
                { t(getMonthTranslation(penalty.date)) }
            </div>
            <div>
                <Money money={sumMoney(penalty.returned.sum, penalty.returned.sum)} />
            </div>
            <div>
                <Money money={penalty.returned.sum} />
            </div>
            <div>
                <Money money={penalty.inDefault.sum} />
            </div>
        </li>
    );
}

export default AnalyticsDefaultSum;