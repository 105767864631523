import React, {FC} from "react";
import {DetailedPaymentSchedule} from "../../../api-client";
import styles from "./ActivePaymentSchedule.module.scss";
import {CommonSubText, CommonText, Money} from "../../common";
import {formatDate} from "../../../formatters";
import sumMoney from "../../../utils/money/sumMoney";
import PaymentStatus from "../PaymentStatus";
import {useTranslator} from "../../../hooks";

interface Props {
    schedule: DetailedPaymentSchedule;
}

const ActivePaymentSchedule: FC<Props> = ({ schedule }) => {
    const t = useTranslator();
    const renderRows = () => {
        return schedule.payments.map((payment) => {
            return <li className={styles.active_payment_schedule__element} key={payment.uuid}>
                <CommonText>
                    { formatDate(payment.date) }
                </CommonText>
                <CommonText>
                    <Money withPennies money={sumMoney(payment.interest, payment.principal)} />
                </CommonText>
                <CommonText>
                    <Money withPennies money={payment.principal} />
                </CommonText>
                <CommonText>
                    <Money withPennies money={payment.interest} />
                </CommonText>
                <PaymentStatus payment={payment} />
            </li>
        });
    }
    return (
        <div className={styles.active_payment_schedule}>
            <ul className={styles.active_payment_schedule__list}>
                <li className={styles.active_payment_schedule__element}>
                    <CommonSubText small>
                        { t("my_detailed_project.schedule.date") }
                    </CommonSubText>
                    <CommonSubText small>
                        { t("my_detailed_project.schedule.sum") }
                    </CommonSubText>
                    <CommonSubText small>
                        { t("my_detailed_project.schedule.principal") }
                    </CommonSubText>
                    <CommonSubText small>
                        { t("my_detailed_project.schedule.percents") }
                    </CommonSubText>
                </li>
                { renderRows() }
            </ul>
        </div>
    );
}

export default ActivePaymentSchedule;