import React from "react";
import './LenderNavigation.scoped.scss';
import {RouteDictionary} from "../../../../utils";
import {AnalyticsSvg, FinancesSvg, FolderSvg, LightningSvg, ProfileSvg} from "../../../svg";
import CommonAside from "../../common/CommonAside";
import {matchPath, useLocation} from "react-router-dom";
import {useTranslator} from "../../../../hooks";

const LenderNavigation = () => {
    const t = useTranslator();
    const { pathname } = useLocation();
    return (
        <CommonAside showLogo={false}>
            <CommonAside.Link to={RouteDictionary.MARKET} icon={<LightningSvg />}>
                { t("navigation.new_investments") }
            </CommonAside.Link>
            <CommonAside.Link to={RouteDictionary.MY_INVESTMENTS} icon={<FolderSvg />}>
                { t("navigation.my_investments") }
            </CommonAside.Link>
            <CommonAside.Link
                to={RouteDictionary.ANALYTICS}
                icon={<AnalyticsSvg />}
                explicitActive={matchPath('lender', pathname) !== null}
            >
                { t("navigation.analytics") }
            </CommonAside.Link>
            <CommonAside.Link to={RouteDictionary.FINANCES} icon={<FinancesSvg />}>
                { t("navigation.finances") }
            </CommonAside.Link>
            <CommonAside.Link to={RouteDictionary.LENDER_PROFILE} icon={<ProfileSvg />}>
                { t("navigation.profile") }
            </CommonAside.Link>
        </CommonAside>
    );
}

export default LenderNavigation