import React, {lazy} from "react";
import {Outlet, Route, Routes} from "react-router-dom";
import Loadable from "../../../loading/Loadable";
import AnalyticsPlatform from "../../../pages/analytics/platform/AnalyticsPlatform";
import AnalyticsMkk from "../../../pages/analytics/mkk/AnalyticsMkk";
import UserAnalyticsRoutes from "./analytics/UserAnalyticsRoutes";
import PlatformAnalyticsRoutes from "./analytics/PlatformAnalyticsRoutes";
import MkkAnalyticsRoutes from "./analytics/MkkAnalyticsRoutes";

const LazyAnalytics = Loadable(lazy(() => import("../../../../views/lender/statistics/Analytics")));

const AnalyticsRoutes = () => {
    return (
        <>
            <Routes>
                <Route element={<LazyAnalytics />}>
                    <Route index element={<UserAnalyticsRoutes />} />
                    <Route path="user/*" element={<UserAnalyticsRoutes />} />
                    <Route path="platform/*" element={<PlatformAnalyticsRoutes />} />
                    <Route path="mkk/*" element={<MkkAnalyticsRoutes />} />
                </Route>
            </Routes>
            <Outlet />
        </>
    );
}

export default AnalyticsRoutes;