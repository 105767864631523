import React, {FC, ReactNode} from "react";
import "./InputLabel.scoped.scss";

interface Props {
    id: string;
    text: ReactNode;
}

const InputLabel: FC<Props> = ({ id, text }) => {
    return (
        <label htmlFor={id} className="input-label">
            { text }
        </label>
    );
}

export default InputLabel;