import React, {useContext} from "react";
import './DetailedProjectInvest.scoped.scss';
import {DetailedProjectContext} from "../../DetailedProjectContext";
import {ProjectStatus} from "../../../../../api-client";
import FundraisingProjectInvestInfo from "../FundraisingProjectInvestInfo";
import FundraisingProjectInvestmentInfo from "../FundraisingProjectInvestmentInfo";
import {CommonBlock} from "../../../../common/blocks";
import FundraisingProjectWarning from "../FundraisingProjectWarning";
import LoanPaymentProjectInvestInfo from "../LoanPaymentProjectInvestInfo";
import LoanPaymentProjectInvestmentInfo from "../LoanPaymentProjectInvestmentInfo";

const DetailedProjectInvest = () => {
    const { project, investment } = useContext(DetailedProjectContext);
    const render = () => {
        if (project.status === ProjectStatus.FUND_RAISING) {
            if (investment === undefined) {
                return <FundraisingProjectInvestInfo />
            }
            return <FundraisingProjectInvestmentInfo />
        }
        if (project.status === ProjectStatus.LOAN_PAYMENT) {
            if (investment === undefined) {
                return <LoanPaymentProjectInvestInfo />
            }
            return <LoanPaymentProjectInvestmentInfo />
        }
    }
    return (
        <div className="detailed-project-invest">
            <div className="detailed-project-invest__container">
                <CommonBlock>
                    { render() }
                </CommonBlock>
                { project.status === ProjectStatus.FUND_RAISING
                    && investment !== undefined
                    && <FundraisingProjectWarning />
                }
            </div>
        </div>
    );
}

export default DetailedProjectInvest;