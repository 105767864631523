import {
    AuthTokenManager,
    AuthTokenStorage,
    LockFactory,
    RequestSumViewedStorage,
    RouteProvider,
    RouteUrlProvider
} from "../utils";
import {createAuthManager} from "./apiClient";
import {MoneyFactory, MAIN_CURRENCY} from "../utils";
import {
    LanguageProvider,
    LanguageResolver,
    LanguageStorage,
    SystemLanguageProvider, SystemTranslationProvider,
    TranslationProviderInterface
} from "../i18n";

export function createAuthTokenManager(): AuthTokenManager {
    return new AuthTokenManager (
        createAuthManager(),
        new AuthTokenStorage(),
        new LockFactory(window.localStorage)
    );
}

const createRouteProvider = (): RouteProvider => {
    return new RouteProvider();
}

export const createRouteUrlProvider = (): RouteUrlProvider => {
    return new RouteUrlProvider(
        createRouteProvider()
    );
}

export const createMoneyFactory = (): MoneyFactory => {
    return new MoneyFactory(MAIN_CURRENCY);
}

export function createLanguageStorage(): LanguageStorage {
    if (typeof window === "undefined") {
        throw new Error("Language storage cannot be created outside of browser");
    }
    return new LanguageStorage(window.localStorage);
}

export function createLanguageProvider(): LanguageProvider {
    return new SystemLanguageProvider();
}

export function createLanguageResolver(): LanguageResolver {
    return new LanguageResolver(
        createLanguageStorage(),
        createLanguageProvider()
    );
}

export function createTranslationProvider(): TranslationProviderInterface {
    return new SystemTranslationProvider();
}

export function createRequestSumViewedStorage(): RequestSumViewedStorage {
    return new RequestSumViewedStorage(window.sessionStorage);
}