import React, {AllHTMLAttributes, FC, useMemo} from "react";
import {Money as MoneyModel} from "../../../../api-client/models/common/Money";
import {formatAmount as formatAmountUtil} from "../../../../formatters/formatAmount";
import './Money.scoped.scss';
import CurrentCurrency from "../../../currencies/CurrentCurrency";
import {cn} from "../../../../utils";

interface Props extends AllHTMLAttributes<HTMLSpanElement> {
    money: MoneyModel;
    withPennies?: boolean;
    withCurrency?: boolean;
}

const Money: FC<Props> = ({ money, withPennies = false, withCurrency = true, ...props }) => {
    const formatAmount = useMemo(() => {
        return formatAmountUtil(money.amount / 100);
    }, [money]);
    const [rubles, pennies] = formatAmount.split(".");
    return (
        <span {...props} className={cn("money", props.className)}>
            <span className="money__rubles">
                { rubles }
            </span>
            { withPennies && <span>
                , { pennies }
            </span> }
            { withCurrency && <span>
                &nbsp;<CurrentCurrency />
            </span> }
        </span>
    );
}

export default Money;