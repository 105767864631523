import React, {FC, PropsWithChildren} from "react";
import styles from './AuthFooter.module.scss';
import Container from "../../common/Container";
import AuthChatButton from "../AuthChatButton";
import LanguageSelect from "../../../common/utils/LanguageSelect";

const AuthFooter: FC<PropsWithChildren> = ({ children }) => {
    return (
        <footer className={styles.auth_footer}>
            <Container>
                <div className={styles.auth_footer__container}>
                    <div className={styles.auth_footer__language}>
                        <LanguageSelect />
                    </div>
                    { children }
                    <div className={styles.auth_footer__chat}>
                        <AuthChatButton />
                    </div>
                </div>
            </Container>
        </footer>
    );
}

export default AuthFooter;