import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {UploadManager} from "../UploadManager";

export class UploadManagerFactory {
    private readonly apiClientFactory: ApiClientFactoryInterface;

    constructor(baseUrl: string, headers: Headers) {
        this.apiClientFactory = new ApiClientFactory(
            `${baseUrl}/api/v1/upload`,
            headers
        )
    }

    public createUploadManager(): UploadManager {
        return new UploadManager(this.apiClientFactory.createClient());
    }
}