import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {OfferProvider} from "../OfferProvider";

export class OfferProviderFactory {
    private readonly apiClientFactory: ApiClientFactoryInterface;

    constructor(baseUrl: string, headers: Headers) {
        this.apiClientFactory = new ApiClientFactory(
            `${baseUrl}/api/v1/offer`,
            headers
        )
    }

    public createOfferProvider(authToken: string, abortSignal?: AbortSignal): OfferProvider {
        return new OfferProvider(this.apiClientFactory.createAuthorizedClient(authToken), abortSignal);
    }
}