import React, {PropsWithChildren} from "react";
import {cn, resultIf} from "../../../../../utils";
import './SecondaryButton.scss';
import {ClassInjector} from "../../../../injectors";
import {ClassNameDecorator} from "../../../../decorators";

export enum SecondaryButtonColor {
    GRAY = "secondary-button--gray",
    WHITE = "secondary-button--white",
    BLUE = "secondary-button--blue",
}

interface Props {
    color: SecondaryButtonColor;
    expanded?: boolean;
}

const SecondaryButton: ClassNameDecorator<PropsWithChildren<Props>> = (
    {
        color,
        children,
        expanded,
        className }) => {
    return (
        <ClassInjector classNames={
            cn(
                "secondary-button",
                `${color}`,
                resultIf(expanded === true, "secondary-button--expanded")
            )
        } oldClassName={className}>
            { children }
        </ClassInjector>
    );
}

export default SecondaryButton;