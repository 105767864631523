import React from "react";
import {LayoutLenderIdentification} from "../../layouts";
import {Outlet} from "react-router-dom";

const LenderIdentificationDashboard = () => {
    return (
        <LayoutLenderIdentification>
            <Outlet />
        </LayoutLenderIdentification>
    );
}

export default LenderIdentificationDashboard;