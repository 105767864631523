import React, {PropsWithChildren} from "react";
import {ClassInjector} from "../../../../../injectors";
import {ClassNameDecorator} from "../../../../../decorators";
import './TertiaryHeading.scoped.scss';

const TertiaryHeading: ClassNameDecorator<PropsWithChildren> = ({ children, className }) => {
    return (
        <ClassInjector classNames="tertiary-heading" oldClassName={className}>
            { children }
        </ClassInjector>
    );
}

export default TertiaryHeading;