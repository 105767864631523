import React, {AllHTMLAttributes, Children, FC, PropsWithChildren} from "react";
import './Tabs.scoped.scss';
import {cn} from "../../../../utils";

interface Props extends AllHTMLAttributes<HTMLDivElement> {}

const Tabs: FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
    const renderChildren = () => {
        return Children.map(children, child => {
            return <li className="tabs-navigation__element">
                { child }
            </li>
        });
    }
    return (
        <nav {...props} className={cn("tabs-navigation", props.className)}>
            <ul className="tabs-navigation__list">
                { renderChildren() }
            </ul>
        </nav>
    );
}

export default Tabs;