import React from "react";
import SvgContainer from "./SvgContainer";
import {SvgComponent} from "./SvgComponent";
import {SvgContainerSize} from "./SvgContainer/SvgContainer";

const ChartSvg: SvgComponent = ({ color = "#262B39" }) => {
    return (
        <SvgContainer size={SvgContainerSize.Small}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 6L13 13" stroke={color} strokeWidth="2.5" strokeLinecap="round"/>
                <path d="M8 3L8 13" stroke={color} strokeWidth="2.5" strokeLinecap="round"/>
                <path d="M3 8L3 13" stroke={color} strokeWidth="2.5" strokeLinecap="round"/>
            </svg>
        </SvgContainer>
    );
}

export default ChartSvg;