import React, {AllHTMLAttributes, ElementType, FC, PropsWithChildren} from "react";
import {cn} from "../../../../utils";
import styles from "./LargeText.module.scss";

interface Props extends AllHTMLAttributes<HTMLElement> {
    tag?: ElementType;
}

const LargeText: FC<PropsWithChildren<Props>> = ({ tag: Tag = 'div', children, ...props }) => {
    return (
        <Tag {...props} className={cn(
            styles.large_text,
            props.className
        )}>
            { children }
        </Tag>
    );
}

export default LargeText;