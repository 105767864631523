import React from "react";
import {SvgComponent} from "./SvgComponent";
import {defaultSvgColorDark} from "./SvgProps";
import SvgContainer from "./SvgContainer";
import {SvgContainerSize} from "./SvgContainer/SvgContainer";

const BigCloseSvg: SvgComponent = ({ color= defaultSvgColorDark }) => {
    return (
        <SvgContainer size={SvgContainerSize.Large}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.92969 4.92969L19.0718 19.0718" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M4.92969 19.0703L19.0718 4.92818" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgContainer>
    );
}

export default BigCloseSvg;