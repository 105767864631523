import React, {useContext} from "react";
import {IdentificationContext, IdentificationStep} from "../IdentificationContext";
import CommonAside from "../../../layout/common/CommonAside";
import {useProfileData, useTranslator} from "../../../../hooks";

const IdentificationAside = () => {
    const t = useTranslator();
    const context = useContext(IdentificationContext);
    const profileData = useProfileData();
    return (
        <CommonAside>
            <CommonAside.Step
                index={1}
                text={t("identification.request")}
                active={context.step === IdentificationStep.FillSurvey}
                onClick={() => context.setStep(IdentificationStep.FillSurvey)}
                finished={context.identification !== undefined}
            />
            <CommonAside.Step
                index={2}
                text={t("identification.status")}
                active={context.step === IdentificationStep.CheckStatus}
                onClick={profileData.hasIdentificationDraft ? () => context.setStep(IdentificationStep.CheckStatus) : undefined}
            />
        </CommonAside>
    );
}

export default IdentificationAside;