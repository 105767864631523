import React, {FC} from "react";
import "./DetailedProjectArbitrations.scoped.scss";
import {DetailedProjectDatatable, DetailedProjectSubBlock} from "../../index";
import {CommonSubText, Heading, Money, SecondaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import {Money as MoneyModel, Period, ArbitrationPeriod} from "../../../../../api-client";
import {TranslationDictionary} from "../../../../../utils";
import {useTranslator} from "../../../../../hooks";

interface Props {
    arbitrations: Array<ArbitrationPeriod>;
}

const arbitrationsTranslations: TranslationDictionary<Period> = {
    [Period.OneMonth]: "detailed_project.period.month",
    [Period.ThreeMonths]: "detailed_project.period.three_months",
    [Period.SixMonths]: "detailed_project.period.six_months",
    [Period.TwelveMonths]: "detailed_project.period.twelve_months",
    [Period.AllTime]: "detailed_project.period.all_time",
}

const DetailedProjectArbitrations: FC<Props> = ({ arbitrations }) => {
    const t = useTranslator();
    const renderHeader = () => {
        return arbitrations.map((arbitration) => {
            return <CommonSubText key={arbitration.period}>
                { t(arbitrationsTranslations[arbitration.period]) }
            </CommonSubText>
        });
    }
    const claimantSums: Array<MoneyModel> = arbitrations.map((arbitration) => arbitration.claimant);
    const defendantSums: Array<MoneyModel> = arbitrations.map((arbitration) => arbitration.defendant);
    const finalSums: Array<MoneyModel> = arbitrations.map((arbitration) => arbitration.executionProceeding);
    const renderSums = (sums: Array<MoneyModel>) => {
        return sums.map((sum, index) => {
            return <DetailedProjectDatatable.Text key={index}>
                <Money money={sum} />
            </DetailedProjectDatatable.Text>
        })
    }
    return (
        <>
            <DetailedProjectSubBlock>
                <SecondaryHeading smaller>
                    <Heading headingType={HeadingType.H2}>
                        { t('detailed_project.arbitrations') }
                    </Heading>
                </SecondaryHeading>
                <DetailedProjectDatatable className="detailed-project-arbitration-datatable">
                    <DetailedProjectDatatable.Row>
                        <CommonSubText>
                            { t("detailed_project.arbitrations.role") }
                        </CommonSubText>
                        { renderHeader() }
                    </DetailedProjectDatatable.Row>
                    <DetailedProjectDatatable.Row>
                        <DetailedProjectDatatable.Text>
                            { t("detailed_project.arbitrations.claimant") }
                        </DetailedProjectDatatable.Text>
                        { renderSums(claimantSums) }
                    </DetailedProjectDatatable.Row>
                    <DetailedProjectDatatable.Row>
                        <DetailedProjectDatatable.Text>
                            { t("detailed_project.arbitrations.defendant") }
                        </DetailedProjectDatatable.Text>
                        { renderSums(defendantSums) }
                    </DetailedProjectDatatable.Row>
                </DetailedProjectDatatable>
            </DetailedProjectSubBlock>
            <DetailedProjectSubBlock>
                <SecondaryHeading smaller>
                    <Heading headingType={HeadingType.H2}>
                        { t("detailed_project.arbitrations.execution_proceedings") }
                    </Heading>
                </SecondaryHeading>
                <DetailedProjectDatatable className="detailed-project-arbitration-datatable">
                    <DetailedProjectDatatable.Row>
                        <CommonSubText>
                            { t("detailed_project.arbitrations.index") }
                        </CommonSubText>
                        { renderHeader() }
                    </DetailedProjectDatatable.Row>
                    <DetailedProjectDatatable.Row>
                        <DetailedProjectDatatable.Text>
                            { t("detailed_project.arbitrations.historical") }
                        </DetailedProjectDatatable.Text>
                        { renderSums(finalSums) }
                    </DetailedProjectDatatable.Row>
                </DetailedProjectDatatable>
            </DetailedProjectSubBlock>
        </>
    );
}

export default DetailedProjectArbitrations;