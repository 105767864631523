import React from "react";
import SvgContainer from "../SvgContainer";
import {SvgContainerSize} from "../SvgContainer/SvgContainer";
import {SvgComponent} from "../SvgComponent";
import {ColorDictionary, getCssVariable} from "../../../utils";

const ApprovedSvg: SvgComponent = ({ color= getCssVariable(ColorDictionary.ADDITIONAL_GREEN) }) => {
    return (
        <SvgContainer size={SvgContainerSize.Small}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1575_37260)">
                    <circle cx="8" cy="8" r="7" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
                    <path d="M4.79004 8L7.02222 10.2322L10.9117 5.74634" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_1575_37260">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgContainer>
    );
}

export default ApprovedSvg;