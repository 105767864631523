import React from "react";
import {SvgComponent} from "../SvgComponent";
import SvgContainer from "../SvgContainer";
import {SvgContainerSize} from "../SvgContainer/SvgContainer";
import {defaultSvgColorDark} from "../SvgProps";

const RestartSvg: SvgComponent = ({ color= defaultSvgColorDark }) => {
    return (
        <SvgContainer size={SvgContainerSize.Small}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.78901 6.73224C7.20322 6.73224 7.53901 6.39646 7.53901 5.98224C7.53901 5.56803 7.20322 5.23224 6.78901 5.23224V6.73224ZM3.56055 5.98224H2.81055C2.81055 6.39646 3.14633 6.73224 3.56055 6.73224V5.98224ZM4.31055 2.75378C4.31055 2.33957 3.97476 2.00378 3.56055 2.00378C3.14633 2.00378 2.81055 2.33957 2.81055 2.75378H4.31055ZM6.78901 5.23224H3.56055V6.73224H6.78901V5.23224ZM4.31055 5.98224V2.75378H2.81055V5.98224H4.31055Z" fill={color} />
                <path d="M3.56055 11.0411C4.12298 11.9097 4.94825 12.5746 5.91521 12.9381C6.88217 13.3017 7.93986 13.3447 8.93303 13.061C9.92619 12.7773 10.8025 12.1817 11.4333 11.3617C12.0641 10.5417 12.4161 9.54047 12.4377 8.50502C12.4594 7.46957 12.1494 6.45444 11.5534 5.60867C10.9574 4.76289 10.1068 4.13102 9.12633 3.80583C8.14589 3.48064 7.08732 3.47925 6.10603 3.80186C5.12474 4.12448 4.27243 4.75412 3.67423 5.59832" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgContainer>
    );
}

export default RestartSvg;