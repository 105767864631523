import React from "react";
import {LayoutAuth} from "../../layouts";
import {Outlet} from "react-router-dom";
import AuthFooterRegistration from "../../components/layout/auth/footer/AuthFooterRegistration";
import AuthHeader from "../../components/layout/auth/AuthHeader";

const RegisterAuth = () => {
    return (
        <LayoutAuth header={<AuthHeader />} footer={<AuthFooterRegistration />}>
            <Outlet />
        </LayoutAuth>
    );
}

export default RegisterAuth;