import React, {PropsWithChildren} from 'react';
import {cn, resultIf} from "../../../../../utils";
import './PrimaryButton.scoped.scss';
import {ClassInjector} from "../../../../injectors";
import {ClassNameDecorator} from "../../../../decorators";

export enum PrimaryButtonColor {
    BLUE = "primary-button--blue",
    GRAY = "primary-button--gray"
}

interface Props {
    color: PrimaryButtonColor;
    expanded?: boolean;
    small?: boolean;
}

const PrimaryButton: ClassNameDecorator<PropsWithChildren<Props>> = (
    {
        color,
        children,
        expanded,
        small,
        className
    }
) => {
    return (
        <ClassInjector classNames={
            cn(
                "primary-button",
                `${color}`,
                resultIf(expanded === true, "primary-button--expanded"),
                resultIf(small === true, "primary-button--small")
            )
        } oldClassName={className}>
            { children }
        </ClassInjector>
    );
}

export default PrimaryButton;