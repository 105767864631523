import {ApiClientInterface} from "./clients";
import {AnalyticsPeriod, AnalyticsType} from "./enums";
import {Analytics} from "./models";
import buildQuery from "../utils/router/buildQuery";

export interface AnalyticsFilter {
    period?: AnalyticsPeriod;
    type?: AnalyticsType
}

export class AnalyticsProvider {
    constructor(private readonly apiClient: ApiClientInterface) {}

    public getUserAnalytics(filter?: AnalyticsFilter): Promise<Analytics> {
        const query = buildQuery({ filter });
        return this.apiClient.get(`/user?${query}`);
    }

    public getPlatformAnalytics(filter?: AnalyticsFilter): Promise<Analytics> {
        const query = buildQuery({ filter });
        return this.apiClient.get(`/platform?${query}`);
    }

    public getMkkAnalytics(filter?: AnalyticsFilter): Promise<Analytics> {
        const query = buildQuery({ filter });
        return this.apiClient.get(`/mkk?${query}`);
    }
}