import React, {useEffect, useState} from "react";
import './FundingRequest.scoped.scss';
import FundingRequestContextProvider, {
    FundingRequestContextType,
    FundingRequestStep,
    resolveFundingRequestStep
} from "../../../../components/pages/funding-request/FundingRequestContext";
import {MultiStepForm} from "../../../../components/common";
import FundingRequestUpload from "../../../../components/pages/funding-request/FundingRequestUpload";
import FundingRequestOptions from "../../../../components/pages/funding-request/FundingRequestOptions";
import FundingRequestSignDocument from "../../../../components/pages/funding-request/FundingRequestSignDocument";
import {FundingRequest as FundingRequestModel, moderatedProjectStatuses} from "../../../../api-client";
import {useErrorCallback, useNavigateByName} from "../../../../hooks";
import {createProjectRequestManager} from "../../../../di";
import {useParams} from "react-router-dom";
import {RouteDictionary} from "../../../../utils";
import IntroductionAvailableSum from "../../../../components/pages/introduction/IntroductionAvailableSum";
import FundingRequestNavigation from "../../../../components/pages/funding-request/FundingRequestNavigation";

const FundingRequest = () => {
    const handleError = useErrorCallback();
    const { uuid } = useParams();
    const navigate = useNavigateByName();
    const [step, setStep] = useState(FundingRequestStep.UploadDocuments);
    const [request, setRequest] = useState<FundingRequestModel | null>(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        (async () => {
            try {
                if (uuid !== undefined) {
                    const manager = await createProjectRequestManager();
                    const result = await manager.getFundingRequest(uuid);
                    if (result && !moderatedProjectStatuses.includes(result.projectStatus)) {
                        await navigate(RouteDictionary.MY_PROJECTS);
                        return;
                    }
                    setRequest(result);
                    setStep(resolveFundingRequestStep(result));
                }
            } catch (error: unknown) {
                await handleError(error as Error);
            } finally {
                setLoading(false);
            }
        })()
    }, []);

    const handleUpload = (uuid: string) => {
        navigate(RouteDictionary.PROJECT_REQUEST, { uuid: uuid });
        location.reload();
    }

    const value: FundingRequestContextType = {
        step,
        setStep,
        request,
        setRequest
    }

    return (
        <FundingRequestContextProvider props={value}>
            { loading ? <div>Загрузка...</div> : <div className="funding-request">
                <FundingRequestNavigation />
                <div className="funding-request__inner">
                    <div className="funding-request__container">
                        <MultiStepForm step={step}>
                            <FundingRequestUpload onUploaded={handleUpload} />
                            <IntroductionAvailableSum onNextStep={() => setStep(FundingRequestStep.ChooseLoanOption)} />
                            <></>
                            <FundingRequestOptions />
                            <FundingRequestSignDocument />
                        </MultiStepForm>
                    </div>
                </div>
            </div> }
        </FundingRequestContextProvider>
    );
}

export default FundingRequest;