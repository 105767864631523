import React, {FC} from "react";
import './IntroductionCompanyUrls.scoped.scss';
import {AsLink, Button, InputErrors, InputLabel} from "../../../common";
import {Errors as ValidationErrors} from "../../../../utils";
import AddSvg from "../../../svg/icons/AddSvg";
import {useTranslator} from "../../../../hooks";

interface Props {
    urls: Array<string>;
    setUrls: (urls: Array<string>) => void;
    errors: ValidationErrors | undefined;
}

const maxNumberOfLinks = 5;

const IntroductionCompanyUrls: FC<Props> = ({ urls, setUrls, errors }) => {
    const t = useTranslator();
    const renderInputs = () => {
        return urls.map((url, index) => {
            return <div key={index} className="funding-request-url">
                <input
                    type="text"
                    className="funding-request-url__input"
                    value={url}
                    onChange={(event) => handleInput(event.target.value, index)}
                    placeholder="sitename.ru"
                />
                { index > 0 && <div className="funding-request-url__remove" onClick={() => removeUrl(index)}>
                    { t("common.delete") }
                </div> }
            </div>
        })
    }
    const removeUrl = (index: number) => {
        const newUrls = [...urls];
        newUrls.splice(index, 1);
        setUrls(newUrls);
    }
    const handleInput = (value: string, index: number) => {
        const newUrls = [...urls];
        newUrls[index] = value;
        setUrls(newUrls);
    }
    const addNewUrl = () => {
        setUrls([...urls, ""]);
    }
    return (
        <div className="funding-request-urls">
            <div className="funding-request-urls__inputs">
                <InputLabel id="company-url" text={t("funding_request.survey.company_data.links")} />
                <ul className="funding-request-urls__list">
                    { renderInputs() }
                </ul>
            </div>
            { errors && <InputErrors errors={errors} /> }
            { urls.length < maxNumberOfLinks && <div className="funding-request-urls__bottom">
                <AsLink>
                    <Button onClick={addNewUrl} className="funding-request-urls__add">
                        <span>
                            { t("funding_request.survey.company_data.links.add") }
                        </span>
                        <AddSvg />
                    </Button>
                </AsLink>
            </div> }
        </div>
    );
}

export default IntroductionCompanyUrls;