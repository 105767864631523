import React, {FC, PropsWithChildren} from "react";
import './DetailedProjectSubBlocks.scoped.scss';

const DetailedProjectSubBlocks: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="detailed-project-sub-blocks">
            { children }
        </div>
    );
}

export default DetailedProjectSubBlocks;