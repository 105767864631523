import React from "react";
import Tabs from "../../../../layout/common/Tabs";
import {ProjectLink} from "../../../../common";
import {RouteDictionary} from "../../../../../utils";
import {matchPath, useLocation} from "react-router-dom";
import "./AnalyticsUserNavigation.scoped.scss";
import {useTranslator} from "../../../../../hooks";

const AnalyticsUserNavigation = () => {
    const t = useTranslator();
    const { pathname } = useLocation();
    return (
        <div className="analytics-user-navigation">
            <Tabs>
                <ProjectLink
                    to={RouteDictionary.ANALYTICS_USER_VOLUME}
                    explicitActive={
                        matchPath('lender/analytics', pathname) !== null ||
                        matchPath('lender/analytics/user', pathname) !== null
                    }
                >
                    { t("analytics.user.navigation.volume") }
                </ProjectLink>
                <ProjectLink
                    to={RouteDictionary.ANALYTICS_USER_PROFIT}
                >
                    { t("analytics.user.navigation.yield") }
                </ProjectLink>
                <ProjectLink
                    to={RouteDictionary.ANALYTICS_USER_DEFAULTS}
                >
                    { t("analytics.user.navigation.defaults") }
                </ProjectLink>
                <ProjectLink
                    to={RouteDictionary.ANALYTICS_USER_PENALTIES}
                >
                    { t("analytics.user.navigation.penalties") }
                </ProjectLink>
            </Tabs>
        </div>
    );
}

export default AnalyticsUserNavigation;