import React from "react";
import ClockSvg from "../stats/ClockSvg";
import {SvgContainerSize} from "../SvgContainer/SvgContainer";
import {ColorDictionary, getCssVariable} from "../../../utils";

const PendingSvg = () => {
    return (
        <ClockSvg
            color={getCssVariable(ColorDictionary.ADDITIONAL_ORANGE)}
            containerWidth={SvgContainerSize.Small}
        />
    );
}

export default PendingSvg;