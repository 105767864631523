import {Translations} from "../../types";

export const common: Translations = {
    "common.surname": "Фамилия",
    "common.name": "Имя",
    "common.parent_name": "Отчество",
    "common.birthday": "Дата рождения",
    "common.email": "Email",
    "common.phone": "Номер телефона",
    "common.passport.series": "Серия",
    "common.passport.number": "Номер",
    "common.passport.issued_by": "Кем выдан",
    "common.passport.department_code": "Код подразделения",
    "common.passport.issued_date": "Дата выдачи",
    "common.passport.registration_address": "Адрес регистрации",
    "common.passport.face_photo": "Разворот паспорта с фотографией",
    "common.passport.registration_photo": "Разворот паспорта с пропиской",
    "common.passport.passport_selfie": "Селфи с паспортом",
    "common.lender": "Инвестор",
    "common.borrower": "Заемщик",
    "common.password": "Пароль",
    "common.back": "Назад",
    "common.individual": "Физ. лицо",
    "common.entrepreneur": "ИП",
    "common.legal_entity": "Юр. лицо",
    "common.please_wait": "Пожалуйста, подождите...",
    "common.loading": "Загрузка...",
    "common.reg_number": "Рег. номер",
    "common.company_reg_number": "Рег. номер компании",
    "common.continue": "Продолжить",
    "common.kpp": "КПП",
    "common.bik": "БИК",
    "common.from": "От",
    "common.to": "До",
    "common.chart_mln": "млн",
    "common.chart_tho": "тыс",
    "common.submit": "Подтвердить",
    "common.delete": "Удалить",
    "common.no_translation": "«:lang» перевод не найден",

    "common.euro": "Евро",
    "common.ruble": "Рубль",
    "common.dram": "Драм",
}