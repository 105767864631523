import {useTranslator} from "../../useTranslator";
import {Passport} from "../../../api-client";
import {Errors as ValidationErrors, validateDepartmentCode} from "../../../utils";

export type PassportErrors = {
    series?: ValidationErrors;
    number?: ValidationErrors;
    issuedBy?: ValidationErrors;
    issuedDate?: ValidationErrors;
    departmentCode?: ValidationErrors;
    registrationAddress?: ValidationErrors;
}

export const usePassportValidation = (data: { passport: Passport }) => {
    const t = useTranslator();
    return (initialErrors: PassportErrors) => {
        if (data.passport.series === "") {
            initialErrors.series = [t(
                "errors.field_required",
                { field: t("errors.fields.passport.series"), type: t("errors.field_required.feminine") }
            )];
        } else if (isNaN(parseInt(data.passport.series))) {
            initialErrors.series = [t(
                "errors.not_number",
                { field: t("errors.fields.passport.series"), type: t("errors.field_required.feminine") }
            )];
        }
        if (data.passport.number === "") {
            initialErrors.number = [t(
                "errors.field_required",
                { field: t("errors.fields.passport.number"), type: t("errors.field_required.masculine") }
            )];
        } else if (isNaN(parseInt(data.passport.number))) {
            initialErrors.number = [t(
                "errors.not_number",
                { field: t("errors.fields.passport.number"), type: t("errors.field_required.masculine") }
            )];
        }
        if (data.passport.issuedBy === "") {
            initialErrors.issuedBy = [t(
                "errors.field_required",
                { field: t("errors.fields.passport.issued_by"), type: t("errors.field_required.neuter") }
            )];
        }
        if (data.passport.departmentCode === "") {
            initialErrors.departmentCode = [t(
                "errors.field_required",
                { field: t("errors.fields.passport.department_code"), type: t("errors.field_required.masculine") }
            )];
        } else if (!validateDepartmentCode(data.passport.departmentCode)) {
            initialErrors.departmentCode = [t(
                "errors.wrong_department_code"
            )];
        }
        if (data.passport.registrationAddress === "") {
            initialErrors.registrationAddress = [t(
                "errors.field_required",
                { field: t("errors.fields.passport.registration_address"), type: t("errors.field_required.masculine") }
            )];
        }
        return initialErrors;
    }
}