import React, {FC} from "react";
import {CommonBlock} from "../../../../../common/blocks";
import {CommonSubText, CommonText, Money, PrimaryButton, ProjectLink} from "../../../../../common";
import {PrimaryButtonColor} from "../../../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {RouteDictionary} from "../../../../../../utils";
import CommonPropertiesList from "../../../../../common/blocks/CommonPropertiesList";
import {activeProjectStatuses, DetailedMyModeratedProject} from "../../../../../../api-client";
import {myProjectScheduleTranslations} from "../MyProjectAside/MyProjectAside";
import MyProjectStatusResolver from "../../status/MyProjectStatusResolver";
import {useTranslator} from "../../../../../../hooks";

interface Props {
    project: DetailedMyModeratedProject;
}

//TODO: SOLVE ISSUE WITH NULL OR UNDEFINED INITIAL TERM
const MyModeratedProjectAside: FC<Props> = ({ project }) => {
    const t = useTranslator();
    return (
        <>
            <CommonBlock>
                <CommonBlock.Section underscore={false}>
                    <CommonPropertiesList>
                        <CommonPropertiesList.Element>
                            <CommonSubText>
                                { t("my_detailed_project.aside.requested") }
                            </CommonSubText>
                            <CommonText>
                                { project.moderated.loan.sum === undefined
                                    ? t("my_detailed_project.aside.not_listed")
                                    : <Money money={project.moderated.loan.sum} /> }
                            </CommonText>
                        </CommonPropertiesList.Element>
                        <CommonPropertiesList.Element>
                            <CommonSubText>
                                { t("my_detailed_project.aside.rate") }
                            </CommonSubText>
                            <CommonText>
                                { project.moderated.loan.interestRate === null
                                    ? t("my_detailed_project.aside.not_listed")
                                    : `${project.moderated.loan.interestRate}%` }
                            </CommonText>
                        </CommonPropertiesList.Element>
                        <CommonPropertiesList.Element>
                            <CommonSubText>
                                { t("my_detailed_project.aside.term") }
                            </CommonSubText>
                            <CommonText>
                                { project.moderated.loan.initialTerm === null
                                    ? t("my_detailed_project.aside.not_listed.masculine")
                                    : `${project.moderated.loan.initialTerm} месяцев` }
                            </CommonText>
                        </CommonPropertiesList.Element>
                        <CommonPropertiesList.Element>
                            <CommonSubText>
                                { t("my_detailed_project.schedule.repayment") }
                            </CommonSubText>
                            <CommonText>
                                { project.moderated.paymentSchedule === undefined
                                    ? t("my_detailed_project.aside.not_listed.masculine")
                                    : t(myProjectScheduleTranslations[project.moderated.paymentSchedule.scheduleType]) }
                            </CommonText>
                        </CommonPropertiesList.Element>
                    </CommonPropertiesList>
                </CommonBlock.Section>
            </CommonBlock>
            <CommonBlock>
                <MyProjectStatusResolver status={project.status} />
            </CommonBlock>
            <PrimaryButton expanded color={PrimaryButtonColor.BLUE}>
                <ProjectLink to={RouteDictionary.PROJECT_REQUEST} params={{uuid: project.uuid}}>
                    { t("my_detailed_project.aside.continue") }
                </ProjectLink>
            </PrimaryButton>
        </>
    );
}

export default MyModeratedProjectAside;