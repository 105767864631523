import {ApiClientInterface} from "./clients";
import {UploadedFile} from "./models";

export class UploadManager {
    constructor(private readonly apiClient: ApiClientInterface) {}

    public uploadFile(file: File): Promise<UploadedFile> {
        const formData = new FormData();
        formData.append("file", file);
        return this.apiClient.uploadFile("/file", formData);
    }
}