import React, {FC, ReactNode, useId, useRef, useState} from "react";
import {UploadedFile} from "../../../../../api-client";
import "./IdentificationPassportUpload.scoped.scss";
import {FileUpload, Loader} from "../../index";
import {Image} from "../../../images";
import {cn, resultIf} from "../../../../../utils";
import yesSign from "../../../../../assets/images/icons/yes-sign.svg";
import LoaderContainer from "../../../../loading/LoaderContainer";

interface Props {
    photos: Array<UploadedFile>;
    setPhotos: (value: Array<UploadedFile>) => void;
    name: ReactNode;
    placeholder: string;
    error: boolean;
}

const IdentificationPassportUpload: FC<Props> = ({ photos, setPhotos, name, placeholder, error }) => {
    const id = useId();
    const accept = useRef(".jpg, .jpeg, .png");
    const [loading, setLoading] = useState(false);
    return (
        <FileUpload
            files={photos}
            onUploaded={setPhotos}
            onLoading={(value) => setLoading(value)}
            id={id}
            accept={accept.current}
            multiple={false}
        >
            <div className={cn("identification-passport-upload", resultIf(error, "error"))}>
                { loading && <LoaderContainer>
                    <Loader />
                </LoaderContainer> }
                <div className={cn("identification-passport-upload__image", resultIf(photos.length !== 0, "loaded"))}>
                    { photos.length !== 0 && <div className="identification-passport-upload__state">
                        <Image src={yesSign} />
                    </div> }
                    <Image src={placeholder} />
                </div>
                <div className="identification-passport-upload__name">
                    { name }
                </div>
            </div>
        </FileUpload>
    );
}

export default IdentificationPassportUpload;