import React from "react";
import "./FundingRequestRequisites.scoped.scss";
import {CommonBlock} from "../../../common/blocks";
import PendingSvg from "../../../svg/status/PendingSvg";
import {CommonSubText, Heading, TertiaryHeading} from "../../../common";
import {HeadingType} from "../../../common/typography/headings/Heading/Heading";
import CurrentCurrency from "../../../currencies/CurrentCurrency";
import QrCode from "../../../common/utils/QrCode";
import {Currency} from "../../../../utils";
import {MAIN_CURRENCY} from "../../../../utils/constants/money";
import {useTranslator} from "../../../../hooks";

const currencies: Record<Currency, string> = {
    [Currency.Eur]: "common.euro",
    [Currency.Rub]: "common.ruble",
    [Currency.Dra]: "common.dram",
}

const FundingRequestRequisites = () => {
    const t = useTranslator();
    return (
        <div className="funding-request-requisites">
            <CommonBlock>
                <div className="funding-request-requisites__header">
                    <div className="funding-request-requisites__heading">
                        <PendingSvg />
                        <TertiaryHeading>
                            <Heading headingType={HeadingType.H3}>
                                { t("funding_request.sign_document.requisites.heading_first") } &laquo;{ t("funding_request.sign_document.requisites.heading_second") }&raquo;
                            </Heading>
                        </TertiaryHeading>
                    </div>
                    <div className="funding-request-requisites__text">
                        { t('funding_request.sign_document.requisites.description') } 1&nbsp;<CurrentCurrency /> ({ t("funding_request.sign_document.requisites.one") } { t(currencies[MAIN_CURRENCY]).toLowerCase() })&nbsp;
                        { t("funding_request.sign_document.requisites.description_second") }
                    </div>
                </div>
                <div className="funding-request-requisites__content">
                    <div className="funding-request-requisites__heading">
                        <TertiaryHeading>
                            <Heading headingType={HeadingType.H3} className="funding-request-requisites__heading">
                                { t("funding_request.sign_document.requisites") } 1 <CurrentCurrency />
                            </Heading>
                        </TertiaryHeading>
                    </div>
                    <ul className="funding-request-requisites__list">
                        <li className="funding-request-requisites__text">
                            ООО &quot;Ido.finance&quot;
                        </li>
                        <li className="funding-request-requisites__text">
                            { t("common.reg_number") } 9701046627
                        </li>
                        <li className="funding-request-requisites__text">
                            { t("common.kpp") } 770101001
                        </li>
                        <li className="funding-request-requisites__text">
                            Р/с 40702810901600004898
                        </li>
                        <li className="funding-request-requisites__text">
                            { t("common.bik") } 044525593
                        </li>
                        <li className="funding-request-requisites__text">
                            Кор/сч. 30101810200000000593
                        </li>
                        <li className="funding-request-requisites__text">
                            { t("funding_request.sign_document.requisites.purpose") }
                        </li>
                    </ul>
                </div>
                <div className="funding-request-requisites__footer">
                    <div className="funding-request-requisites__qr">
                        <QrCode />
                    </div>
                    <TertiaryHeading>
                        <Heading headingType={HeadingType.H3}>
                            { t("funding_request.sign_document.qr_code") } 1 <CurrentCurrency />
                        </Heading>
                    </TertiaryHeading>
                    <CommonSubText>
                        { t("funding_request.sign_document.qr_code.camera") }
                    </CommonSubText>
                </div>
            </CommonBlock>
        </div>
    );
}

export default FundingRequestRequisites;