import {Translations} from "../../types";

export const errors: Translations = {
    "errors.field_required": ":field պահանջվում է",
    "errors.fields_required": ":field պահանջվում են",
    "errors.passwords_do_not_match": "Գաղտնաբառերը չեն համապատասխանում",
    "errors.confirm_password": "Հաստատել գաղտնաբառը",
    "errors.accept_something": "Ընդունել :document",
    "errors.field_less_than": ":field պետք է լինի ավելի քան :amount",
    "errors.fields_less_than": ":field պետք է լինի ավելի քան :amount",
    "errors.field_more_than": ":field պետք է լինի :amount փոքր",
    "errors.fields_more_than": ":field պետք է լինի :amount փոքր",
    "errors.incorrect_input": ":field սխալ է մուտքագրվել",
    "errors.incorrect_inputs": ":field սխալ է մուտքագրվել",
    "errors.confirm_phone": "Ձեր հեռախոսը հաստատված չէ",
    "errors.not_number": ":field պետք է լինի թիվ",
    "errors.wrong_department_code": "Բաժանմունքի կոդի անվավեր ձևաչափ",
    "errors.wrong_phone": "Հեռախոսահամարի անվավեր ձևաչափ",

    "errors.fields.name": "Անուն",
    "errors.fields.last_name": "Ազգանունը",
    "errors.fields.email": "Էլ",
    "errors.fields.phone": "Հեռախոսահամար",
    "errors.fields.password": "Գաղտնաբառ",
    "errors.fields.register_type": "Գրանցման տեսակը",
    "errors.fields.platform_rules": "Պլատֆորմի կանոններ",
    "errors.fields.privacy_policy": "Գաղտնիության քաղաքականություն",
    "errors.fields.sum": "Գումար",
    "errors.fields.rate": "Գնահատել",
    "errors.fields.term": "Ժամկետ",
    "errors.fields.passport.series": "Սերիա",
    "errors.fields.passport.number": "Թիվ",
    "errors.fields.passport.issued_by": "Թողարկվել է",
    "errors.fields.passport.department_code": "Բաժանմունքի կոդը",
    "errors.fields.passport.issued_at": "թողարկման ամսաթիվը",
    "errors.fields.passport.registration_address": "Գրանցման հասցեն",
    "errors.fields.company.reg_number": "կանոն. սենյակ",
    "errors.fields.company.kpp": "անցակետ",
    "errors.fields.company.phone": "Հեռախոս",
    "errors.fields.company.description": "Նկարագրություն",
    "errors.fields.company.business_form": "Բիզնեսի ձև",
    "errors.fields.company.activity": "Գործունեություն",
    "errors.fields.company.address": "Հասցեն",
    "errors.fields.company.site_urls": "Հղումներ դեպի կայքեր",
    "errors.fields.company.reputation_rating": "Վարկանիշ",
    "errors.fields.company.money_source": "Եկամտի աղբյուր",
};
