import React, {FC} from "react";
import {SellingProjectInvestment} from "../../../../../api-client";
import styles from "./SellingInvestment.module.scss";

interface Props {
    investment: SellingProjectInvestment;
}

const SellingInvestment: FC<Props> = ({ investment }) => {
    return (
        <></>
    );
}

export default SellingInvestment;