import React, {Children, FC, isValidElement, PropsWithChildren} from "react";
import {cn} from "../../utils";

interface Props {
    classNames: string;
    oldClassName?: string | undefined;
}

const ClassInjector: FC<PropsWithChildren<Props>> = ({ classNames, oldClassName, children }) => {
    if (Children.count(children) > 1) {
        throw new Error("More than one child is passed to class injector");
    }
    const StyledChildren = () => {
        return Children.map(children, child => {
            if (isValidElement(child)) {
                return React.cloneElement(child, {
                    className: `${cn(classNames, oldClassName ?? "", child.props.className)}`
                } as Partial<HTMLElement>)
            }
        })
    }

    // @ts-ignore
    return <StyledChildren />;
}

export default ClassInjector;