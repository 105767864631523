import {Translations} from "../../types";

export const word_case: Translations = {
    "word_case.days.nominative": "օր",
    "word_case.days.genitive": "օրեր",
    "word_case.days.multiple": "օրեր",

    "word_case.months.nominative": "ամիս",
    "word_case.months.genitive": "ամիսներ",
    "word_case.months.multiple": "ամիսներ",

    "word_case.times.nominative": "մեկ անգամ",
    "word_case.times.genitive": "անգամ",
    "word_case.times.multiple": "մեկ անգամ",

    "word_case.offers.nominative": "առաջարկ",
    "word_case.offers.genitive": "առաջարկներ",
    "word_case.offers.multiple": "առաջարկներ",

    "word_case.loans.nominative": "վարկ",
    "word_case.loans.genitive": "վարկեր",
    "word_case.loans.multiple": "վարկեր",

    "word_case.loans-returned.nominative": "վերադարձել է",
    "word_case.loans-returned.genitive": "վերադարձել է",
    "word_case.loans-returned.multiple": "վերադարձել է",
}