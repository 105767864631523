import React, {FC, PropsWithChildren} from "react";
import styles from "./CommonPropertiesListElement.module.scss";

const CommonPropertiesListElement: FC<PropsWithChildren> = ({ children }) => {
    return (
        <li className={styles.common_properties_list_element}>
            { children }
        </li>
    );
}

export default CommonPropertiesListElement;