import {NamedUrl} from "../types";
import {RouteDictionary} from "./RouteDictionary";

//TODO: REMOVE CONTACT US< WILL BE REPLACED WITH CHAT
export const userRoutes: Array<NamedUrl> = [
    {
        name: RouteDictionary.CONTACT_US,
        url: "/contact-us"
    },
    {
        name: RouteDictionary.DYNAMIC_PAGE,
        url: "/page/:name"
    }
]