import {Translations} from "../../types";

export const finances: Translations = {
    "finances": "Finances",
    "finances.schedule": "Repayment schedule",
    "finances.repayment.date": "Date",
    "finances.repayment.amount": "Payment amount",
    "finances.repayment.debt": "Principal",
    "finances.repayment.percents": "Interest",
    "finances.no_data": "No payments found",
    "finances.calendar": "Payments calendar",
    "finances.calendar.no_payments": "There are no payments on this day.",
    "finances.calendar.payment.id": "Loan ID",
    "finances.calendar.payment.sum": "Investment sum",
    "finances.calendar.payment.rate": "Rate",
    "finances.calendar.payment.month": "Term",
    "finances.calendar.payment.date": "Payment date",
    "finances.calendar.payment.payment_sum": "Payment amount",
}