import React, {FC, useMemo} from "react";
import"./InputErrors.scoped.scss";
import {Errors} from "../../../../utils";

interface Props {
    errors: Errors;
}

const InputErrors: FC<Props> = ({ errors }) => {
    const renderErrors = useMemo(() => {
        return errors.map((error) => {
            return <li key={error} className="input-errors-list">{ error }</li>
        })
    }, [errors]);
    return (
        <ul className="input-error">
            { renderErrors }
        </ul>
    );
}

export default InputErrors;