import {Language} from "../api-client";
import {invariant} from "../utils";

export abstract class LanguageProvider {
    private cachedLanguages: Array<Language> = [];

    abstract provideLanguages(): Array<Language>;

    public getLanguages(): Array<Language> {
        if (this.cachedLanguages.length === 0) {
            this.cachedLanguages = this.provideLanguages();
        }
        return this.cachedLanguages;
    }

    public getSupportedLanguageCodes(): Array<string> {
        return this.cachedLanguages.map(language => language.short);
    }

    public getDefaultLanguage(): Language {
        const filtered = this.getLanguages().filter(language => language.isDefault);
        invariant(filtered.length === 1, "More than one default language provided!");
        return filtered[0];
    }

    public getByShort(short: string): Language {
        const filtered = this.getLanguages().filter(language => language.short === short);
        invariant(filtered.length === 1, "Found more than one language with given short code");
        return filtered[0];
    }
}