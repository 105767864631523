import {Translations} from "../../types";

export const deposit: Translations = {
    "deposit": "Пополнить",
    "deposit.id_assigned": "Вам присвоен ID",
    "deposit.terms": "Осуществлять инвестиции на платформе возможно лишь при наличии свободных средств на индивидуальном учетном счете",
    "deposit.transfer": "Пополнение средств осуществляется исключительно переводом средств с банковского счета, принадлежащего инвестору на номинальный счёт Оператора платформы",
    "deposit.requisites": "Реквизиты для пополнения",
    "deposit.reg_number": "Рег. номер",
    "deposit.account": "Р/с",
    "deposit.bik": "БИК",
    "deposit.correspondent": "Кор/сч",
    "deposit.purpose": "Назначение платежа",
    "deposit.agreement": "Пополнение номинального счета по договору от :date, ID 160499, НДС не облагается",
    "deposit.qr": "QR-код для отправки",
    "deposit.camera": "Наведите камеру смартфона",
}