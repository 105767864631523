import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {GuarantorManager} from "../GuarantorManager";

export class GuarantorManagerFactory {
    private readonly apiClientFactory: ApiClientFactoryInterface;

    constructor(baseUrl: string, headers: Headers) {
        this.apiClientFactory = new ApiClientFactory(
            `${baseUrl}/api/v1/guarantor`,
            headers
        )
    }

    public createGuarantorManager(): GuarantorManager {
        return new GuarantorManager(this.apiClientFactory.createClient());
    }
}