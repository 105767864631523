import React from "react";
import {useNavigateByName, useLogout} from "../../../hooks";
import {RouteDictionary} from "../../../utils";

const Logout = () => {
    const navigate = useNavigateByName();
    useLogout(() => navigate(RouteDictionary.LOGIN));
    return (
        <></>
    );
}

export default Logout;