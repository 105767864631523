import apiClientConfig from '../configs/app';
import * as apiClient from '../api-client';
import {createAuthTokenManager} from "./utils";
import {AnalyticsProvider} from "../api-client";

function getBaseHeaders(): apiClient.Headers {
    return {
        "Accept-Language": "ru"
    }
}

async function getAuthToken(): Promise<string> {
    const authTokenManager = createAuthTokenManager();
    return await authTokenManager.get();
}

//Authorization is not required
export function createAuthManager(): apiClient.AuthManager {
    const factory = new apiClient.AuthManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createAuthManager();
}

export function createRegistrationManager(): apiClient.RegistrationManager {
    const factory = new apiClient.RegistrationManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createRegistrationManager();
}

export function createApiLanguageProvider(): apiClient.LanguageProvider {
    const factory = new apiClient.LanguageProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createLanguageProvider();
}

export function createGuarantorManager(): apiClient.GuarantorManager {
    const factory = new apiClient.GuarantorManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createGuarantorManager();
}

export function createCompanyProvider(abortSignal?: AbortSignal): apiClient.CompanyProvider {
    const factory = new apiClient.CompanyProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createCompanyProvider(abortSignal);
}

export function createKonturFocusRequisitesProvider(): apiClient.KonturFocusRequisitesProvider {
    const factory = new apiClient.KonturFocusRequisitesProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createKonturFocusRequisitesProvider();
}

//Authorization required

export async function createProfileManager(): Promise<apiClient.ProfileManager> {
    const factory = new apiClient.ProfileManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createProfileManager(await getAuthToken());
}

export async function createAnalyticsProvider(): Promise<AnalyticsProvider> {
    const factory = new apiClient.AnalyticsProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createAnalyticsProvider(await getAuthToken());
}

export async function createBorrowerCompanyManager(): Promise<apiClient.BorrowerCompanyManager> {
    const factory = new apiClient.BorrowerCompanyManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createBorrowerCompanyManager(await getAuthToken());
}

export async function createCancelInvestmentManager(): Promise<apiClient.CancelInvestmentManager> {
    const factory = new apiClient.CancelInvestmentManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createCancelInvestmentManager(await getAuthToken());
}

export async function createIdentificationManager(): Promise<apiClient.IdentificationManager> {
    const factory = new apiClient.IdentificationManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createIdentificationManager(await getAuthToken());
}

export async function createProjectRequestManager(signal?: AbortSignal): Promise<apiClient.ProjectRequestManager> {
    const factory = new apiClient.ProjectRequestManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createProjectRequestManager(await getAuthToken(), signal);
}

export async function createMyProjectsProvider(signal: AbortSignal): Promise<apiClient.MyProjectsProvider> {
    const factory = new apiClient.MyProjectsProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createMyProjectsProvider(await getAuthToken(), signal);
}

export async function createOfferProvider(signal?: AbortSignal): Promise<apiClient.OfferProvider> {
    const factory = new apiClient.OfferProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createOfferProvider(await getAuthToken(), signal);
}

export async function createProjectManager(): Promise<apiClient.ProjectManager> {
    const factory = new apiClient.ProjectManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createProjectManager(await getAuthToken());
}

export async function createInvestmentManager(): Promise<apiClient.InvestmentManager> {
    const factory = new apiClient.InvestmentManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createInvestmentManager(await getAuthToken());
}

export async function createMyMoneyProvider(signal: AbortSignal): Promise<apiClient.MyMoneyProvider> {
    const factory = new apiClient.MyMoneyProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createMoneyProvider(await getAuthToken(), signal);
}

export async function createDayRepaymentProvider(signal: AbortSignal): Promise<apiClient.DayRepaymentProvider> {
    const factory = new apiClient.DayRepaymentProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createDayRepaymentProvider(await getAuthToken(), signal);
}

export async function createRepaymentProvider(signal: AbortSignal): Promise<apiClient.RepaymentProvider> {
    const factory = new apiClient.RepaymentProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createRepaymentProvider(await getAuthToken(), signal);
}

export function createUploadManager(): apiClient.UploadManager {
    const factory = new apiClient.UploadManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createUploadManager();
}

export async function createVerificationManager(needVerification: boolean = true): Promise<apiClient.VerificationManager> {
    const factory = new apiClient.VerificationManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return needVerification ? factory.createVerificationManager(await getAuthToken()) : factory.createPublicVerificationManager();
}

export async function createWithdrawManager(): Promise<apiClient.WithdrawManager> {
    const factory = new apiClient.WithdrawManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
    return factory.createWithdrawManager(await getAuthToken());
}