import {Translations} from "../../types";

export const common: Translations = {
    "common.surname": "Surname",
    "common.name": "Name",
    "common.parent_name": "Parent name",
    "common.birthday": "Birthday",
    "common.email": "Email",
    "common.phone": "Phone number",
    "common.passport.series": "Series",
    "common.passport.number": "Number",
    "common.passport.issued_by": "Issued by",
    "common.passport.department_code": "Department code",
    "common.passport.issued_date": "Issued date",
    "common.passport.registration_address": "Registration address",
    "common.passport.face_photo": "Page with photo",
    "common.passport.registration_photo": "Page with registration",
    "common.passport.passport_selfie": "Selfie with passport",
    "common.lender": "Lender",
    "common.borrower": "Borrower",
    "common.password": "Password",
    "common.back": "Go back",
    "common.individual": "Individual",
    "common.entrepreneur": "Entrepreneur",
    "common.legal_entity": "Legal entity",
    "common.please_wait": "Please, wait...",
    "common.loading": "Loading...",
    "common.reg_number": "Reg. number",
    "common.company_reg_number": "Company reg. number",
    "common.continue": "Continue",
    "common.kpp": "LLC",
    "common.bik": "BIK",
    "common.from": "From",
    "common.to": "To",
    "common.chart_mln": "mln",
    "common.chart_tho": "ths",
    "common.submit": "Submit",
    "common.delete": "Delete",
    "common.no_translation": "«:lang» translation not found",

    "common.euro": "Euro",
    "common.ruble": "Ruble",
    "common.dram": "Dram",
}