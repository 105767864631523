import {useMemo, useState} from "react";
import {PassportPhotos, UploadedFile} from "../../api-client";

export const usePassportPhotos = (): [PassportPhotos, (photos: PassportPhotos) => void] => {
    const [firstPhotos, setFirstPhotos] = useState<Array<UploadedFile>>([]);
    const [secondPhotos, setSecondPhotos] = useState<Array<UploadedFile>>([]);
    const [selfiePhotos, setSelfiePhotos] = useState<Array<UploadedFile>>([]);

    const passportPhotos = useMemo((): PassportPhotos => {
        return {
            firstPhotos,
            secondPhotos,
            selfiePhotos
        }
    }, [firstPhotos, secondPhotos, selfiePhotos]);

    const setPassportPhotos = (photos: PassportPhotos) => {
        setFirstPhotos(photos.firstPhotos);
        setSecondPhotos(photos.secondPhotos);
        setSelfiePhotos(photos.selfiePhotos);
    }

    return [passportPhotos, setPassportPhotos];
}