import React, {useContext} from "react";
import {ProjectLink} from "../../../../common";
import {getQueryObject, ObjectValues, RouteDictionary} from "../../../../../utils";
import {matchPath, useLocation} from "react-router-dom";
import './DetailedProjectNavigation.scoped.scss';
import {DetailedProjectContext} from "../../DetailedProjectContext";
import Tabs from "../../../../layout/common/Tabs";
import {useTranslator} from "../../../../../hooks";
import {activeProjectStatuses} from "../../../../../api-client";

export const DETAILED_PROJECT_TAB = {
    OFFERS: "OFFERS",
    SUMMARY: "SUMMARY",
    ANALYTICS: "ANALYTICS",
    REPUTATION: "REPUTATION",
    DOCUMENTS: "DOCUMENTS"
} as const;

export type DetailedProjectTab = ObjectValues<typeof DETAILED_PROJECT_TAB>;

export type TabQuery = {
    tab: DetailedProjectTab;
}

const DetailedProjectNavigation = () => {
    const t = useTranslator();
    const tab = getQueryObject<TabQuery>(useLocation().search).tab;
    const { pathname } = useLocation();
    const { investment, project } = useContext(DetailedProjectContext);
    const resolveRoute = () => {
        return investment !== undefined ? RouteDictionary.DETAILED_PROJECT_INVESTMENT : RouteDictionary.DETAILED_PROJECT;
    }
    const resolveParams = () => {
        return { uuid: project.uuid, investmentUuid: investment !== undefined ? investment.uuid : "" };
    }
    return (
        <div className="detailed-project-navigation">
            <Tabs>
                { activeProjectStatuses.includes(project.status) && <ProjectLink
                    to={resolveRoute()}
                    params={resolveParams()}
                    queryObject={{ tab: DETAILED_PROJECT_TAB.OFFERS }}
                    className={() => tab === DETAILED_PROJECT_TAB.OFFERS ? "active " : ""}
                    explicitActive={tab === DETAILED_PROJECT_TAB.OFFERS}
                >
                    { t("detailed_project.navigation.offers") }
                </ProjectLink> }
                <ProjectLink
                    to={resolveRoute()}
                    params={resolveParams()}
                    queryObject={{ tab: DETAILED_PROJECT_TAB.SUMMARY }}
                    className={() => tab === DETAILED_PROJECT_TAB.SUMMARY ? "active " : ""}
                    explicitActive={
                        tab === DETAILED_PROJECT_TAB.SUMMARY
                            || matchPath('lender/market/:uuid', pathname) !== null
                            && tab === undefined
                            || matchPath('lender/market/:uuid/investment/:investmentUuid', pathname) !== null
                            && tab === undefined
                    }
                >
                    { t("detailed_project.navigation.summary") }
                </ProjectLink>
                <ProjectLink
                    to={resolveRoute()}
                    params={resolveParams()}
                    queryObject={{ tab: DETAILED_PROJECT_TAB.ANALYTICS }}
                    className={() => tab === DETAILED_PROJECT_TAB.ANALYTICS ? "active " : ""}
                    explicitActive={tab === DETAILED_PROJECT_TAB.ANALYTICS}
                >
                    { t("detailed_project.navigation.analytics") }
                </ProjectLink>
                <ProjectLink
                    to={resolveRoute()}
                    params={resolveParams()}
                    queryObject={{ tab: DETAILED_PROJECT_TAB.REPUTATION }}
                    className={() => tab === DETAILED_PROJECT_TAB.REPUTATION ? "active " : ""}
                    explicitActive={tab === DETAILED_PROJECT_TAB.REPUTATION}
                >
                    { t("detailed_project.navigation.reputation") }
                </ProjectLink>
                { activeProjectStatuses.includes(project.status) && <ProjectLink
                    to={resolveRoute()}
                    params={resolveParams()}
                    queryObject={{ tab: DETAILED_PROJECT_TAB.DOCUMENTS }}
                    className={() => tab === DETAILED_PROJECT_TAB.DOCUMENTS ? "active " : ""}
                    explicitActive={tab === DETAILED_PROJECT_TAB.DOCUMENTS}
                >
                    { t("detailed_project.navigation.documents") }
                </ProjectLink> }
            </Tabs>
        </div>
    );
}

export default DetailedProjectNavigation;