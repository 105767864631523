import React from "react";
import {defaultSvgColor} from "../SvgProps";
import {SvgComponent} from "../SvgComponent";
import SvgContainer from "../SvgContainer";

const FolderSvg: SvgComponent = ({ color = defaultSvgColor }) => {
    return (
        <SvgContainer>
            <svg className="folder-svg" width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M16.1949 4.60647C16.1744 4.44757 16.152 4.28885 16.1277 4.13037L16.0737 3.77814C15.884 2.53979 14.8589 1.6286 13.6554 1.6286L6.82025 1.6286C6.56675 0.688919 5.73832 0 4.75547 0H2.82578C1.57712 0 0.525374 0.972168 0.376499 2.26395L0.147044 4.25493C-0.099389 6.39323 -0.0357889 8.55799 0.335712 10.6766C0.534457 11.81 1.44419 12.6596 2.54713 12.7418L3.82205 12.8368C6.74047 13.0544 9.67007 13.0544 12.5885 12.8368L13.9677 12.734C14.6486 12.6833 15.2515 12.3228 15.6398 11.7803C16.1566 11.2638 16.5632 10.6329 16.8263 9.928L17.8785 7.10898C18.3279 5.90491 17.4754 4.60647 16.2355 4.60647H16.1949ZM2.82578 1.31613C2.21648 1.31613 1.70326 1.79052 1.63061 2.42087L1.40115 4.41185C1.16982 6.41913 1.22952 8.45124 1.57826 10.44C1.67344 10.9828 2.10909 11.3896 2.63727 11.429L2.7097 11.4344L4.6735 6.14834C5.01873 5.21904 5.87671 4.60647 6.83306 4.60647H14.9196C14.9071 4.51684 14.8941 4.42729 14.8804 4.33781L14.8264 3.98559C14.7345 3.38595 14.2381 2.94474 13.6554 2.94474L6.31847 2.94474C5.94206 2.94474 5.63693 2.62679 5.63693 2.23459C5.63693 1.72734 5.24228 1.31613 4.75547 1.31613H2.82578Z" fill={color}/>
            </svg>
        </SvgContainer>
    );
}

export default FolderSvg;