import React from "react";
import {defaultSvgColor} from "../SvgProps";
import {SvgComponent} from "../SvgComponent";
import SvgContainer from "../SvgContainer";

const LightningSvg: SvgComponent = ({ color = defaultSvgColor }) => {
    return (
        <SvgContainer>
            <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.32286 0.421063C7.32286 0.252264 7.2288 0.0997813 7.08389 0.0336594C6.93898 -0.0324625 6.77124 0.000558714 6.65771 0.117554L5.26074 1.55719C3.18078 3.70069 1.42327 6.17666 0.0507618 8.89695C0.0184468 8.95812 0 9.02881 0 9.10412C0 9.33666 0.175888 9.52517 0.392857 9.52517H3.77929V15.5789C3.77929 15.7457 3.87109 15.8967 4.01339 15.9641C4.1557 16.0315 4.32169 16.0025 4.4367 15.8902L5.06175 15.28C7.24973 13.1438 9.10656 10.6481 10.5626 7.88633L10.9492 7.15298C11.0179 7.02262 11.0169 6.86295 10.9465 6.73363C10.876 6.60431 10.7468 6.52479 10.6071 6.52479H7.32286V0.421063Z" fill={color} />
            </svg>
        </SvgContainer>
    );
}

export default LightningSvg;