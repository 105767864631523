import React, {FC} from "react";
import styles from "./Language.module.scss";
import {Language as LanguageModel} from "../../../api-client";
import {useCurrentLanguage} from "../../../hooks";
import {Image} from "../../common";
import ApprovedSvg from "../../svg/status/ApprovedSvg";

interface Props {
    language: LanguageModel;
    onLanguageSelected: (language: LanguageModel) => void;
}

const Language: FC<Props> = ({ language , onLanguageSelected}) => {
    const currentLanguage = useCurrentLanguage();
    return (
        <li className={styles.language} onClick={() => onLanguageSelected(language)}>
            <div className={styles.language__image}>
                <Image src={language.flag} alt={`${language.name} flag`} />
            </div>
            <div className={styles.language__content}>
                <div className={styles.language__name}>
                    { language.name }
                </div>
                <div className={styles.language__short}>
                    { language.short }
                </div>
            </div>
            { currentLanguage!.short === language.short && <div className={styles.language__selected}>
                <ApprovedSvg />
            </div> }
        </li>
    );
}

export default Language;