import {Money} from "../../api-client";

const sumMoney = (money: Money, ...another: Money[]): Money => {
    another.forEach((anotherMoney) => {
        if (money.currencyCode !== anotherMoney.currencyCode) {
            throw new Error("Cannot sum different currency codes");
        }
    });
    return another.reduce((prev, cur) => {
        return new Money(prev.amount + cur.amount, prev.currencyCode);
    }, money);
}

export default sumMoney;