import React, {useEffect, useState} from "react";
import Overlay from "../../../components/layout/common/Overlay";
import ModalContainer from "../../../components/layout/common/ModalContainer";
import {useParams} from "react-router-dom";
import GuarantorContextProvider, {
    GuarantorContextType,
    GuarantorStep
} from "../../../components/pages/guarantor/GuarantorContextProvider";
import {Guarantor} from "../../../api-client";
import MainGrid from "../../../components/layout/common/MainGrid";
import LoaderContainer from "../../../components/loading/LoaderContainer";
import {Loader, MultiStepForm} from "../../../components/common";
import GuarantorStatus from "../../../components/pages/guarantor/GuarantorStatus";
import SubPageMain from "../../../components/layout/common/SubPageMain";
import GuarantorAside from "../../../components/pages/guarantor/GuarantorAside";
import GuarantorForm from "../../../components/pages/guarantor/GuarantorForm";
import {useNavigateByName} from "../../../hooks";
import {RouteDictionary} from "../../../utils";

const GuarantorIdentification = () => {
    const { uuid } = useParams();
    const navigate = useNavigateByName();
    const [step, setStep] = useState<GuarantorStep>(GuarantorStep.FillSurvey);
    const [guarantorModel, setGuarantorModel] = useState<Guarantor | undefined>(undefined);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            if (uuid === undefined) {
                await navigate(RouteDictionary.LOGIN);
                return;
            }
            setLoading(false);
        })();
    }, []);

    const value: GuarantorContextType = {
        step,
        setStep,
        guarantor: guarantorModel,
        setGuarantor: setGuarantorModel
    }

    return (
        <>
            { loading ? <Loader /> : <>
                <Overlay />
                <div className="layout-lender-identification">
                    <GuarantorContextProvider props={value}>
                        <MainGrid subpage>
                            <GuarantorAside />
                            <SubPageMain>
                                { loading ? <LoaderContainer>
                                    <Loader />
                                </LoaderContainer> : <MultiStepForm step={step}>
                                    <GuarantorForm uuid={uuid!} />
                                    <GuarantorStatus />
                                </MultiStepForm> }
                            </SubPageMain>
                        </MainGrid>
                    </GuarantorContextProvider>
                </div>
                <ModalContainer />
            </> }
        </>
    );
}

export default GuarantorIdentification;