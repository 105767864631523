import React, {FC} from "react";
import {MyRefundedProject as MyRefundedProjectModel} from "../../../../../api-client";
import styles from "./MyRefundedProject.module.scss";
import MyProject from "../../MyProject";
import {CommonText, Money, ProjectLink} from "../../../../common";
import {RouteDictionary} from "../../../../../utils";
import {formatDate} from "../../../../../formatters";
import {loansScheduleTypeTranslations} from "../../../../../views/borrower/statistics/MyLoans/MyLoans";
import {useTranslator} from "../../../../../hooks";

interface Props {
    project: MyRefundedProjectModel;
}

const MyRefundedProject: FC<Props> = ({ project }) => {
    const t = useTranslator();
    return (
        <MyProject className={styles.my_refunded_project}>
            <ProjectLink to={RouteDictionary.MY_PROJECT} params={{uuid: project.uuid}}>
                <MyProject.Column className={styles.my_refunded_project__id}>
                    <CommonText>
                        { project.paymentCode ?? t("my_loans.not_listed") }
                    </CommonText>
                </MyProject.Column>
                <MyProject.Column className={styles.my_refunded_project__target}>
                    <CommonText>
                        { project.targetSum !== undefined
                            ? <Money money={project.targetSum} />
                            : <div>{ t("my_loans.not_listed.feminine") }</div>
                        }
                    </CommonText>
                </MyProject.Column>
                <MyProject.Column className={styles.my_refunded_project__rate}>
                    <CommonText>
                        { project.interestRate ? `${project.interestRate}%` : t("my_loans.not_listed") }
                    </CommonText>
                </MyProject.Column>
                <MyProject.Column className={styles.my_refunded_project__date}>
                    <CommonText>
                        { formatDate(project.createdAt) }
                    </CommonText>
                </MyProject.Column>
                <MyProject.Column className={styles.my_refunded_project__schedule}>
                    <CommonText>
                        { project.scheduleType ? t(loansScheduleTypeTranslations[project.scheduleType]) : t("my_loans.not_listed") }
                    </CommonText>
                </MyProject.Column>
            </ProjectLink>
        </MyProject>
    );
}

export default MyRefundedProject;