import React, {useContext} from "react";
import './DetailedProjectSummary.scoped.scss';
import {Heading, SecondaryHeading} from "../../../../common/typography/headings";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import {
    DetailedProjectSubBlock,
    DetailedProjectSubBlocks,
    DetailedProjectSubPage
} from "../../index";
import DetailedProjectFounder from "../DetailedProjectFounder";
import {DetailedProjectContext} from "../../DetailedProjectContext";
import DetailedProjectPlaceholder from "../../common/DetailedProjectPlaceholder";
import DetailedProjectGuarantors from "../DetailedProjectGuarantors";
import DetailedProjectBorrowerInfo from "../DetailedProjectBorrowerInfo";
import DetailedProjectGeneral from "../DetailedProjectGeneral";
import {useTranslator} from "../../../../../hooks";

const DetailedProjectSummary = () => {
    const t = useTranslator();
    const { general, summary } = useContext(DetailedProjectContext).project;
    if (summary === undefined) {
        return <DetailedProjectPlaceholder />
    }
    const renderFounders = () => {
        if (summary.founders.length === 0) {
            return <DetailedProjectPlaceholder />
        }
        return summary.founders.map((founder, index) => {
            return <DetailedProjectFounder founder={founder} key={index} />
        });
    }
    //TODO: RETURN GUARANTORS LATER
    return (
        <DetailedProjectSubPage>
            <DetailedProjectSubBlocks>
                <DetailedProjectGeneral general={general} />
                {/*<DetailedProjectGuarantors guarantors={summary.guarantors} />*/}
                <DetailedProjectBorrowerInfo borrower={summary.borrower} />
                <DetailedProjectSubBlock>
                    <SecondaryHeading smaller>
                        <Heading headingType={HeadingType.H2}>
                            { t("detailed_project.borrower_info.founders") }
                        </Heading>
                    </SecondaryHeading>
                    <div className="detailed-project-summary__founders">
                        { renderFounders() }
                    </div>
                </DetailedProjectSubBlock>
            </DetailedProjectSubBlocks>
        </DetailedProjectSubPage>
    );
}

export default DetailedProjectSummary;