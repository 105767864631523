import {Translations} from "../../types";

export const auth: Translations = {
    "auth.login": "Մուտք գործել",
    "auth.login.enter": "Մուտքագրեք",
    "auth.login.forgot_password": "Մոռացել եք գաղտնաբառը?",
    "auth.create_account": "Ստեղծեք նոր հաշիվ",
    "auth.register": "Գրանցում",
    "auth.register.have_account": "Ես արդեն հաշիվ ունեմ",
    "auth.register_lender": "Գրանցեք նոր վարկատու",
    "auth.register.password": "Գաղտնաբառ (:length կամ ավելի տառեր)",
    "auth.register.confirm_password": "Հաստատել գաղտնաբառը",
    "auth.register_lender.information_exchange_agree": "Համաձայն եմ տեղեկատվության փոխանակմանը",
    "auth.register_lender.platform_interaction_rules": "Ես համաձայն եմ հարթակի փոխազդեցության կանոններին",
    "auth.register_borrower": "Գրանցեք նոր վարկառու",
    "auth.register_borrower.as": "Գրանցվել որպես",
    "auth.register_borrower.data": "Հաշվի տվյալները",
    "auth.register_borrower.agree_with": "Համաձայն եմ",
    "auth.register_borrower.platform_rules": "հարթակի կանոնները",
    "auth.register_borrower.register_as": "գրանցվել որպես ընկերության գլխավոր տնօրեն կամ անհատ ձեռնարկատեր",
    "auth.register_borrower.familiar_with": "Ծանոթացել է",
    "auth.register_borrower.processing_policy": "անձնական տվյալների մշակման քաղաքականություն",
}