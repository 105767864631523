import {useNavigateByName} from "./useNavigateByName";
import {createAuthTokenManager} from "../di";
import {useAppDispatch} from "./store";
import {clear} from "../store/profile";
import {Unauthorized, ValidationError} from "../api-client";
import {AuthTokenExpired, RouteDictionary} from "../utils";

export const useErrorCallback = () => {
    const navigate = useNavigateByName();
    const dispatch = useAppDispatch();
    const tokenManager = createAuthTokenManager();
    return async (error: Error) => {
        if (error instanceof Unauthorized || error instanceof AuthTokenExpired) {
            //TODO: make redirectTo store
            tokenManager.remove();
            await dispatch(clear());
            await navigate(RouteDictionary.LOGIN);
            return;
        } else if (error instanceof ValidationError) {
            console.log(error);
        }
    };
}