import React, {FC, PropsWithChildren} from "react";
import './Main.scoped.scss';
import {cn, resultIf} from "../../../../utils";

interface Props {
    min?: boolean;
}

const Main: FC<PropsWithChildren<Props>> = ({ min= false, children }) => {
    return (
        <main className={cn("main", resultIf(min, "main--min"))}>
            { children }
        </main>
    );
}

export default Main;