import React from "react";
import {Paginator, usePaginationContext} from "../index";

const PaginationPaginator = () => {
    const context = usePaginationContext();
    return (
        <>
            { context.perPage !== 0 && context.count !== context.totalCount && <Paginator /> }
        </>
    );
}

export default PaginationPaginator;