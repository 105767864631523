import React, {PropsWithChildren} from "react";
import {ClassInjector} from "../../../../../injectors";
import './PrimaryHeading.scoped.scss';
import {ClassNameDecorator} from "../../../../../decorators";

const PrimaryHeading: ClassNameDecorator<PropsWithChildren> = ({ children, className }) => {
    return (
        <ClassInjector classNames="primary-heading" oldClassName={className}>
            { children }
        </ClassInjector>
    );
}

export default PrimaryHeading;