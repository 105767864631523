import {Translations} from "../../types";

export const finances: Translations = {
    "finances": "Ֆինանսներ",
    "finances.schedule": "Մարման ժամանակացույց",
    "finances.repayment.date": "Ամսաթիվ",
    "finances.repayment.amount": "Վճարի չափը",
    "finances.repayment.debt": "Սկզբունքային",
    "finances.repayment.percents": "Հետաքրքրություն",
    "finances.no_data": "Վճարումներ չեն գտնվել",
    "finances.calendar": "Վճարումների օրացույց",
    "finances.calendar.no_payments": "Այս օրը վճարումներ չկան։",
    "finances.calendar.payment.id": "Վարկի ID",
    "finances.calendar.payment.sum": "Ներդրումների գումարը",
    "finances.calendar.payment.rate": "Գնահատել",
    "finances.calendar.payment.month": "Ժամկետ",
    "finances.calendar.payment.date": "Վճարման օր",
    "finances.calendar.payment.payment_sum": "Վճարի չափը",
}