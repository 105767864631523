import React from "react";
import {matchPath, useLocation} from "react-router-dom";
import Tabs from "../../../../layout/common/Tabs";
import {ProjectLink} from "../../../../common";
import {RouteDictionary} from "../../../../../utils";

const AnalyticsPlatformNavigation = () => {
    const { pathname } = useLocation();
    return (
        <div className="analytics-user-navigation">
            <Tabs>
                <ProjectLink
                    to={RouteDictionary.ANALYTICS_PLATFORM_VOLUME}
                    explicitActive={
                        matchPath('lender/analytics', pathname) !== null ||
                        matchPath('lender/analytics/platform', pathname) !== null
                    }
                >
                    Объем выданных займов
                </ProjectLink>
                <ProjectLink
                    to={RouteDictionary.ANALYTICS_PLATFORM_PROFIT}
                >
                    Доходность
                </ProjectLink>
                <ProjectLink
                    to={RouteDictionary.ANALYTICS_PLATFORM_DEFAULTS}
                >
                    Дефолты
                </ProjectLink>
                <ProjectLink
                    to={RouteDictionary.ANALYTICS_PLATFORM_PENALTIES}
                >
                    Взыскания
                </ProjectLink>
            </Tabs>
        </div>
    );
}

export default AnalyticsPlatformNavigation;