import React, {FC, PropsWithChildren} from "react";
import './SubPageMain.scoped.scss';
import BorrowerLanguageSelect from "../../../language/BorrowerLanguageSelect";

const SubPageMain: FC<PropsWithChildren> = ({ children }) => {
    return (
        <main className="sub-page-main">
            <BorrowerLanguageSelect />
            <div className="sub-page-main__container">
                { children }
            </div>
        </main>
    );
}

export default SubPageMain;