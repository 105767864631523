import React, {FC} from "react";
import {DefaultProjectInvestment} from "../../../../../api-client";
import styles from "./DefaultInvestment.module.scss";

interface Props {
    investment: DefaultProjectInvestment;
}

const DefaultInvestment: FC<Props> = ({ investment }) => {
    return (
        <></>
    );
}

export default DefaultInvestment;