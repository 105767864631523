import React from "react";
import "./AuthHeaderRegisterUser.scoped.scss";
import {ProjectLink} from "../../../../common";
import AuthHeader from "../../AuthHeader";
import {RouteDictionary} from "../../../../../utils";
import {ArrowLeft} from "../../../../svg";
import {useTranslator} from "../../../../../hooks";

const AuthHeaderRegisterUser = () => {
    const t = useTranslator();
    return (
        <AuthHeader>
            <ProjectLink to={RouteDictionary.REGISTER} className="register_user_back">
                <ArrowLeft />
                <span>{ t("common.back") }</span>
            </ProjectLink>
        </AuthHeader>
    );
}

export default AuthHeaderRegisterUser;