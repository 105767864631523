import React from "react";
import {Outlet, Route, Routes} from "react-router-dom";
import AnalyticsUser from "../../../../pages/analytics/user/AnalyticsUser";
import AnalyticsUserVolume from "../../../../pages/analytics/user/routes/AnalyticsUserVolume";
import AnalyticsUserProfit from "../../../../pages/analytics/user/routes/AnalyticsUserProfit";
import AnalyticsUserDefaults from "../../../../pages/analytics/user/routes/AnalyticsUserDefaults";
import AnalyticsUserPenalties from "../../../../pages/analytics/user/routes/AnalyticsUserPenalties";

const UserAnalyticsRoutes = () => {
    return (
        <>
            <Routes>
                <Route element={<AnalyticsUser />}>
                    <Route index element={<AnalyticsUserVolume />} />
                    <Route path="volume" element={<AnalyticsUserVolume />} />
                    <Route path="profit" element={<AnalyticsUserProfit />} />
                    <Route path="defaults" element={<AnalyticsUserDefaults />} />
                    <Route path="penalties" element={<AnalyticsUserPenalties />} />
                </Route>
            </Routes>
            <Outlet />
        </>
    );
}

export default UserAnalyticsRoutes;