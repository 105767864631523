import React, {FC, LiHTMLAttributes} from "react";
import {cn} from "../../../../utils";
import styles from "./CommonListEntry.module.scss";

const CommonListEntry: FC<LiHTMLAttributes<HTMLLIElement>> = ({ ...props }) => {
    return (
        <li {...props} className={cn(styles.common_list_entry, props.className)}>
            { props.children }
        </li>
    );
}

export default CommonListEntry;