import {Translations} from "../../types";

export const guarantor: Translations = {
    "guarantor": "Заполните анкету поручителя",
    "guarantor.personal_data": "Персональные данные",
    "guarantor.personal_data.last_name": "Фамилия",
    "guarantor.personal_data.first_name": "Имя",
    "guarantor.personal_data.middle_name": "Отчество",
    "guarantor.personal_data.birthday": "Дата рождения",
    "guarantor.confirmation": "Подтверждение введенных данных",
    "guarantor.confirmation.first": "Предоставляю",
    "guarantor.confirmation.second": "согласие на обработку персональных данных, запрос в бюро кредитных историй и проверку данных мобильного оператора",
    "guarantor.send": "Отправить анкету",
    "guarantor.survey_not_found": "Не заполнена анкета",
    "guarantor.status": "Статус вашей заявки",
    "guarantor.awaiting_administrator": "Ваша заявка проверяется администратором...",
}