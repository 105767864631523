import React, {PropsWithChildren, ReactElement, useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {PaginationContent, PaginationContextType, PaginationHeader, PaginationPaginator} from "../index";
import {useEffectOnUpdate} from "../../../hooks";
import {concatenateQueries, getQueryObject, QueryObject} from "../../../utils";
import buildQuery from "../../../utils/router/buildQuery";
import PaginationContextProvider from "../PaginationContext";

export type PaginationConfig = {
    firstPage: number;
    defaultPerPage: number;
    pageParam: string;
    perPageParam: string;
    filterParam: string;
}

export type PaginationProps<T> = {
    onLoad: (page: number, perPage: number, filter: T | undefined, signal: AbortSignal) => void;
    count: number;
    totalCount: number;
    config?: Partial<PaginationConfig>,
    filterObject?: T | undefined;
    initialChangeFilter?: (currentFilter: T) => void;
    compareFilters?: (prev: T | undefined, curr: T | undefined) => boolean;
}

const defaultConfig: PaginationConfig = {
    firstPage: 1,
    defaultPerPage: 5,
    pageParam: "page",
    perPageParam: "perPage",
    filterParam: "filter"
}

type Filter<T> = {
    [filterKey: string]: T;
}

function Pagination<T>(
    {onLoad, count, totalCount, config, filterObject, initialChangeFilter, compareFilters, children}: PropsWithChildren<PaginationProps<T>>
): ReactElement {
    const [searchParams] = useSearchParams({});
    const location = useLocation();
    const navigate = useNavigate();

    const compiledConfig = useMemo(() => {
        return { ...defaultConfig, ...config }
    }, [config]);

    const [page, setPage] = useState(compiledConfig.firstPage);
    const [perPage, setPerPage] = useState(compiledConfig.defaultPerPage);
    const [filter, setFilter] = useState<T | undefined>(filterObject);

    const [updated, setUpdated] = useState(false);

    const value: PaginationContextType = useMemo(() => {
        return {
            count: count,
            totalCount: totalCount,
            page: page,
            pageParam: compiledConfig.pageParam,
            perPage: perPage,
            perPageParam: compiledConfig.perPageParam
        }
    }, [count, totalCount, page, perPage, compiledConfig]);

    const parseUrlFilter = () => {
        return (getQueryObject(location.search) as Filter<T>)[compiledConfig.filterParam];
    }

    const update = (initial: boolean = false) => {
        const pageParam = searchParams.get(compiledConfig.pageParam);
        setPage(pageParam !== null ? parseInt(pageParam) : compiledConfig.firstPage);
        const perPageParam = searchParams.get(compiledConfig.perPageParam);
        setPerPage(perPageParam !== null ? parseInt(perPageParam) : compiledConfig.defaultPerPage);

        const newFilter = parseUrlFilter();
        setFilter(newFilter);
        if (compareFilters === undefined) {
            setUpdated(true);
        } else if (!compareFilters(filter, newFilter) || initial) {
            setUpdated(true);
        }
    }

    useEffect(() => {
        update();
    }, [searchParams]);

    //Initial update
    useEffect(() => {
        update(true);
        if (initialChangeFilter !== undefined && filterObject !== undefined) {
            const newFilter = parseUrlFilter();
            initialChangeFilter(newFilter);
        }
    }, []);

    useEffectOnUpdate(() => {
        handleFilterChange();
    }, [filterObject]);

    const handleFilterChange = (): void => {
        const query = buildQuery({ [compiledConfig.filterParam]: filterObject });
        const currentQueryObject: QueryObject = getQueryObject(location.search);
        delete currentQueryObject[compiledConfig.filterParam];
        navigate({
            pathname: location.pathname,
            search: concatenateQueries(query, buildQuery(currentQueryObject))
        });
    }

    useEffectOnUpdate(() => {
        const abortController = new AbortController();
        if (updated) {
            onLoad(page, perPage, filter, abortController.signal);
        }
        setUpdated(false);
        return () => {
            if (!updated) {
                abortController.abort();
            }
        }
    }, [updated]);

    return (
        <PaginationContextProvider {...value}>
            { children }
        </PaginationContextProvider>
    );
}

Pagination.Paginator = PaginationPaginator;
Pagination.Content = PaginationContent;
Pagination.Header = PaginationHeader;

export default Pagination;