import React, {FC, PropsWithChildren} from "react";
import styles from "./MarketHeading.module.scss";

const MarketHeading: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className={styles.market_heading}>
            { children }
        </div>
    );
}

export default MarketHeading;