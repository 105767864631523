import React, {AllHTMLAttributes, FC, PropsWithChildren} from "react";
import styles from "./CommonBlock.module.scss";
import {cn} from "../../../../utils";
import CommonBlockHeader from "./extensions/CommonBlockHeader";
import CommonBlockSection from "./extensions/CommonBlockSection";
import CommonBlockFooter from "./extensions/CommonBlockFooter";

interface Props extends AllHTMLAttributes<HTMLDivElement> {}

type CommonBlockExtensions = {
    Header: typeof CommonBlockHeader;
    Section: typeof CommonBlockSection;
    Footer: typeof CommonBlockFooter;
}

const CommonBlock: FC<PropsWithChildren<Props>> & CommonBlockExtensions = ({ children, ...props }) => {
    return (
        <div {...props} className={cn(styles.common_block, props.className)}>
            { children }
        </div>
    );
}

CommonBlock.Header = CommonBlockHeader;
CommonBlock.Section = CommonBlockSection;
CommonBlock.Footer = CommonBlockFooter;

export default CommonBlock;