import {Translations} from "../../types";

export const errors: Translations = {
    "errors.field_required": ":field is required",
    "errors.fields_required": ":field are required",
    "errors.passwords_do_not_match": "Passwords do not match",
    "errors.confirm_password": "Confirm password",
    "errors.accept_something": "Accept the :document",
    "errors.field_less_than": ":field must be more than :amount",
    "errors.fields_less_than": ":field must be more than :amount",
    "errors.field_more_than": ":field must be less than :amount",
    "errors.fields_more_than": ":field must be less than :amount",
    "errors.incorrect_input": ":field entered incorrectly",
    "errors.incorrect_inputs": ":field entered incorrectly",
    "errors.confirm_phone": "Your phone is not confirmed",
    "errors.not_number": ":field must be a number",
    "errors.wrong_department_code": "Wrong department code format",
    "errors.wrong_phone": "Wrong phone number format",

    "errors.fields.name": "Name",
    "errors.fields.last_name": "Last name",
    "errors.fields.email": "Email",
    "errors.fields.phone": "Phone",
    "errors.fields.password": "Password",
    "errors.fields.register_type": "Register type",
    "errors.fields.platform_rules": "Platform rules",
    "errors.fields.privacy_policy": "Privacy policy",
    "errors.fields.sum": "Sum",
    "errors.fields.rate": "Rate",
    "errors.fields.term": "Term",
    "errors.fields.passport.series": "Series",
    "errors.fields.passport.number": "Number",
    "errors.fields.passport.issued_by": "Issued by field",
    "errors.fields.passport.department_code": "Department code",
    "errors.fields.passport.issued_at": "Issued date",
    "errors.fields.passport.registration_address": "Registration address",
    "errors.fields.company.reg_number": "Registration number",
    "errors.fields.company.kpp": "KPP",
    "errors.fields.company.phone": "Phone",
    "errors.fields.company.description": "Description",
    "errors.fields.company.business_form": "Business form",
    "errors.fields.company.activity": "Activity",
    "errors.fields.company.address": "Address",
    "errors.fields.company.site_urls": "Site urls",
    "errors.fields.company.reputation_rating": "Reputation rating",
    "errors.fields.company.money_source": "Money source",
};
