import {Translations} from "../../types";

export const my_investments: Translations = {
    "my_investments": "My investments",
    "my_investments.filters": "Filters",
    "my_investments.navigation.loan": "Loan Payment",
    "my_investments.navigation.fundraising": "Fundraising",
    "my_investments.navigation.returned": "Returned",
    "my_investments.navigation.default": "Default",
    "my_investments.navigation.selling": "Selling",
    "my_investments.loan.company": "Company",
    "my_investments.loan.rate": "Rate",
    "my_investments.loan.date": "Investment date",
    "my_investments.loan.return": "Return",
    "my_investments.loan.sum": "Sum",
    "my_investments.loan.redeemed": "Redeemed",
    "my_investments.loan.sell": "Sell",
    "my_investments.fundraising.company": "Company",
    "my_investments.fundraising.target": "Target",
    "my_investments.fundraising.collected": "Collected",
    "my_investments.fundraising.investment_sum": "Investment sum",
    "my_investments.fundraising.rate": "Rate",
    "my_investments.fundraising.investment_date": "Investment date",
    "my_investments.fundraising.schedule": "Schedule type",
    "my_investments.schedule.monthly": "Monthly",
    "my_investments.schedule.monthly_twice": "Twice a month",
    "my_investments.schedule.monthly_thrice": "Thrice a month",
    "my_investments.schedule.four_times": "Four times a month",
    "my_investments.schedule.quarterly": "Quarterly",
    "my_investments.schedule.at_the_end": "At the end"
}