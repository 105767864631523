import React, {FC, PropsWithChildren} from "react";
import styles from "./AnalyticsRows.module.scss";

const AnalyticsRows: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className={styles.analytics_rows}>
            { children }
        </div>
    );
}

export default AnalyticsRows;