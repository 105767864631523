import React, {lazy, Suspense} from "react";
import {Outlet, Route, Routes} from "react-router-dom";
import MyInvestmentsLoanPayment from "../../../pages/my-investments/loan-payment/MyInvestmentsLoanPayment";
import MyInvestmentsFundraising from "../../../pages/my-investments/fundraising/MyInvestmentsFundraising";
import MyInvestmentsRefunded from "../../../pages/my-investments/refunded/MyInvestmentsRefunded";
import MyInvestmentsDefault from "../../../pages/my-investments/default/MyInvestmentsDefault";
import MyInvestmentsSelling from "../../../pages/my-investments/selling/MyInvestmentsSelling";
import Loadable from "../../../loading/Loadable";

const LazyMyInvestments = Loadable(lazy(() => import("../../../../views/lender/statistics/MyInvestments")));

const MyInvestmentRoutes = () => {
    return (
        <>
            <Routes>
                <Route element={<LazyMyInvestments />}>
                    <Route index element={<MyInvestmentsFundraising />} />
                    <Route path="loan-payment" element={<MyInvestmentsLoanPayment />} />
                    <Route path="fundraising" element={<MyInvestmentsFundraising />} />
                    <Route path="refunded" element={<MyInvestmentsRefunded />} />
                    <Route path="default" element={<MyInvestmentsDefault />} />
                    <Route path="selling" element={<MyInvestmentsSelling />} />
                </Route>
            </Routes>
            <Outlet />
        </>
    );
}

export default MyInvestmentRoutes;