import React, {createContext, PropsWithChildren} from "react";
import {ProjectsFilter} from "../../../../utils";

export interface MyInvestmentsContextType {
    filter: ProjectsFilter;
}

type Props = {
    props: MyInvestmentsContextType;
}

export const MyInvestmentsContext = createContext<MyInvestmentsContextType>({
    filter: {}
});

function MyInvestmentsContextProvider(props: PropsWithChildren<Props>) {
    const { children, ...value } = props;
    return <MyInvestmentsContext.Provider value={value.props}>
        { children }
    </MyInvestmentsContext.Provider>
}

export default MyInvestmentsContextProvider;