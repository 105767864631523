import {Translations} from "../../types";

export const analytics: Translations = {
    "analytics.navigation.platform": "Платформа",
    "analytics.navigation.mkk": "МКК",
    "analytics.user": "Выдачи по поколениям",
    "analytics.user.error": "Произошла ошибка, аналитика не найдена",
    "analytics.user.navigation.volume": "Объем выданных займов",
    "analytics.user.navigation.yield": "Доходность",
    "analytics.user.navigation.defaults": "Дефолты",
    "analytics.user.navigation.penalties": "Взыскания",
    "analytics.user.period.twelve_months": "Последние 12 месяцев",
    "analytics.user.period.six_months": "Последние 6 месяцев",
    "analytics.user.period.three_months": "Последние 3 месяца",
    "analytics.user.period.year_only": "Только :year год",
    "analytics.user.period": "Период",
    "analytics.user.type": "Тип займа",
    "analytics.user.type.buyback": "Buyback",
    "analytics.user.volume.month": "Месяц",
    "analytics.user.volume.issued": "Выдано",
    "analytics.user.volume.borrower_number": "Кол-во заемщиков",
    "analytics.user.volume.average_rate": "Средняя ставка",
    "analytics.user.volume.average_term": "Средний срок",
    "analytics.user.volume.average_sum": "Средняя сумма",
    "analytics.user.profitabilities.month": "Месяц",
    "analytics.user.profitabilities.irr": "IRR",
    "analytics.user.profitabilities.result": "Накопленный результат",
    "analytics.user.penalties.navigation.number": "Кол-во",
    "analytics.user.penalties.navigation.sum": "Сумма",
    "analytics.user.penalties.navigation.percent": "% от суммы",
    "analytics.user.default.header.month": "Месяц",
    "analytics.user.default.header.all": "Всего",
    "analytics.user.default.header.returned": "Возвращено",
    "analytics.user.default.header.defaulted": "В дефолте",
    "analytics.platform_statistics": "Статистика",
    "analytics.platform_statistics.issued_first": "Выдано займов",
    "analytics.platform_statistics.issued_second": "на площадке",
    "analytics.platform_statistics.number_of_loans": "Кол-во займов на площадке",
    "analytics.platform_statistics.number_of_borrowers": "Кол-во заемщиков",
    "analytics.platform_statistics.number_of_lenders": "Кол-во инвесторов",
    "analytics.platform_statistics.paid_first": "Выплачено",
    "analytics.platform_statistics.paid_second": "инвесторам",
    "analytics.platform_statistics.yield": "Доходность за все время",

    "analytics.user_statistics": "Статистика",
    "analytics.user_statistics.issued": "Проектов инвестировано",
    "analytics.user_statistics.invested": "Инвестированная сумма",
    "analytics.user_statistics.number_of_borrowers": "Кол-во заемщиков",
    "analytics.user_statistics.paid_first": "Получено",
    "analytics.user_statistics.yield": "Доходность за все время",
}