import React, {FC} from "react";
import styles from "./RefundedInvestment.module.scss";
import {RefundedProjectInvestment} from "../../../../../api-client";
import {CommonSubText, CommonText, Money} from "../../../../common";
import {DynamicTranslation} from "../../../../language";
import {formatDate} from "../../../../../formatters";
import CommonInvestment from "../../CommonInvestment";
import {useTranslator} from "../../../../../hooks";

interface Props {
    investment: RefundedProjectInvestment;
}

const RefundedInvestment: FC<Props> = ({ investment }) => {
    const t = useTranslator();
    return (
        <CommonInvestment
            className={styles.refunded_investment}
            linkTo={{ projectUuid: investment.project.uuid, investmentUuid: investment.investments[0].uuid }}
        >
            <div className={styles.refunded_investment__name}>
                <CommonSubText small>
                    ID { investment.project.paymentCode }
                </CommonSubText>
                <CommonText>
                    <DynamicTranslation translations={ investment.project.name } />
                </CommonText>
            </div>
            <div className={styles.refunded_investment__rate}>
                <CommonText>
                    { investment.project.interestRate }%
                </CommonText>
            </div>
            <div className={styles.refunded_investment__date}>
                <CommonText>
                    { formatDate(new Date(investment.investments[0].createdAt)) }
                </CommonText>
            </div>
            <div className={styles.refunded_investment__sum}>
                <CommonText>
                    <Money money={investment.investedSum} />
                </CommonText>
            </div>
        </CommonInvestment>
    );
}

export default RefundedInvestment;