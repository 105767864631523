import React, {FC, ReactNode, useRef} from "react";
import "./InputSmsCode.scoped.scss";
import {SMS_CODE_LENGTH} from "../../../../utils";
import {useEffectOnUpdate} from "../../../../hooks";

interface Props {
    code: string;
    onInput: (code: string) => void;
}

const InputSmsCode: FC<Props> = ({ code, onInput }) => {
    const input = useRef<HTMLInputElement>(null);
    const handleInput = (char: string, index: number) => {
        onInput((code.substring(0, index) + char + code.substring(index + 1)).substring(0, SMS_CODE_LENGTH));
    }
    useEffectOnUpdate(() => {
        if (code.length === SMS_CODE_LENGTH) {
            input.current!.blur();
        }
    }, [code]);
    const renderCode = (): Array<ReactNode> => {
        const inputCells: Array<ReactNode> = [];
        for (let i = 0; i < SMS_CODE_LENGTH; ++i) {
            const currentChar = code.charAt(i);
            const nextChar = code.charAt(i + 1);
            const currentInner = (currentChar !== "" && nextChar === "") || (currentChar === "" && i === 0)
                ? <input
                    ref={input}
                    autoFocus
                    type="text"
                    className="input-sms-code__input"
                    value={code.charAt(i)}
                    onChange={(event) => handleInput(event.target.value, i)}
                />
                : <div className="input-sms-code__input">{code.charAt(i)}</div>;
            inputCells.push(<div className="input-sms-code__cell" key={i}>
                { currentInner }
            </div>);
        }
        return inputCells;
    }
    return (
        <div className="input-sms-code">
            <div className="input-sms-code__container">
                { renderCode().map((element) => {
                    return element;
                }) }
            </div>
        </div>
    );
}

export default InputSmsCode;