import React from "react";
import {LayoutAuth} from "../../layouts";
import {Outlet} from "react-router-dom";
import AuthFooterLogin from "../../components/layout/auth/footer/AuthFooterLogin";
import AuthHeader from "../../components/layout/auth/AuthHeader";

const LoginAuth = () => {
    return (
        <LayoutAuth header={<AuthHeader />} footer={<AuthFooterLogin />}>
            <Outlet />
        </LayoutAuth>
    );
}

export default LoginAuth;