import React from "react";
import {SvgComponent} from "../SvgComponent";
import {defaultStatSvgColor} from "./defaultStatSvgColor";

const ArrowUpSvg: SvgComponent = ({ color = defaultStatSvgColor}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 12.75L5.5 7.75L8.3827 10.473L15 3.25M15 3.25L9 3.75M15 3.25V9.25"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default ArrowUpSvg;