import React, {Suspense} from "react";
import SuspenseLoader from "../SuspenseLoader";

const Loadable = (Component: any) => (props: object) => {
    return (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );
}

export default Loadable;