import {Translations} from "../../types";

export const my_investments: Translations = {
    "my_investments": "Мои инвестиции",
    "my_investments.filters": "Фильтры",
    "my_investments.navigation.loan": "Текущие",
    "my_investments.navigation.fundraising": "Сбор средств",
    "my_investments.navigation.returned": "Возвращенные",
    "my_investments.navigation.default": "В дефолте",
    "my_investments.navigation.selling": "На продаже",
    "my_investments.loan.company": "Компания",
    "my_investments.loan.rate": "Ставка",
    "my_investments.loan.date": "Дата инвестиции",
    "my_investments.loan.return": "Возврат",
    "my_investments.loan.sum": "Сумма",
    "my_investments.loan.redeemed": "Погашено",
    "my_investments.loan.sell": "Продать заём",
    "my_investments.fundraising.company": "Компания",
    "my_investments.fundraising.target": "Цель",
    "my_investments.fundraising.collected": "Собрано",
    "my_investments.fundraising.investment_sum": "Сумма инвестиции",
    "my_investments.fundraising.rate": "Ставка",
    "my_investments.fundraising.investment_date": "Дата инвестиции",
    "my_investments.fundraising.schedule": "График выплат",
    "my_investments.schedule.monthly": "Ежемесячно",
    "my_investments.schedule.monthly_twice": "Дважды в месяц",
    "my_investments.schedule.monthly_thrice": "Трижды в месяц",
    "my_investments.schedule.four_times": "Четырежды в месяц",
    "my_investments.schedule.quarterly": "Ежеквартально",
    "my_investments.schedule.at_the_end": "В конце периода"
}