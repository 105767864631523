import React, {createContext, PropsWithChildren} from "react";
import {Guarantor} from "../../../api-client";

export enum GuarantorStep {
    FillSurvey = 0,
    CheckStatus = 1
}

export interface GuarantorContextType {
    step: GuarantorStep;
    setStep: (step: GuarantorStep) => void;
    guarantor: Guarantor | undefined;
    setGuarantor: (guarantor: Guarantor) => void;
}

type Props = {
    props: GuarantorContextType;
}

export const GuarantorContext = createContext<GuarantorContextType>({
    step: GuarantorStep.FillSurvey,
    setStep: () => {},
    guarantor: undefined,
    setGuarantor: () => {}
});

function GuarantorContextProvider(props: PropsWithChildren<Props>) {
    const { children, ...value } = props;
    return <GuarantorContext.Provider value={value.props}>
        { children }
    </GuarantorContext.Provider>
}

export default GuarantorContextProvider;