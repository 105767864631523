import React, {useMemo, useState} from "react";
import {useNavigateByName} from "../../../../../hooks";
import BorrowerRegistrationContextProvider, {
    BorrowerRegistrationContextType,
    BorrowerRegistrationInput,
    BorrowerRegistrationStep
} from "../BorrowerRegistrationForm/context/BorrowerRegistrationContext";
import {DaDataCompany} from "../../../../../api-client/models/company";
import {Phone} from "../../../../../api-client";
import {createRegistrationManager} from "../../../../../di";
import {RouteDictionary} from "../../../../../utils";
import {MultiStepForm} from "../../../../common";
import BorrowerRegistrationCompany from "../BorrowerRegistrationCompany";
import BorrowerRegistrationForm from "../BorrowerRegistrationForm";

const firstStep = BorrowerRegistrationStep.EnterCompany;

const BorrowerRegistration = () => {
    const navigate = useNavigateByName();
    const [step, setStep] = useState<BorrowerRegistrationStep>(firstStep);
    const [company, setCompany] = useState<DaDataCompany | undefined>(undefined);
    const [phone, setPhone] = useState<Phone>(new Phone("374", ""));
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [rulesAgree, setRulesAgree] = useState(false);
    const [policyAgree, setPolicyAgree] = useState(false);

    const borrowerInput = useMemo((): BorrowerRegistrationInput => {
        return {
            company,
            phone,
            email,
            password,
            confirmPassword,
            rulesAgree,
            policyAgree
        }
    }, [company, phone, email, password, confirmPassword, rulesAgree, policyAgree]);

    const setBorrowerInput = (input: BorrowerRegistrationInput) => {
        setCompany(input.company);
        setPhone(input.phone);
        setEmail(input.email);
        setPassword(input.password);
        setConfirmPassword(input.confirmPassword);
        setRulesAgree(input.rulesAgree);
        setPolicyAgree(input.policyAgree);
    }

    const value: BorrowerRegistrationContextType = {
        step,
        setStep,
        input: borrowerInput,
        setInput: setBorrowerInput
    }

    const handleSubmit = async () => {
        const manager = await createRegistrationManager();
        await manager.registerBorrower({
            name: company!.name,
            email,
            phone,
            password
        });
        navigate(RouteDictionary.LOGIN);
    }

    return (
        <BorrowerRegistrationContextProvider props={value}>
            <MultiStepForm step={step}>
                <BorrowerRegistrationCompany />
                <BorrowerRegistrationForm onSubmit={handleSubmit} />
            </MultiStepForm>
        </BorrowerRegistrationContextProvider>
    );
}

export default BorrowerRegistration;