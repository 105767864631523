import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {ProjectRequestManager} from "../ProjectRequestManager";

export class ProjectRequestManagerFactory {
    private apiClientFactory: ApiClientFactoryInterface;

    constructor(baseUrl: string, headers: Headers) {
        this.apiClientFactory = new ApiClientFactory(
            `${baseUrl}/api/v1/project-request`,
            headers
        )
    }

    createProjectRequestManager(authToken: string, signal?: AbortSignal): ProjectRequestManager {
        return new ProjectRequestManager(this.apiClientFactory.createAuthorizedClient(authToken), signal);
    }
}