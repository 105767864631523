import React, {FC, useContext, useEffect, useState} from "react";
import {ModalProps} from "../Modal/Modal";
import {Modal} from "../index";
import {ConfirmSmsCodeForm, Loader} from "../../common/utils";
import {createProjectRequestManager} from "../../../di";
import {FundingRequestConfirmation} from "../../../api-client";
import {useErrorCallback, useTranslator} from "../../../hooks";
import {FundingRequestContext} from "../../pages/funding-request/FundingRequestContext";

interface Props extends ModalProps {
    onSuccess: () => void;
}

const FundingRequestConfirmModal: FC<Props> = ({ active, onClose, onSuccess }) => {
    const { request } = useContext(FundingRequestContext);
    const handleError = useErrorCallback();
    const [confirmation, setConfirmation] = useState<FundingRequestConfirmation | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (active) {
            (async () => {
                try {
                    const manager = await createProjectRequestManager();
                    const result = await manager.request(request!.uuid);
                    setConfirmation(result);
                } catch (error: any) {
                    await handleError(error);
                } finally {
                    setLoading(false);
                }
            })()
        }
    }, [active]);

    const handleSend = async (code: string) => {
        const manager = await createProjectRequestManager();
        await manager.confirm(request!.uuid, code);
    }

    const handleResend = async () => {
        console.log("HANDLE RESEND");
    }

    return (
        <Modal active={active} onClose={onClose}>
            { loading ? <Loader /> : <ConfirmSmsCodeForm
                sendCallback={handleSend}
                resendCallback={handleResend}
                successCallback={onSuccess}
            /> }
        </Modal>
    );
}

export default FundingRequestConfirmModal;