import {Language} from "../api-client";
import {createTranslationProvider} from "../di";

export const t = (language: Language | string, message: string, params: { [code: string]: string } = {}) => {
    const translationProvider = createTranslationProvider();
    const messages = translationProvider.get(typeof language === "string" ? language : language.short);
    const text = messages[message] !== undefined ? messages[message] : message;
    return text.replace(/:(\w+)/g, (search, name) =>
        params[name] !== undefined ? params[name] : search
    );
}