import {useMemo, useState} from "react";
import {CompanyInfo, CompanyMoneySource, CompanyReputationRating, Phone} from "../../api-client";

export const useCompanyInfo = (companyName: string, initial?: CompanyInfo): [CompanyInfo, (info: CompanyInfo) => void] => {
    const [registrationNumber, setRegistrationNumber] = useState(initial?.registrationNumber ?? "");
    const [kpp, setKpp] = useState(initial?.kpp ?? "");
    const [companyPhone, setCompanyPhone] = useState<Phone>(initial?.phone ?? new Phone("354", ""));
    const [description, setDescription] = useState(initial?.description ?? "");
    const [businessForm, setBusinessForm] = useState(initial?.businessForm ?? "");
    const [activity, setActivity] = useState(initial?.activity ?? "");
    const [address, setAddress] = useState(initial?.address ?? "");
    const [siteUrls, setSiteUrls] = useState<Array<string>>(initial?.siteUrls ?? [""]);
    const [reputationRating, setReputationRating] =
        useState<CompanyReputationRating>(initial?.reputationRating ?? CompanyReputationRating.Positive);
    const [companyMoneySource, setCompanyMoneySource] =
        useState<CompanyMoneySource>(initial?.moneySource ?? CompanyMoneySource.Economic);

    const companyInfo: CompanyInfo = useMemo(() => {
        return {
            name: companyName,
            registrationNumber,
            kpp,
            phone: companyPhone,
            description,
            businessForm,
            activity,
            address,
            siteUrls,
            reputationRating,
            moneySource: companyMoneySource
        }
    }, [
        companyName,
        registrationNumber,
        kpp,
        companyPhone,
        description,
        businessForm,
        activity,
        address,
        siteUrls,
        reputationRating,
        companyMoneySource
    ]);

    const setCompanyInfo = (companyInfo: CompanyInfo) => {
        setRegistrationNumber(companyInfo.registrationNumber);
        setKpp(companyInfo.kpp);
        setCompanyPhone(companyInfo.phone);
        setDescription(companyInfo.description);
        setBusinessForm(companyInfo.businessForm);
        setActivity(companyInfo.activity);
        setAddress(companyInfo.address);
        setSiteUrls(companyInfo.siteUrls);
        setReputationRating(companyInfo.reputationRating);
        setCompanyMoneySource(companyInfo.moneySource);
    }

    return [companyInfo, setCompanyInfo];
}