import React from "react";
import {SvgComponent} from "./SvgComponent";
import {defaultSvgColorDark} from "./SvgProps";
import SvgContainer from "./SvgContainer";

const CloseSvg: SvgComponent = ({ color= defaultSvgColorDark }) => {
    return (
        <SvgContainer>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.53553 1.46424L1.46447 8.53531M1.46447 1.46424L8.53553 8.53531" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
            </svg>
        </SvgContainer>
    );
}

export default CloseSvg;