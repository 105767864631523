import {Currency} from "../utils";

export const MAIN_CURRENCY = (): Currency => {
    return Currency.Rub;
};

export const EURO_SYMBOL = "€";
export const RUB_SYMBOL = "₽";
export const DRAM_SYMBOL = "֏";

const SYMBOL_MAP: Record<Currency, string> = {
    [Currency.Eur]: EURO_SYMBOL,
    [Currency.Rub]: RUB_SYMBOL,
    [Currency.Dra]: DRAM_SYMBOL
}

export const CURRENT_CURRENCY_SYMBOL = () => {
    return SYMBOL_MAP[MAIN_CURRENCY()];
}

export const commissions = {
    withdraw: 3,
    sell_investment: 4
};