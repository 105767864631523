import {useMemo, useState} from "react";
import {Passport, UploadedFile} from "../../api-client";
import moment from "moment";

export const usePassport = (initial?: Passport): [Passport, (passport: Passport) => void] => {
    const [passportSeries, setPassportSeries] = useState(initial?.series || "");
    const [passportNumber, setPassportNumber] = useState(initial?.number || "");
    const [passportIssuedBy, setPassportIssuedBy] = useState(initial?.issuedBy || "");
    const [passportCode, setPassportCode] = useState(initial?.departmentCode || "");
    const [passportIssuedDate, setPassportIssuedDate] = useState<Date>(initial !== undefined
        ? new Date(initial.issuedDate)
        : new Date()
    );
    const [registrationAddress, setRegistrationAddress] = useState(initial?.registrationAddress || "");
    const [firstPhotos, setFirstPhotos] = useState<Array<string>>(initial?.firstPhotos || []);
    const [secondPhotos, setSecondPhotos] = useState<Array<string>>(initial?.secondPhotos || []);
    const [selfiePhotos, setSelfiePhotos] = useState<Array<string>>(initial?.selfiePhotos || []);

    const passport = useMemo((): Passport => {
        return {
            series: passportSeries,
            number: passportNumber,
            issuedBy: passportIssuedBy,
            issuedDate: moment(passportIssuedDate).format("YYYY-MM-DD"),
            departmentCode: passportCode,
            registrationAddress: registrationAddress,
            firstPhotos,
            secondPhotos,
            selfiePhotos
        }
    }, [
        passportSeries,
        passportNumber,
        passportIssuedBy,
        passportCode,
        passportIssuedDate,
        registrationAddress,
        firstPhotos,
        secondPhotos,
        selfiePhotos
    ]);

    const setPassport = (passport: Passport) => {
        setPassportSeries(passport.series);
        setPassportNumber(passport.number);
        setPassportIssuedBy(passport.issuedBy);
        setPassportIssuedDate(new Date(passport.issuedDate));
        setPassportCode(passport.departmentCode);
        setRegistrationAddress(passport.registrationAddress);
        setFirstPhotos(passport.firstPhotos);
        setSecondPhotos(passport.secondPhotos);
        setSelfiePhotos(passport.selfiePhotos);
    }

    return [passport, setPassport];
}