import {Translations} from "../../types";

export const detailed_project: Translations = {
    "detailed_project.not_found": "Проект не найден",
    "detailed_project.navigation.summary": "Сводка",
    "detailed_project.navigation.analytics": "Аналитика",
    "detailed_project.navigation.reputation": "Репутация",
    "detailed_project.navigation.documents": "Документы",
    "detailed_project.navigation.offers": "Купить заём",
    "detailed_project.invest.investment_created_at": "Дата инвестиции",
    "detailed_project.invest.investment_sum": "Сумма инвестиции",
    "detailed_project.invest.investment_income": "Доход",
    "detailed_project.invest.investment_cancel": "Отменить",
    "detailed_project.invest.investment_sell": "Продать",
    "detailed_project.invest.fundraising_warning": "Выплаты по заёму начнут воспроизводиться после того, как заём будет полностью собран",
    "detailed_project.invest.rate": "Ставка",
    "detailed_project.invest.collected": "Собрано",
    "detailed_project.invest.target": "Цель",
    "detailed_project.invest.paid": "Выплачено",
    "detailed_project.invest.paid_sum": "Сумма выплаты",
    "detailed_project.invest.loan_sum": "Сумма заёма",
    "detailed_project.invest.number_of_offers": "Количество предложений",
    "detailed_project.invest.term": "Срок",
    "detailed_project.invest.buyback": "Buyback",
    "detailed_project.invest.buyback_explanation": "Сама того не зная, в процессе становления идеи и реализации проекта " +
        "принимала активное участие Гильдия Вольных Проектировщиков, за что " +
        "ей огромное спасибо. Комрады, вы реально лучшие.",
    "detailed_project.invest.easy_exit": "Easy exit",
    "detailed_project.invest.easy_exit_explanation": "123",
    "detailed_project.invest": "Инвестировать",
    "detailed_project.invest.buy": "Купить заём",
    "detailed_project.placeholder": "Информация не найдена.",
    "detailed_project.founder.share": "Доля в компании",
    "detailed_project.founder.legal": "юл",
    "detailed_project.founder.individual": "ип",
    "detailed_project.founder.capital": "Уставной капитал",
    "detailed_project.founder.registration_date": "Дата регистрации",
    "detailed_project.founder.number": "Номер",
    "detailed_project.founder.companies_number": "Кол-во компаний",
    "detailed_project.general": "Параметры займа",
    "detailed_project.general.product": "Продукт",
    "detailed_project.general.rate": "Ставка",
    "detailed_project.general.term": "Срок",
    "detailed_project.general.percents": "Начисление процентов",
    "detailed_project.general.on_remainder": "На остаток ОД",
    "detailed_project.general.frequency": "Частота выплат",
    "detailed_project.guarantors": "Поручители",
    "detailed_project.guarantors.fio": "ФИО",
    "detailed_project.borrower_info": "Общая информация по заемщику",
    "detailed_project.borrower_info.name": "Название",
    "detailed_project.borrower_info.registration_date": "Дата регистрации",
    "detailed_project.borrower_info.address": "Адрес",
    "detailed_project.borrower_info.okved": "ОКВЭД",
    "detailed_project.borrower_info.description": "Описание деятельности",
    "detailed_project.borrower_info.not_listed": "Не указано",
    "detailed_project.borrower_info.links": "Ссылки на сайт",
    "detailed_project.borrower_info.founders": "Учредители и участники",
    "detailed_project.borrower_info.credit_turnover": "Чистый кредитный оборот",
    "detailed_project.borrower_info.accounting.active": "Бухгалтерский баланс (Актив)",
    "detailed_project.borrower_info.accounting.passive": "Бухгалтерский баланс (Пассив)",
    "detailed_project.borrower_info.accounting.tangible_non_assets": "Материальные внеоборотные активы",
    "detailed_project.borrower_info.accounting.intangible_financial": "Нематериальные финансовые и другие",
    "detailed_project.borrower_info.accounting.stocks": "Запасы",
    "detailed_project.borrower_info.accounting.financial_assets": "Финансовые и другие оборотные активы",
    "detailed_project.borrower_info.accounting.balance": "Баланс актив",
    "detailed_project.borrower_info.accounting.capital_and_reserves": "Капитал и резервы",
    "detailed_project.borrower_info.accounting.long_term_borrowings": "Долгосрочные заемные средства",
    "detailed_project.borrower_info.accounting.short_term_borrowings": "Краткосрочные заемные средства",
    "detailed_project.borrower_info.accounting.accounts_payable": "Кредиторская задолженность",
    "detailed_project.borrower_info.accounting.other_liabilities": "Другие краткосрочные обязательства",
    "detailed_project.borrower_info.accounting.balance_passive": "Баланс пассив",
    "detailed_project.borrower_info.accounting.period": "Период",
    "detailed_project.distribution": "Распределение долей покупателей",
    "detailed_project.admissions": "Количество поступлений на расчетный счет",

    "detailed_project.reputation.credit_history": "Кредитная история",
    "detailed_project.reputation.no_delay": "Текущая просрочка отсутствует",
    "detailed_project.reputation.debt_load": "Текущая долговая нагрузка",
    "detailed_project.reputation.delays_30_days_12_months": "Кол-во просрочек до 30 дней за последние 12 месяцев",
    "detailed_project.reputation.max_borrower_sum": "Макс. сумма займа, успешно обслуживаемая заёмщиком",
    "detailed_project.reputation.last_month_loans_number": "Кол-во кредитов и займов за последний месяц",
    "detailed_project.reputation.microcredits_3_months": "Микрокредиты за последние 3 месяца",

    "detailed_project.portfolio": "Кредитный портфель",
    "detailed_project.portfolio.mortgage": "Ипотека",
    "detailed_project.portfolio.leasing": "Лизинг",
    "detailed_project.portfolio.bank_credit": "Банковский кредит",
    "detailed_project.portfolio.crowdlending": "Краудлендинг",

    "detailed_project.arbitrations": "Арбитражи",
    "detailed_project.arbitrations.role": "Роль",
    "detailed_project.arbitrations.claimant": "Истец",
    "detailed_project.arbitrations.defendant": "Ответчик",
    "detailed_project.arbitrations.execution_proceedings": "Исполнительные производства",
    "detailed_project.arbitrations.index": "Показатель",
    "detailed_project.arbitrations.historical": "Исторические",

    "detailed_project.proceeding_history": "История производства",
    "detailed_project.proceeding_history.id": "ID займа",
    "detailed_project.proceeding_history.status": "Статус",
    "detailed_project.proceeding_history.date": "Дата выдачи",
    "detailed_project.proceeding_history.sum": "Сумма",
    "detailed_project.proceeding_history.rate": "Ставка",
    "detailed_project.proceeding_history.term": "Срок",
    "detailed_project.proceeding_history.remainder": "Остаток ОД",
    "detailed_project.proceeding_history.delay_count": "Кол-во просрочек",
    "detailed_project.proceeding_history.max_delay": "Макс. просрочка",

    "detailed_project.status.fundraising": "Сбор средств",
    "detailed_project.status.finished": "Сбор завершен",
    "detailed_project.status.loan_payment": "Выплата",
    "detailed_project.status.paid": "Возвращён",
    "detailed_project.status.refunded": "Возвращён",

    "detailed_project.period.month": "За месяц",
    "detailed_project.period.three_months": "За 3 месяца",
    "detailed_project.period.six_months": "За 6 месяцев",
    "detailed_project.period.twelve_months": "За 12 месяцев",
    "detailed_project.period.all_time": "За все время",

    "detailed_project.turnover_period.month": "за последний месяц",
    "detailed_project.turnover_period.three_months": "за 3 месяца в среднем",
    "detailed_project.turnover_period.six_months": "за 6 месяцев в среднем",
    "detailed_project.turnover_period.twelve_months": "за 12 месяцев в среднем",
    "detailed_project.turnover_period.all_time": "за все время",

    "detailed_project.schedule.monthly": "Раз в месяц",
    "detailed_project.schedule.monthly_twice": "2 раза в месяц",
    "detailed_project.schedule.monthly_thrice": "3 раза в месяц",
    "detailed_project.schedule.monthly_four_times": "4 раза в месяц",
    "detailed_project.schedule.quarterly": "Ежеквартально",
    "detailed_project.schedule.at_the_end": "В конце периода",

    "detailed_project.invest.months.nominative": "месяц",
    "detailed_project.invest.months.genitive": "месяца",
    "detailed_project.invest.months.multiple": "месяцев",

    "detailed_project.days.nominative": "день",
    "detailed_project.days.genitive": "дня",
    "detailed_project.days.multiple": "дней",

    "detailed_project.times.nominative": "раз",
    "detailed_project.times.genitive": "раза",
    "detailed_project.times.multiple": "раз",
}