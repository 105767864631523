import React, {AllHTMLAttributes, FC, PropsWithChildren} from "react";
import "./ConfirmationBlock.scoped.scss";
import ConfirmationBlockHeading from "../extensions/ConfirmationBlockHeading";
import ConfirmationBlockText from "../extensions/ConfirmationBlockText";
import {cn} from "../../../../utils";

type ConfirmationBlockExtensions = {
    Heading: typeof ConfirmationBlockHeading;
    Text: typeof ConfirmationBlockText;
}

interface Props extends AllHTMLAttributes<HTMLDivElement> {}

const ConfirmationBlock: FC<PropsWithChildren<Props>> & ConfirmationBlockExtensions = ({ children, ...props }) => {
    return (
        <div {...props} className={cn("confirmation-block", props.className)}>
            { children }
        </div>
    );
}

ConfirmationBlock.Heading = ConfirmationBlockHeading;
ConfirmationBlock.Text = ConfirmationBlockText;

export default ConfirmationBlock;