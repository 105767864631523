import {Translations} from "../../types";

export const guarantor: Translations = {
    "guarantor": "Լրացրեք երաշխավորի ձևը",
    "guarantor.personal_data": "Անձնական տվյալներ",
    "guarantor.personal_data.last_name": "Ազգանունը",
    "guarantor.personal_data.first_name": "Անուն",
    "guarantor.personal_data.middle_name": "Ազգանունը",
    "guarantor.personal_data.birthday": "Ծննդյան ամսաթիվ",
    "guarantor.confirmation": "Մուտքագրված տվյալների հաստատում",
    "guarantor.confirmation.first": "տրամադրում եմ",
    "guarantor.confirmation.second": "համաձայնություն անձնական տվյալների մշակմանը, վարկային բյուրոյին հարցում և բջջային օպերատորի տվյալների ստուգում",
    "guarantor.send": "Ուղարկել հարցաթերթիկ",
    "guarantor.survey_not_found": "Հարցաթերթիկը լրացված չէ",
    "guarantor.status": "Ձեր դիմումի կարգավիճակը:",
    "guarantor.awaiting_administrator": "Ձեր դիմումը վերանայվում է ադմինիստրատորի կողմից...",
}