import {Translations} from "../../types";

export const funding_request: Translations = {
    "funding_request.navigation.upload_documents": "Հայտարարության ներբեռնում",
    "funding_request.navigation.count_available": "Առկա գումարի հաշվարկ",
    "funding_request.navigation.survey": "Վարկառուի հարցաթերթիկ",
    "funding_request.navigation.loan_options": "Վարկի պայմանների ընտրություն",
    "funding_request.navigation.sign_documents": "Փաստաթղթերի ստորագրում",
    "funding_request.upload": "Բեռնել",
    "funding_request.upload.more": "Ներբեռնեք ավելին",
    "funding_request.upload.restriction": "մեկ կամ մի քանի ֆայլ (.txt մինչև 1 մբ)",
    "funding_request.upload.restriction.more": "(.txt մինչև 1 մբ)",
    "funding_request.upload.count": "Հաշվարկել սահմանաչափը",
    "funding_request.upload.description": "Ներբեռնեք ստանդարտ բանկային հաշվի քաղվածքներ՝ վարկի հասանելի գումարները տեսնելու համար",
    "funding_request.upload.requirements": "Պահանջներ",
    "funding_request.upload.requirements.first": "Քաղվածքը պետք է ներառի ընթացիկ ամիսը (պետք է ստեղծվի ընթացիկ ամսաթվով)",
    "funding_request.upload.requirements.second": "Քաղվածքը պետք է լինի առնվազն 12 ամսական",
    "funding_request.upload.requirements.third": "Եթե ձեր բիզնեսը 1 տարուց պակաս է, խնդրում ենք վերբեռնել քաղվածք գործունեության ողջ իրական ժամանակահատվածի համար",
    "funding_request.upload.recommendations": "Առաջարկություններ",
    "funding_request.upload.several_accounts": "Եթե դուք օգտագործում եք բազմաթիվ հաշիվներ մեկ կամ մի քանի " +
        "բանկերում, խնդրում ենք ներբեռնել քաղվածքներ բոլոր հաշիվներից՝ ընկերության սահմանաչափը ճիշտ հաշվարկելու համար:",
    "funding_request.available_sum.declined": "Ստուգումը մերժված է",
    "funding_request.available_sum.pending": "Սպասում է ադմինիստրատորի վերանայման...",
    "funding_request.available_sum.success": "Ստուգումը հաջողությամբ ավարտվեց",
    "funding_request.available_sum.preliminary_declined": "Նախնական ստուգումը մերժվել է։",
    "funding_request.available_sum.pending_more": "Սպասվում է հարթակի ադմինիստրատորի վերանայում, որի արդյունքում " +
        "կհաստատվեք վարկի նախնական գումարի համար։",
    "funding_request.available_sum.approved_more_first": "Ձեր հաշվի վրա կատարված գործարքների ավտոմատ վերլուծության " +
        "արդյունքների հիման վրա դուք նախապես հաստատվել եք այն գումարի համար, որի շրջանակներում կարող եք վարկ " +
        "վերցնել: Խնդրում ենք նկատի ունենալ, որ հաջորդ քայլերում գումարը կարող է փոխվել " +
        "այն բանից հետո, երբ հայտը վերանայվի տեղաբաշխողի կողմից:",
    "funding_request.available_sum.approved_more_second": "Դուք կարող եք փորձել ստանալ ավելի բարձր սահմանաչափ: " +
        "Դա անելու համար վերբեռնեք լրացուցիչ քաղվածքներ այլ հաշիվներից (այդ թվում՝ այլ " +
        "բանկերից) կամ գնացեք այլ բանկերի անձնական հաշիվ: Նախկինում ներբեռնված հայտարարություններն ամփոփված " +
        "են. ներբեռնեք միայն լրացուցիչ հայտարարություններ:",
    "funding_request.available_sum.preliminary_approved": "Նախապես հաստատված գումարը",
    "funding_request.survey": "Լրացրեք վարկի հայտը",
    "funding_request.survey.fill": "Վարկի գրանցումը և դիմումը լրացնելու համար լրացրեք ձևաթուղթը։",
    "funding_request.survey.personal_data": "Անձնական տվյալներ",
    "funding_request.survey.company_data": "Տեղեկություններ ընկերության մասին",
    "funding_request.survey.company_data.phone": "Հեռախոս",
    "funding_request.survey.company_data.description": "Ընթացիկ գործունեության մանրամասն նկարագրությունը",
    "funding_request.survey.company_data.business_form": "Բիզնեսի ձև",
    "funding_request.survey.company_data.activity": "Հաճախորդների գործունեություն",
    "funding_request.survey.company_data.business_address": "Փաստացի բիզնես հասցե",
    "funding_request.survey.company_data.links": "Հղումներ դեպի ձեր ընկերության կայքը կամ սոցիալական մեդիայի պրոֆիլները",
    "funding_request.survey.company_data.links.add": "Ավելացնել մեկ այլ հղում",
    "funding_request.survey.mark": "Գործարար հեղինակության գնահատում",
    "funding_request.survey.mail_for_reviews": "Եթե դուք ունեք գուդվիլի վերաբերյալ ակնարկներ վարկային կամ ոչ վարկային " +
        "ֆինանսական հաստատություններից, որտեղ վարկառուն եղել է/ծառայում է, ուղարկեք դրանք.",
    "funding_request.survey.reputation.positive": "Դրական",
    "funding_request.survey.reputation.negative": "բացասական",
    "funding_request.survey.reputation.no_reputation": "Ոչ մի վարկանիշ",
    "funding_request.survey.reputation.no_crime": "Հաստատում եմ չմարված կամ չմարված դատվածության բացակայությունը տնտեսագիտության " +
        "բնագավառում կամ պետական իշխանության դեմ ուղղված հանցագործությունների, պետական ծառայության շահերի և " +
        "տեղական ինքնակառավարման մարմիններում ծառայության համար",
    "funding_request.survey.reputation.no_negative": "Հաստատում եմ փաստերի (միջոցառումների, գործողություններ) " +
        "բացակայությունը, որոնք կարող են էական բացասական ազդեցություն ունենալ վարկի մարման վրա.",
    "funding_request.survey.reputation.money_source": "Նշեք շրջանառու միջոցների հիմնական աղբյուրը",
    "funding_request.survey.reputation.money_source.economic": "Եկամուտներ ձեռնարկատիրական գործունեությունից",
    "funding_request.survey.reputation.money_source.borrowed": "Փոխառու միջոցներ",
    "funding_request.survey.reputation.money_source.other": "Այլ",
    "funding_request.survey.reputation.confirm": "Մուտքագրված տվյալների հաստատում",
    "funding_request.survey.reputation.confirmation.first": "տրամադրում եմ",
    "funding_request.survey.reputation.confirmation.second": "համաձայնություն անձնական տվյալների մշակմանը, " +
        "վարկային բյուրոյին հարցում և բջջային օպերատորի տվյալների ստուգում",
    "funding_request.survey.send": "Ուղարկել հարցաթերթիկ",
    "funding_request.loan_options": "Ընտրեք վարկի պայմանները",
    "funding_request.loan_options.sum": "Գումար, :currency (Հասանելի սահմանաչափ՝ :min_amount :currency-ից մինչև :max_amount :currency)",
    "funding_request.loan_options.term": "Ժամկետ, ամիսներ (Առավելագույն ժամկետ՝ ժամկետային ամիսներ)",
    "funding_request.loan_options.promo": "Պրոմոկոդ (եթե այդպիսիք կան)",
    "funding_request.loan_options.send_request": "Ներկայացրեք հարցում",
    "funding_request.loan_options.finish_registration": "Լրիվ գրանցում",
    "funding_request.loan_options.schedule": "Վճարման ժամանակացույց",
    "funding_request.loan_options.preliminary_warning": "Ուշադրություն. Վճարման նախնական ժամանակացույց",
    "funding_request.loan_options.preliminary_explanation": "Վճարման ճշգրիտ գրաֆիկին կկարողանաք ծանոթանալ ձեր անձնական " +
        "հաշվում վարկ ստանալուց հետո։ Դուք կարող եք ժամանակից շուտ մարել վարկը՝ առանց տույժերի և միջնորդավճարների։",
    "funding_request.loan_options.terms": "Վարկի պայմաններ",
    "funding_request.loan_options.terms.sum": "Վարկի գումար",
    "funding_request.loan_options.terms.commission": "Թողարկման վճար (:percent% վարկի գումարի չափով)",
    "funding_request.loan_options.terms.final_sum": "Վարկավորման ենթակա գումարը",
    "funding_request.loan_options.terms.schedule": "Հասունության հաճախականությունը",
    "funding_request.loan_options.terms.term": "Վարկի ժամկետ",
    "funding_request.loan_options.terms.term.monthly": "Ամսական",
    "funding_request.loan_options.terms.term.twice_a_month": "Ամսական 2 անգամ",
    "funding_request.loan_options.terms.term.thrice_a_month": "Ամսական 3 անգամ",
    "funding_request.loan_options.terms.term.four_times_a_month": "Ամսական 4 անգամ",
    "funding_request.loan_options.terms.term.quarterly": "Եռամսյակային",
    "funding_request.loan_options.terms.term.at_the_end": "Ժամանակահատվածի վերջում",
    "funding_request.loan_options.terms.term.not_listed": "Նշված չէ",
    "funding_request.sign_document": "Դիմումի քննարկում և փաստաթղթերի ստորագրում",
    "funding_request.sign_document.sms_signed": "Ստորագրված է SMS-ով",
    "funding_request.sign_document.approved_manager": "Հաստատված է մենեջերի կողմից",
    "funding_request.sign_document.final_review": "Հայտը ներկայացվել է վերանայման",
    "funding_request.sign_document.final_review_text.first": "Մշակման ժամանակը շատ դեպքերում 1-2 աշխատանքային օր է:",
    "funding_request.sign_document.final_review_text.second": "Դրական որոշման մասին կտեղեկացվի մենեջերի կողմից, " +
        "որոշ դեպքերում կարող է անհրաժեշտ լինել ճշտել մանրամասները:",
    "funding_request.sign_document.requisites.heading_first": "Սպասվում է ստորագրում",
    "funding_request.sign_document.requisites.heading_second": "արժույթ",
    "funding_request.sign_document.requisites.description": "Փաստաթղթեր ստորագրելու համար փոխառուի հաշվից " +
        "(իրավաբանական անձի կամ անհատ ձեռնարկատիրոջ հաշվից) փոխանցել վճար՝",
    "funding_request.sign_document.requisites.description_second": "ըստ ստորև ներկայացված մանրամասների։",
    "funding_request.sign_document.requisites.one": "Մեկը",
    "funding_request.sign_document.requisites": "Մանրամասներ ուղարկելու համար",
    "funding_request.sign_document.requisites.purpose": "Վճարման նպատակը՝ փոխանցում potok.digital կանոններին միանալը " +
        "հաստատելու համար, թիվ 31.28 ներդրումային առաջարկի ստորագրում 664. ԱԱՀ-ից ազատված",
    "funding_request.sign_document.qr_code": "QR կոդը ուղարկելու համար",
    "funding_request.sign_document.qr_code.camera": "Ուղղեք ձեր սմարթֆոնի տեսախցիկը",

    "funding_request.months.nominative": "ամիս",
    "funding_request.months.genitive": "ամիսներ",
    "funding_request.months.multiple": "ամիսներ"
}