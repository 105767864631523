import React, {FC} from "react";
import {defaultArrowColor} from "./detaultArrowColor";
import {SvgProps} from "../SvgProps";
import SvgContainer from "../SvgContainer";
import {SvgContainerSize} from "../SvgContainer/SvgContainer";

const ArrowLeft: FC<SvgProps> = ({ color = defaultArrowColor }) => {
    return (
        <SvgContainer size={SvgContainerSize.Small}>
            <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 5L12 5M2 5L6 9M2 5L6 1" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
            </svg>
        </SvgContainer>
    );
}

export default ArrowLeft;