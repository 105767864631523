import React, {FC} from "react";
import {SecondaryButton} from "../decorators";
import {SecondaryButtonColor} from "../decorators/SecondaryButton/SecondaryButton";
import {Button} from "../index";
import {useNavigate} from "react-router-dom";
import styles from "./CommonBackButton.module.scss";
import {ArrowLeft} from "../../../svg";
import {ButtonProps} from "../Button/Button";
import {cn} from "../../../../utils";
import {useTranslator} from "../../../../hooks";

const CommonBackButton: FC<ButtonProps> = ({ ...props }) => {
    const t = useTranslator();
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    return (
        <SecondaryButton color={SecondaryButtonColor.GRAY}>
            <Button {...props} onClick={goBack} className={cn(styles.common_back_button, props.className)}>
                <div className={styles.common_back_button__container}>
                    <ArrowLeft />
                    <span>
                        { t("common.back") }
                    </span>
                </div>
            </Button>
        </SecondaryButton>
    );
}

export default CommonBackButton;