import React from "react";
import {Outlet} from "react-router-dom";
import LayoutBorrowerCabinet from "../../layouts/LayoutBorrowerCabinet";

const BorrowerCabinetDashboard = () => {
    return (
        <LayoutBorrowerCabinet>
            <Outlet />
        </LayoutBorrowerCabinet>
    );
}

export default BorrowerCabinetDashboard;