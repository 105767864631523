import React, {useContext} from "react";
import {MyProjectContext} from "../../MyProjectContext";
import {
    moderatedProjectStatuses,
    DetailedMyModeratedProject,
    activeProjectStatuses,
    DetailedMyActiveProject,
    fundraisingProjectStatuses,
    DetailedMyFundraisingProject,
    PaymentScheduleType
} from "../../../../../../api-client";
import MyModeratedProjectAside from "../MyModeratedProjectAside";
import MyActiveProjectAside from "../MyActiveProjectAside";
import MyFundraisingProjectAside from "../MyFundraisingProjectAside";
import {TranslationDictionary} from "../../../../../../utils";

export const myProjectScheduleTranslations: TranslationDictionary<PaymentScheduleType> = {
    [PaymentScheduleType.MONTHLY_PAYMENTS]: "my_detailed_project.schedule.monthly",
    [PaymentScheduleType.MONTHLY_TWO_PAYMENTS]: "my_detailed_project.schedule.monthly_twice",
    [PaymentScheduleType.MONTHLY_THREE_PAYMENTS]: "my_detailed_project.schedule.monthly_thrice",
    [PaymentScheduleType.MONTHLY_FOUR_PAYMENTS]: "my_detailed_project.schedule.monthly_four_times",
    [PaymentScheduleType.QUARTERLY_PAYMENTS]: "my_detailed_project.schedule.quarterly",
    [PaymentScheduleType.PAYMENT_AT_THE_END]: "my_detailed_project.schedule.at_the_end",
}


const MyProjectAside = () => {
    const project = useContext(MyProjectContext).project;
    if (moderatedProjectStatuses.includes(project.status)) {
        return <MyModeratedProjectAside project={project as DetailedMyModeratedProject} />
    }
    if (activeProjectStatuses.includes(project.status)) {
        return <MyActiveProjectAside project={project as DetailedMyActiveProject} />
    }
    if (fundraisingProjectStatuses.includes(project.status)) {
        return <MyFundraisingProjectAside project={project as DetailedMyFundraisingProject} />
    }
    return (
        <div>
            Произошла ошибка...
        </div>
    );
}

export default MyProjectAside;