import React, {useEffect, useState} from "react";
import {Heading, Loader, PrimaryHeading} from "../../../../common";
import "./AnalyticsUser.scoped.scss";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import ContentContainer from "../../../../layout/common/ContentContainer";
import AnalyticsUserNavigation from "../AnalyticsUserNavigation";
import {Outlet} from "react-router-dom";
import AnalyticsPeriodSelect from "../../AnalyticsPeriodSelect";
import {Analytics} from "../../../../../api-client";
import AnalyticsUserContextProvider, {AnalyticsUserContextType} from "../AnalyticsUserContext";
import {useAnalyticsFilter, useErrorCallback, useTranslator} from "../../../../../hooks";
import {createAnalyticsProvider} from "../../../../../di";
import LoaderContainer from "../../../../loading/LoaderContainer";
import AnalyticsTypeSelect from "../../AnalyticsTypeSelect";
import AnalyticsUserStatistics from "../AnalyticsUserStatistics/AnalyticsUserStatistics";

const AnalyticsUser = () => {
    const t = useTranslator();
    const { selectedYear, setSelectedYear, types, setTypes, filter } = useAnalyticsFilter();
    const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const handleError = useErrorCallback();

    const requestAnalytics = async () => {
        setLoading(true);
        try {
            const provider = await createAnalyticsProvider();
            setAnalytics(await provider.getUserAnalytics(filter));
        } catch (error: any) {
            await handleError(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        requestAnalytics();
        return () => {
            setAnalytics(undefined);
        }
    }, [filter]);

    const value: AnalyticsUserContextType = {
        analytics
    }
    return (
        <ContentContainer>
            <div className="user-analytics">
                <div className="user-analytics__main">
                    <PrimaryHeading>
                        <Heading headingType={HeadingType.H1}>
                            { t("analytics.user") }
                        </Heading>
                    </PrimaryHeading>
                    <AnalyticsUserNavigation />
                    <div className="user-analytics__selects">
                        <AnalyticsPeriodSelect
                            selectedOption={selectedYear}
                            setSelectedOption={setSelectedYear}
                        />
                        <AnalyticsTypeSelect
                            selected={types}
                            setSelected={setTypes}
                        />
                    </div>
                    { loading && <LoaderContainer><Loader /></LoaderContainer> }
                    { !loading && analytics === undefined && <div>{ t("analytics.user.error") }</div> }
                    { !loading && analytics !== undefined && <AnalyticsUserContextProvider props={value}>
                        <Outlet />
                    </AnalyticsUserContextProvider> }
                </div>
                <div className="user-analytics__stats">
                    <AnalyticsUserStatistics />
                </div>
            </div>
        </ContentContainer>
    );
}

export default AnalyticsUser;