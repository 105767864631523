import {Translations} from "../../types";

export const profile: Translations = {
    "profile": "Профиль",
    "profile.edit": "Редактировать",
    "profile.cancel": "Отменить",
    "profile.account": "Данные аккаунта",
    "profile.passport": "Паспортные данные",
    "profile.passport.disclaimer": "При изменении паспортных данных, потребуется дополнительная идентификация. " +
        "На время проверки данных, функционал платформы будет недоступен.",
    "profile.documents": "Документы",
    "profile.save": "Сохранить изменения"
}