import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {CancelInvestmentManager} from "../CancelInvestmentManager";

export class CancelInvestmentManagerFactory {
    private readonly apiClientFactory: ApiClientFactoryInterface;

    constructor(baseUrl: string, headers: Headers) {
        this.apiClientFactory = new ApiClientFactory(
            `${baseUrl}/api/v1/cancel-investment`,
            headers
        )
    }

    public createCancelInvestmentManager(authToken: string): CancelInvestmentManager {
        return new CancelInvestmentManager(this.apiClientFactory.createAuthorizedClient(authToken));
    }
}