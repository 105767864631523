import {Translations} from "../../types";

export const my_loans: Translations = {
    "my_loans": "Իմ վարկերը",
    "my_loans.navigation.active": "Ակտիվ",
    "my_loans.navigation.fundraising": "Վարկի հավաքագրում",
    "my_loans.navigation.returned": "Վերադարձել է",
    "my_loans.navigation.moderated": "Չափավորություն",
    "my_loans.new": "Ստեղծեք նոր վարկ",
    "my_loans.id": "Վարկի ID",
    "my_loans.sum": "Գումար",
    "my_loans.paid": "Վճարված",
    "my_loans.collected": "Հավաքված",
    "my_loans.target": "Թիրախ",
    "my_loans.rate": "Հայտ",
    "my_loans.term": "Ժամկետ",
    "my_loans.schedule": "Մարման ժամանակացույց",
    "my_loans.schedule_payments": "Վճարումների ժամանակացույց",
    "my_loans.nearest": "հաջորդ վճարում",
    "my_loans.created_at": "ստեղծման ամսաթիվը",
    "my_loans.status": "Կարգավիճակ",
    "my_loans.will_be_added": "Ավելացվող",
    "my_loans.not_listed": "Նշված չէ",
    "my_loans.not_listed.feminine": "Նշված չէ",

    "my_loans.schedule.monthly": "Ամսական",
    "my_loans.schedule.monthly_twice": "Ամիսը երկու անգամ",
    "my_loans.schedule.monthly_thrice": "Ամսական երեք անգամ",
    "my_loans.schedule.monthly_four_times": "Ամսական չորս անգամ",
    "my_loans.schedule.monthly_quarterly": "Եռամսյակային",
    "my_loans.schedule.monthly_at_the_end": "Ժամանակահատվածի վերջում",

    "my_loans.status.review": "Առաջին ակնարկ",
    "my_loans.status.review_approved": "Առաջին ակնարկն ընդունվեց",
    "my_loans.status.review_rejected": "Առաջին վերանայումը մերժվեց",
    "my_loans.status.final_review": "Վերջնական վերանայում",
    "my_loans.status.final_review_approved": "Վերջնական վերանայումն ընդունվեց",
    "my_loans.status.final_review_rejected": "Վերջնական վերանայումը մերժվեց",
}