import {Translations} from "../../types";

export const navigation: Translations = {
    "navigation.new_investments": "Նոր ներդրումներ",
    "navigation.my_investments": "Իմ ներդրումները",
    "navigation.analytics": "Վերլուծություն",
    "navigation.finances": "Ֆինանսներ",
    "navigation.profile": "Անձնագիր",
    "navigation.my_loans": "Իմ վարկերը",
    "navigation.history": "Պատմություն",
    "navigation.contact_us": "Կապվեք մեզ հետ",
    "navigation.logout": "Դուրս գալ"
}