import React, {FC, PropsWithChildren, useRef, useState} from "react";
import './Information.scoped.scss';
import {Image} from "../../images";
import questionImage from "../../../../assets/images/icons/question.svg";
import {CSSTransition} from "react-transition-group";

const Information: FC<PropsWithChildren> = ({ children }) => {
    const [active, setActive] = useState(false);
    const timeoutRef = useRef(0);

    const onHover = () => {
        clearTimeout(timeoutRef.current);
        setActive(true);
    }

    const onLeave = () => {
        timeoutRef.current = window.setTimeout(() => {
            setActive(false);
        }, 250)
    }

    return (
        <div
            className="information"
            onMouseEnter={onHover}
            onMouseLeave={onLeave}
        >
            <Image src={questionImage} />
            <CSSTransition
                in={active}
                timeout={250}
                classNames="information__answer-"
            >
                <div className="information__answer">
                    <div className="information__text">
                        { children }
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}

export default Information;