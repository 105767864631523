import React, {FC, InputHTMLAttributes} from "react";
import styles from "./InputNumber.module.scss";
import {cn} from "../../../../utils";

const InputNumber: FC<InputHTMLAttributes<HTMLInputElement>> = ({ ...props}) => {
    return (
        <input
            {...props}
            type="number"
            className={cn(styles.input_number, props.className)}
        />
    );
}

export default InputNumber;