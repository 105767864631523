import React from "react";
import AuthFooter from "../../AuthFooter";
import {ProjectLink} from "../../../../common";
import {RouteDictionary} from "../../../../../utils";
import "./AuthFooterRegistration.scss";
import {useTranslator} from "../../../../../hooks";

const AuthFooterRegistration = () => {
    const t = useTranslator();
    return (
        <AuthFooter>
            <ProjectLink to={RouteDictionary.LOGIN} className="auth_footer_login__link">
                { t("auth.register.have_account") }
            </ProjectLink>
        </AuthFooter>
    )
}

export default AuthFooterRegistration;