import React from "react";
import styles from "./AnalyticsProfitabilityHeader.module.scss";
import {CommonSubText} from "../../../../../common";
import CurrentCurrency from "../../../../../currencies/CurrentCurrency";
import {useTranslator} from "../../../../../../hooks";

const AnalyticsProfitabilityHeader = () => {
    const t = useTranslator();
    return (
        <div className={styles.analytics_profitability_header}>
            <CommonSubText small>
                { t("analytics.user.profitabilities.month") }
            </CommonSubText>
            <CommonSubText small>
                { t("analytics.user.profitabilities.irr") }
            </CommonSubText>
            <CommonSubText small>
                { t("analytics.user.profitabilities.result") }, %
            </CommonSubText>
            <CommonSubText small>
                { t("analytics.user.profitabilities.result") }, <CurrentCurrency />
            </CommonSubText>
        </div>
    );
}

export default AnalyticsProfitabilityHeader;