import {ApiClientInterface} from "./clients";
import buildQuery from "../utils/router/buildQuery";
import {DaDataCompany} from "./models/company";

export class CompanyProvider {
    constructor(private readonly apiClient: ApiClientInterface, private readonly abortSignal?: AbortSignal) {}

    public async getDaDataCompanies(companyNumber: string): Promise<Array<DaDataCompany>> {
        const query = buildQuery({ query: companyNumber })
        return this.apiClient.get(`/suggestions?${query}`, {}, this.abortSignal);
    }
}