import {Translations} from "../../types";

export const market: Translations = {
    "market.primary": "Рынок первичных размещений",
    "market.secondary": "Рынок вторичных размещений",
    "market.primary.company": "Компания",
    "market.primary.sum": "Сумма",
    "market.primary.rate": "Ставка",
    "market.primary.term": "Срок",
    "market.primary.schedule": "График погашения",
    "market.primary.collected": "Собрано",
    "market.primary.invest": "Инвестировать",
    "market.primary.filters": "Фильтры",
    "market.primary.filters.term": "Срок займа, дни",
    "market.primary.filters.submit": "Применить",
    "market.primary.filters.reset": "Сбросить",
    "market.primary.schedule.monthly": "Ежемесячно",
    "market.primary.schedule.monthly_twice": "Дважды в месяц",
    "market.primary.schedule.monthly_thrice": "Трижды в месяц",
    "market.primary.schedule.monthly_four_times": "Четырежды в месяц",
    "market.primary.schedule.quarterly": "Ежеквартально",
    "market.primary.schedule.at_the_end": "В конце периода"
}