import React, {AllHTMLAttributes, FC, PropsWithChildren} from "react";
import {cn} from "../../../../utils";
import "./SecondaryNavigation.scoped.scss";
import SecondaryNavigationLink from "./extensions/SecondaryNavigationLink";
import SecondaryNavigationList from "./extensions/SecondaryNavigationList";

interface Props extends AllHTMLAttributes<HTMLDivElement> {}

type SecondaryNavigationExtensions = {
    Link: typeof SecondaryNavigationLink;
    List: typeof SecondaryNavigationList;
}

const SecondaryNavigation: FC<PropsWithChildren<Props>> & SecondaryNavigationExtensions = ({ children, ...props }) => {
    return (
        <nav {...props} className={cn("secondary-navigation", props.className)}>
            { children }
        </nav>
    );
}

SecondaryNavigation.Link = SecondaryNavigationLink;
SecondaryNavigation.List = SecondaryNavigationList;

export default SecondaryNavigation;