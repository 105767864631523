import React, {FC} from "react";
import styles from "./AnalyticsPenalty.module.scss";
import {AnalyticsPenalty as AnalyticsPenaltyModel} from "../../../../../../api-client";
import {useTranslator} from "../../../../../../hooks";
import {getMonthTranslation} from "../../../../../../i18n/utils";

interface Props {
    penalty: AnalyticsPenaltyModel;
}

const AnalyticsPenalty: FC<Props> = ({ penalty }) => {
    const t = useTranslator();
    return (
        <li className={styles.analytics_penalty}>
            <div>
                { t(getMonthTranslation(penalty.date)) }
            </div>
            <div>
                { penalty.returned.count + penalty.inDefault.count }
            </div>
            <div>
                { penalty.returned.count }
            </div>
            <div>
                { penalty.inDefault.count }
            </div>
        </li>
    );
}

export default AnalyticsPenalty;