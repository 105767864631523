import React, {useContext} from "react";
import './FundingRequestSign.scoped.scss';
import {Heading, PrimaryHeading} from "../../../common";
import {HeadingType} from "../../../common/typography/headings/Heading/Heading";
import StatusBlock from "../../../common/utils/StatusBlock";
import {StatusBlockType} from "../../../common/utils/StatusBlock/StatusBlock";
import {ProjectStatus} from "../../../../api-client";
import FundingRequestReview from "../FundingRequestReview";
import FundingRequestRequisites from "../FundingRequestRequisites";
import {FundingRequestContext} from "../FundingRequestContext";
import FundingRequestLoanConditions from "../FundingRequestLoanConditions";
import {useTranslator} from "../../../../hooks";

const FundingRequestSignDocument = () => {
    const t = useTranslator();
    const { request } = useContext(FundingRequestContext);
    if (request === null) {
        return <div>Error has occurred</div>;
    }
    return (
        <div className="funding-request-sign">
            <div className="funding-request-sign__container">
                <div>
                    <PrimaryHeading>
                        <Heading headingType={HeadingType.H1} className="funding-request-sign__heading">
                            { t("funding_request.sign_document") }
                        </Heading>
                    </PrimaryHeading>
                    <div className="funding-request-sign__statuses">
                        <StatusBlock type={StatusBlockType.Success} text={t("funding_request.sign_document.sms_signed")} />
                        { request.projectStatus === ProjectStatus.FINAL_REVIEW_APPROVED
                            && <StatusBlock type={StatusBlockType.Success} text={t("funding_request.sign_document.approved_manager")} /> }
                    </div>
                </div>
                <div className="funding-request-sign__block">
                    { request.projectStatus === ProjectStatus.FINAL_REVIEW
                        && <FundingRequestReview heading={t("funding_request.sign_document.final_review")}>
                            <div>
                                { t("funding_request.sign_document.final_review_text.first") }
                            </div>
                            <div>
                                { t("funding_request.sign_document.final_review_text.second") }
                            </div>
                        </FundingRequestReview>}
                    { request.projectStatus === ProjectStatus.FINAL_REVIEW_APPROVED
                        && <FundingRequestRequisites />}
                </div>
                <FundingRequestLoanConditions
                    sum={request.desiredMoney!}
                    initialTerm={request.initialTerm!}
                    scheduleType={request.scheduleType}
                />
            </div>
        </div>
    );
}

export default FundingRequestSignDocument;