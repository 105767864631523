import React, {FC, ReactNode, useId} from "react";
import DatePickerComponent, {DatePickerProps as ReactDatePickerProps} from "react-date-picker";
import "./DatePicker.scoped.scss";
import {InputLabel} from "../../form";
import {defaultCalendarProps} from "../../../calendar/defaultCalendarProps";
import {createLanguageStorage} from "../../../../di";

export interface DatePickerProps extends ReactDatePickerProps {
    value: Date;
    onSelected: (date: Date) => void;
    text: ReactNode;
}

const DatePicker: FC<DatePickerProps> = ({ value, onSelected, text, ...props }) => {
    const locale = createLanguageStorage().getPreferredLanguage() || "ru";
    const combinedProps: ReactDatePickerProps = {...defaultCalendarProps, ...props, locale};
    const id = useId();
    return (
        <div className="date-select">
            <InputLabel id={id} text={text} />
            <div className="date-select__input">
                <DatePickerComponent
                    {...combinedProps}
                    showLeadingZeros
                    clearIcon={null}
                    calendarIcon={null}
                    className="date-select"
                    value={value}
                    onChange={onSelected}
                    format="dd.MM.y"
                />
            </div>
        </div>
    );
}

export default DatePicker;