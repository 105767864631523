import React, {FC} from "react";
import {FundRaisingProjectInvestment, PaymentScheduleType} from "../../../../../api-client";
import styles from "./FundRaisingInvestment.module.scss";
import {createMoneyFactory} from "../../../../../di";
import {CommonSubText, CommonText, Money} from "../../../../common";
import CommonInvestment from "../../CommonInvestment";
import {useTranslator} from "../../../../../hooks";
import {formatDate} from "../../../../../formatters";
import {DynamicTranslation} from "../../../../language";

interface Props {
    investment: FundRaisingProjectInvestment;
}

const moneyFactory = createMoneyFactory();

const ScheduleTypeTranslation: Record<PaymentScheduleType, string> = {
    [PaymentScheduleType.MONTHLY_PAYMENTS]: "my_investments.schedule.monthly",
    [PaymentScheduleType.MONTHLY_TWO_PAYMENTS]: "my_investments.schedule.monthly_twice",
    [PaymentScheduleType.MONTHLY_THREE_PAYMENTS]: "my_investments.schedule.monthly_thrice",
    [PaymentScheduleType.MONTHLY_FOUR_PAYMENTS]: "my_investments.schedule.monthly_four_times",
    [PaymentScheduleType.QUARTERLY_PAYMENTS]: "my_investments.schedule.quarterly",
    [PaymentScheduleType.PAYMENT_AT_THE_END]: "my_investments.schedule.at_the_end",
}

const FundraisingInvestment: FC<Props> = ({ investment }) => {
    const t = useTranslator();
    const investedSum = investment.project.investedSum;
    return (
        <CommonInvestment
            linkTo={{ projectUuid: investment.project.uuid, investmentUuid: investment.investments[0].uuid }}
            className={styles.fundraising_investment}
        >
            <div className={styles.fundraising_investment__name}>
                <CommonSubText small>
                    ID { investment.project.paymentCode }
                </CommonSubText>
                <CommonText>
                    <DynamicTranslation translations={ investment.project.name } />
                </CommonText>
            </div>
            <div className={styles.fundraising_investment__target}>
                <CommonText>
                    <Money money={investment.project.targetSum} />
                </CommonText>
            </div>
            <div className={styles.fundraising_investment__collected}>
                <CommonText>
                    <Money money={investedSum !== undefined ? investedSum : moneyFactory.createMoney()} />
                </CommonText>
            </div>
            <div className={styles.fundraising_investment__sum}>
                <CommonText>
                    <Money money={investment.investedSum} />
                </CommonText>
            </div>
            <div className={styles.fundraising_investment__interest}>
                <CommonText>
                    { investment.project.interestRate }%
                </CommonText>
            </div>
            <div className={styles.fundraising_investment__date}>
                <CommonText>
                    { formatDate(new Date(investment.investments[0].createdAt)) }
                </CommonText>
            </div>
            <div className={styles.fundraising_investment__schedule}>
                <CommonText>
                    { t(ScheduleTypeTranslation[investment.project.scheduleType]) }
                </CommonText>
            </div>
        </CommonInvestment>
    );
}

export default FundraisingInvestment;