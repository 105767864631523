import React, {useContext} from "react";
import {GuarantorContext, GuarantorStep} from "../GuarantorContextProvider";
import CommonAside from "../../../layout/common/CommonAside";

const GuarantorAside = () => {
    const context = useContext(GuarantorContext);
    return (
        <CommonAside>
            <CommonAside.Step
                index={1}
                text="Ваша заявка"
                active={context.step === GuarantorStep.FillSurvey}
                onClick={() => context.setStep(GuarantorStep.FillSurvey)}
            />
            <CommonAside.Step
                index={2}
                text="Статус заявки"
                active={context.step === GuarantorStep.CheckStatus}
                onClick={() => context.setStep(GuarantorStep.CheckStatus)}
            />
        </CommonAside>
    );
}

export default GuarantorAside;