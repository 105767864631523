import {ApiClientInterface} from "./clients";
import {BorrowerInfo, LenderInfo, RegisteredUser} from "./models";

export class RegistrationManager {
    constructor(private readonly apiClient: ApiClientInterface) {}

    public async registerLender(lenderInfo: LenderInfo): Promise<RegisteredUser> {
        return this.apiClient.post("/lender", { ...lenderInfo });
    }

    public async registerBorrower(borrowerInfo: BorrowerInfo): Promise<RegisteredUser> {
        return this.apiClient.post("/borrower", { ...borrowerInfo });
    }
}