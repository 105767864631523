import React, {useContext} from "react";
import './DetailedProjectAnalytics.scoped.scss';
import {
    DetailedProjectDistribution,
    DetailedProjectSubBlock,
    DetailedProjectSubBlocks,
    DetailedProjectSubPage
} from "../../index";
import {Heading, SecondaryHeading} from "../../../../common/typography/headings";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import {DetailedProjectContext} from "../../DetailedProjectContext";
import {Period} from "../../../../../api-client";
import DetailedProjectPlaceholder from "../../common/DetailedProjectPlaceholder";
import DetailedProjectNetCreditTurnovers from "../DetailedProjectNetCreditTurnovers";
import DetailedProjectAccounting from "../DetailedProjectAccounting";
import {useTranslator} from "../../../../../hooks";

const investmentsPeriodTranslations: Record<Period, string> = {
    [Period.OneMonth]: "detailed_project.period.month",
    [Period.ThreeMonths]: "detailed_project.period.three_months",
    [Period.SixMonths]: "detailed_project.period.six_months",
    [Period.TwelveMonths]: "detailed_project.period.twelve_months",
    [Period.AllTime]: "detailed_project.period.all_time"
}

const DetailedProjectAnalytics = () => {
    const t = useTranslator();
    const { analytics } = useContext(DetailedProjectContext).project;
    if (analytics === undefined) {
        return <DetailedProjectPlaceholder />
    }
    const renderAdmissions = () => {
        if (analytics.investments.length === 0) {
            return <DetailedProjectPlaceholder />
        }
        return analytics.investments.map((investment, index) => {
            return <div className="detailed-project-analytics__receipt" key={index}>
                <div>
                    { investment.countInvestments }
                </div>
                <div className="detailed-project-analytics-list__subtext">
                    { t(investmentsPeriodTranslations[investment.period]) }
                </div>
            </div>
        });
    }
    return (
        <DetailedProjectSubPage>
            <DetailedProjectSubBlocks>
                <DetailedProjectNetCreditTurnovers />
                <DetailedProjectAccounting balances={analytics.accountBalances} />
                <DetailedProjectDistribution />
                <DetailedProjectSubBlock>
                    <SecondaryHeading smaller>
                        <Heading headingType={HeadingType.H2}>
                            { t("detailed_project.admissions") }
                        </Heading>
                    </SecondaryHeading>
                    <div className="detailed-project-analytics__receipts">
                        { renderAdmissions() }
                    </div>
                </DetailedProjectSubBlock>
            </DetailedProjectSubBlocks>
        </DetailedProjectSubPage>
    );
}

export default DetailedProjectAnalytics;
