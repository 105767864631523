import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {MyMoney} from "../api-client";
import {createMoneyFactory} from "../di";

export type MyMoneyState = {
    myMoney?: MyMoney;
}

const initialState: MyMoneyState = {}

export const myMoneySlice = createSlice({
    name: "myMoney",
    initialState,
    reducers: {
        set: (state: MyMoneyState, { payload }: PayloadAction<MyMoney>) => {
            state.myMoney = payload;
        }
    }
});

export const { set } = myMoneySlice.actions;

export default myMoneySlice.reducer;