import {AuthManager, BearerToken, Unauthorized} from "../../api-client";
import {AuthTokenExpired, AuthTokenStorage, LockFactory} from "../index";
import moment from "moment";

export class AuthTokenManager {
    constructor(
        private readonly authManager: AuthManager,
        private readonly authTokenStorage: AuthTokenStorage,
        private readonly lockFactory: LockFactory
    ) {}

    public async has(): Promise<boolean> {
        const authToken = await this.resolve();
        return authToken !== null;
    }

    public async get(): Promise<string> {
        const authToken = await this.resolve();
        if (!authToken) {
            throw new AuthTokenExpired();
        }
        return authToken;
    }

    public create(bearerToken: BearerToken): void {
        this.authTokenStorage.set(bearerToken);
    }

    public remove(): void {
        this.authTokenStorage.remove();
    }

    private async resolve(): Promise<string | null> {
        const lock = this.lockFactory.createLock("auth-token:resolve");
        try {
            await lock.acquire();

            const bearerToken = this.authTokenStorage.get();
            if (!bearerToken) {
                return null;
            }
            if (moment(bearerToken.accessTokenExpiredAt).diff(moment()) > 0) {
                return bearerToken.accessToken;
            }
            return this.refresh();
        } finally {
            lock.release();
        }
    }

    private async refresh(): Promise<string | null> {
        const authToken = this.authTokenStorage.get();
        if (!authToken) {
            return null;
        }

        if (moment(authToken.refreshTokenExpiredAt).diff(moment()) <= 0) {
            return null;
        }

        let bearerToken;
        try {
            bearerToken = await this.authManager.refresh(authToken.refreshToken);
        } catch (err) {
            if (err instanceof Unauthorized) {
                return null;
            }
            throw err;
        }

        this.authTokenStorage.set(bearerToken);

        return bearerToken.accessToken;
    }
}