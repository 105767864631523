import {Translations} from "../../types";

export const auth: Translations = {
    "auth.login": "Вход",
    "auth.login.enter": "Войти",
    "auth.login.forgot_password": "Забыли пароль?",
    "auth.create_account": "Создать новый аккаунт",
    "auth.register": "Регистрация",
    "auth.register.have_account": "У меня уже есть аккаунт",
    "auth.register_lender": "Зарегистрировать нового инвестора",
    "auth.register.password": "Пароль (:length или больше символов)",
    "auth.register.confirm_password": "Подвердите пароль",
    "auth.register_lender.information_exchange_agree": "Я согласен на информационное взаимодействие",
    "auth.register_lender.platform_interaction_rules": "Я согласен на правила взаимодействия с платформой",
    "auth.register_borrower": "Зарегистрировать нового заемщика",
    "auth.register_borrower.as": "Регистрация от имени",
    "auth.register_borrower.data": "Данные аккаунта",
    "auth.register_borrower.agree_with": "Согласен с",
    "auth.register_borrower.platform_rules": "правилами платформы",
    "auth.register_borrower.register_as": "зарегистрироваться в качестве генерального директора компании или индивидуального предпринимателя",
    "auth.register_borrower.familiar_with": "Ознакомлен с",
    "auth.register_borrower.processing_policy": "политикой обработки персональных данных",
}