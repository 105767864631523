import {LanguageProvider} from "./LanguageProvider";
import {Language} from "../api-client";
import russianFlag from "../assets/images/flags/russia.png";
import americanFlag from "../assets/images/flags/united-states.png";
import armenianFlag from "../assets/images/flags/armenia.png";

export class SystemLanguageProvider extends LanguageProvider {
    provideLanguages(): Array<Language> {
        return [
            {
                name: "Русский",
                short: "ru",
                flag: russianFlag,
                isDefault: true
            },
            {
                name: "English",
                short: "en",
                flag: americanFlag,
                isDefault: false
            },
            {
                name: "Armenian",
                short: "am",
                flag: armenianFlag,
                isDefault: false
            }
        ]
    }
}