import {Translations} from "../../types";

export const investment_confirmation: Translations = {
    "investment_confirmation": "Invest in loan",
    "investment_confirmation.sum": "Sum",
    "investment_confirmation.term": "Term",
    "investment_confirmation.rate": "Rate",
    "investment_confirmation.create": "Creating confirmation...",
    "investment_confirmation.balance": "Balance",
    "investment_confirmation.input": "Invest sum (from 400 :currency to 1 000 000 :currency)",
    "investment_confirmation.final_sum": "Payout amount based on income",
    "investment_confirmation.invest": "Invest",
    "investment_confirmation.confirm": "Clicking «Invest» I understand, that investing in loan is considered high risk.",
    "investment_confirmation.preliminary": "Preliminary payment schedule",
    "investment_confirmation.generating_schedule": "Generating schedule...",
}