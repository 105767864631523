import React, {AllHTMLAttributes, ElementType, FC, PropsWithChildren} from "react";
import styles from "./CommonSubText.module.scss";
import {cn, resultIf} from "../../../../utils";

interface Props extends AllHTMLAttributes<HTMLElement> {
    tag?: ElementType;
    small?: boolean;
}

const CommonSubText: FC<PropsWithChildren<Props>> = ({ tag: Tag = 'div', children, small = false, ...props }) => {
    return (
        <Tag {...props} className={cn(
            styles.common_sub_text,
            resultIf(small, styles.common_sub_text___small),
            props.className
        )}>
            { children }
        </Tag>
    );
}

export default CommonSubText;