import React from "react";
import {defaultSvgColor} from "./SvgProps";
import {SvgComponent} from "./SvgComponent";
import SvgContainer from "./SvgContainer";

const SortingSvg: SvgComponent = ({ color= defaultSvgColor }) => {
    return (
        <SvgContainer>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 4L12 12M12 4L9.5 6.5M12 4L14.5 6.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
                <path d="M4 12L4 4M4 12L1.5 9.5M4 12L6.5 9.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
            </svg>
        </SvgContainer>
    );
}

export default SortingSvg;