import React from "react";
import {Outlet} from "react-router-dom";
import {LayoutLender, MainLayout} from "../../layouts";

const LenderDashboard = () => {
    return (
        <MainLayout>
            <LayoutLender>
                <Outlet />
            </LayoutLender>
        </MainLayout>
    );
}

export default LenderDashboard;