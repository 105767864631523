import React, {useState} from "react";
import './Login.scoped.scss';
import {
    Button,
    Checkbox,
    Heading,
    InputErrors,
    PrimaryButton,
    ProjectLink, SecondaryHeading
} from "../../../components/common";
import {HeadingType} from "../../../components/common/typography/headings/Heading/Heading";
import {Phone} from "../../../api-client";
import {CommonInput, InputPassword, InputPhone} from "../../../components/common/inputs";
import {Errors as ValidationErrors, RouteDictionary} from "../../../utils";
import {PrimaryButtonColor} from "../../../components/common/buttons/decorators/PrimaryButton/PrimaryButton";
import {
    useEffectOnUpdate,
    useErrorCallback,
    useLogout,
    useNavigateByName,
    useToggle,
    useTranslator
} from "../../../hooks";
import {createAuthTokenManager, createAuthManager} from "../../../di";

type FormErrors = {
    username?: ValidationErrors;
    password?: ValidationErrors;
}

const Login = () => {
    useLogout();
    const t = useTranslator();
    const handleError = useErrorCallback();
    const navigate = useNavigateByName();
    const [emailActive, toggleEmailActive] = useToggle();
    const [username, setUsername] = useState<Phone>(new Phone("374", ""));
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState<FormErrors>({});
    const [loading, setLoading] = useState(false);

    useEffectOnUpdate(() => {
        setErrors({});
    }, [username, password]);

    const validateForm = (): boolean => {
        const errors: FormErrors = {};
        if (emailActive) {
            if (email === "") {
                errors.username = ["Поле обязательно для заполнения"];
            }
        } else {
            if (username.number === "") {
                errors.username = ["Поле обязательно для заполнения"];
            }
        }
        if (password === "") {
            errors.password = ["Поле обязательно для заполнения"];
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const handleSubmit = async () => {
        if (validateForm()) {
            setLoading(true);
            try {
                const manager = await createAuthManager();
                const token = await manager.login(emailActive ? email.trim() : username.fullNumber, password);
                const authTokenManager = createAuthTokenManager();
                authTokenManager.create(token);
                navigate(RouteDictionary.REDIRECT_AFTER_AUTH);
            } catch (error: any) {
                await handleError(error);
                const newErrors: FormErrors = {};
                newErrors.password = emailActive ? ["Неверный адрес эл. почты или пароль"] : ["Неверный номер телефона или пароль"];
                newErrors.username = [" "];
                setErrors(newErrors);
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <div className="login">
            <div className="login__container">
                <SecondaryHeading>
                    <Heading headingType={HeadingType.H1} className="login__heading">
                        { t("auth.login") }
                    </Heading>
                </SecondaryHeading>
                <div className="login__inputs">
                    <div>
                        { emailActive ? <CommonInput
                            value={email}
                            onChange={setEmail}
                            text={ t("common.email") }
                            error={errors.username !== undefined}
                        /> : <InputPhone
                            phone={username}
                            onPhoneInput={setUsername}
                            text={ t("common.phone") }
                            error={errors.username !== undefined}
                        /> }
                        { errors.username && <InputErrors errors={errors.username} /> }
                    </div>
                    <div>
                        <Checkbox id="email-active" checked={emailActive} onChange={toggleEmailActive}>
                            { emailActive ? t("common.lender") : t("common.borrower") }
                        </Checkbox>
                    </div>
                    <div>
                        <InputPassword
                            password={password}
                            onChangePassword={setPassword}
                            text={ t("common.password") }
                            error={errors.password !== undefined}
                        />
                        { errors.password && <InputErrors errors={errors.password} /> }
                    </div>
                </div>
                <div className="login__buttons">
                    <PrimaryButton expanded color={PrimaryButtonColor.BLUE}>
                        <Button onClick={handleSubmit} loading={loading}>
                            { t("auth.login.enter") }
                        </Button>
                    </PrimaryButton>
                    <PrimaryButton expanded color={PrimaryButtonColor.GRAY}>
                        <ProjectLink to={RouteDictionary.FORGOT_PASSWORD}>
                            { t("auth.login.forgot_password") }
                        </ProjectLink>
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
}

export default Login;