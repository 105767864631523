import {Translations} from "../../types";

export const my_loans: Translations = {
    "my_loans": "Мои займы",
    "my_loans.navigation.active": "Активные",
    "my_loans.navigation.fundraising": "Сбор заёма",
    "my_loans.navigation.returned": "Возвращённые",
    "my_loans.navigation.moderated": "На модерации",
    "my_loans.new": "Создать новый заём",
    "my_loans.id": "ID заёма",
    "my_loans.sum": "Сумма",
    "my_loans.paid": "Выплачено",
    "my_loans.collected": "Собрано",
    "my_loans.target": "Цель",
    "my_loans.rate": "Ставка",
    "my_loans.term": "Срок",
    "my_loans.schedule": "График погашения",
    "my_loans.schedule_payments": "График выплат",
    "my_loans.nearest": "Ближайший платеж",
    "my_loans.created_at": "Дата создания",
    "my_loans.status": "Статус",
    "my_loans.will_be_added": "Будет добавлено",
    "my_loans.not_listed": "Не указан",
    "my_loans.not_listed.feminine": "Не указана",

    "my_loans.schedule.monthly": "Ежемесячно",
    "my_loans.schedule.monthly_twice": "Дважды в месяц",
    "my_loans.schedule.monthly_thrice": "Трижды в месяц",
    "my_loans.schedule.monthly_four_times": "Четырежды в месяц",
    "my_loans.schedule.monthly_quarterly": "Ежеквартальный",
    "my_loans.schedule.monthly_at_the_end": "В конце периода",

    "my_loans.status.review": "Первое ревью",
    "my_loans.status.review_approved": "Первое ревью принято",
    "my_loans.status.review_rejected": "Первое ревью отклонено",
    "my_loans.status.final_review": "Финальное ревью",
    "my_loans.status.final_review_approved": "Финальное ревью принято",
    "my_loans.status.final_review_rejected": "Финальное ревью отклонено",
}