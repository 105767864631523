import {ApiClientInterface} from "./clients";
import {Phone, PhoneResult, Profile} from "./models";

export class ProfileManager {
    constructor(private readonly apiClient: ApiClientInterface) {}

    public async get(): Promise<Profile> {
        return this.apiClient.get("");
    }

    public async addPhone(phone: Phone): Promise<PhoneResult> {
        return this.apiClient.post("/add-phone", { phone })
    }
}