import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";
import BorrowerCabinetDashboard from "../../../views/borrower/BorrowerCabinetDashboard";
import MyLoansRoutes from "./cabinet/MyLoansRoutes";
import Loadable from "../../loading/Loadable";

const LazyMyHistory = Loadable(lazy(() => import("../../../views/borrower/statistics/MyHistory")));
const LazyProfile = Loadable(lazy(() => import("../../../views/borrower/user/Profile")));

const BorrowerCabinetRoutes = () => {
    return (
        <Routes>
            <Route element={<BorrowerCabinetDashboard />}>
                <Route index element={<LazyMyHistory />} />
                <Route path="my-history" element={<LazyMyHistory />} />
                <Route path="my-projects/*" element={<MyLoansRoutes />} />
                <Route path="profile" element={<LazyProfile />} />
            </Route>
        </Routes>
    );
}

export default BorrowerCabinetRoutes;