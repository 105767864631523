import React from "react";
import {Heading, PrimaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import CommonBackButton from "../../../../common/buttons/CommonBackButton";
import styles from "./MyDetailedProjectPlaceholder.module.scss";
import {useTranslator} from "../../../../../hooks";

const MyDetailedProjectPlaceholder = () => {
    const t = useTranslator();
    return (
        <div className={styles.my_detailed_project_placeholder}>
            <PrimaryHeading>
                <Heading headingType={HeadingType.H1}>
                    { t("my_detailed_project.placeholder") }
                </Heading>
            </PrimaryHeading>
            <CommonBackButton />
        </div>
    );
}

export default MyDetailedProjectPlaceholder;