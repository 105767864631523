import React, {FC, ReactNode} from "react";
import './StatusBlock.scoped.scss';
import {cn} from "../../../../utils";
import RejectedSvg from "../../../svg/status/RejectedSvg";
import PendingSvg from "../../../svg/status/PendingSvg";
import ApprovedSvg from "../../../svg/status/ApprovedSvg";

export enum StatusBlockType {
    Success = "status-block--success",
    Warning = "status-block--warning",
    Error = 'status-block--error'
}

interface Props {
    type: StatusBlockType,
    text: ReactNode
}

const StatusBlock: FC<Props> = ({ type, text }) => {
    const renderSign = () => {
        if (type === StatusBlockType.Success) {
            return <ApprovedSvg />
        }
        if (type === StatusBlockType.Warning) {
            return <PendingSvg />
        }
        return <RejectedSvg />
    }
    return (
        <div className={cn("status-block", type)}>
            { renderSign() }
            { text }
        </div>
    );
}

export default StatusBlock;