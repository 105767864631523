import React, {FC} from "react";
import {File} from "../../../../../api-client";
import styles from "./DetailedProjectDocument.module.scss";
import DocumentSvg from "../../../../svg/DocumentSvg";

interface Props {
    document: File;
}

const DetailedProjectDocument: FC<Props> = ({ document }) => {
    return (
        <div className={styles.detailed_project_document}>
            <a href={document.url} className={styles.detailed_project_document__link} target="_blank" rel="noreferrer">
                <div className={styles.detailed_project_document__content}>
                    <DocumentSvg />
                    <div className={styles.detailed_project_document__name}>
                        { document.name }
                    </div>
                </div>
            </a>
        </div>
    );
}

export default DetailedProjectDocument;