import React, {FC, PropsWithChildren, ReactNode} from "react";
import './FundingRequestReview.scoped.scss';
import {CommonBlock} from "../../../common/blocks";
import PendingSvg from "../../../svg/status/PendingSvg";

interface Props {
    heading: ReactNode;
}

const FundingRequestReview: FC<PropsWithChildren<Props>> = ({ heading, children }) => {
    return (
        <div className="funding-request-review">
            <CommonBlock>
                <div className="funding-request-review__header">
                    <PendingSvg />
                    <div className="funding-request-options__subheader funding-request-review__subheader">
                        { heading }
                    </div>
                </div>
                <div className="funding-request__text">
                    { children }
                </div>
            </CommonBlock>
        </div>
    );
}

export default FundingRequestReview;