import React, {FC, PropsWithChildren} from "react";
import {cn, resultIf} from "../../../../utils";
import "./DropdownOpener.scoped.scss";

interface Props {
    active: boolean;
}

const DropdownOpener: FC<PropsWithChildren<Props>> = ({ active, children }) => {
    return (
        <div className={cn("dropdown-opener", resultIf(active, "active"))}>
            { children }
        </div>
    );
}

export default DropdownOpener;