import React, {FC, PropsWithChildren} from "react";
import styles from "./MainContainer.module.scss";
import {cn, resultIf} from "../../../../utils";

interface Props {
    max?: boolean;
}

const MainContainer: FC<PropsWithChildren<Props>> = ({ max, children }) => {
    return (
        <div className={cn(styles.main_container, resultIf(max === true, styles.main_container___max))}>
            { children }
        </div>
    );
}

export default MainContainer;