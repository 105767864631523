import React, {FC, PropsWithChildren} from "react";
import {Heading, SecondaryHeading} from "../../../../../common";
import {HeadingType} from "../../../../../common/typography/headings/Heading/Heading";
import styles from "./MyProjectScheduleHeading.module.scss";

const MyProjectScheduleHeading: FC<PropsWithChildren> = ({ children }) => {
    return (
        <SecondaryHeading>
            <Heading headingType={HeadingType.H2} className={styles.my_project_schedule_heading}>
                { children }
            </Heading>
        </SecondaryHeading>
    );
}

export default MyProjectScheduleHeading;