import React, {FC, PropsWithChildren} from "react";
import './DetailedProjectSubBlock.scoped.scss';

const DetailedProjectSubBlock: FC<PropsWithChildren> = ({ children }) => {
    return (
        <section className="detailed-project-sub-block">
            { children }
        </section>
    );
}

export default DetailedProjectSubBlock;