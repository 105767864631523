import {invariant} from "../functions";
import {NamedUrl} from "./types";
import {RouteProvider} from "./RouteProvider";
import {RouteDictionary} from "./routes";
import buildQuery, {QueryObject} from "./buildQuery";

export type UrlParamType = {
    [key: string]: string
}

export class RouteUrlProvider {
    private readonly routeProvider: RouteProvider;

    constructor(routeProvider: RouteProvider) {
        this.routeProvider = routeProvider;
    }

    public getByName(name: RouteDictionary, params?: UrlParamType, queryObject?: QueryObject): string {
        const current = this.routeProvider.getUrls().filter((namedUrl: NamedUrl) => {
            return namedUrl.name === name
        });
        invariant(current.length === 1, `Error when mapping urls, got ${name}`);
        let url = current[0].url;
        if (params) {
            Object.entries(params).forEach((param) => {
                const regexp = new RegExp(":" + param[0], 'gi');
                url = url.replace(regexp, param[1]);
            })
        }
        if (queryObject) {
            const query = buildQuery(queryObject);
            url += `?${query}`;
        }
        return url;
    }
}