import React, {FC, useMemo} from "react";
import "./DetailedProjectAccounting.scoped.scss";
import {DetailedProjectDatatable, DetailedProjectSubBlock} from "../../index";
import {AccountYearBalances} from "../../../../../api-client";
import {CommonSubText, Heading, Money, SecondaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import {
    AccountBalanceName,
    accountBalancesActive,
    accountBalancesPassive,
    Money as MoneyModel
} from "../../../../../api-client";
import {useTranslator} from "../../../../../hooks";
import {TranslationDictionary} from "../../../../../utils";

interface Props {
    balances: Array<AccountYearBalances>
}

interface MappedAccountYearBalances {
    name: AccountBalanceName;
    values: [{
        year: Date,
        amount: MoneyModel
    }];
}

const translations: TranslationDictionary<AccountBalanceName> = {
    [AccountBalanceName.TangibleNonCurrentAssets]: "detailed_project.borrower_info.accounting.tangible_non_assets",
    [AccountBalanceName.IntangibleFinancialAndOther]: "detailed_project.borrower_info.accounting.intangible_financial",
    [AccountBalanceName.Stocks]: "detailed_project.borrower_info.accounting.stocks",
    [AccountBalanceName.FinancialAndOtherCurrentAssets]: "detailed_project.borrower_info.accounting.financial_assets",
    [AccountBalanceName.BalanceActive]: "detailed_project.borrower_info.accounting.balance",
    [AccountBalanceName.CapitalAndReserves]: "detailed_project.borrower_info.accounting.capital_and_reserves",
    [AccountBalanceName.LongTermBorrowings]: "detailed_project.borrower_info.accounting.long_term_borrowings",
    [AccountBalanceName.ShortTermBorrowings]: "detailed_project.borrower_info.accounting.short_term_borrowings",
    [AccountBalanceName.AccountsPayable]: "detailed_project.borrower_info.accounting.accounts_payable",
    [AccountBalanceName.OtherCurrentLiabilities]: "detailed_project.borrower_info.accounting.other_liabilities",
    [AccountBalanceName.BalancePassive]: "detailed_project.borrower_info.accounting.balance_passive",
}

const DetailedProjectAccounting: FC<Props> = ({ balances }) => {
    const t = useTranslator();
    const years = balances.reduce<Array<number>>((prev, cur) => {
        return [new Date(cur.year).getFullYear(), ...prev];
    }, []);

    const renderYears = () => {
        return <DetailedProjectDatatable.Row>
            <CommonSubText>
                { t("detailed_project.borrower_info.accounting.period") }
            </CommonSubText>
            <>
                { years.map((year) => {
                    return <CommonSubText key={year}>
                        { year }
                    </CommonSubText>;
                }) }
            </>
        </DetailedProjectDatatable.Row>
    }

    const mappedBalances = useMemo(() => {
        const newBalances: Array<MappedAccountYearBalances> = [];
        balances.map((yearBalance) => {
            yearBalance.balances.map((certainBalance) => {
                const certainElements = newBalances.filter((element) => element.name === certainBalance.name);
                if (certainElements.length > 0) {
                    certainElements[0].values.unshift({ year: yearBalance.year, amount: certainBalance.balance });
                    return;
                }
                newBalances.push({ name: certainBalance.name, values: [{ year: yearBalance.year, amount: certainBalance.balance }] });
            })
        });
        return newBalances;
    }, [balances]);
    const activeBalances = mappedBalances.filter((balance) => accountBalancesActive.includes(balance.name));
    const passiveBalances = mappedBalances.filter((balance) => accountBalancesPassive.includes(balance.name));

    const renderBalanceTable = (balances: Array<MappedAccountYearBalances>) => {
        const renderRow = (mappedBalance: MappedAccountYearBalances) => {
            return <DetailedProjectDatatable.Row key={mappedBalance.name}>
                <DetailedProjectDatatable.Text>
                    { t(translations[mappedBalance.name]) }
                </DetailedProjectDatatable.Text>
                <>
                    { mappedBalance.values.map((value, index) => {
                        return <Money key={index} money={value.amount} withCurrency={false} />
                    }) }
                </>
            </DetailedProjectDatatable.Row>
        }
        return <DetailedProjectDatatable className="detailed-project-accounting__datatable">
            { renderYears() }
            <>
                { balances.map((balance) => {
                    return renderRow(balance);
                }) }
            </>
        </DetailedProjectDatatable>
    }

    return (
        <>
            <DetailedProjectSubBlock>
                <SecondaryHeading smaller>
                    <Heading headingType={HeadingType.H2}>
                        { t("detailed_project.borrower_info.accounting.active") }
                    </Heading>
                </SecondaryHeading>
                { renderBalanceTable(activeBalances) }
            </DetailedProjectSubBlock>
            <DetailedProjectSubBlock>
                <SecondaryHeading smaller>
                    <Heading headingType={HeadingType.H2}>
                        { t("detailed_project.borrower_info.accounting.passive") }
                    </Heading>
                </SecondaryHeading>
                { renderBalanceTable(passiveBalances) }
            </DetailedProjectSubBlock>
        </>
    );
}

export default DetailedProjectAccounting;