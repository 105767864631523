import React, {InputHTMLAttributes, ReactNode} from "react";
import './RadioButton.scoped.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    children: ReactNode;
}

const RadioButton = ({ children, ...props }: Props) => {
    return (
        <div className="custom-radio-button">
            <input type="radio" className="custom-radio-button__input" { ...props } />
            <label htmlFor={props.id} className="custom-radio-button__label">
                { children }
            </label>
        </div>
    );
}

export default RadioButton;