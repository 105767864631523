import {Translations} from "../../types";

export const date: Translations = {
    "date.january": "Январь",
    "date.february": "Февраль",
    "date.march": "Март",
    "date.april": "Апрель",
    "date.may": "Май",
    "date.june": "Июнь",
    "date.july": "Июль",
    "date.august": "Август",
    "date.september": "Сентябрь",
    "date.october": "Октябрь",
    "date.november": "Ноябрь",
    "date.december": "Декабрь",
}