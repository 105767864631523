import React, {FC, useEffect, useState} from "react";
import styles from "./IntroductionCompany.module.scss";
import {useErrorCallback, useProfileData, useTranslator} from "../../../../hooks";
import {createCompanyProvider, createKonturFocusRequisitesProvider} from "../../../../di";
import {CommonInput, CommonTextarea, InputPhone} from "../../../common/inputs";
import {Heading, InputErrors, Loader, TertiaryHeading} from "../../../common";
import {CompanyInfo, Phone} from "../../../../api-client";
import IntroductionCompanyUrls from "../IntroductionCompanyUrls";
import LoaderContainer from "../../../loading/LoaderContainer";
import {CompanyInfoErrors} from "../../../../hooks/data/validation";
import {HeadingType} from "../../../common/typography/headings/Heading/Heading";

interface Props {
    needLoading: boolean;
    companyInfo: CompanyInfo;
    setCompanyInfo: (companyInfo: CompanyInfo) => void;
    errors: CompanyInfoErrors;
}

type IntegratedFields = {
    registrationNumber?: boolean;
    kpp?: boolean;
    phone?: boolean;
    description?: boolean;
    address?: boolean;
}

const IntroductionCompany: FC<Props> = ({ needLoading, companyInfo, setCompanyInfo, errors }) => {
    const t = useTranslator();
    const [loading, setLoading] = useState(false);
    const [integratedFields, setIntegratedFields] = useState<IntegratedFields>({});
    const profileData = useProfileData();
    const handleError = useErrorCallback();
    const [isIp, setIsIp] = useState(false);
    //TODO: REMOVE MONKEY CODE LATER
    useEffect(() => {
        if (needLoading) {
            setLoading(true);
            (async () => {
                try {
                    const companyProvider = createCompanyProvider();
                    const results = await companyProvider.getDaDataCompanies(profileData.name!);
                    const inn = results[0].inn;
                    const konturFocusProvider = createKonturFocusRequisitesProvider();
                    const konturResults = await konturFocusProvider.getCompanyRequisites(inn);
                    const firstResult = konturResults[0];
                    const address = firstResult.UL?.legalAddress.parsedAddressRF;
                    setCompanyInfo({
                        ...companyInfo,
                        registrationNumber: inn,
                        kpp: firstResult.UL?.kpp ?? "0",
                        address: address !== undefined
                            ? `г. ${address.city?.topoValue ?? address.regionName.topoValue}, ул. ${address.street.topoValue}
                    ${address.houseRaw !== undefined ? `, ${address.houseRaw}` : ""}`
                            : ""
                    });
                    setIntegratedFields({
                        registrationNumber: inn !== "",
                        kpp: firstResult.UL?.kpp !== "",
                        address: address !== undefined,
                    });
                    setIsIp(firstResult.IP !== undefined);
                } catch (error: unknown) {
                    await handleError(error as Error);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [needLoading, profileData]);

    return (
        <div className="funding-request-identification__block funding-request-identification__block--three">
            <TertiaryHeading>
                <Heading headingType={HeadingType.H3} className="funding-request-identification__subheader">
                    { t("funding_request.survey.company_data") }
                </Heading>
            </TertiaryHeading>
            { loading ? <div className={styles.introduction_company__placeholder}>
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            </div> : <>
                <div>
                    <CommonInput
                        value={companyInfo.registrationNumber}
                        onChange={(value: string) => setCompanyInfo({...companyInfo, registrationNumber: value})}
                        text={t("common.reg_number")}
                        error={errors.registrationNumber !== undefined}
                        disabled={integratedFields.registrationNumber !== undefined}
                    />
                    { errors.registrationNumber && <InputErrors errors={errors.registrationNumber} /> }
                </div>
                <div style={{display: `${isIp ? "none" : "block"}`}}>
                    <CommonInput
                        value={companyInfo.kpp}
                        onChange={(value: string) => setCompanyInfo({...companyInfo, kpp: value})}
                        text={t("common.kpp")}
                        error={errors.kpp !== undefined}
                        disabled={integratedFields.kpp !== undefined}
                    />
                    { errors.kpp && <InputErrors errors={errors.kpp} /> }
                </div>
                <div>
                    <InputPhone
                        phone={companyInfo.phone}
                        onPhoneInput={(phone: Phone) => setCompanyInfo({...companyInfo, phone: phone})}
                        text={t("funding_request.survey.company_data.phone")}
                        error={errors.companyPhone !== undefined}
                        disabled={integratedFields.phone !== undefined}
                    />
                    { errors.companyPhone && <InputErrors errors={errors.companyPhone} /> }
                </div>
                <div className="funding-request-identification--full">
                    <CommonTextarea
                        value={companyInfo.description}
                        onChange={(value: string) => setCompanyInfo({...companyInfo, description: value})}
                        text={t("funding_request.survey.company_data.description")}
                        error={errors.description !== undefined}
                        disabled={integratedFields.description !== undefined}
                    />
                    { errors.description && <InputErrors errors={errors.description} /> }
                </div>
                <div className="funding-request-identification--full">
                    <CommonTextarea
                        value={companyInfo.businessForm}
                        onChange={(value: string) => setCompanyInfo({...companyInfo, businessForm: value})}
                        text={t("funding_request.survey.company_data.business_form")}
                        error={errors.businessForm !== undefined}
                    />
                    { errors.businessForm && <InputErrors errors={errors.businessForm} /> }
                </div>
                <div className="funding-request-identification--full">
                    <CommonInput
                        value={companyInfo.activity}
                        onChange={(value: string) => setCompanyInfo({...companyInfo, activity: value})}
                        text={t("funding_request.survey.company_data.activity")}
                        error={errors.activity !== undefined}
                    />
                    { errors.activity && <InputErrors errors={errors.activity} /> }
                </div>
                <div className="funding-request-identification--full">
                    <CommonInput
                        value={companyInfo.address}
                        onChange={(value: string) => setCompanyInfo({...companyInfo, address: value})}
                        text={t("funding_request.survey.company_data.business_address")}
                        error={errors.address !== undefined}
                        disabled={integratedFields.address}
                    />
                    { errors.address && <InputErrors errors={errors.address} /> }
                </div>
                <div className="funding-request-identification--full">
                    <IntroductionCompanyUrls
                        urls={companyInfo.siteUrls}
                        setUrls={(urls) => setCompanyInfo({ ...companyInfo, siteUrls: urls })}
                        errors={errors.siteUrls}
                    />
                </div>
            </> }
        </div>
    );
}

export default IntroductionCompany;