import React, {PropsWithChildren} from "react";
import './CloseButton.scoped.scss';
import {cn} from "../../../../../utils";
import {ClassInjector} from "../../../../injectors";
import {ClassNameDecorator} from "../../../../decorators";

export enum CloseButtonSize {
    Small = "close-button--small",
    Medium = "close-button--medium",
    Large = "close-button--large",
}

interface Props {
    size?: CloseButtonSize;
}

const CloseButton: ClassNameDecorator<PropsWithChildren<Props>> = ({ size = CloseButtonSize.Medium, children, className }) => {
    return (
        <ClassInjector
            classNames={
                cn("close-button", size)
            }
            oldClassName={className}
        >
            { children }
        </ClassInjector>
    );
}

export default CloseButton;