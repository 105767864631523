import React, {useMemo, useState} from "react";
import styles from "./MyActiveProjects.module.scss";
import {useErrorCallback, useTranslator} from "../../../../../hooks";
import {DataCollection, MyActiveProjectsFilter} from "../../../../../utils";
import {MyActiveProject as MyActiveProjectModel} from "../../../../../api-client";
import {createMyProjectsProvider} from "../../../../../di";
import {Pagination, PaginationContent, PaginationHeader, PaginationPaginator} from "../../../../pagination";
import SortingDirectionBlock from "../../../../common/utils/SortingDirectionBlock";
import MyActiveProject from "../MyActiveProject";

const defaultFilter: MyActiveProjectsFilter = {};

const MyActiveProjects = () => {
    const t = useTranslator();
    const handleError = useErrorCallback();
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState<MyActiveProjectsFilter>(defaultFilter);
    const [projects, setProjects]
        = useState<DataCollection<MyActiveProjectModel>>(new DataCollection());

    const loadData = async (
        page: number,
        perPage: number,
        filter: MyActiveProjectsFilter | undefined,
        abortSignal: AbortSignal
    ): Promise<void> => {
        setLoading(true);
        try {
            const provider = await createMyProjectsProvider(abortSignal);
            const projects = await provider.getActiveList(page, perPage, filter ?? defaultFilter);
            const collection = new DataCollection<MyActiveProjectModel>();
            collection.setCollection(projects);
            setProjects(collection);
        } catch (error: any) {
            await handleError(error);
        } finally {
            setLoading(false);
        }
    }

    const renderProjects = useMemo(() => {
        return projects.items.map((project) => {
            return <MyActiveProject key={project.uuid} project={project} />
        })
    }, [projects]);

    return (
        <div className={styles.my_active_projects}>
            <Pagination
                onLoad={loadData}
                count={projects.count}
                totalCount={projects.totalCount}
                filterObject={filter}
                initialChangeFilter={(value: Partial<MyActiveProjectsFilter>) => setFilter({...filter, ...value})}
            >
                <PaginationHeader className={styles.my_active_projects__list_header}>
                    <div>
                        { t("my_loans.id") }
                    </div>
                    <SortingDirectionBlock
                        direction={filter.targetSumSorting}
                        handleChange={(direction) => setFilter((filter) => { return {...filter, targetSumSorting: direction} })}
                    >
                        { t("my_loans.sum") }
                    </SortingDirectionBlock>
                    <SortingDirectionBlock
                        direction={filter.paidSumSorting}
                        handleChange={(direction) => setFilter((filter) => { return {...filter, paidSumSorting: direction} })}
                    >
                        { t("my_loans.paid") }
                    </SortingDirectionBlock>
                    <SortingDirectionBlock
                        direction={filter.interestRateSorting}
                        handleChange={(direction) => setFilter((filter) => { return {...filter, interestRateSorting: direction} })}
                    >
                        { t("my_loans.rate") }
                    </SortingDirectionBlock>
                    <SortingDirectionBlock
                        direction={filter.initialTermSorting}
                        handleChange={(direction) => setFilter((filter) => { return {...filter, initialTermSorting: direction} })}
                    >
                        { t("my_loans.term") }
                    </SortingDirectionBlock>
                    <div>
                        { t("my_loans.schedule") }
                    </div>
                    <div>
                        { t("my_loans.nearest") }
                    </div>
                </PaginationHeader>
                <PaginationContent loading={loading}>
                    <div className="market__content">
                        <ul className="market__list">
                            { renderProjects }
                        </ul>
                    </div>
                </PaginationContent>
                <PaginationPaginator />
            </Pagination>
        </div>
    );
}

export default MyActiveProjects;