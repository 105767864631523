export enum ProjectStatus {
    REVIEW = "REVIEW",
    REVIEW_APPROVED = "REVIEW_APPROVED",
    REVIEW_REJECTED = "REVIEW_REJECTED",
    FINAL_REVIEW = "FINAL_REVIEW",
    FINAL_REVIEW_APPROVED = "FINAL_REVIEW_APPROVED",
    FINAL_REVIEW_REJECTED = "FINAL_REVIEW_REJECTED",
    FUND_RAISING = "FUND_RAISING",
    FINISHED = "FINISHED",
    LOAN_PAYMENT = "LOAN_PAYMENT",
    PAID = "PAID",
    REFUNDED = "REFUNDED"
}

export const activeProjectStatuses = [ProjectStatus.LOAN_PAYMENT];
export const fundraisingProjectStatuses = [ProjectStatus.FUND_RAISING, ProjectStatus.FINISHED];
export const moderatedProjectStatuses = [
    ProjectStatus.REVIEW,
    ProjectStatus.REVIEW_APPROVED,
    ProjectStatus.REVIEW_REJECTED,
    ProjectStatus.FINAL_REVIEW,
    ProjectStatus.FINAL_REVIEW_APPROVED,
    ProjectStatus.FINAL_REVIEW_REJECTED
];
export const refundedProjectStatuses = [ProjectStatus.REFUNDED, ProjectStatus.PAID];