import React, {FC, PropsWithChildren, ReactNode} from "react";
import {SortingDirection} from "../../../../api-client";
import "./SortingDirectionBlock.scoped.scss";
import {ArrowDown, ArrowUp} from "../../../svg";
import {CommonSubText} from "../../typography";
import {ColorDictionary, getCssVariable} from "../../../../utils";
import {useCurrentLanguage} from "../../../../hooks";

interface Props {
    direction?: SortingDirection;
    handleChange: (direction?: SortingDirection) => void;
}

const SortingDirectionBlock: FC<PropsWithChildren<Props>> = ({ direction, handleChange, children }) => {
    const renderArrow = (): ReactNode => {
        if (direction === SortingDirection.ASC) {
            return <ArrowUp color={getCssVariable(ColorDictionary.PRIMARY_DARK)} />
        }
        if (direction === SortingDirection.DESC) {
            return <ArrowDown color={getCssVariable(ColorDictionary.PRIMARY_DARK)} />
        }
        return <ArrowDown color={getCssVariable(ColorDictionary.ADDITIONAL_DARK)} />;
    }
    const handleClick = () => {
        if (direction === undefined) {
            handleChange(SortingDirection.DESC);
            return;
        }
        if (direction === SortingDirection.DESC) {
            handleChange(SortingDirection.ASC);
            return;
        }
        handleChange(undefined);
    }
    return (
        <div className="sorting-direction-block" onClick={handleClick}>
            <CommonSubText small>
                { children }
            </CommonSubText>
            { renderArrow() }
        </div>
    );
}

export default SortingDirectionBlock;