import React from "react";
import {RouteDictionary} from "../../../../utils";
import {AnalyticsSvg, FolderSvg, ProfileSvg} from "../../../svg";
import CommonAside from "../../common/CommonAside";
import {matchPath, useLocation} from "react-router-dom";
import {useTranslator} from "../../../../hooks";

const BorrowerNavigation = () => {
    const t = useTranslator();
    const { pathname } = useLocation();
    return (
        <CommonAside>
            <CommonAside.Link to={RouteDictionary.MY_PROJECTS} icon={<FolderSvg />}>
                { t("navigation.my_loans") }
            </CommonAside.Link>
            <CommonAside.Link
                to={RouteDictionary.MY_HISTORY}
                icon={<AnalyticsSvg />}
                explicitActive={matchPath('borrower', pathname) !== null}
            >
                { t("navigation.history") }
            </CommonAside.Link>
            <CommonAside.Link to={RouteDictionary.BORROWER_PROFILE} icon={<ProfileSvg />}>
                { t("navigation.profile") }
            </CommonAside.Link>
        </CommonAside>
    );
}

export default BorrowerNavigation;