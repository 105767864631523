import {ApiClientInterface} from "./clients";
import {Collection, InvestmentRepayment} from "./models";
import {CancelToken} from "axios";

export class RepaymentProvider {
    constructor(private readonly apiClient: ApiClientInterface, private readonly signal: AbortSignal) {}

    public async getList(page: number, perPage: number): Promise<Collection<InvestmentRepayment>> {
        return this.apiClient.get("", undefined, this.signal);
    }
}