import {useEffect} from "react";

const useBodyOverflow = () => {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "";
        }
    }, [])
}

export default useBodyOverflow;