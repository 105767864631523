import React, {useContext} from "react";
import {useTranslator} from "../../../../../hooks";
import {DetailedProjectContext} from "../../DetailedProjectContext";
import MoneyProgress from "../../../../progress/MoneyProgress";
import {PrimaryButton, ProjectLink} from "../../../../common";
import {PrimaryButtonColor} from "../../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {RouteDictionary} from "../../../../../utils";
import DetailedProjectInvestmentStat from "../../common/DetailedProjectInvestmentStat";
import {CommonBlock} from "../../../../common/blocks";

const LoanPaymentProjectInvestmentInfo = () => {
    const t = useTranslator();
    const { project, investment } = useContext(DetailedProjectContext);
    return (
        <>
            <CommonBlock.Header>
                <MoneyProgress
                    firstPoint={{ name: t("detailed_project.invest.paid"), money: project.general.investedSum }}
                    secondPoint={{ name: t("detailed_project.invest.paid_sum"), money: project.general.loanSum }}
                    showPercent={false}
                />
            </CommonBlock.Header>
            <CommonBlock.Section>
                <DetailedProjectInvestmentStat />
                <div className="detailed-project-invest__submit">
                    <PrimaryButton expanded color={PrimaryButtonColor.BLUE}>
                        <ProjectLink
                            to={RouteDictionary.SELL_CONFIRMATION}
                            params={{uuid: project.uuid, investmentUuid: investment!.uuid }}
                        >
                            { t("detailed_project.invest.investment_sell") }
                        </ProjectLink>
                    </PrimaryButton>
                </div>
            </CommonBlock.Section>
        </>
    );
}

export default LoanPaymentProjectInvestmentInfo;