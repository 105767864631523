import {Translations} from "../../types";

export const selling_confirmation: Translations = {
    "selling_confirmation": "Продать заём ID :id",
    "selling_confirmation.commission": "Комиссия за продажу - :amount% от суммы займа.",
    "selling_confirmation.paid": "Выплачено",
    "selling_confirmation.amount": "Сумма выплаты",
    "selling_confirmation.investment.sum": "Сумма инвестиции",
    "selling_confirmation.investment.rate": "Ставка",
    "selling_confirmation.investment.income": "Доход",
    "selling_confirmation.investment.term": "Срок",
    "selling_confirmation.investment.earn_without_commission": "С вычетом комиссии вы получите",
    "selling_confirmation.policy_agree": "Согласен с ",
    "selling_confirmation.policy_agree_link": "политикой продажи заёма",
    "selling_confirmation.sell": "Выставить на продажу",
}