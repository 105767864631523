import React from "react";
import './Header.scoped.scss';
import HeaderNavigation from "../HeaderNavigation";
import {Button, LogoImage, Money, ProjectLink, PublicLink, SecondaryButton} from "../../../common";
import {RouteDictionary} from "../../../../utils";
import {SecondaryButtonColor} from "../../../common/buttons/decorators/SecondaryButton/SecondaryButton";
import {useProfileData, useTranslator} from "../../../../hooks";
import {createMoneyFactory} from "../../../../di";
import WithdrawSvg from "../../../svg/header/WithdrawSvg";
import DepositSvg from "../../../svg/header/DepositSvg";
import LanguageSelect from "../../../common/utils/LanguageSelect";

const factory = createMoneyFactory();

const Header = () => {
    const t = useTranslator();
    const profileData = useProfileData();
    return (
        <header className="header">
            <div className="header__content">
                <div className="header__main">
                    <div className="header__logo">
                        <PublicLink url="/">
                            <LogoImage />
                        </PublicLink>
                    </div>
                    <HeaderNavigation />
                </div>
                <div className="header__money">
                    <div className="header__balance">
                        <div className="header__available">
                            { t("header.available") }:
                        </div>
                        <div>
                            <Money money={profileData.balance ?? factory.createMoney()} />
                        </div>
                    </div>
                    <div className="header__money-links">
                        <SecondaryButton color={SecondaryButtonColor.BLUE}>
                            <ProjectLink className="header__money-link" to={RouteDictionary.DEPOSIT}>
                                <Button.Inner>
                                    <span>
                                        { t("header.deposit") }
                                    </span>
                                    <DepositSvg />
                                </Button.Inner>
                            </ProjectLink>
                        </SecondaryButton>
                        <SecondaryButton color={SecondaryButtonColor.GRAY}>
                            <ProjectLink className="header__money-link" to={RouteDictionary.WITHDRAW}>
                                <Button.Inner>
                                        <span>
                                            { t("header.withdraw") }
                                        </span>
                                    <WithdrawSvg />
                                </Button.Inner>
                            </ProjectLink>
                        </SecondaryButton>
                        <LanguageSelect />
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;