import React, {FC} from "react";
import styles from "./AnalyticsProfitability.module.scss";
import {AnalyticsProfitability as AnalyticsProfitabilityModel} from "../../../../../../api-client";
import {Money} from "../../../../../common";
import {useTranslator} from "../../../../../../hooks";
import {getMonthTranslation} from "../../../../../../i18n/utils";

interface Props {
    profitability: AnalyticsProfitabilityModel;
}

const AnalyticsProfitability: FC<Props> = ({ profitability }) => {
    const t = useTranslator();
    return (
        <li className={styles.analytics_profitability}>
            <div>
                { t(getMonthTranslation(profitability.date)) }
            </div>
            <div>
                { profitability.irr }%
            </div>
            <div>
                { profitability.accumulatedPercent }%
            </div>
            <div>
                <Money money={profitability.accumulatedSum} />
            </div>
        </li>
    );
}

export default AnalyticsProfitability;