import React, {useContext} from "react";
import MoneyProgress from "../../../../progress/MoneyProgress";
import {CommonSubText, PrimaryButton, ProjectLink, Toggle} from "../../../../common";
import {resolveMonthTranslation} from "../../../../../i18n";
import Information from "../../../../common/utils/Information";
import {PrimaryButtonColor} from "../../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {RouteDictionary} from "../../../../../utils";
import {useToggle, useTranslator} from "../../../../../hooks";
import {DetailedProjectContext} from "../../DetailedProjectContext";
import DetailedProjectInvestBlocks from "../DetailedProjectInvest/extensions/DetailedProjectInvestBlocks";
import DetailedProjectInvestBlock from "../DetailedProjectInvest/extensions/DetailedProjectInvestBlock";
import {CommonBlock} from "../../../../common/blocks";

const FundraisingProjectInvestInfo = () => {
    const t = useTranslator();
    const project = useContext(DetailedProjectContext).project;
    const [buybackActive, toggleBuyback] = useToggle();
    const [easyExitActive, toggleEasyExit] = useToggle();
    return (
        <>
            <CommonBlock.Header>
                <MoneyProgress
                    firstPoint={{ name: t("detailed_project.invest.collected"), money: project.general.investedSum }}
                    secondPoint={{ name: t("detailed_project.invest.target"), money: project.general.loanSum }}
                />
            </CommonBlock.Header>
            <CommonBlock.Section underscore={false}>
                <DetailedProjectInvestBlocks>
                    <DetailedProjectInvestBlock>
                        <CommonSubText>
                            { t("detailed_project.invest.rate") }
                        </CommonSubText>
                        <div className="detailed-project-invest__value">
                            {project.general.interestRate}%
                        </div>
                    </DetailedProjectInvestBlock>
                    <DetailedProjectInvestBlock>
                        <CommonSubText>
                            { t("detailed_project.invest.term") }
                        </CommonSubText>
                        <div className="detailed-project-invest__value">
                            {project.general.initialTerm} { t(resolveMonthTranslation(project.general.initialTerm)) }
                        </div>
                    </DetailedProjectInvestBlock>
                    <DetailedProjectInvestBlock>
                        <div className="detailed-project-invest__information">
                            <CommonSubText>
                                { t("detailed_project.invest.buyback") }
                            </CommonSubText>
                            <Information>
                                { t("detailed_project.invest.buyback_explanation") }
                            </Information>
                        </div>
                        <div className="detailed-project-invest__value">
                            <Toggle active={buybackActive} onToggled={toggleBuyback} />
                        </div>
                    </DetailedProjectInvestBlock>
                    <DetailedProjectInvestBlock>
                        <div className="detailed-project-invest__information">
                            <CommonSubText>
                                { t("detailed_project.invest.easy_exit") }
                            </CommonSubText>
                            <Information>
                                { t("detailed_project.invest.easy_exit_explanation") }
                            </Information>
                        </div>
                        <div className="detailed-project-invest__value">
                            <Toggle active={easyExitActive} onToggled={toggleEasyExit} />
                        </div>
                    </DetailedProjectInvestBlock>
                </DetailedProjectInvestBlocks>
                <div className="detailed-project-invest__submit">
                    <PrimaryButton expanded color={PrimaryButtonColor.BLUE}>
                        <ProjectLink to={RouteDictionary.INVESTMENT_CONFIRMATION} params={{uuid: project.uuid}}>
                            { t("detailed_project.invest") }
                        </ProjectLink>
                    </PrimaryButton>
                </div>
            </CommonBlock.Section>
        </>
    );
}

export default FundraisingProjectInvestInfo;