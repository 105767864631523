import React, {FC} from "react";
import {NavLink, NavLinkProps} from "react-router-dom";
import {UrlParamType, RouteDictionary, QueryObject, cn, resultIf} from "../../../../utils";
import {createRouteUrlProvider} from "../../../../di";

export interface ProjectLinkProps extends NavLinkProps {
    to: RouteDictionary;
    params?: UrlParamType;
    queryObject?: QueryObject;
    explicitActive?: boolean | undefined;
}

const ProjectLink: FC<ProjectLinkProps> = ({ to, params, queryObject, explicitActive, children, ...props }) => {
    const urlProvider = createRouteUrlProvider();
    const url = urlProvider.getByName(to, params, queryObject);
    const isActiveFunc = ({ isActive }: { isActive: boolean }) => {
        if (typeof props.className === "function") {
            return cn(props.className({ isActive }), resultIf(explicitActive === true, "active"));
        }
        return (isActive || explicitActive ? "active " : "") + props.className;
    }
    return (
        <NavLink
            {...props}
            to={url}
            className={isActiveFunc}
        >
            { children }
        </NavLink>
    );
}

export default ProjectLink;