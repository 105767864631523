import React, {FC} from "react";
import "./DetailedProjectBorrowerInfo.scoped.scss";
import {CommonSubText, Heading, SecondaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import {DetailedProjectDatatable, DetailedProjectSubBlock} from "../../index";
import DetailedProjectPlaceholder from "../../common/DetailedProjectPlaceholder";
import CommonLink from "../../../../common/links/CommonLink";
import {ProjectBorrower} from "../../../../../api-client";
import {useTranslator} from "../../../../../hooks";
import {formatDate} from "../../../../../formatters";

interface Props {
    borrower: ProjectBorrower;
}

const DetailedProjectBorrowerInfo: FC<Props> = ({ borrower }) => {
    const t = useTranslator();
    const renderCompanySiteUrls = () => {
        if (borrower.siteUrls.length === 0) {
            return <DetailedProjectPlaceholder />
        }
        return borrower.siteUrls.map((url) => {
            return <CommonLink key={url} href={url} target="_blank" rel="noopener noreferrer">
                { url }
            </CommonLink>
        })
    }
    return (
        <DetailedProjectSubBlock>
            <SecondaryHeading smaller>
                <Heading headingType={HeadingType.H2}>
                    { t("detailed_project.borrower_info") }
                </Heading>
            </SecondaryHeading>
            <DetailedProjectDatatable className="detailed-project-summary__datatable">
                <DetailedProjectDatatable.Row>
                    <CommonSubText>
                        { t("detailed_project.borrower_info.name") }
                    </CommonSubText>
                    <DetailedProjectDatatable.Text>
                        { borrower.companyName }
                    </DetailedProjectDatatable.Text>
                </DetailedProjectDatatable.Row>
                <DetailedProjectDatatable.Row>
                    <CommonSubText>
                        { t("common.reg_number") }
                    </CommonSubText>
                    <DetailedProjectDatatable.Text>
                        { borrower.registerNumber }
                    </DetailedProjectDatatable.Text>
                </DetailedProjectDatatable.Row>
                <DetailedProjectDatatable.Row>
                    <CommonSubText>
                        { t("detailed_project.borrower_info.registration_date") }
                    </CommonSubText>
                    <DetailedProjectDatatable.Text>
                        { formatDate(borrower.registerDate) }
                    </DetailedProjectDatatable.Text>
                </DetailedProjectDatatable.Row>
                <DetailedProjectDatatable.Row>
                    <CommonSubText>
                        { t("detailed_project.borrower_info.address") }
                    </CommonSubText>
                    <DetailedProjectDatatable.Text>
                        { borrower.address }
                    </DetailedProjectDatatable.Text>
                </DetailedProjectDatatable.Row>
                <DetailedProjectDatatable.Row>
                    <CommonSubText>
                        { t("detailed_project.borrower_info.okved") }
                    </CommonSubText>
                    <DetailedProjectDatatable.Text>
                        { borrower.businessForm }
                    </DetailedProjectDatatable.Text>
                </DetailedProjectDatatable.Row>
                <DetailedProjectDatatable.Row>
                    <CommonSubText>
                        { t("detailed_project.borrower_info.description") }
                    </CommonSubText>
                    <DetailedProjectDatatable.Text>
                        { borrower.description ?? t("detailed_project.borrower_info.not_listed") }
                    </DetailedProjectDatatable.Text>
                </DetailedProjectDatatable.Row>
                <DetailedProjectDatatable.Row>
                    <CommonSubText>
                        { t("detailed_project.borrower_info.links") }
                    </CommonSubText>
                    <DetailedProjectDatatable.Text>
                        { renderCompanySiteUrls() }
                    </DetailedProjectDatatable.Text>
                </DetailedProjectDatatable.Row>
            </DetailedProjectDatatable>
        </DetailedProjectSubBlock>
    );
}

export default DetailedProjectBorrowerInfo;