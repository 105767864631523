import React from "react";
import {LayoutBorrower, MainLayout} from "../../layouts";
import {Outlet} from "react-router-dom";

const BorrowerDashboard = () => {
    return (
        <MainLayout>
            <LayoutBorrower>
                <Outlet />
            </LayoutBorrower>
        </MainLayout>
    );
}

export default BorrowerDashboard;