import React, {FC} from "react";
import Tabs from "../../../../layout/common/Tabs";
import {AsLink, Button} from "../../../../common";
import {ObjectValues, resultIf} from "../../../../../utils";
import styles from "./AnalyticsPenaltiesNavigation.module.scss";
import {useTranslator} from "../../../../../hooks";

export const PENALTIES_NAVIGATION_PAGE = {
    NUMBER: "Number",
    SUM: "Sum",
    PERCENT: "Percent",
} as const;

export type PenaltiesNavigationPage = ObjectValues<typeof PENALTIES_NAVIGATION_PAGE>;

interface Props {
    current: PenaltiesNavigationPage;
    setCurrent: (page: PenaltiesNavigationPage) => void;
}

const AnalyticsPenaltiesNavigation: FC<Props> = ({ current, setCurrent }) => {
    const t = useTranslator();
    return (
        <div className={styles.analytics_penalties_navigation}>
            <Tabs>
                <AsLink>
                    <Button
                        className={resultIf(current === PENALTIES_NAVIGATION_PAGE.NUMBER, "active")}
                        onClick={() => setCurrent(PENALTIES_NAVIGATION_PAGE.NUMBER)}
                    >
                        { t("analytics.user.penalties.navigation.number") }
                    </Button>
                </AsLink>
                <AsLink>
                    <Button
                        className={resultIf(current === PENALTIES_NAVIGATION_PAGE.SUM, "active")}
                        onClick={() => setCurrent(PENALTIES_NAVIGATION_PAGE.SUM)}
                    >
                        { t("analytics.user.penalties.navigation.sum") }
                    </Button>
                </AsLink>
                <AsLink>
                    <Button
                        className={resultIf(current === PENALTIES_NAVIGATION_PAGE.PERCENT, "active")}
                        onClick={() => setCurrent(PENALTIES_NAVIGATION_PAGE.PERCENT)}
                    >
                        { t("analytics.user.penalties.navigation.percent") }
                    </Button>
                </AsLink>
            </Tabs>
        </div>
    );
}

export default AnalyticsPenaltiesNavigation;