import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {KonturFocusRequisitesProvider} from "../KonturFocusRequisitesProvider";

export class KonturFocusRequisitesProviderFactory {
    private readonly apiClientFactory: ApiClientFactoryInterface;

    constructor(baseUrl: string, headers: Headers) {
        this.apiClientFactory = new ApiClientFactory(
            `${baseUrl}/api/v1/kontur-focus`,
            headers
        )
    }

    public createKonturFocusRequisitesProvider(): KonturFocusRequisitesProvider {
        return new KonturFocusRequisitesProvider(this.apiClientFactory.createClient());
    }
}