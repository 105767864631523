import React, {FC} from "react";
import './DetailedProject.scoped.scss';
import {AsLink, Button, CommonSubText, SecondaryButton} from "../../../common";
import {SecondaryButtonColor} from "../../../common/buttons/decorators/SecondaryButton/SecondaryButton";
import {ArrowLeft} from "../../../svg";
import {Heading, PrimaryHeading} from "../../../common/typography/headings";
import {HeadingType} from "../../../common/typography/headings/Heading/Heading";
import {Outlet, useNavigate} from "react-router-dom";
import {DetailedProjectInvest} from "../index";
import DetailedProjectNavigation from "../layout/DetailedProjectNavigation/DetailedProjectNavigation";
import {useNavigateByName, useTranslator} from "../../../../hooks";
import {DetailedProject as DetailedProjectModel} from "../../../../api-client/models/project/DetailedProject";
import DetailedProjectContextProvider, {DetailedProjectContextType} from "../DetailedProjectContext";
import {RouteDictionary} from "../../../../utils";
import {DynamicTranslation} from "../../../language";
import DetailedProjectPageResolver from "../DetailedProjectPageResolver";
import {Investment} from "../../../../api-client";

interface Props {
    project: DetailedProjectModel;
    investment?: Investment;
}

const DetailedProject: FC<Props> = ({ project, investment }) => {
    const t = useTranslator();
    const navigate = useNavigate();
    const value: DetailedProjectContextType = {
        project,
        investment
    }
    const goBack = () => {
        navigate(-1);
    }
    return (
        <DetailedProjectContextProvider props={value}>
            <div className="detailed-project">
                <div className="detailed-project__grid">
                    <div className="detailed-project__main">
                        <div className="detailed-project__back">
                            <AsLink>
                                <SecondaryButton color={SecondaryButtonColor.GRAY}>
                                    <Button onClick={goBack}>
                                        <Button.Inner>
                                            <ArrowLeft color="#000" />
                                            <span>
                                                { t("common.back") }
                                            </span>
                                        </Button.Inner>
                                    </Button>
                                </SecondaryButton>
                            </AsLink>
                        </div>
                        { investment !== undefined && <CommonSubText small className="detailed-project__investment_id">
                            ID { investment.uuid.toUpperCase() }
                        </CommonSubText> }
                        <div className="detailed-project__heading">
                            <PrimaryHeading>
                                <Heading headingType={HeadingType.H1} className="detailed-project__header">
                                    <DynamicTranslation translations={ project.general.name } />
                                </Heading>
                            </PrimaryHeading>
                        </div>
                        <DetailedProjectNavigation />
                        <DetailedProjectPageResolver />
                        <Outlet />
                    </div>
                    <DetailedProjectInvest />
                </div>
            </div>
        </DetailedProjectContextProvider>
    );
}

export default DetailedProject;