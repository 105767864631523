import React, {AnchorHTMLAttributes, FC, PropsWithChildren} from "react";
import {cn} from "../../../../utils";
import './CommonLink.scoped.scss';

export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {}

const CommonLink: FC<PropsWithChildren<LinkProps>> = ({ children, ...props }) => {
    return (
        <a {...props} className={cn("common-link", props.className)}>
            { children }
        </a>
    );
}

export default CommonLink;
