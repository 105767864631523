import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {AnalyticsProvider} from "../AnalyticsProvider";

export class AnalyticsProviderFactory {
    private readonly apiClientFactory: ApiClientFactoryInterface;

    constructor(baseUrl: string, headers: Headers) {
        this.apiClientFactory = new ApiClientFactory(
            `${baseUrl}/api/v1/analytics`,
            headers
        )
    }

    public createAnalyticsProvider(authToken: string): AnalyticsProvider {
        return new AnalyticsProvider(this.apiClientFactory.createAuthorizedClient(authToken));
    }
}