import React, {createContext, PropsWithChildren} from "react";
import {DetailedProject, Investment} from "../../../api-client";

export interface DetailedProjectContextType {
    project: DetailedProject;
    investment?: Investment;
}

type Props = {
    props: DetailedProjectContextType;
}

//@ts-ignore
export const DetailedProjectContext = createContext<DetailedProjectContextType>();

function DetailedProjectContextProvider(props: PropsWithChildren<Props>) {
    const {children, ...value} = props;
    return <DetailedProjectContext.Provider value={value.props}>
        { children }
    </DetailedProjectContext.Provider>
}

export default DetailedProjectContextProvider;