import {Translations} from "../../types";

export const selling_confirmation: Translations = {
    "selling_confirmation": "Վաճառել վարկ ID :id",
    "selling_confirmation.commission": "Վաճառքի միջնորդավճար - :amount% վարկի գումարի.",
    "selling_confirmation.paid": "Վճարված",
    "selling_confirmation.amount": "Վճարման գումարը",
    "selling_confirmation.policy_agree": "Համաձայն եմ ",
    "selling_confirmation.policy_agree_link": "վարկի վաճառքի քաղաքականություն",
    "selling_confirmation.investment.sum": "Ներդրումների գումարը",
    "selling_confirmation.investment.rate": "Հայտ",
    "selling_confirmation.investment.income": "Եկամուտ",
    "selling_confirmation.investment.term": "Ժամկետ",
    "selling_confirmation.investment.earn_without_commission": "Հանեցված միջնորդավճարով դուք կստանաք",
    "selling_confirmation.sell": "վաճառքի հանել",
}