import React from "react";
import {Image} from "../../images";
import qrCodeImage from "../../../../assets/images/QR.svg";

const QrCode = () => {
    //TODO: ADD CREATING QR CODE LOGIC
    return (
        <Image src={qrCodeImage} />
    );
}

export default QrCode;