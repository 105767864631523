import React, {FC, PropsWithChildren} from "react";
import "./SecondaryNavigationLink.scoped.scss";

const SecondaryNavigationLink: FC<PropsWithChildren> = ({ children }) => {
    return (
        <li className="secondary-navigation-link">
            { children }
        </li>
    );
}

export default SecondaryNavigationLink;