import * as React from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import "./assets/scss/app.scss";
import {Provider} from "react-redux";
import store from "./store/store";
import {AuthProtectedRouteGroup} from "./components/routes/groups";
import {AuthRoutes} from "./components/routes/auth";
import BorrowerRoutes from "./components/routes/borrower/BorrowerRoutes";
import LenderRoutes from "./components/routes/lender/LenderRoutes";
import IndexPage from "./views/IndexPage/IndexPage";
import GuarantorRoutes from "./components/routes/guarantor/GuarantorRoutes";
import InitialLayout from "./layouts/InitialLayout";

const root = createRoot(
    document.getElementById('root') as HTMLElement
);

const Root = () => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route element={<InitialLayout />}>
                        <Route element={<AuthProtectedRouteGroup />}>
                            <Route path="borrower/*" element={<BorrowerRoutes />} />
                            <Route path="lender/*" element={<LenderRoutes />} />
                        </Route>
                        <Route path="auth/*" element={<AuthRoutes />} />
                        <Route path="guarantor/*" element={<GuarantorRoutes />} />
                        <Route path="/" element={<IndexPage />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </Provider>
    );
}

root.render(<Root />);
