import React, {FC} from "react";
import {AnalyticsLoan as AnalyticsLoanModel} from "../../../../../../api-client";
import styles from "./AnalyticsLoan.module.scss";
import {Money} from "../../../../../common";
import {useTranslator} from "../../../../../../hooks";
import {getMonthTranslation} from "../../../../../../i18n";

interface Props {
    loan: AnalyticsLoanModel
}

const AnalyticsLoan: FC<Props> = ({ loan }) => {
    const t = useTranslator();
    return (
        <li className={styles.analytics_loan}>
            <div>
                { t(getMonthTranslation(loan.date)) }
            </div>
            <div>
                <Money money={loan.totalSum} />
            </div>
            <div>
                { loan.countBorrowers }
            </div>
            <div>
                { Math.floor(loan.averageInterestRate) }%
            </div>
            <div>
                { Math.floor(loan.averageInitialTerm) }%
            </div>
            <div>
                <Money money={loan.averageSum} />
            </div>
        </li>
    );
}

export default AnalyticsLoan;