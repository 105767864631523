import React, {FC, InputHTMLAttributes, ReactNode, useId} from "react";
import "./CommonInput.scoped.scss";
import {cn, resultIf} from "../../../../utils";
import {InputLabel} from "../../form";
import {Loader} from "../../utils";
import {LOADER_COLOR} from "../../../loading/Loader/Loader";

export interface CommonInputProps extends InputHTMLAttributes<HTMLInputElement> {
    value: any;
    onChange: (value: any) => void;
    text: ReactNode;
    error: boolean;
    disabled?: boolean;
    loading?: boolean;
}

const CommonInput: FC<CommonInputProps> = (
    {
        value,
        onChange,
        text,
        error,
        disabled,
        loading = false,
        ...props
    }
) => {
    const onInput = (event: any) => {
        onChange(event.target.value);
    };
    const id = useId();
    return (
        <div className="common-input__container">
            <InputLabel id={id} text={text} />
            <div className="common-input__inner">
                <input
                    {...props}
                    id={id}
                    className={cn(
                        "common-input",
                        props.className,
                        resultIf(error, "common-input--error"),
                        resultIf(disabled === true, "common-input--disabled")
                    )}
                    value={value}
                    onChange={onInput}
                    disabled={disabled}
                />
                { loading && <div className="common-input__loader">
                    <Loader small color={LOADER_COLOR.DARK} />
                </div> }
            </div>
        </div>
    );
}

export default CommonInput;