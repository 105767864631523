import React, {useEffect, useState} from "react";
import './RedirectAfterAuth.scoped.scss';
import {useAppDispatch, useEffectOnUpdate, useNavigateByName, useProfileData} from "../../../hooks";
import {createAuthTokenManager, createProfileManager} from "../../../di";
import {RouteDictionary} from "../../../utils";
import {set} from "../../../store/profile";
import {Loader} from "../../../components/common";

const RedirectAfterAuth = () => {
    const navigate = useNavigateByName();
    const profileData = useProfileData();
    const [credentialValidated, setCredentialValidated] = useState(false);
    const dispatch = useAppDispatch();
    useEffect(() => {
        const checkToken = async () => {
            const tokenExists = await createAuthTokenManager().has();
            if (!tokenExists) {
                navigate(RouteDictionary.LOGIN);
                return;
            }
        }
        (async () => {
            await checkToken();
            if (!profileData.uuid) {
                await initProfile();
            }
        })()
    }, []);

    const initProfile = async () => {
        const manager = await createProfileManager();
        const profile = await manager.get();
        await dispatch(set(profile));
    }

    useEffectOnUpdate(() => {
        (async () => {
            if (profileData.isBorrower && !profileData.phoneVerified) {
                await navigate(RouteDictionary.CONFIRM_PHONE);
                return;
            } else if (profileData.isLender && !profileData.emailVerified) {
                await navigate(RouteDictionary.CONFIRM_EMAIL);
                return;
            }
            setCredentialValidated(true);
        })();
    }, [profileData.uuid]);

    useEffectOnUpdate(() => {
        if (credentialValidated) {
            if (profileData.isBorrower) {
                resolveBorrower();
                return;
            } else if (profileData.isLender) {
                resolveLender();
                return;
            }
        }
    }, [credentialValidated]);

    const resolveLender = () => {
        if (!profileData.lenderVerified) {
            navigate(RouteDictionary.IDENTIFICATION);
            return;
        }
        navigate(RouteDictionary.MARKET);
    }

    const resolveBorrower = () => {
        if (!profileData.hasCompletedIntroduction) {
            navigate(RouteDictionary.INTRODUCTION);
            return;
        }
        navigate(RouteDictionary.MY_PROJECTS);
    }

    return (
        <div className="redirect-after-auth">
            <Loader />
        </div>
    );
}

export default RedirectAfterAuth;