import React, {FC, PropsWithChildren} from "react";
import "./LoaderContainer.scoped.scss";

const LoaderContainer: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="loader-container">
            { children }
        </div>
    );
}

export default LoaderContainer;