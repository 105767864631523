import React from "react";
import {SvgComponent} from "./SvgComponent";
import SvgContainer from "./SvgContainer";
import {SvgContainerSize} from "./SvgContainer/SvgContainer";
import {defaultSvgColor} from "./SvgProps";

const InformationSvg: SvgComponent = ({ color= defaultSvgColor }) => {
    return <SvgContainer size={SvgContainerSize.Small}>
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1575_36752)">
                <circle cx="8.5" cy="8" r="7" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
                <path d="M8.5 12L8.5 8" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
                <path d="M8.5 4.5L8.5 5.5" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
            </g>
            <defs>
                <clipPath id="clip0_1575_36752">
                    <rect width="16" height="16" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>

    </SvgContainer>
}

export default InformationSvg;