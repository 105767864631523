import React, {AllHTMLAttributes, FC, PropsWithChildren, useContext} from "react";
import './DropdownContent.scoped.scss';
import {cn, resultIf} from "../../../../../../utils";
import {DropdownContext} from "../DropdownContext";

interface Props extends AllHTMLAttributes<HTMLDivElement> {}

const DropdownContent: FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
    const context = useContext(DropdownContext);
    return (
        <div
            { ...props }
            className={cn(
                "dropdown__content",
                resultIf(context.active, "active"),
                props.className
            )}
        >
            { children }
        </div>
    );
}

export default DropdownContent;