import React from "react";
import "./IdentificationStatus.scoped.scss";
import {Heading, PrimaryHeading} from "../../../common";
import {HeadingType} from "../../../common/typography/headings/Heading/Heading";
import {useProfileData, useTranslator} from "../../../../hooks";

const IdentificationStatus = () => {
    const t = useTranslator();
    const profileData = useProfileData();
    return (
        <div className="identification-status">
            <div>
                <PrimaryHeading>
                    <Heading headingType={HeadingType.H1} className="identification-status__heading">
                        { t("identification.your_status") }
                    </Heading>
                </PrimaryHeading>
                <div className="identification-status__text">
                    { profileData.hasIdentificationDraft && t("identification.draft") }
                </div>
            </div>
        </div>
    );
}

export default IdentificationStatus;