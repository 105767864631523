import React, {useContext, useMemo, useState} from "react";
import "./FundingRequestOptions.scoped.scss";
import {Button, Heading, InputErrors, Loader, PrimaryButton, PrimaryHeading, TertiaryHeading} from "../../../common";
import {HeadingType} from "../../../common/typography/headings/Heading/Heading";
import {CommonInput} from "../../../common/inputs";
import {DesiredLoan, Money as MoneyModel, PaymentSchedule, ProjectStatus} from "../../../../api-client";
import InputMoney from "../../../common/inputs/InputMoney";
import {
    useAppDispatch,
    useEffectOnUpdate,
    useErrorCallback,
    useModalState,
    useProfileData,
    useTranslator
} from "../../../../hooks";
import {Errors as ValidationErrors} from "../../../../utils";
import {PrimaryButtonColor} from "../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import FundingRequestConfirmModal from "../../../modals/FundingRequestConfirmModal";
import {createMoneyFactory, createProjectRequestManager} from "../../../../di";
import {set} from "../../../../store/overlay";
import {FundingRequestContext, FundingRequestStep} from "../FundingRequestContext";
import PaymentsSchedule from "../../../payment-schedule/PaymentsSchedule";
import FundingRequestLoanConditions from "../FundingRequestLoanConditions";
import useDebounce from "../../../../hooks/useDebounce";
import {formatApiDate} from "../../../../formatters";
import {CURRENT_CURRENCY_SYMBOL} from "../../../../configs";

interface OptionsInput {
    sum: MoneyModel;
    initialTerm: string;
    code: string;
}

type FormErrors = {
    sum?: ValidationErrors;
    maturityDate?: ValidationErrors;
    code?: ValidationErrors;
}

const moneyFactory = createMoneyFactory();

const loanOptionsLimits = {
    minSum: 5000000,
    maxSum: 10000000000,
    maxTerm: 16
}

const FundingRequestOptions = () => {
    const t = useTranslator();
    const profileData = useProfileData();
    const handleError = useErrorCallback();
    const dispatch = useAppDispatch();
    const { setStep, request, setRequest } = useContext(FundingRequestContext);
    const [sum, setSum] = useState(request?.desiredMoney ?? moneyFactory.createMoney());
    const [initialTerm, setInitialTerm] = useState(request?.initialTerm?.toString() ?? "");
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [modalOpened, setModalOpened] = useModalState(false);
    const [initialInput, setInitialInput] = useState(true);
    const [errors, setErrors] = useState<FormErrors>({});
    const [schedule, setSchedule] = useState<PaymentSchedule | null>(null);

    const optionsInput = useMemo((): OptionsInput => {
        return {
            sum,
            initialTerm,
            code
        }
    }, [sum, initialTerm, code]);

    const loanInput = useMemo((): DesiredLoan => {
        const date = new Date();
        date.setMonth(date.getMonth() + parseInt(optionsInput.initialTerm));
        return {
            sum: optionsInput.sum,
            initialTerm: parseInt(optionsInput.initialTerm),
            maturityDate: formatApiDate(date)
        };
    }, [optionsInput]);

    const debouncedLoanInput = useDebounce(loanInput);

    useEffectOnUpdate(() => {
        const abortController = new AbortController();
        (async () => {
            if (validateInput()) {
                setLoading(true);
                try {
                    const manager = await createProjectRequestManager(abortController.signal);
                    const schedule = await manager.getPreliminaryPaymentSchedule(request!.uuid, loanInput);
                    setSchedule(schedule);
                } catch (error: any) {
                    await handleError(error);
                } finally {
                    setLoading(false);
                }
            }
        })();
        return () => {
            abortController.abort();
            setSchedule(null);
        }
    }, [debouncedLoanInput]);

    const validateInput = (): boolean => {
        const errors: FormErrors = {};
        if (sum.amount < loanOptionsLimits.minSum) {
            errors.sum = [t(
                "errors.field_less_than",
                { field: t("errors.fields.sum"), type: t("errors.field_must.feminine"), amount: "50 000" }
            )];
        } else if (sum.amount > loanOptionsLimits.maxSum) {
            errors.sum = [t(
                "errors.field_more_than",
                { field: t("errors.fields.sum"), type: t("errors.field_must.feminine"), amount: "100 00 00" }
            )];
        }
        if (isNaN(parseInt(initialTerm))) {
            errors.maturityDate = [t(
                "errors.incorrect_input",
                { field: t("errors.fields.term"), type: t("errors.input.masculine") }
            )];
        }
        setErrors(errors);
        return !hasErrors(errors);
    }

    const hasErrors = (errorsToCheck = errors) => {
        return Object.keys(errorsToCheck).length !== 0;
    }

    useEffectOnUpdate(() => {
        validateInput();
        setInitialInput(false);
        setLoading(true);
    }, [optionsInput]);

    const handleSubmit = async () => {
        setModalOpened(true);
    }

    const handleSuccess = async () => {
        setLoading(true);
        try {
            const manager = await createProjectRequestManager();
            const date = new Date();
            date.setMonth(date.getMonth() + parseInt(initialTerm));
            await manager.setLoan(request!.uuid, {
                sum,
                initialTerm: parseInt(initialTerm),
                maturityDate: formatApiDate(date)

            });
            setRequest({
                ...request,
                uuid: request!.uuid,
                financialStatements: request!.financialStatements,
                confirmed: true,
                projectStatus: ProjectStatus.FINAL_REVIEW,
                approvedSum: request!.approvedSum,
                desiredMoney: sum,
                initialTerm: parseInt(initialTerm),
                companyUuid: request?.companyUuid
            });
            setModalOpened(false);
            dispatch(set(false));
            setStep(FundingRequestStep.SignDocuments);
        } catch (error: any) {
            await handleError(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="funding-request-options">
            <PrimaryHeading>
                <Heading headingType={HeadingType.H1} className="funding-request-options__heading">
                    { t("funding_request.loan_options") }
                </Heading>
            </PrimaryHeading>
            <div className="funding-request-options__container">
                <div>
                    <div className="funding-request-options__block">
                        <div className="funding-request-options__full">
                            <InputMoney
                                money={sum}
                                onMoneyChanged={setSum}
                                text={t(
                                    "funding_request.loan_options.sum",
                                    {
                                        currency: CURRENT_CURRENCY_SYMBOL(),
                                        min_amount: `${loanOptionsLimits.minSum / 100}`,
                                        max_amount: `${loanOptionsLimits.maxSum / 100}`
                                    }
                                )}
                                error={errors.sum !== undefined}
                                disabled={request!.desiredMoney !== undefined}
                            />
                            { errors.sum && <InputErrors errors={errors.sum} /> }
                        </div>
                        <div>
                            <CommonInput
                                value={initialTerm}
                                type="number"
                                onChange={setInitialTerm}
                                id="maturity-date"
                                text={t(
                                    "funding_request.loan_options.term",
                                    { term: `${loanOptionsLimits.maxTerm}` }
                                )}
                                error={errors.maturityDate !== undefined}
                                disabled={request!.desiredMoney !== undefined}
                            />
                            { errors.maturityDate && <InputErrors errors={errors.maturityDate} /> }
                        </div>
                        <div>
                            <CommonInput
                                value={code}
                                onChange={setCode}
                                id="code"
                                text={t("funding_request.loan_options.promo")}
                                error={errors.code !== undefined}
                                disabled={request!.desiredMoney !== undefined}
                            />
                        </div>
                    </div>
                    <div>
                        { request!.desiredMoney === undefined && <PrimaryButton expanded color={hasErrors() || initialInput ? PrimaryButtonColor.GRAY : PrimaryButtonColor.BLUE}>
                            <Button disabled={hasErrors() || initialInput} onClick={handleSubmit}>
                                { profileData.hasCompletedIntroduction
                                    ? t("funding_request.loan_options.send_request")
                                    : t("funding_request.loan_options.finish_registration") }
                            </Button>
                        </PrimaryButton> }
                    </div>
                </div>
                { !hasErrors() && !initialInput && <>
                    { loading ? <div className="funding-request-options__loader">
                        <Loader />
                    </div> : <>
                        <FundingRequestLoanConditions sum={sum} initialTerm={initialTerm} scheduleType={request?.scheduleType} />
                        { schedule !== null && <div>
                            <TertiaryHeading>
                                <Heading headingType={HeadingType.H3} className="funding-request-options__subheader">
                                    { t("funding_request.loan_options.schedule") }
                                </Heading>
                            </TertiaryHeading>
                            <PaymentsSchedule schedule={schedule} />
                        </div> }
                        <div className="funding-request-options__preliminary">
                            <div className="funding-request-options__info">
                                !
                            </div>
                            <div>
                                <div className="funding-request-options__preliminary-heading">
                                    { t("funding_request.loan_options.preliminary_warning") }
                                </div>
                                <div className="funding-request-options__preliminary-text">
                                    { t("funding_request.loan_options.preliminary_explanation") }
                                </div>
                            </div>
                        </div>
                    </> }
                </> }
            </div>
            <FundingRequestConfirmModal
                active={modalOpened}
                onClose={() => setModalOpened(false)}
                onSuccess={handleSuccess}
            />
        </div>
    );
}

export default FundingRequestOptions;