import {Translations} from "../../types";

export const my_detailed_project: Translations = {
    "my_detailed_project.placeholder": "No such project found",
    "my_detailed_project.schedule": "Payment schedule",
    "my_detailed_project.schedule.repayment": "Repayment schedule",
    "my_detailed_project.schedule.not_found": "Payout schedule not available",
    "my_detailed_project.schedule.preliminary": "Preliminary payment schedule",
    "my_detailed_project.schedule.date": "Date",
    "my_detailed_project.schedule.sum": "Amount of payment",
    "my_detailed_project.schedule.principal": "Principal debt",
    "my_detailed_project.schedule.percents": "Percents",
    "my_detailed_project.schedule.unexpected": "Unexpected project type",
    "my_detailed_project.aside.requested": "Requested amount",
    "my_detailed_project.aside.not_listed": "Not listed",
    "my_detailed_project.aside.not_listed.masculine": "Not listed",
    "my_detailed_project.aside.rate": "Rate",
    "my_detailed_project.aside.term": "Term",
    "my_detailed_project.aside.continue": "Continue filling",
    "my_detailed_project.aside.collected": "Paid out",
    "my_detailed_project.aside.sum": "Loan amount",
    "my_detailed_project.aside.payout_sum": "Payout amount",
    "my_detailed_project.aside.fundraising_collected": "Collected",
    "my_detailed_project.aside.target": "Target",
    "my_detailed_project.aside.make_payment": "Make a payment",
    "my_detailed_project.aside.tax": "Pay tax",
    "my_detailed_project.status.review": "Application under consideration",
    "my_detailed_project.status.review_approved": "Application approved by committee",
    "my_detailed_project.status.review_rejected_first": "Application rejected",
    "my_detailed_project.status.review_rejected_second": "At the moment, your application cannot be approved, as there " +
        "is not enough information in the statements on the current activities of the company.",
    "my_detailed_project.status.final_review": "Application under final review",
    "my_detailed_project.status.final_review_approved": "Application re-approved by the credit committee",
    "my_detailed_project.status.final_review_rejected": "The credit committee refused to issue a loan upon re-inspection",
    "my_detailed_project.status.fundraising": "The loan funds will be sent to your current account after the goal " +
        "is reached. The exact payment schedule will be formed after the funds are sent to your account.",
    "my_detailed_project.status.finished": "Collection completed",
    "my_detailed_project.status.unknown": "Unknown project status, contact administrator for clarification",

    "my_detailed_project.schedule.monthly": "Once a month",
    "my_detailed_project.schedule.monthly_twice": "Twice a month",
    "my_detailed_project.schedule.monthly_thrice": "Thrice a month",
    "my_detailed_project.schedule.monthly_four_times": "Four times a month",
    "my_detailed_project.schedule.quarterly": "Quarterly",
    "my_detailed_project.schedule.at_the_end": "At the end",
}