import React, {createContext, PropsWithChildren} from "react";
import {DetailedMyProject} from "../../../../api-client";

export interface MyProjectContextType {
    project: DetailedMyProject;
}

type Props = {
    props: MyProjectContextType;
}


// @ts-ignore
export const MyProjectContext = createContext<MyProjectContextType>({});

function MyProjectContextProvider(props: PropsWithChildren<Props>) {
    const {children, ...value} = props;
    return <MyProjectContext.Provider value={value.props}>
        { children }
    </MyProjectContext.Provider>
}

export default MyProjectContextProvider;