import React, {FC} from "react";
import {SellingOffer} from "../../../../../api-client";
import styles from "./SecondaryMarketOffer.module.scss";
import {useTranslator} from "../../../../../hooks";
import CommonOffer from "../../CommonOffer";

interface Props {
    offer: SellingOffer;
}

const SecondaryMarketOffer: FC<Props> = ({ offer }) => {
    const t = useTranslator();
    return (
        <CommonOffer className={styles.secondary_market_offer}>

        </CommonOffer>
    );
}

export default SecondaryMarketOffer;