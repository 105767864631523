import {Translations} from "../../types";

export const detailed_project: Translations = {
    "detailed_project.not_found": "Project is not found",
    "detailed_project.navigation.summary": "Summary",
    "detailed_project.navigation.analytics": "Analytics",
    "detailed_project.navigation.reputation": "Reputation",
    "detailed_project.navigation.documents": "Documents",
    "detailed_project.navigation.offers": "Offers",
    "detailed_project.invest.investment_created_at": "Created at",
    "detailed_project.invest.investment_sum": "Sum",
    "detailed_project.invest.investment_income": "Income",
    "detailed_project.invest.investment_cancel": "Cancel",
    "detailed_project.invest.investment_sell": "Sell",
    "detailed_project.invest.fundraising_warning": "Loan payments will begin after the loan is fully collected",
    "detailed_project.invest.rate": "Rate",
    "detailed_project.invest.collected": "Collected",
    "detailed_project.invest.target": "Target",
    "detailed_project.invest.paid": "Paid",
    "detailed_project.invest.paid_sum": "Payout amount",
    "detailed_project.invest.loan_sum": "Loan amount",
    "detailed_project.invest.number_of_offers": "Number of offers",
    "detailed_project.invest.term": "Term",
    "detailed_project.invest.buyback": "Buyback",
    "detailed_project.invest.buyback_explanation": "Сама того не зная, в процессе становления идеи и реализации проекта\n" +
        "принимала активное участие Гильдия Вольных Проектировщиков, за что " +
        "ей огромное спасибо. Комрады, вы реально лучшие.",
    "detailed_project.invest.easy_exit": "Easy exit",
    "detailed_project.invest.easy_exit_explanation": "123",
    "detailed_project.invest": "Invest",
    "detailed_project.invest.buy": "See offers",
    "detailed_project.placeholder": "Information not found.",
    "detailed_project.founder.share": "Share in the company",
    "detailed_project.founder.legal": "le",
    "detailed_project.founder.individual": "in",
    "detailed_project.founder.capital": "Authorized capital",
    "detailed_project.founder.registration_date": "Registration date",
    "detailed_project.founder.number": "Number",
    "detailed_project.founder.companies_number": "Number of companies",
    "detailed_project.general": "Loan options",
    "detailed_project.general.product": "Product",
    "detailed_project.general.rate": "Rate",
    "detailed_project.general.term": "Term",
    "detailed_project.general.percents": "Interest accrual",
    "detailed_project.general.on_remainder": "For the rest of the principal remainder",
    "detailed_project.general.frequency": "Payout frequency",
    "detailed_project.guarantors": "Guarantors",
    "detailed_project.guarantors.fio": "Full name",
    "detailed_project.borrower_info": "Borrower info",
    "detailed_project.borrower_info.name": "Name",
    "detailed_project.borrower_info.registration_date": "Registration date",
    "detailed_project.borrower_info.address": "Address",
    "detailed_project.borrower_info.okved": "OKVED",
    "detailed_project.borrower_info.description": "Description of activity",
    "detailed_project.borrower_info.not_listed": "Not listed",
    "detailed_project.borrower_info.links": "Site links",
    "detailed_project.borrower_info.founders": "Founders and members",
    "detailed_project.borrower_info.credit_turnover": "Credit turnover",
    "detailed_project.borrower_info.accounting.active": "Balance sheet (Asset)",
    "detailed_project.borrower_info.accounting.passive": "Balance sheet (Liability)",
    "detailed_project.borrower_info.accounting.tangible_non_assets": "Tangible non-current assets",
    "detailed_project.borrower_info.accounting.intangible_financial": "Intangible financial and other",
    "detailed_project.borrower_info.accounting.stocks": "Stocks",
    "detailed_project.borrower_info.accounting.financial_assets": "Financial and other current assets",
    "detailed_project.borrower_info.accounting.balance": "Asset balance",
    "detailed_project.borrower_info.accounting.capital_and_reserves": "Capital and reserves",
    "detailed_project.borrower_info.accounting.long_term_borrowings": "Long-term borrowings",
    "detailed_project.borrower_info.accounting.short_term_borrowings": "Short-term borrowings",
    "detailed_project.borrower_info.accounting.accounts_payable": "Accounts payable",
    "detailed_project.borrower_info.accounting.other_liabilities": "Other current liabilities",
    "detailed_project.borrower_info.accounting.balance_passive": "Liability balance",
    "detailed_project.borrower_info.accounting.period": "Period",
    "detailed_project.distribution": "Distribution of buyers' shares",
    "detailed_project.admissions": "Number of receipts to the current account",

    "detailed_project.reputation.credit_history": "Credit history",
    "detailed_project.reputation.no_delay": "There is no current delay",
    "detailed_project.reputation.debt_load": "Current debt load",
    "detailed_project.reputation.delays_30_days_12_months": "Number of delays up to 30 days in the last 12 months",
    "detailed_project.reputation.max_borrower_sum": "Max. loan amount successfully serviced by the borrower",
    "detailed_project.reputation.last_month_loans_number": "Number of loans and borrowings for the last month",
    "detailed_project.reputation.microcredits_3_months": "Microcredits for the last 3 months",

    "detailed_project.portfolio": "Credit portfolio",
    "detailed_project.portfolio.mortgage": "Mortgage",
    "detailed_project.portfolio.leasing": "Leasing",
    "detailed_project.portfolio.bank_credit": "Bank credit",
    "detailed_project.portfolio.crowdlending": "Crowdlending",

    "detailed_project.arbitrations": "Arbitrations",
    "detailed_project.arbitrations.role": "Role",
    "detailed_project.arbitrations.claimant": "Claimant",
    "detailed_project.arbitrations.defendant": "Defendant",
    "detailed_project.arbitrations.execution_proceedings": "Execution proceedings",
    "detailed_project.arbitrations.index": "Index",
    "detailed_project.arbitrations.historical": "Historical",

    "detailed_project.proceeding_history": "Production history",
    "detailed_project.proceeding_history.id": "Loan ID",
    "detailed_project.proceeding_history.status": "Status",
    "detailed_project.proceeding_history.date": "Issue date",
    "detailed_project.proceeding_history.sum": "Sum",
    "detailed_project.proceeding_history.rate": "Rate",
    "detailed_project.proceeding_history.term": "Term",
    "detailed_project.proceeding_history.remainder": "Principal remainder",
    "detailed_project.proceeding_history.delay_count": "Number of delays",
    "detailed_project.proceeding_history.max_delay": "Max. delay",

    "detailed_project.status.fundraising": "Fundraising",
    "detailed_project.status.finished": "Finished",
    "detailed_project.status.loan_payment": "Loan payment",
    "detailed_project.status.paid": "Paid",
    "detailed_project.status.refunded": "Refunded",

    "detailed_project.period.month": "1 month",
    "detailed_project.period.three_months": "3 months",
    "detailed_project.period.six_months": "6 months",
    "detailed_project.period.twelve_months": "12 months",
    "detailed_project.period.all_time": "All the time",

    "detailed_project.turnover_period.month": "for the last month",
    "detailed_project.turnover_period.three_months": "3 months on average",
    "detailed_project.turnover_period.six_months": "6 months on average",
    "detailed_project.turnover_period.twelve_months": "12 months on average",
    "detailed_project.turnover_period.all_time": "for all the time",

    "detailed_project.schedule.monthly": "Monthly",
    "detailed_project.schedule.monthly_twice": "2 times a month",
    "detailed_project.schedule.monthly_thrice": "3 times a month",
    "detailed_project.schedule.monthly_four_times": "4 times a month",
    "detailed_project.schedule.quarterly": "Quarterly",
    "detailed_project.schedule.at_the_end": "At the end",

    "detailed_project.invest.months.nominative": "month",
    "detailed_project.invest.months.genitive": "months",
    "detailed_project.invest.months.multiple": "months",

    "detailed_project.days.nominative": "day",
    "detailed_project.days.genitive": "days",
    "detailed_project.days.multiple": "days",

    "detailed_project.times.nominative": "time",
    "detailed_project.times.genitive": "times",
    "detailed_project.times.multiple": "times",
}