import {Translations} from "../../types";

export const word_case: Translations = {
    "word_case.days.nominative": "day",
    "word_case.days.genitive": "days",
    "word_case.days.multiple": "days",

    "word_case.months.nominative": "month",
    "word_case.months.genitive": "months",
    "word_case.months.multiple": "months",

    "word_case.times.nominative": "time",
    "word_case.times.genitive": "times",
    "word_case.times.multiple": "times",

    "word_case.offers.nominative": "offer",
    "word_case.offers.genitive": "offers",
    "word_case.offers.multiple": "offers",

    "word_case.loans.nominative": "loan",
    "word_case.loans.genitive": "loans",
    "word_case.loans.multiple": "loans",

    "word_case.loans-returned.nominative": "returned",
    "word_case.loans-returned.genitive": "returned",
    "word_case.loans-returned.multiple": "returned",
}