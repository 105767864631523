import React from "react";
import {Outlet, Route, Routes} from "react-router-dom";
import Register from "../../../views/auth/Register";
import Verification from "../../../views/auth/Verification";
import LoginAuth from "../../../views/auth/LoginAuth";
import Login from "../../../views/auth/Login";
import Logout from "../../../views/auth/Logout";
import RegisterAuth from "../../../views/auth/RegisterAuth";
import RedirectAfterAuth from "../../../views/auth/RedirectAfterAuth";
import ForgotPassword from "../../../views/auth/ForgotPassword";
import RegisterLender from "../../../views/auth/RegisterLender";
import RegisterBorrower from "../../../views/auth/RegisterBorrower";
import RegisterUserAuth from "../../../views/auth/RegisterUserAuth";

const AuthRoutes = () => {
    return (
        <>
            <Routes>
                <Route element={<LoginAuth />}>
                    <Route path="login" element={<Login />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                </Route>
                <Route element={<RegisterAuth />}>
                    <Route path="register" element={<Register />} />
                </Route>
                <Route element={<RegisterUserAuth />}>
                    <Route path="register-lender" element={<RegisterLender />} />
                    <Route path="register-borrower" element={<RegisterBorrower />} />
                </Route>
                <Route path="redirect-after-auth" element={<RedirectAfterAuth />} />
                <Route path="logout" element={<Logout />} />
                <Route path="verification/:code" element={<Verification />} />
            </Routes>
            <Outlet />
        </>
    );
}

export default AuthRoutes;