import React from "react";
import {AsLink, Button} from "../../../common";
import styles from "./AuthChatButton.module.scss";
import {ChatSvg} from "../../../svg";
import {ColorDictionary, getCssVariable} from "../../../../utils";
import {SvgContainerSize} from "../../../svg/SvgContainer/SvgContainer";

const AuthChatButton = () => {
    const openGuestChat = () => {
        console.log("GUEST CHAT OPENED")
    }
    return (
        <AsLink>
            <Button className={styles.auth_chat_button} onClick={openGuestChat}>
                <ChatSvg containerWidth={SvgContainerSize.Medium} color={getCssVariable(ColorDictionary.PRIMARY_DARK)} />
            </Button>
        </AsLink>
    );
}

export default AuthChatButton;