import React, {useContext, useMemo, useState} from "react";
import "./DetailedProjectNetCreditTurnovers.scoped.scss";
import {DropdownArrowImage, Heading, OptionBox, SecondaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import CurrentCurrency from "../../../../currencies/CurrentCurrency";
import {OptionBoxContext} from "../../../../common/selects/OptionBox/OptionBoxContext";
import {cn, resultIf} from "../../../../../utils";
import {DetailedProjectAnalyticsChart, DetailedProjectAnalyticsList, DetailedProjectSubBlock} from "../../index";
import {DetailedProjectContext} from "../../DetailedProjectContext";
import DetailedProjectPlaceholder from "../../common/DetailedProjectPlaceholder";
import {ChartSvg} from "../../../../svg";
import ListSvg from "../../../../svg/ListSvg";
import Tabs from "../../../../layout/common/Tabs";
import {useTranslator} from "../../../../../hooks";

enum AnalyticsMode {
    CHART = "CHART",
    LIST = "LIST"
}

const DetailedProjectNetCreditTurnovers = () => {
    const t = useTranslator();
    const { analytics } = useContext(DetailedProjectContext).project;
    if (analytics === undefined) {
        return <DetailedProjectPlaceholder />
    }
    const turnovers = analytics.netCreditTurnovers;
    const availableYears = turnovers.map((turnover) => {
        return new Date(turnover.year).getFullYear();
    });
    const [selectedMode, setSelectedMode] = useState(AnalyticsMode.LIST);
    const [selectedYear, setSelectedYear] = useState(availableYears[0]);

    const selectedTurnover = useMemo(() => {
        return turnovers.filter((turnover) => {
            return new Date(turnover.year).getFullYear() === selectedYear;
        })[0];
    }, [turnovers, selectedYear]);

    return (
        <DetailedProjectSubBlock>
            <div className="detailed-project-analytics__header">
                <SecondaryHeading smaller>
                    <Heading headingType={HeadingType.H2}>
                        { t("detailed_project.borrower_info.credit_turnover") }, &nbsp;<CurrentCurrency />
                    </Heading>
                </SecondaryHeading>
                <div className="detailed-project-analytics__options">
                    <OptionBox options={availableYears} selectedOption={selectedYear}>
                        <OptionBox.Head>
                            <OptionBoxContext.Consumer>
                                {({ active, selectedOption }) => <>
                                    <div className="year-select__header">
                                        <div className="year-select__heading">
                                            { selectedOption }
                                        </div>
                                        <DropdownArrowImage active={active} />
                                    </div>
                                </>
                                }
                            </OptionBoxContext.Consumer>
                        </OptionBox.Head>
                        <OptionBox.Content
                            onSelected={setSelectedYear}
                            className="year-select__content"
                        />
                    </OptionBox>
                    <Tabs>
                        <div
                            className={cn(
                                "detailed-project-analytics__option",
                                resultIf(selectedMode === AnalyticsMode.CHART, "active")
                            )}
                            onClick={() => setSelectedMode(AnalyticsMode.CHART)}
                        >
                            <ChartSvg />
                        </div>
                        <div
                            className={cn(
                                "detailed-project-analytics__option",
                                resultIf(selectedMode === AnalyticsMode.LIST, "active")
                            )}
                            onClick={() => setSelectedMode(AnalyticsMode.LIST)}
                        >
                            <ListSvg />
                        </div>
                    </Tabs>
                </div>
            </div>
            { selectedMode === AnalyticsMode.CHART && <DetailedProjectAnalyticsChart
                months={selectedTurnover.months}
            /> }
            { selectedMode === AnalyticsMode.LIST && <DetailedProjectAnalyticsList
                months={selectedTurnover.months}
                periods={selectedTurnover.periods}
            /> }
        </DetailedProjectSubBlock>
    );
}

export default DetailedProjectNetCreditTurnovers;