import React, {FC, useContext, useState} from "react";
import {Heading, Image, PrimaryHeading, TertiaryHeading} from "../../../common";
import {HeadingType} from "../../../common/typography/headings/Heading/Heading";
import './FundingRequestUpload.scoped.scss';
import FundingRequestFiles from "../FundingRequestFiles";
import acceptBlueIcon from "../../../../assets/images/icons/accept-blue-icon.svg";
import {useErrorCallback, useTranslator} from "../../../../hooks";
import {createProjectRequestManager} from "../../../../di";
import {FundingRequestContext} from "../FundingRequestContext";
import {ProjectStatus} from "../../../../api-client";
import LargeText from "../../../common/typography/LargeText";

interface Props {
    onUploaded?: (uuid: string) => void;
}

const FundingRequestUpload: FC<Props> = ({ onUploaded }) => {
    const t = useTranslator();
    const handleError = useErrorCallback();
    const { request, setRequest } = useContext(FundingRequestContext);
    const [loading, setLoading] = useState(false);
    const handleSubmit = async (financialStatements: Array<string>) => {
        setLoading(true);
        try {
            const manager = await createProjectRequestManager();
            const result = await manager.create(financialStatements);
            setRequest({
                uuid: result.uuid,
                financialStatements,
                confirmed: false,
                projectStatus: ProjectStatus.REVIEW
            });
            onUploaded && onUploaded(result.uuid);
        } catch (error: any) {
            await handleError(error);
        } finally {
            setLoading(false);
        }
    }
    return (
        <div className="funding-request-upload">
            <PrimaryHeading>
                <Heading headingType={HeadingType.H1} className="funding-request__heading">
                    { t("funding_request.upload.description") }
                </Heading>
            </PrimaryHeading>
            <div className="funding-request-upload__file">
                <FundingRequestFiles
                    urls={request === null ? [] : request.financialStatements}
                    onSubmit={handleSubmit}
                    loadingAllFiles={loading}
                />
            </div>
            <div className="funding-request-upload__requirements">
                <Heading headingType={HeadingType.H3} className="funding-request__subheader">
                    { t("funding_request.upload.requirements") }
                </Heading>
                <ul className="funding-request-upload__list">
                    <li className="funding-request-upload__requirement">
                        <Image src={acceptBlueIcon} />
                        <LargeText>
                            { t("funding_request.upload.requirements.first") }
                        </LargeText>
                    </li>
                    <li className="funding-request-upload__requirement">
                        <Image src={acceptBlueIcon} />
                        <LargeText>
                            { t("funding_request.upload.requirements.second") }
                        </LargeText>
                    </li>
                    <li className="funding-request-upload__requirement">
                        <Image src={acceptBlueIcon} />
                        <LargeText>
                            { t("funding_request.upload.requirements.third") }
                        </LargeText>
                    </li>
                </ul>
            </div>
            <div className="funding-request-upload__recommendations">
                <TertiaryHeading>
                    <Heading headingType={HeadingType.H3} className="funding-request__subheader">
                        { t("funding_request.upload.recommendations") }
                    </Heading>
                </TertiaryHeading>
                <LargeText className="funding-request__text">
                    { t("funding_request.upload.several_accounts") }
                </LargeText>
            </div>
        </div>
    );
}

export default FundingRequestUpload;