import {Translations} from "../../types";

export const date: Translations = {
    "date.january": "հունվար",
    "date.february": "փետրվար",
    "date.march": "փետրվար",
    "date.april": "ապրիլ",
    "date.may": "մայիս",
    "date.june": "հունիս",
    "date.july": "հուլիս",
    "date.august": "օգոստոս",
    "date.september": "սեպտեմբեր",
    "date.october": "հոկտեմբեր",
    "date.november": "նոյեմբեր",
    "date.december": "դեկտեմբեր",
}