import React from "react";
import {SvgComponent} from "../SvgComponent";
import {ColorDictionary, getCssVariable} from "../../../utils";
import SvgContainer from "../SvgContainer";
import {SvgContainerSize} from "../SvgContainer/SvgContainer";

const whiteColor = getCssVariable(ColorDictionary.WHITE);

const DepositSvg: SvgComponent = ({ color = whiteColor }) => {
    return (
        <SvgContainer size={SvgContainerSize.Small}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 1V11M1 6H11" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
            </svg>
        </SvgContainer>
    );
}

export default DepositSvg;