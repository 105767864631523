import React, {useContext, useMemo, useState} from "react";
import styles from "./MyInvestmentsLoanPayment.module.scss";
import commonStyles from "../../../../../views/lender/statistics/MyInvestments/MyInvestments.module.scss";
import {useErrorCallback, useTranslator} from "../../../../../hooks";
import {cn, DataCollection, LoanPaymentInvestmentsFilter, MyInvestmentsFilter} from "../../../../../utils";
import {LoanPaymentProjectInvestment, SortingDirection} from "../../../../../api-client";
import {createInvestmentManager} from "../../../../../di";
import LoanPaymentInvestment from "../LoanPaymentInvestment";
import {Pagination, PaginationContent, PaginationHeader, PaginationPaginator} from "../../../../pagination";
import {CommonSubText} from "../../../../common";
import SortingDirectionBlock from "../../../../common/utils/SortingDirectionBlock";
import {MyInvestmentsContext} from "../../../../../views/lender/statistics/MyInvestments/MyInvestmentsContext";

const MyInvestmentsLoanPayment = () => {
    const t = useTranslator();
    const filter = useContext(MyInvestmentsContext).filter;
    const [interestRateSorting, setInterestRateSorting] = useState<SortingDirection | undefined>(undefined);
    const [createdAtSorting, setCreatedAtSorting] = useState<SortingDirection | undefined>(undefined);
    const [loanAmountSorting, setLoanAmountSorting] = useState<SortingDirection | undefined>(undefined);
    const investmentsFilter = useMemo((): MyInvestmentsFilter => {
        return {
            ...filter,
            interestRateSorting,
            createdAtSorting,
            loanAmountSorting
        }
    }, [filter, interestRateSorting, createdAtSorting, loanAmountSorting]);
    const handleError = useErrorCallback();
    const [loading, setLoading] = useState(false);
    const [investments, setInvestments] =
        useState<DataCollection<LoanPaymentProjectInvestment>>(new DataCollection());

    const loadData = async (page: number, perPage: number, filter?: LoanPaymentInvestmentsFilter) => {
        setLoading(true);
        try {
            const manager = await createInvestmentManager();
            const collection = await manager.getListLoanPaymentOwned(page, perPage, filter);
            const investments = new DataCollection<LoanPaymentProjectInvestment>();
            investments.setCollection(collection);
            setInvestments(investments);
        } catch (error: any) {
            await handleError(error);
        } finally {
            setLoading(false);
        }
    }

    const renderInvestments = useMemo(() => {
        return investments.items.map((investment) => {
            return <LoanPaymentInvestment investment={investment} key={investment.project.uuid} />
        });
    }, [investments]);

    return (
        <div className={styles.loan_payment_investments}>
            <Pagination
                onLoad={loadData}
                count={investments.count}
                totalCount={investments.totalCount}
                filterObject={investmentsFilter}
            >
                <PaginationHeader className={cn(styles.loan_payment_investments__header, commonStyles.my_investments__header)}>
                    <CommonSubText small>
                        { t("my_investments.loan.company") }
                    </CommonSubText>
                    <SortingDirectionBlock
                        direction={investmentsFilter.interestRateSorting}
                        handleChange={setInterestRateSorting}
                    >
                        { t("my_investments.loan.rate") }
                    </SortingDirectionBlock>
                    <SortingDirectionBlock
                        direction={investmentsFilter.createdAtSorting}
                        handleChange={setCreatedAtSorting}
                    >
                        { t("my_investments.loan.date") }
                    </SortingDirectionBlock>
                    <CommonSubText small>
                        { t("my_investments.loan.return") }
                    </CommonSubText>
                    <SortingDirectionBlock
                        direction={investmentsFilter.loanAmountSorting}
                        handleChange={setLoanAmountSorting}
                    >
                        { t("my_investments.loan.sum") }
                    </SortingDirectionBlock>
                    <CommonSubText small>
                        { t("my_investments.loan.redeemed") }
                    </CommonSubText>
                </PaginationHeader>
                <PaginationContent loading={loading}>
                    <div className={commonStyles.my_investments__content}>
                        <ul className={commonStyles.my_investments__list}>
                            { renderInvestments }
                        </ul>
                    </div>
                </PaginationContent>
                <PaginationPaginator />
            </Pagination>
        </div>
    );
}

export default MyInvestmentsLoanPayment;