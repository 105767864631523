import React, {FC} from "react";
import "./Loader.scoped.scss";
import {cn, ObjectValues, resultIf} from "../../../utils";

export const LOADER_COLOR = {
    BLUE: "loader--blue",
    WHITE: "loader--white",
    DARK: "loader--dark",
} as const;

export type LoaderColor = ObjectValues<typeof LOADER_COLOR>;

interface Props {
    small?: boolean;
    color?: LoaderColor;
}

const Loader: FC<Props> = ({ small = false, color }) => {
    return (
        <div className={cn("loader", resultIf(small, "loader--small"), color)}></div>
    );
}

export default Loader