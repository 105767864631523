import React from "react";
import LanguageSelect from "../../common/utils/LanguageSelect";
import styles from "./BorrowerLanguageSelect.module.scss";

const BorrowerLanguageSelect = () => {
    return (
        <div className={styles.borrower_language_select}>
            <div className={styles.borrower_language_select__wrapper}>
                <LanguageSelect />
            </div>
        </div>
    );
}

export default BorrowerLanguageSelect;