import {Translations} from "../../types";

export const date: Translations = {
    "date.january": "January",
    "date.february": "February",
    "date.march": "March",
    "date.april": "April",
    "date.may": "May",
    "date.june": "June",
    "date.july": "July",
    "date.august": "August",
    "date.september": "September",
    "date.october": "October",
    "date.november": "November",
    "date.december": "December",
}