import React, {useMemo, useState} from "react";
import './Withdraw.scoped.scss';
import ContentContainer from "../../../../components/layout/common/ContentContainer";
import Section from "../../../../components/layout/common/Section";
import {
    Button,
    CommonSubText,
    CommonText,
    Heading, InputErrors,
    Money,
    PrimaryButton,
    PrimaryHeading
} from "../../../../components/common";
import {HeadingType} from "../../../../components/common/typography/headings/Heading/Heading";
import {CommonBlock} from "../../../../components/common/blocks";
import {
    useCurrentLanguage, useEffectOnUpdate,
    useErrorCallback,
    useNavigateByName,
    useProfileData,
    useTranslator
} from "../../../../hooks";
import {createMoneyFactory} from "../../../../di";
import InputMoney from "../../../../components/common/inputs/InputMoney";
import {Money as MoneyModel} from "../../../../api-client/models/common/Money";
import {countMoneyPercent, RouteDictionary} from "../../../../utils";
import {PrimaryButtonColor} from "../../../../components/common/buttons/decorators/PrimaryButton/PrimaryButton";
import {createWithdrawManager} from "../../../../di";
import {Errors as ValidationErrors} from "../../../../utils";
import {commissions, CURRENT_CURRENCY_SYMBOL} from "../../../../configs";

const factory = createMoneyFactory();

type WithdrawErrors = {
    amount?: ValidationErrors;
}

const Withdraw = () => {
    const t = useTranslator();
    const currentLanguage = useCurrentLanguage();
    const handleError = useErrorCallback();
    const profileData = useProfileData();
    const [withdrawAmount, setWithdrawAmount] = useState<MoneyModel>(factory.createMoney());
    const [errors, setErrors] = useState<WithdrawErrors>({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigateByName();

    const commissionAmount = useMemo(() => {
        return countMoneyPercent(withdrawAmount, commissions.withdraw);
    }, [withdrawAmount]);

    const validateRequest = (): boolean => {
        const newErrors: WithdrawErrors = {};
        if (withdrawAmount.amount === 0) {
            newErrors.amount = [t(
                "errors.field_less_than",
                { field: t("errors.fields.sum"), type: t("errors.field_must.feminine"), amount: "0" }
            )];
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    useEffectOnUpdate(() => {
        if (Object.keys(errors).length !== 0) {
            validateRequest();
        }
    }, [currentLanguage]);

    const handleSubmit = async () => {
        if (validateRequest()) {
            setLoading(true);
            try {
                const manager = await createWithdrawManager();
                await manager.create(withdrawAmount);
                await navigate(RouteDictionary.ANALYTICS);
            } catch (error: any) {
                await handleError(error);
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <div className="withdraw">
            <ContentContainer>
                <Section className="withdraw__section">
                    <PrimaryHeading>
                        <Heading headingType={HeadingType.H1}>
                            { t("withdraw") }
                        </Heading>
                    </PrimaryHeading>
                    <div className="withdraw__container">
                        <CommonBlock>
                            <CommonBlock.Section underscore={false}>
                                <CommonText large>
                                    { t("withdraw.balance") }: <Money money={profileData.balance!} />
                                </CommonText>
                                <div className="withdraw__input">
                                    <InputMoney
                                        money={withdrawAmount}
                                        onMoneyChanged={setWithdrawAmount}
                                        text={ t("withdraw.sum", { amount: "10000", currency: CURRENT_CURRENCY_SYMBOL() }) }
                                        error={false}
                                    />
                                    { errors.amount !== undefined && <InputErrors errors={errors.amount} /> }
                                </div>
                                <div style={{marginBottom: "1rem"}} className="investment-confirmation-main__result">
                                    <CommonSubText>
                                        { t("withdraw.commission") }
                                    </CommonSubText>
                                    <Money money={commissionAmount} withPennies />
                                </div>
                                <div>
                                    <PrimaryButton expanded color={PrimaryButtonColor.BLUE}>
                                        <Button onClick={handleSubmit} loading={loading}>
                                            { t("withdraw.submit") }
                                        </Button>
                                    </PrimaryButton>
                                </div>
                            </CommonBlock.Section>
                        </CommonBlock>
                    </div>
                </Section>
            </ContentContainer>
        </div>
    );
}

export default Withdraw;