import React, {FC, PropsWithChildren} from "react";
import {ProjectLink} from "../../../common";
import {cn, RouteDictionary} from "../../../../utils";
import styles from "./MyInvestmentLink.module.scss";
import {NavLinkProps} from "react-router-dom";

interface Props extends Omit<NavLinkProps, "to" | "className"> {
    uuid: string;
    investmentUuid: string;
    className?: string;
}

const MyInvestmentLink: FC<PropsWithChildren<Props>> = (
    {
        uuid,
        investmentUuid,
        children,
        ...props
    }) => {
    return (
        <ProjectLink
            {...props}
            to={RouteDictionary.DETAILED_PROJECT_INVESTMENT}
            params={{ uuid, investmentUuid }}
            className={cn(styles.my_investment_link, props.className)}
        >
            { children }
        </ProjectLink>
    );
}

export default MyInvestmentLink;