import React from "react";
import styles from "./AnalyticsLoanHeader.module.scss";
import {CommonSubText} from "../../../../../common";
import {useTranslator} from "../../../../../../hooks";

const AnalyticsLoanHeader = () => {
    const t = useTranslator();
    return (
        <div className={styles.analytics_loan_row_header}>
            <CommonSubText small>
                { t("analytics.user.volume.month") }
            </CommonSubText>
            <CommonSubText small>
                { t("analytics.user.volume.issued") }
            </CommonSubText>
            <CommonSubText small>
                { t("analytics.user.volume.borrower_number") }
            </CommonSubText>
            <CommonSubText small>
                { t("analytics.user.volume.average_rate") }
            </CommonSubText>
            <CommonSubText small>
                { t("analytics.user.volume.average_term") }
            </CommonSubText>
            <CommonSubText small>
                { t("analytics.user.volume.average_sum") }
            </CommonSubText>
        </div>
    )
}

export default AnalyticsLoanHeader;