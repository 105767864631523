import {Translations} from "../../types";

export const my_loans: Translations = {
    "my_loans": "My loans",
    "my_loans.navigation.active": "Active",
    "my_loans.navigation.fundraising": "Fundraising",
    "my_loans.navigation.returned": "Returned",
    "my_loans.navigation.moderated": "Moderated",
    "my_loans.new": "Create new request",
    "my_loans.id": "Loan ID",
    "my_loans.sum": "Sum",
    "my_loans.paid": "Paid",
    "my_loans.collected": "Collected",
    "my_loans.target": "Target",
    "my_loans.rate": "Rate",
    "my_loans.term": "Term",
    "my_loans.schedule": "Schedule",
    "my_loans.schedule_payments": "Payment schedule",
    "my_loans.nearest": "Nearest repayment",
    "my_loans.created_at": "Created ay",
    "my_loans.status": "Status",
    "my_loans.will_be_added": "Will be added",
    "my_loans.not_listed": "Not listed",
    "my_loans.not_listed.feminine": "Not listed",

    "my_loans.schedule.monthly": "Monthly",
    "my_loans.schedule.monthly_twice": "Twice a month",
    "my_loans.schedule.monthly_thrice": "Thrice a month",
    "my_loans.schedule.monthly_four_times": "Four times a month",
    "my_loans.schedule.monthly_quarterly": "Quarterly",
    "my_loans.schedule.monthly_at_the_end": "At the end",

    "my_loans.status.review": "First review",
    "my_loans.status.review_approved": "First review approved",
    "my_loans.status.review_rejected": "First review rejected",
    "my_loans.status.final_review": "Final review",
    "my_loans.status.final_review_approved": "Final review approved",
    "my_loans.status.final_review_rejected": "Final review rejected",
}