import React from "react";
import {useTranslator} from "../../../../../hooks";

const DetailedProjectPlaceholder = () => {
    const t = useTranslator();
    return (
        <div className="detailed-project__placeholder">
            { t("detailed_project.placeholder") }
        </div>
    );
}

export default DetailedProjectPlaceholder;