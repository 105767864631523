import {Headers} from "../types";
import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {WithdrawManager} from "../WithdrawManager";

export class WithdrawManagerFactory {
    private readonly apiClientFactory: ApiClientFactoryInterface;

    constructor(baseUrl: string, headers: Headers) {
        this.apiClientFactory = new ApiClientFactory(
            `${baseUrl}/api/v1/withdraw`,
            headers
        )
    }

    public createWithdrawManager(authToken: string): WithdrawManager {
        return new WithdrawManager(this.apiClientFactory.createAuthorizedClient(authToken));
    }
}