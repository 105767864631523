import React, {AllHTMLAttributes, FC} from "react";
import {cn, resultIf} from "../../../../utils";
import './Toggle.scoped.scss';

interface Props extends AllHTMLAttributes<HTMLDivElement>{
    active: boolean;
    onToggled: () => void;
}

const Toggle: FC<Props> = ({ active, onToggled, ...props }) => {
    return (
        <div
            { ...props }
            className={cn("toggle", resultIf(active, "active"), props.className)}
            onClick={onToggled}
        >
            <div className={cn("toggle__button")}>
            </div>
        </div>
    );
}

export default Toggle;