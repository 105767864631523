import {Translations} from "../../types";

export const analytics: Translations = {
    "analytics.navigation.platform": "Platform",
    "analytics.navigation.mkk": "MKK",
    "analytics.user": "Analytics",
    "analytics.user.error": "An error occurred, analytics not found",
    "analytics.user.navigation.volume": "Volume",
    "analytics.user.navigation.yield": "Yield",
    "analytics.user.navigation.defaults": "Defaults",
    "analytics.user.navigation.penalties": "Penalties",
    "analytics.user.period.twelve_months": "Last 12 months",
    "analytics.user.period.six_months": "Last 6 months",
    "analytics.user.period.three_months": "last 3 months",
    "analytics.user.period.year_only": "Only :year year",
    "analytics.user.period": "Period",
    "analytics.user.type": "Loan type",
    "analytics.user.type.buyback": "Buyback",
    "analytics.user.volume.month": "Month",
    "analytics.user.volume.issued": "Issued",
    "analytics.user.volume.borrower_number": "Number of borrowers",
    "analytics.user.volume.average_rate": "Average rate",
    "analytics.user.volume.average_term": "Average term",
    "analytics.user.volume.average_sum": "Average sum",
    "analytics.user.profitabilities.month": "Month",
    "analytics.user.profitabilities.irr": "IRR",
    "analytics.user.profitabilities.result": "Accumulated result",
    "analytics.user.penalties.navigation.number": "Number",
    "analytics.user.penalties.navigation.sum": "Sum",
    "analytics.user.penalties.navigation.percent": "Sum percent",
    "analytics.user.default.header.month": "Month",
    "analytics.user.default.header.all": "All",
    "analytics.user.default.header.returned": "Returned",
    "analytics.user.default.header.defaulted": "In default",
    "analytics.platform_statistics": "Statistics",
    "analytics.platform_statistics.issued_first": "Loans issued",
    "analytics.platform_statistics.issued_second": "on platform",
    "analytics.platform_statistics.number_of_loans": "Number of loans on platform",
    "analytics.platform_statistics.number_of_borrowers": "Number of borrowers",
    "analytics.platform_statistics.number_of_lenders": "Number of investors",
    "analytics.platform_statistics.paid_first": "Paid out",
    "analytics.platform_statistics.paid_second": "to investors",
    "analytics.platform_statistics.yield": "Yield for all time",

    "analytics.user_statistics": "Статистика",
    "analytics.user_statistics.issued": "Проектов инвестировано",
    "analytics.user_statistics.invested": "Инвестированная сумма",
    "analytics.user_statistics.number_of_borrowers": "Кол-во заемщиков",
    "analytics.user_statistics.paid_first": "Получено",
    "analytics.user_statistics.yield": "Доходность за все время",
}