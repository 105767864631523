import React from "react";
import {defaultSvgColor} from "../SvgProps";
import {SvgComponent} from "../SvgComponent";
import SvgContainer from "../SvgContainer";

const FinancesSvg: SvgComponent = ({ color = defaultSvgColor }) => {
    return (
        <SvgContainer>
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.9978 5.5332L16 8.43907C15.9787 8.7159 15.9513 8.99241 15.9178 9.26841C15.8616 9.72994 15.4716 10.1022 14.9818 10.1549C13.4477 10.3198 11.8602 10.3198 10.3261 10.1549C9.83631 10.1022 9.44627 9.72994 9.39016 9.26841C9.2069 7.76127 9.2069 6.23872 9.39016 4.73159C9.44627 4.27006 9.83631 3.89777 10.3261 3.84512C11.8602 3.68019 13.4477 3.68019 14.9818 3.84512C15.4716 3.89777 15.8616 4.27006 15.9178 4.73159C15.9502 4.99837 15.9769 5.26563 15.9978 5.5332ZM12.654 5.79686C11.9632 5.79686 11.4032 6.33552 11.4032 7C11.4032 7.66447 11.9632 8.20313 12.654 8.20313C13.3447 8.20313 13.9047 7.66447 13.9047 7C13.9047 6.33552 13.3447 5.79686 12.654 5.79686Z" fill={color} />
                <path d="M15.4921 2.21931C15.6217 2.43528 15.3784 2.67712 15.1207 2.64942C13.4943 2.47457 11.8136 2.47457 10.1872 2.64942C9.12873 2.76322 8.2727 3.56494 8.14784 4.59182C7.9533 6.19182 7.9533 7.80818 8.14784 9.40817C8.2727 10.4351 9.12873 11.2368 10.1872 11.3506C11.8136 11.5254 13.4943 11.5254 15.1207 11.3506C15.3801 11.3227 15.6254 11.5662 15.4945 11.7834C14.8453 12.8605 13.6878 13.6122 12.3428 13.7483L11.7992 13.8033C9.03844 14.0828 6.25384 14.0644 3.49732 13.7484L3.13714 13.7071C1.65111 13.5368 0.473665 12.42 0.275732 10.9931C-0.0919105 8.34277 -0.0919105 5.65723 0.275732 3.00691C0.473665 1.58001 1.65111 0.46322 3.13714 0.292884L3.49732 0.251599C6.25383 -0.0643654 9.03844 -0.0827935 11.7992 0.196658L12.3428 0.251679C13.6889 0.38794 14.8446 1.14079 15.4921 2.21931Z" fill={color} />
            </svg>
        </SvgContainer>
    );
}

export default FinancesSvg;