import React, {useState} from "react";
import './Paginator.scoped.scss';
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {cn, getQueryObject} from "../../../utils";
import buildQuery from "../../../utils/router/buildQuery";
import {PaginationContextType, usePaginationContext} from "../PaginationContext";
import {SecondaryButton} from "../../common";
import {SecondaryButtonColor} from "../../common/buttons/decorators/SecondaryButton/SecondaryButton";
import {ArrowLeft, ArrowRight} from "../../svg";
import InputNumber from "../../common/inputs/InputNumber";
import {useEffectOnUpdate, useTranslator} from "../../../hooks";

type PaginatorQuery = {
    [key: string]: number;
}

const Paginator = () => {
    const t = useTranslator();
    const context: PaginationContextType = usePaginationContext();
    const [page, setPage] = useState<number>(context.page);
    const [changed, setChanged] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const countPages = (): number => {
        return Math.ceil(context.totalCount / context.perPage);
    }

    useEffectOnUpdate(() => {
        setPage(context.page);
    }, [context.page]);

    const generatePageUrl = (pageNumber: number): string => {
        let url = `${location.pathname}`;
        const queryObject = getQueryObject<PaginatorQuery>(location.search);
        pageNumber === 1 ? delete queryObject[context.pageParam] : queryObject[context.pageParam] = pageNumber;
        const query = buildQuery(queryObject);
        url += `?${query}`;
        return url;
    }

    useEffectOnUpdate(() => {
        if (changed && page !== context.page) {
            const url = generatePageUrl(page);
            navigate(url);
        }
        return () => {
            setChanged(false);
        }
    }, [changed, page]);

    const parsePage = (value: string): void => {
        if (value === "") {
            setPage(1);
            return;
        }
        const parsed = parseInt(value);
        if (parsed > countPages()) {
            setPage(countPages());
            return;
        }
        if (!isNaN(parsed)) {
            setPage(parsed);
        }
    }

    const previousPageLink = () => {
        const isDisabled = context.page === 1;
        const previousPageUrl = isDisabled ? "#" : generatePageUrl(context.page - 1);
        return <SecondaryButton color={SecondaryButtonColor.GRAY}>
            <NavLink
                to={previousPageUrl}
                className={cn('previous-page-link', `${isDisabled ? "previous-page-link--disabled" : ""}`)}
            >
                <ArrowLeft />
                <span>
                    { t("pagination.paginator.prev") }.
                </span>
            </NavLink>
        </SecondaryButton>
    }

    const nextPageLink = () => {
        const isDisabled = context.page === countPages();
        const previousPageUrl = isDisabled ? "#" : generatePageUrl(context.page + 1);
        return <SecondaryButton color={SecondaryButtonColor.GRAY}>
            <NavLink
                to={previousPageUrl}
                className={cn('next-page-link', `${isDisabled ? "next-page-link--disabled" : ""}`)}
            >
                <span>
                    { t("pagination.paginator.next") }.
                </span>
                <ArrowRight />
            </NavLink>
        </SecondaryButton>
    }

    return (
        <div className="paginator__container">
            <div className="paginator">
                { previousPageLink() }
                <div className="paginator__pages">
                    <InputNumber
                        value={page}
                        onChange={(event) => parsePage((event.target as HTMLInputElement).value)}
                        className="paginator__input"
                        size={1}
                        onBlur={() => setChanged(true)}
                    />
                    <span>
                        { t("pagination.paginator.from") } {countPages()}
                    </span>
                </div>
                { nextPageLink() }
            </div>
        </div>
    );
}

const MemoizedPaginator = React.memo(Paginator);

export default MemoizedPaginator;