import React from "react";
import {DetailedProjectSubBlock} from "../../index";
import DetailedProjectDocument from "../DetailedProjectDocument";
import {File} from "../../../../../api-client";
import styles from "./DetailedProjectDocumentsList.module.scss";

// TODO: IMPLEMENT REAL PROJECT DOCUMENTS
const mockDocuments: Array<File> = [
    {
        name: "Договор займа",
        url: "https://youtube.com"
    },
    {
        name: "Договор поручительства",
        url: "https://youtube.com"
    }
];

const DetailedProjectDocumentsList = () => {
    const renderDocuments = () => {
        return mockDocuments.map((document) => {
            return <DetailedProjectDocument document={document} key={document.name} />
        })
    }
    return (
        <DetailedProjectSubBlock>
            <div className={styles.detailed_project_documents}>
                { renderDocuments() }
            </div>
        </DetailedProjectSubBlock>
    );
}

export default DetailedProjectDocumentsList;