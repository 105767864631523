import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {DayRepaymentProvider} from "../DayRepaymentProvider";

export class DayRepaymentProviderFactory {
    private readonly apiClientFactory: ApiClientFactoryInterface;

    constructor(baseUrl: string, headers: Headers) {
        this.apiClientFactory = new ApiClientFactory(
            `${baseUrl}/api/v1/day-repayment`,
            headers
        )
    }

    public createDayRepaymentProvider(authToken: string, signal: AbortSignal): DayRepaymentProvider {
        return new DayRepaymentProvider(this.apiClientFactory.createAuthorizedClient(authToken), signal);
    }
}