import React from "react";
import {useMyMoney, useTranslator} from "../../../../../hooks";
import styles from "../AnalyticsPlatformStatistics/AnalyticsPlatformStatistics.module.scss";
import {CommonBlock} from "../../../../common/blocks";
import {CommonSubText, CommonText, Heading, Loader, Money, TertiaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import {createMoneyFactory} from "../../../../../di";
import LoaderContainer from "../../../../loading/LoaderContainer";

const moneyFactory = createMoneyFactory();

const AnalyticsUserStatistics = () => {
    const { myMoney, loading } = useMyMoney();
    const t = useTranslator();
    if (loading) {
        return <LoaderContainer>
            <Loader />
        </LoaderContainer>
    }
    return (
        <section className={styles.analytics_platform_statistics}>
            <CommonBlock>
                <CommonBlock.Section underscore={false}>
                    <TertiaryHeading>
                        <Heading headingType={HeadingType.H2} className={styles.analytics_platform_statistics__header}>
                            { t("analytics.user_statistics") }
                        </Heading>
                    </TertiaryHeading>
                    <ul className={styles.analytics_platform_statistics__list}>
                        <li className={styles.analytics_platform_statistics__element}>
                            <div>
                                <CommonSubText>
                                    { t("analytics.user_statistics.issued") }
                                </CommonSubText>
                            </div>
                            <div className={styles.analytics_platform_statistics__text}>
                                { myMoney?.numberInvestedProjects ?? 0 }
                            </div>
                        </li>
                        <li className={styles.analytics_platform_statistics__element}>
                            <div>
                                <CommonSubText>
                                    { t("analytics.user_statistics.invested") }
                                </CommonSubText>
                            </div>
                            <div className={styles.analytics_platform_statistics__text}>
                                <Money money={myMoney?.investedSum ?? moneyFactory.createMoney()} />
                            </div>
                        </li>
                        <li className={styles.analytics_platform_statistics__element}>
                            <div>
                                <CommonSubText>
                                    { t("analytics.user_statistics.paid_first") }
                                </CommonSubText>
                            </div>
                            <div className={styles.analytics_platform_statistics__text}>
                                <Money money={myMoney?.earned ?? moneyFactory.createMoney()} />
                            </div>
                        </li>
                        <li className={styles.analytics_platform_statistics__element}>
                            <CommonSubText>
                                { t("analytics.user_statistics.yield") }
                            </CommonSubText>
                            <CommonText>
                                26%
                            </CommonText>
                        </li>
                    </ul>
                </CommonBlock.Section>
            </CommonBlock>
        </section>
    );
}

export default AnalyticsUserStatistics;