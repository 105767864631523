import React, {FC} from "react";
import {DetailedMyActiveProject} from "../../../../../../api-client";
import {CommonBlock} from "../../../../../common/blocks";
import CommonPropertiesList from "../../../../../common/blocks/CommonPropertiesList";
import MoneyProgress from "../../../../../progress/MoneyProgress";
import {Button, CommonSubText, CommonText, Money, PrimaryButton} from "../../../../../common";
import {myProjectScheduleTranslations} from "../MyProjectAside/MyProjectAside";
import {formatDate} from "../../../../../../formatters";
import styles from "./MyActiveProjectAside.module.scss";
import {PrimaryButtonColor} from "../../../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {useTranslator} from "../../../../../../hooks";
import {resolveMonthTranslation} from "../../../../../../i18n";

interface Props {
    project: DetailedMyActiveProject;
}

const MyActiveProjectAside: FC<Props> = ({ project }) => {
    const t = useTranslator();
    const startDate: Date = new Date(project.active.loan.maturityDate);
    const endDate: Date = new Date(startDate.setMonth(startDate.getMonth() + project.active.loan.initialTerm));
    return (
        <CommonBlock>
            <CommonBlock.Header>
                <MoneyProgress
                    firstPoint={{ name: t("my_detailed_project.aside.collected"), money: project.active.paidSum }}
                    secondPoint={{ name: t("my_detailed_project.aside.target"), money: project.active.loan.sum }}
                />
            </CommonBlock.Header>
            <CommonBlock.Section underscore={false}>
                <CommonPropertiesList className={styles.my_active_project_aside__list}>
                    <CommonPropertiesList.Element>
                        <CommonSubText>
                            { t("my_detailed_project.aside.sum") }
                        </CommonSubText>
                        <CommonText>
                            <Money money={project.active.loan.sum} />
                        </CommonText>
                    </CommonPropertiesList.Element>
                    <CommonPropertiesList.Element>
                        <CommonSubText>
                            { t("my_detailed_project.aside.rate") }
                        </CommonSubText>
                        <CommonText>
                            <span>{project.active.loan.interestRate}%</span>
                        </CommonText>
                    </CommonPropertiesList.Element>
                    <CommonPropertiesList.Element>
                        <CommonSubText>
                            { t("my_detailed_project.aside.term") }
                        </CommonSubText>
                        <div className={styles.my_active_project_aside__term}>
                            <CommonText>
                                <span>{project.active.loan.initialTerm} { t(resolveMonthTranslation(project.active.loan.initialTerm)) }</span>
                            </CommonText>
                            <CommonSubText small>
                                { formatDate(startDate) } - { formatDate(endDate) }
                            </CommonSubText>
                        </div>
                    </CommonPropertiesList.Element>
                    <CommonPropertiesList.Element>
                        <CommonSubText>
                            { t("my_detailed_project.schedule.repayment") }
                        </CommonSubText>
                        <CommonText>
                            { t(myProjectScheduleTranslations[project.active.paymentSchedule.scheduleType]) }
                        </CommonText>
                    </CommonPropertiesList.Element>
                </CommonPropertiesList>
                <div className={styles.my_active_project_aside__buttons}>
                    <PrimaryButton color={PrimaryButtonColor.BLUE}>
                        <Button>
                            { t("my_detailed_project.aside.make_payment") }
                        </Button>
                    </PrimaryButton>
                    <PrimaryButton color={PrimaryButtonColor.GRAY}>
                        <Button>
                            { t("my_detailed_project.aside.tax") }
                        </Button>
                    </PrimaryButton>
                </div>
            </CommonBlock.Section>
        </CommonBlock>
    );
}

export default MyActiveProjectAside;