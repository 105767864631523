import React, {useMemo, useState} from "react";
import styles from "./Archive.module.scss";
import {Heading, PrimaryHeading} from "../../../../components/common";
import {HeadingType} from "../../../../components/common/typography/headings/Heading/Heading";
import {useErrorCallback, useTranslator} from "../../../../hooks";
import {DataCollection} from "../../../../utils";
import {ArchivedProject as ArchivedProjectModel} from "../../../../api-client";
import {createProjectManager} from "../../../../di";
import ArchivedProject from "../../../../components/pages/archive/ArchivedProject";
import {Pagination, PaginationContent, PaginationHeader, PaginationPaginator} from "../../../../components/pagination";

const Archive = () => {
    const t = useTranslator();
    const [projects, setProjects] = useState(new DataCollection<ArchivedProjectModel>());
    const handleError = useErrorCallback();
    const [loading, setLoading] = useState(true);

    const loadData = async (page: number, perPage: number) => {
        setLoading(true);
        try {
            const manager = await createProjectManager();
            const projects = await manager.getArchivedProjects(page, perPage);
            const dataCollection = new DataCollection<ArchivedProjectModel>();
            dataCollection.setCollection(projects);
            setProjects(dataCollection);
        } catch (error: any) {
            await handleError(error);
        } finally {
            setLoading(false);
        }
    }

    const renderProjects = useMemo(() => {
        return projects.items.map((project) => {
            return <ArchivedProject project={project} key={project.uuid} />
        });
    }, [projects]);

    return (
        <section className={styles.archive__section}>
            <PrimaryHeading>
                <Heading headingType={HeadingType.H1} className={styles.archive__heading}>
                    { t("archive") }
                </Heading>
            </PrimaryHeading>
            <Pagination onLoad={loadData} count={projects.count} totalCount={projects.totalCount}>
                <PaginationHeader className={styles.archive__list_heading}>
                    <div>
                        ID
                    </div>
                    <div>
                        { t("archive.header.name") }
                    </div>
                    <div>
                        { t("archive.header.term") }
                    </div>
                    <div>
                        { t("archive.header.rate") }
                    </div>
                    <div>
                        { t("archive.header.amount") }
                    </div>
                </PaginationHeader>
                <PaginationContent loading={loading}>
                    <ul>
                        { renderProjects }
                    </ul>
                    <PaginationPaginator />
                </PaginationContent>
            </Pagination>
        </section>
    );
}

export default Archive;