import React, {FC} from "react";
import {useNavigateByName} from "../../../hooks";
import {Navigate} from "react-router-dom";
import {RouteDictionary} from "../../../utils";
import {createRouteUrlProvider} from "../../../di";

interface Props {
    to: RouteDictionary;
}

const ProjectNavigate: FC<Props> = ({ to }) => {
    const routeUrlProvider = createRouteUrlProvider();
    const url = routeUrlProvider.getByName(to);
    return (
        <Navigate to={url} />
    );
}

export default ProjectNavigate;