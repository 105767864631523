import { en } from "./en";
import { ru } from "./ru";
import { am } from "./am";
import {Translations} from "../types";

export const translations: { [code: string]: Translations } = {
    en,
    ru,
    am
};

export * from "./SystemTranslationProvider";
export * from "./TranslationProviderInterface";