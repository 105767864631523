import React, {FC, PropsWithChildren} from "react";
import './Content.scoped.scss';
import {cn, resultIf} from "../../../../utils";

interface Props {
    auth?: boolean;
    min?: boolean;
}

const Content: FC<PropsWithChildren<Props>> = ({ auth = false, min = false, children }) => {
    return (
        <div className={cn("content", resultIf(auth, "content--auth"), resultIf(min, "content--min"))}>
            { children }
        </div>
    );
}

export default Content;