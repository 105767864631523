import React, {FC, useState} from "react";
import Modal, {ModalProps} from "../Modal/Modal";
import {cn, ProjectsFilter, resultIf} from "../../../utils";
import {Button, CommonSubText, Heading, InputErrors, PrimaryButton, TertiaryHeading} from "../../common";
import {HeadingType} from "../../common/typography/headings/Heading/Heading";
import styles from "./ProjectsFilterModal.module.scss";
import CurrentCurrency from "../../currencies/CurrentCurrency";
import InputNumber from "../../common/inputs/InputNumber";
import {PaymentScheduleType} from "../../../api-client";
import {PrimaryButtonColor} from "../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import RestartSvg from "../../svg/icons/RestartSvg";
import {Errors as ValidationErrors} from "../../../utils";
import {useEffectOnUpdate, useTranslator} from "../../../hooks";

interface Props extends ModalProps {
    filter: ProjectsFilter;
    onFilterChange: (filter: ProjectsFilter) => void;
}

interface ProjectsFilterInput {
    loanAmountFrom: string;
    loanAmountTo: string;
    interestRateFrom: string;
    interestRateTo: string;
    initialTermFrom: string;
    initialTermTo: string;
    paymentScheduleType: PaymentScheduleType | null;
}

const defaultFilterInput: ProjectsFilterInput = {
    loanAmountFrom: "",
    loanAmountTo: "",
    interestRateFrom: "",
    interestRateTo: "",
    initialTermFrom: "",
    initialTermTo: "",
    paymentScheduleType: null
}

type FormErrors = {
    loanAmountFrom?: ValidationErrors;
    loanAmountTo?: ValidationErrors;
    interestRateFrom?: ValidationErrors;
    interestRateTo?: ValidationErrors;
    initialTermFrom?: ValidationErrors;
    initialTermTo?: ValidationErrors;
    paymentScheduleType?: ValidationErrors;
}

const mapFilterToInput = (filter: ProjectsFilter): ProjectsFilterInput => {
    return {
        loanAmountFrom: filter.loanAmountFrom !== undefined ? filter.loanAmountFrom + "" : "",
        loanAmountTo: filter.loanAmountTo !== undefined ? filter.loanAmountTo + "" : "",
        interestRateFrom: filter.interestRateFrom !== undefined ? filter.interestRateFrom + "" : "",
        interestRateTo: filter.interestRateTo !== undefined ? filter.interestRateTo + "" : "",
        initialTermFrom: filter.initialTermFrom !== undefined ? filter.initialTermFrom + "" : "",
        initialTermTo: filter.initialTermTo !== undefined ? filter.initialTermTo + "" : "",
        paymentScheduleType: filter.paymentScheduleType !== undefined ? filter.paymentScheduleType : null
    }
}

const filterRestrictions = {
    minSum: 300,
    maxSum: 1000,
    minRate: 12,
    maxRate: 32,
    minTerm: 90,
    maxTerm: 360
}

const ProjectsFilterModal: FC<Props> = ({ filter, onFilterChange, active, onClose }) => {
    const t = useTranslator();
    const [filterCopy, setFilterCopy] = useState<ProjectsFilterInput>(mapFilterToInput(filter));

    useEffectOnUpdate(() => {
        setFilterCopy(mapFilterToInput(filter))
    }, [filter]);

    const [errors, setErrors] = useState<FormErrors>({});

    const resetFilter = () => {
        setFilterCopy({...defaultFilterInput});
    }

    const validateFilter = (): boolean => {
        const newErrors: FormErrors = {};
        if (filterCopy.loanAmountFrom !== "" && isNaN(parseInt(filterCopy.loanAmountFrom))) {
            newErrors.loanAmountFrom = [t(
                "errors.incorrect_input",
                { field: t("errors.fields.sum"), type: t("errors.input.feminine") }
            )];
        }
        if (filterCopy.loanAmountTo !== "" && isNaN(parseInt(filterCopy.loanAmountTo))) {
            newErrors.loanAmountTo = [t(
                "errors.incorrect_input",
                { field: t("errors.fields.sum"), type: t("errors.input.feminine") }
            )];
        }
        if (filterCopy.interestRateFrom !== "" && isNaN(parseInt(filterCopy.interestRateFrom))) {
            newErrors.interestRateFrom = [t(
                "errors.incorrect_input",
                { field: t("errors.fields.percent"), type: t("errors.input.neuter") }
            )];
        }
        if (filterCopy.interestRateTo !== "" && isNaN(parseInt(filterCopy.interestRateTo))) {
            newErrors.interestRateTo = [t(
                "errors.incorrect_input",
                { field: t("errors.fields.percent"), type: t("errors.input.neuter") }
            )];
        }
        if (filterCopy.initialTermFrom !== "" && isNaN(parseInt(filterCopy.initialTermFrom))) {
            newErrors.initialTermFrom = [t(
                "errors.incorrect_input",
                { field: t("errors.fields.term"), type: t("errors.input.neuter") }
            )];
        }
        if (filterCopy.initialTermTo !== "" && isNaN(parseInt(filterCopy.initialTermTo))) {
            newErrors.initialTermTo = [t(
                "errors.incorrect_input",
                { field: t("errors.fields.term"), type: t("errors.input.neuter") }
            )];
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const setPaymentType = (paymentScheduleType: PaymentScheduleType) => {
        if (filterCopy.paymentScheduleType === paymentScheduleType) {
            setFilterCopy({...filterCopy, paymentScheduleType: null});
            return;
        }
        setFilterCopy({...filterCopy, paymentScheduleType});
    }

    useEffectOnUpdate(() => {
        validateFilter();
    }, [filterCopy]);

    const handleFiltering = () => {
        if (validateFilter()) {
            onFilterChange({
                loanAmountFrom: filterCopy.loanAmountFrom !== "" ? +filterCopy.loanAmountFrom : undefined,
                loanAmountTo: filterCopy.loanAmountTo !== "" ? +filterCopy.loanAmountTo : undefined,
                interestRateFrom: filterCopy.interestRateFrom !== "" ? +filterCopy.interestRateFrom : undefined,
                interestRateTo: filterCopy.interestRateTo !== "" ? +filterCopy.interestRateTo : undefined,
                initialTermFrom: filterCopy.initialTermFrom !== "" ? +filterCopy.initialTermFrom : undefined,
                initialTermTo: filterCopy.initialTermTo !== "" ? +filterCopy.initialTermTo : undefined,
                paymentScheduleType: filterCopy.paymentScheduleType
            });
        }
    }

    return (
        <Modal active={active} onClose={onClose} className={styles.projects_filter_modal}>
            <TertiaryHeading>
                <Heading headingType={HeadingType.H3} className={styles.projects_filter_modal__heading}>
                    { t("market.primary.filters") }
                </Heading>
            </TertiaryHeading>
            <div className={styles.projects_filter_modal__input_group}>
                <div className={styles.projects_filter_modal__inputs}>
                    <CommonSubText tag="label" small htmlFor="loanAmountFrom" className={styles.projects_filter_modal__label}>
                        { t("market.primary.sum") }, <CurrentCurrency />
                    </CommonSubText>
                    <div>
                        <InputNumber
                            className={styles.projects_filter_modal__input}
                            id="loanAmountFrom"
                            value={filterCopy.loanAmountFrom}
                            onChange={(event) => setFilterCopy({...filterCopy, loanAmountFrom: (event.target as HTMLInputElement).value})}
                            min={filterRestrictions.minSum}
                            max={filterRestrictions.maxSum - 1}
                            placeholder={`${t("common.from")} ${filterRestrictions.minSum}`}
                        />
                        { errors.loanAmountFrom && <InputErrors errors={errors.loanAmountFrom} /> }
                    </div>
                    <div>
                        <InputNumber
                            className={styles.projects_filter_modal__input}
                            id="loanAmountTo"
                            value={filterCopy.loanAmountTo}
                            onChange={(event) => setFilterCopy({...filterCopy, loanAmountTo: (event.target as HTMLInputElement).value})}
                            max={filterRestrictions.maxSum}
                            placeholder={`${t("common.to")} ${filterRestrictions.maxSum}`}
                        />
                    </div>
                </div>
                <div className={styles.projects_filter_modal__inputs}>
                    <CommonSubText tag="label" small htmlFor="interestRateFrom" className={styles.projects_filter_modal__label}>
                        { t("market.primary.rate") }, %
                    </CommonSubText>
                    <InputNumber
                        className={styles.projects_filter_modal__input}
                        id="interestRateFrom"
                        value={filterCopy.interestRateFrom}
                        onChange={(event) => setFilterCopy({...filterCopy, interestRateFrom: (event.target as HTMLInputElement).value})}
                        min={filterRestrictions.minRate}
                        max={filterRestrictions.maxRate - 1}
                        placeholder={`${t("common.from")} ${filterRestrictions.minRate}`}
                    />
                    <InputNumber
                        className={styles.projects_filter_modal__input}
                        id="interestRateTo"
                        value={filterCopy.interestRateTo}
                        onChange={(event) => setFilterCopy({...filterCopy, interestRateTo: (event.target as HTMLInputElement).value})}
                        max={filterRestrictions.maxRate}
                        placeholder={`${t("common.to")} ${filterRestrictions.maxRate}`}
                    />
                </div>
                <div className={styles.projects_filter_modal__inputs}>
                    <CommonSubText tag="label" small htmlFor="initialTermFrom" className={styles.projects_filter_modal__label}>
                        { t("market.primary.filters.term") }
                    </CommonSubText>
                    <InputNumber
                        className={styles.projects_filter_modal__input}
                        id="initialTermFrom"
                        value={filterCopy.initialTermFrom}
                        onChange={(event) => setFilterCopy({...filterCopy, initialTermFrom: (event.target as HTMLInputElement).value})}
                        min={filterRestrictions.minTerm}
                        max={filterRestrictions.maxTerm - 1}
                        placeholder={`${t("common.from")} ${filterRestrictions.minTerm}`}
                    />
                    <InputNumber
                        className={styles.projects_filter_modal__input}
                        id="initialTermTo"
                        value={filterCopy.initialTermTo}
                        onChange={(event) => setFilterCopy({...filterCopy, initialTermTo: (event.target as HTMLInputElement).value})}
                        max={filterRestrictions.maxTerm}
                        placeholder={`${t("common.to")} ${filterRestrictions.maxTerm}`}
                    />
                </div>
                <div className={styles.projects_filter_modal__schedule}>
                    <CommonSubText tag="label" small className={styles.projects_filter_modal__schedule_label}>
                        { t("market.primary.schedule") }
                    </CommonSubText>
                    <div className={styles.projects_filter_modal__schedule_types}>
                        <div
                            className={cn(
                                styles.projects_filter_modal__schedule_type,
                                resultIf(
                                    filterCopy.paymentScheduleType === PaymentScheduleType.MONTHLY_PAYMENTS,
                                    styles.projects_filter_modal__schedule_type___active
                                )
                            )}
                            onClick={() => setPaymentType(PaymentScheduleType.MONTHLY_PAYMENTS)}
                        >
                            { t("market.primary.schedule.monthly") }
                        </div>
                        <div
                            className={cn(
                                styles.projects_filter_modal__schedule_type,
                                resultIf(
                                    filterCopy.paymentScheduleType === PaymentScheduleType.MONTHLY_TWO_PAYMENTS,
                                    styles.projects_filter_modal__schedule_type___active
                                )
                            )}
                            onClick={() => setPaymentType(PaymentScheduleType.MONTHLY_TWO_PAYMENTS)}
                        >
                            { t("market.primary.schedule.monthly_twice") }
                        </div>
                        <div
                            className={cn(
                                styles.projects_filter_modal__schedule_type,
                                resultIf(
                                    filterCopy.paymentScheduleType === PaymentScheduleType.MONTHLY_THREE_PAYMENTS,
                                    styles.projects_filter_modal__schedule_type___active
                                )
                            )}
                            onClick={() => setPaymentType(PaymentScheduleType.MONTHLY_THREE_PAYMENTS)}
                        >
                            { t("market.primary.schedule.monthly_thrice") }
                        </div>
                        <div
                            className={cn(
                                styles.projects_filter_modal__schedule_type,
                                resultIf(
                                    filterCopy.paymentScheduleType === PaymentScheduleType.MONTHLY_FOUR_PAYMENTS,
                                    styles.projects_filter_modal__schedule_type___active
                                )
                            )}
                            onClick={() => setPaymentType(PaymentScheduleType.MONTHLY_FOUR_PAYMENTS)}
                        >
                            { t("market.primary.schedule.monthly_four_times") }
                        </div>
                        <div
                            className={cn(
                                styles.projects_filter_modal__schedule_type,
                                resultIf(
                                    filterCopy.paymentScheduleType === PaymentScheduleType.QUARTERLY_PAYMENTS,
                                    styles.projects_filter_modal__schedule_type___active
                                )
                            )}
                            onClick={() => setPaymentType(PaymentScheduleType.QUARTERLY_PAYMENTS)}
                        >
                            { t("market.primary.schedule.quarterly") }
                        </div>
                        <div
                            className={cn(
                                styles.projects_filter_modal__schedule_type,
                                resultIf(
                                    filterCopy.paymentScheduleType === PaymentScheduleType.PAYMENT_AT_THE_END,
                                    styles.projects_filter_modal__schedule_type___active
                                )
                            )}
                            onClick={() => setPaymentType(PaymentScheduleType.PAYMENT_AT_THE_END)}
                        >
                            { t("market.primary.schedule.at_the_end") }
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.projects_filter_modal__buttons}>
                <PrimaryButton color={PrimaryButtonColor.BLUE} expanded>
                    <Button onClick={handleFiltering}>
                        { t("market.primary.filters.submit") }
                    </Button>
                </PrimaryButton>
                <PrimaryButton color={PrimaryButtonColor.GRAY} expanded>
                    <Button onClick={resetFilter}>
                        <div className={styles.projects_filter_modal__reset}>
                            <span>
                                { t("market.primary.filters.reset") }
                            </span>
                            <RestartSvg />
                        </div>
                    </Button>
                </PrimaryButton>
            </div>
        </Modal>
    );
}

export default ProjectsFilterModal;