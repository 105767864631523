import React, {FC} from "react";
import './ProgressBar.scoped.scss';
import {cn, resultIf} from "../../../utils";

type Props = {
    filled: number
}

const ProgressBar: FC<Props> = ({ filled }) => {
    const isFinished = (): boolean => {
        return filled >= 100;
    }
    return (
        <div className="progress-bar">
            <div
                style={{width: `${filled}%`}}
                className={cn(
                    "progress-bar__filled",
                    resultIf(isFinished(), "finished")
                )}
            ></div>
        </div>
    );
}

export default ProgressBar;