import React, {useContext, useState} from "react";
import {AnalyticsUserContext} from "../../AnalyticsUserContext";
import AnalyticsDefault from "../../../rows/default/AnalyticsDefault";
import AnalyticsRows from "../../../rows/AnalyticsRows";
import AnalyticsDefaultHeader from "../../../rows/default/AnalyticsDefaultHeader";
import AnalyticsPenaltiesNavigation from "../../../rows/AnalyticsPenaltiesNavigation";
import {
    PENALTIES_NAVIGATION_PAGE,
    PenaltiesNavigationPage
} from "../../../rows/AnalyticsPenaltiesNavigation/AnalyticsPenaltiesNavigation";
import AnalyticsDefaultSum from "../../../rows/default/AnalyticsDefaultSum";
import AnalyticsDefaultPercent from "../../../rows/default/AnalyticsDefaultPercent";
import moment from "moment";
import BarChart from "../../../../../chart/BarChart";
import {useTranslator} from "../../../../../../hooks";

const AnalyticsUserDefaults = () => {
    const t = useTranslator();
    const defaults = useContext(AnalyticsUserContext).analytics?.defaults;
    if (defaults === undefined) {
        return <div>Analytics is undefined (somehow)</div>
    }

    const [page, setPage] = useState<PenaltiesNavigationPage>(PENALTIES_NAVIGATION_PAGE.NUMBER);

    const renderRows = () => {
        if (page === PENALTIES_NAVIGATION_PAGE.NUMBER) {
            return defaults.map((entry, index) => {
                return <AnalyticsDefault penalty={entry} key={index} />
            });
        }
        if (page === PENALTIES_NAVIGATION_PAGE.SUM) {
            return defaults.map((entry, index) => {
                return <AnalyticsDefaultSum penalty={entry} key={index} />
            });
        }
        return defaults.map((entry, index) => {
            return <AnalyticsDefaultPercent penalty={entry} key={index} />
        })
    }

    const chartData = defaults.map((entry) => {
        return (entry.returned.sum.amount + entry.inDefault.sum.amount) / 100;
    });

    const chartHeadings = defaults.map((entry) => {
        const momentDate = moment(entry.date);
        return [momentDate.format("MMM"), momentDate.format("YYYY")];
    });

    return (
        <AnalyticsRows>
            <div className="user-analytics__chart">
                <BarChart
                    data={chartData}
                    labels={chartHeadings}
                    stepSize={1000000}
                    ticksFunc={(tickValue) => `${Math.floor(+tickValue / 1000000)} ${t("common.chart_mln")}`}
                />
            </div>
            <AnalyticsPenaltiesNavigation current={page} setCurrent={setPage} />
            <AnalyticsDefaultHeader />
            <ul>
                { renderRows() }
            </ul>
        </AnalyticsRows>
    );
}

export default AnalyticsUserDefaults;