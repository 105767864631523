import {FundingRequest} from "../../api-client";

export class RequestSumViewedStorage {
    private readonly storageKey: string = "request-sum-viewed";

    constructor(private readonly storage: Storage) {
    }

    public get(request: FundingRequest): boolean {
        const value = this.storage.getItem(`${this.storageKey}-${request.uuid}`);
        return !!value;
    }

    public set(request: FundingRequest): void {
        this.storage.setItem(`${this.storageKey}-${request.uuid}`, "true");
    }
}