import {Translations} from "../../types";

export const my_detailed_project: Translations = {
    "my_detailed_project.placeholder": "Такой проект не найден",
    "my_detailed_project.schedule": "График выплат",
    "my_detailed_project.schedule.repayment": "График погашения",
    "my_detailed_project.schedule.not_found": "График выплат недоступен",
    "my_detailed_project.schedule.preliminary": "Предварительный график выплат",
    "my_detailed_project.schedule.date": "Дата",
    "my_detailed_project.schedule.sum": "Сумма платежа",
    "my_detailed_project.schedule.principal": "Основной долг",
    "my_detailed_project.schedule.percents": "Проценты",
    "my_detailed_project.schedule.unexpected": "Unexpected project type",
    "my_detailed_project.aside.requested": "Запрошенная сумма",
    "my_detailed_project.aside.not_listed": "Не указана",
    "my_detailed_project.aside.not_listed.masculine": "Не указан",
    "my_detailed_project.aside.rate": "Ставка",
    "my_detailed_project.aside.term": "Срок",
    "my_detailed_project.aside.continue": "Продолжить заполнение",
    "my_detailed_project.aside.collected": "Выплачено",
    "my_detailed_project.aside.sum": "Сумма займа",
    "my_detailed_project.aside.payout_sum": "Сумма выплат",
    "my_detailed_project.aside.fundraising_collected": "Собрано",
    "my_detailed_project.aside.target": "Цель",
    "my_detailed_project.aside.make_payment": "Внести оплату",
    "my_detailed_project.aside.tax": "Заплатить налог",
    "my_detailed_project.status.review": "Заявка на рассмотрении",
    "my_detailed_project.status.review_approved": "Заявка одобрена комитетом",
    "my_detailed_project.status.review_rejected_first": "Заявка отклонена",
    "my_detailed_project.status.review_rejected_second": "В данный момент ваша завка не может быть одобрена, " +
        "так как в выписках недостаточно информации по текущей деятельности компании.",
    "my_detailed_project.status.final_review": "Заявка на окончательном рассмотрении",
    "my_detailed_project.status.final_review_approved": "Заявка повторно одобрена кредитным комитетом",
    "my_detailed_project.status.final_review_rejected": "Кредитный комитет отказал в выдаче займа при повторной проверке",
    "my_detailed_project.status.fundraising": "Средства по заёму будут отправлены на ваш расчётный счёт после того, как будет " +
        "достигнута цель. Точный график выплат будет сформирован после отправки средств на ваш счёт.",
    "my_detailed_project.status.finished": "Сбор завершен",
    "my_detailed_project.status.unknown": "Неизвестный статус проекта, обратитесь к администратору для уточнения",

    "my_detailed_project.schedule.monthly": "Раз в месяц",
    "my_detailed_project.schedule.monthly_twice": "2 раза в месяц",
    "my_detailed_project.schedule.monthly_thrice": "3 раза в месяц",
    "my_detailed_project.schedule.monthly_four_times": "4 раза в месяц",
    "my_detailed_project.schedule.quarterly": "Раз в квартал",
    "my_detailed_project.schedule.at_the_end": "В конце проекта",
}