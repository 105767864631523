import {Translations} from "../../types";

export const word_case: Translations = {
    "word_case.days.nominative": "день",
    "word_case.days.genitive": "дня",
    "word_case.days.multiple": "дней",

    "word_case.months.nominative": "месяц",
    "word_case.months.genitive": "месяца",
    "word_case.months.multiple": "месяцев",

    "word_case.times.nominative": "раз",
    "word_case.times.genitive": "раза",
    "word_case.times.multiple": "раз",

    "word_case.offers.nominative": "предложение",
    "word_case.offers.genitive": "предложения",
    "word_case.offers.multiple": "предложений",

    "word_case.loans.nominative": "займ",
    "word_case.loans.genitive": "займа",
    "word_case.loans.multiple": "займов",

    "word_case.loans-returned.nominative": "возвращен",
    "word_case.loans-returned.genitive": "возвращено",
    "word_case.loans-returned.multiple": "возвращено",
}