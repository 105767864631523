import React, {useContext, useMemo, useState} from "react";
import "./IdentificationForm.scoped.scss";
import {
    Button,
    Checkbox,
    DatePicker,
    Heading,
    IdentificationPassportFiles,
    InfoPageLink, Loader,
    PrimaryButton,
    PrimaryHeading,
    ProjectLink,
    SecondaryHeading
} from "../../../common";
import {HeadingType} from "../../../common/typography/headings/Heading/Heading";
import {CommonInput} from "../../../common/inputs";
import {
    useAppDispatch,
    useEffectOnUpdate,
    useErrorCallback, useFullName,
    usePassport,
    usePassportPhotos,
    useProfileData,
    useToggle, useTranslator
} from "../../../../hooks";
import {LenderIdentification, Phone} from "../../../../api-client";
import IdentificationPassport from "../../../common/utils/identification/IdentificationPassport";
import {Errors as ValidationErrors, RouteDictionary} from "../../../../utils";
import {PrimaryButtonColor} from "../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {
    PassportPhotoErrors
} from "../../../common/utils/identification/IdentificationPassportFiles/IdentificationPassportFiles";
import {createIdentificationManager} from "../../../../di";
import IdentificationPhone from "../IdentificationPhone";
import {IdentificationContext, IdentificationStep} from "../IdentificationContext";
import {setIdentificationDraft} from "../../../../store/profile";
import {formatApiDate} from "../../../../formatters";
import {PassportErrors, PersonalDataErrors} from "../../../../hooks/data/validation";

type FormErrors = PersonalDataErrors & {
    regNumber?: ValidationErrors;
    phone?: ValidationErrors;
} & PassportErrors & PassportPhotoErrors;

const IdentificationForm = () => {
    const t = useTranslator();
    const context = useContext(IdentificationContext);
    const profile = useProfileData();
    const handleError = useErrorCallback();
    const dispatch = useAppDispatch();
    const [fullName, setFullName] = useFullName(context.identification?.fullName);
    const [birthday, setBirthday] = useState<Date>(new Date());
    const [registrationNumber, setRegistrationNumber] = useState(context.identification?.registrationNumber ?? "");
    const [phone, setPhone] = useState<Phone>(profile.phone ?? new Phone("374", ""));
    const [passport, setPassport] = usePassport(context.identification?.passport);
    const [passportPhotos, setPassportPhotos] = usePassportPhotos();

    useEffectOnUpdate(() => {
        setPassport({
            ...passport,
            firstPhotos: passportPhotos.firstPhotos.map((file) => file.url),
            secondPhotos: passportPhotos.secondPhotos.map((file) => file.url),
            selfiePhotos: passportPhotos.selfiePhotos.map((file) => file.url),
        });
    }, [passportPhotos]);

    const [conditionsAgree, toggleConditionsAgree] = useToggle();
    const [policyAgree, togglePolicyAgree] = useToggle();

    const formattedBirthday = useMemo(() => {
        return formatApiDate(birthday);
    }, [birthday]);

    const identificationInput = useMemo((): LenderIdentification => {
        return {
            fullName,
            birthday: formattedBirthday,
            registrationNumber,
            phone,
            passport
        }
    }, [
        fullName,
        birthday,
        registrationNumber,
        phone,
        passport
    ]);

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<FormErrors>({});

    const validateForm = (): boolean => {
        const newErrors: FormErrors = {};
        if (identificationInput.fullName.lastName === "") {
            newErrors.lastName = [t(
                "errors.field_required",
                { field: t("errors.fields.last_name"), type: t("errors.field_required.feminine") }
            )];
        }
        if (identificationInput.phone.number === "") {
            newErrors.phone = [t(
                "errors.field_required",
                { field: t("errors.fields.phone"), type: t("errors.field_required.masculine") }
            )];
        } else if (!profile.phoneVerified) {
            newErrors.phone = [t("errors.confirm_phone")];
        }
        setErrors(newErrors);
        return isValid(newErrors);
    }

    useEffectOnUpdate(() => {
        validateForm();
    }, [identificationInput, profile]);

    const isValid = (newErrors: FormErrors = errors) => {
        return Object.keys(newErrors).length === 0;
    }

    const [initial, setInitial] = useState(true);

    useEffectOnUpdate(() => {
        setInitial(false);
    }, [identificationInput]);

    const handleSubmit = async () => {
        if (validateForm()) {
            setLoading(true);
            try {
                const manager = await createIdentificationManager();
                if (profile.isIndividual) {
                    await manager.createIndividualIdentification(identificationInput);
                } else if (profile.isEntrepreneur) {
                    await manager.createEntrepreneurIdentification(identificationInput);
                } else if (profile.isLegalEntity) {
                    await manager.createLegalEntityIdentification(identificationInput);
                }
                await dispatch(setIdentificationDraft(true));
                context.setIdentification(identificationInput);
                context.setStep(IdentificationStep.CheckStatus);
            } catch (error: any) {
                await handleError(error);
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <div className="identification-form">
            <div className="identification-form__container">
                <div>
                    <PrimaryHeading>
                        <Heading headingType={HeadingType.H1} className="identification-form__heading">
                            { t("identification") }
                        </Heading>
                    </PrimaryHeading>
                    <div className="identification-form__text">
                        { t("identification.disclaimer") }
                    </div>
                </div>
                <div className="identification-form__personal">
                    <SecondaryHeading>
                        <Heading headingType={HeadingType.H2} className="identification-form__subheading">
                            { t("identification.personal_data") }
                        </Heading>
                    </SecondaryHeading>
                    <div>
                        <CommonInput
                            value={fullName.lastName}
                            onChange={(value: string) => setFullName({...fullName, lastName: value})}
                            text={t("common.surname")}
                            error={false}
                        />
                    </div>
                    <div>
                        <CommonInput
                            value={fullName.firstName}
                            onChange={(value: string) => setFullName({...fullName, firstName: value})}
                            text={t("common.name")}
                            error={false}
                        />
                    </div>
                    <div>
                        <CommonInput
                            value={fullName.middleName}
                            onChange={(value: string) => setFullName({...fullName, middleName: value})}
                            text={t("common.parent_name")}
                            error={false}
                        />
                    </div>
                    <div>
                        <DatePicker
                            value={birthday}
                            onSelected={setBirthday}
                            text={t("common.birthday")}
                        />
                    </div>
                    <div>
                        <CommonInput
                            value={registrationNumber}
                            onChange={setRegistrationNumber}
                            text={t("common.reg_number")}
                            placeholder="8027456745"
                            error={false}
                        />
                    </div>
                    <IdentificationPhone phone={phone} setPhone={setPhone} errors={errors.phone} />
                </div>
                <IdentificationPassport passport={passport} setPassport={setPassport} errors={errors} />
                <IdentificationPassportFiles photos={passportPhotos} setPhotos={setPassportPhotos} errors={errors} />
                <div>
                    <SecondaryHeading>
                        <Heading headingType={HeadingType.H2} className="identification-form__subheading">
                            { t("identification.confirmation") }
                        </Heading>
                    </SecondaryHeading>
                    <div className="identification-form__checkboxes">
                        <Checkbox checked={conditionsAgree} onChange={toggleConditionsAgree}>
                            { t("identification.agree") } <InfoPageLink>
                                <ProjectLink to={RouteDictionary.DYNAMIC_PAGE} params={{ name: "conditions" }}>
                                    { t("identification.promotion_conditions") }
                                </ProjectLink>
                            </InfoPageLink>
                        </Checkbox>
                        <Checkbox checked={policyAgree} onChange={togglePolicyAgree}>
                            { t("identification.agree") } <InfoPageLink>
                                <ProjectLink to={RouteDictionary.DYNAMIC_PAGE} params={{ name: "policy" }}>
                                    { t("identification.data_policy") }
                                </ProjectLink>
                            </InfoPageLink>
                        </Checkbox>
                    </div>
                </div>
                { loading && <div className="identification-form__loading">
                    <Loader />
                </div> }
                <div>
                    <PrimaryButton expanded color={initial || !isValid() || profile.hasIdentificationDraft
                        ? PrimaryButtonColor.GRAY : PrimaryButtonColor.BLUE}
                    >
                        <Button disabled={initial || !isValid() || profile.hasIdentificationDraft} onClick={handleSubmit}>
                            { t("identification.send") }
                        </Button>
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
}

export default IdentificationForm;