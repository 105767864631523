import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {VerificationManager} from "../VerificationManager";

export class VerificationManagerFactory {
    private apiClientFactory: ApiClientFactoryInterface;

    constructor(baseUrl: string, headers: Headers) {
        this.apiClientFactory = new ApiClientFactory(
            `${baseUrl}/api/v1/verification`,
            headers
        );
    }

    public createVerificationManager(authToken: string): VerificationManager {
        return new VerificationManager(this.apiClientFactory.createAuthorizedClient(authToken));
    }

    public createPublicVerificationManager(): VerificationManager {
        return new VerificationManager(this.apiClientFactory.createClient());
    }
}