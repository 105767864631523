import React from "react";
import {SvgComponent} from "../SvgComponent";
import SvgContainer from "../SvgContainer";
import {SvgContainerSize} from "../SvgContainer/SvgContainer";
import {defaultStatSvgColor} from "./defaultStatSvgColor";

const CalendarSvg: SvgComponent = ({ color = defaultStatSvgColor}) => {
    return (
        <SvgContainer size={SvgContainerSize.Small}>
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2.41699" y="3.25" width="12.5" height="10.5" rx="1.25" stroke={color} strokeWidth="1.5" strokeLinejoin="round" />
                <path d="M10.667 1.5V4.5" stroke={defaultStatSvgColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.66699 1.5V4.5" stroke={defaultStatSvgColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <circle cx="5.66699" cy="7.38525" r="1" fill={defaultStatSvgColor} />
                <circle cx="5.66699" cy="10.3853" r="1" fill={defaultStatSvgColor} />
                <circle cx="8.66699" cy="7.38525" r="1" fill={defaultStatSvgColor} />
                <circle cx="8.66699" cy="10.3853" r="1" fill={defaultStatSvgColor} />
                <circle cx="11.667" cy="7.38525" r="1" fill={defaultStatSvgColor} />
                <circle cx="11.667" cy="10.3853" r="1" fill={defaultStatSvgColor} />
            </svg>
        </SvgContainer>
    );
}

export default CalendarSvg;