import {useAppDispatch, useAppSelector} from "./store";
import {MyMoneyState, set} from "../store/myMoney";
import {createMyMoneyProvider} from "../di";
import {useEffect, useState} from "react";
import {useErrorCallback} from "./useErrorCallback";
import {MyMoney} from "../api-client";

type Response = {
    myMoney: MyMoney | undefined;
    loading: boolean;
}

export const useMyMoney = (): Response => {
    const localMoney = useAppSelector<MyMoneyState>((state) => state.myMoney);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(localMoney.myMoney === undefined);
    const handleError = useErrorCallback();
    useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            try {
                if (localMoney.myMoney === undefined) {
                    const myMoneyProvider = await createMyMoneyProvider(abortController.signal);
                    const myMoney = await myMoneyProvider.getStats();
                    dispatch(set(myMoney));
                }
            } catch (error: any) {
                await handleError(error);
            } finally {
                setLoading(false);
            }
        })();
        return () => {
            abortController.abort();
        }
    }, []);

    return {
        loading,
        myMoney: localMoney.myMoney
    };
}