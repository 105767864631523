import {Headers} from "../../types";
import {ApiClient} from "../../clients";
import {ApiClientFactoryInterface} from "./ApiClientFactoryInterface";

export class ApiClientFactory implements ApiClientFactoryInterface{
    constructor(
        private readonly baseUrl: string,
        private readonly headers: Headers = {}
    ) {}

    public createClient(): ApiClient {
        return new ApiClient(this.baseUrl, this.headers);
    }

    public createAuthorizedClient(authToken: string): ApiClient {
        return new ApiClient(this.baseUrl, this.headers, authToken);
    }
}