import {Translations} from "../../types";

export const guarantor: Translations = {
    "guarantor": "Fill the guarantor form",
    "guarantor.personal_data": "Personal data",
    "guarantor.personal_data.last_name": "Last name",
    "guarantor.personal_data.first_name": "First name",
    "guarantor.personal_data.middle_name": "Middle name",
    "guarantor.personal_data.birthday": "Birthday",
    "guarantor.confirmation": "Confirmation of entered data",
    "guarantor.confirmation.first": "I provide",
    "guarantor.confirmation.second": "consent to the processing of personal data, a request to the credit bureau and verification of mobile operator data",
    "guarantor.send": "Send questionnaire",
    "guarantor.survey_not_found": "Survey is not filled",
    "guarantor.status": "Request status:",
    "guarantor.awaiting_administrator": "Your request is being checked by administrator",
}