import React, {FC, PropsWithChildren} from "react";
import './ButtonInner.scss';

const ButtonInner: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="button__inner">
            { children }
        </div>
    );
}

export default ButtonInner;