import React, {FC, PropsWithChildren} from "react";
import {cn} from "../../../../utils";
import "./ContentContainer.scoped.scss";

export enum ContentContainerSize {
    Small = "content-container--small",
    Normal = "content-container--normal",
}

interface Props {
    size?: ContentContainerSize
}

const ContentContainer: FC<PropsWithChildren<Props>> = ({ size = ContentContainerSize.Normal, children }) => {
    return (
        <div className={cn("content-container", size)}>
            { children }
        </div>
    );
}

export default ContentContainer;