import React, {FC} from "react";
import './DetailedProjectFounder.scoped.scss';
import {Heading, TertiaryHeading} from "../../../../common/typography/headings";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import {CommonSubText, Money} from "../../../../common";
import {formatDate, formatFullName} from "../../../../../formatters";
import {ProjectFounder} from "../../../../../api-client";
import {useTranslator} from "../../../../../hooks";

interface Props {
    founder: ProjectFounder;
}

const DetailedProjectFounder: FC<Props> = ({ founder}) => {
    const t = useTranslator();
    const renderCompanies = (): string => {
        const arr = [];
        if (founder.countCompaniesLegal !== 0) {
            arr.push(`${founder.countCompaniesLegal} ${t("detailed_project.founder.legal")}`);
        }
        if (founder.countCompaniesEntrepreneur !== 0) {
            arr.push(`${founder.countCompaniesEntrepreneur} ${t("detailed_project.founder.individual")}`);
        }
        return arr.join(", ");
    }
    return (
        <div className="detailed-project-founder">
            <TertiaryHeading>
                <Heading headingType={HeadingType.H3}>
                    { formatFullName(founder.fullName) }
                </Heading>
            </TertiaryHeading>
            <div className="detailed-project-founder__data">
                <div className="detailed-project-founder__column">
                    <CommonSubText>
                        { t("detailed_project.founder.share") }
                    </CommonSubText>
                    <div>
                        { founder.share }%
                    </div>
                </div>
                <div className="detailed-project-founder__column">
                    <CommonSubText>
                        { t("detailed_project.founder.capital") }
                    </CommonSubText>
                    <div>
                        <Money money={founder.funds} />
                    </div>
                </div>
                <div className="detailed-project-founder__column">
                    <CommonSubText>
                        { t("detailed_project.founder.registration_date") }
                    </CommonSubText>
                    <div>
                        { formatDate(founder.registerDate) }
                    </div>
                </div>
                <div className="detailed-project-founder__column">
                    <CommonSubText>
                        { t("detailed_project.founder.registration_date") }
                    </CommonSubText>
                    <div>
                        { founder.inn }
                    </div>
                </div>
                <div className="detailed-project-founder__column">
                    <CommonSubText>
                        { t("detailed_project.founder.registration_date") }
                    </CommonSubText>
                    <div>
                        { renderCompanies() }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailedProjectFounder;
