import React, {useContext, useState} from "react";
import "./InvestmentConfirmation.scoped.scss";
import {
    useEffectOnUpdate,
    useErrorCallback,
    useGoBack,
    useNavigateByName,
    useProfileData,
    useTranslator
} from "../../../../hooks";
import {useParams} from "react-router-dom";
import {Money as MoneyModel, PaymentSchedule, UuidResult} from "../../../../api-client";
import {DetailedProjectContext} from "../../../../components/pages/detailed-project/DetailedProjectContext";
import {
    Button,
    CommonSubText,
    CommonText,
    Heading, InputErrors,
    Money,
    PrimaryButton,
    PrimaryHeading,
    TertiaryHeading
} from "../../../../components/common";
import {Errors as ValidationErrors, RouteDictionary} from "../../../../utils";
import {HeadingType} from "../../../../components/common/typography/headings/Heading/Heading";
import InputMoney from "../../../../components/common/inputs/InputMoney";
import {PrimaryButtonColor} from "../../../../components/common/buttons/decorators/PrimaryButton/PrimaryButton";
import InvestmentConfirmationModal from "../../../../components/modals/InvestmentConfirmationModal";
import {createMoneyFactory, createInvestmentManager} from "../../../../di";
import CommonStatBlock from "../../../../components/common/blocks/CommonStatBlock";
import {CommonStatsContainer} from "../../../../components/common/blocks";
import CurrencySvg from "../../../../components/svg/stats/CurrencySvg";
import CalendarSvg from "../../../../components/svg/stats/CalendarSvg";
import PercentSvg from "../../../../components/svg/stats/PercentSvg";
import PaymentsSchedule from "../../../../components/payment-schedule/PaymentsSchedule";
import {CURRENT_CURRENCY_SYMBOL, MAIN_CURRENCY} from "../../../../configs";
import {resolveDayTranslation} from "../../../../i18n";
import CommonConfirmationSubPage from "../../../../components/common/blocks/CommonConfirmationSubPage";

const moneyFactory = createMoneyFactory();

type FormErrors = {
    investmentAmount?: ValidationErrors;
}

const investmentConfirmationSettings = {
    minAmount: 400 * 100,
    maxAmount: 1000000 * 100
}

const InvestmentConfirmation = () => {
    const t = useTranslator();
    const project = useContext(DetailedProjectContext).project;
    const navigateByName = useNavigateByName();
    const profileData = useProfileData();
    const handleError = useErrorCallback();
    const { uuid } = useParams();
    const [loading, setLoading] = useState(false);
    const [scheduleLoading, setScheduleLoading] = useState(false);
    //TODO: ADD COUNTING OF RECEIVED AMOUNT
    const [investmentAmount, setInvestmentAmount] = useState<MoneyModel>(moneyFactory.createMoney(0, MAIN_CURRENCY()));
    const [errors, setErrors] = useState<FormErrors>({});
    const [schedule, setSchedule] = useState<PaymentSchedule | null>(null);
    const [confirmationUuid, setConfirmationUuid] = useState<UuidResult | null>(null);
    const [codeSent, setCodeSent] = useState(false);

    const validateInput = (): boolean => {
        const newErrors: FormErrors = {};
        if (investmentAmount.amount < investmentConfirmationSettings.minAmount) {
            newErrors.investmentAmount = [t(
                "errors.field_less_than",
                {
                    field: t("errors.fields.sum"),
                    type: t("errors.field_must.feminine"),
                    amount: `${investmentConfirmationSettings.minAmount / 100}`
                }
            )];
        }
        if (investmentAmount.amount >= investmentConfirmationSettings.maxAmount) {
            newErrors.investmentAmount = [t(
                "errors.field_more_than",
                {
                    field: t("errors.fields.sum"),
                    type: t("errors.field_must.feminine"),
                    amount: `${investmentConfirmationSettings.maxAmount / 100}`
                }
            )];
        }
        setErrors(newErrors);
        return !hasErrors(newErrors);
    }

    const hasErrors = (errorsToCheck = errors): boolean => {
        return Object.keys(errorsToCheck).length !== 0;
    }

    const closePage = useGoBack();

    //TODO: ADD ABORT CONTROLLER (URGENT)
    useEffectOnUpdate(() => {
        if (validateInput()) {
            setScheduleLoading(true);
            (async () => {
                try {
                    const manager = await createInvestmentManager();
                    const result = await manager.getPreliminarySchedule(uuid!, investmentAmount);
                    setSchedule(result);
                } catch (error: any) {
                    await handleError(error);
                } finally {
                    setScheduleLoading(false);
                }
            })();
        }
        return () => {
            setSchedule(null);
        }
    }, [investmentAmount]);

    const handleSubmit = async (): Promise<void> => {
        if (validateInput()) {
            setLoading(true);
            try {
                if (confirmationUuid === null) {
                    const investmentManager = await createInvestmentManager();
                    const confirmationUuid = await investmentManager.createInvestmentConfirmation(uuid!, investmentAmount);
                    setConfirmationUuid(confirmationUuid);
                }
                setCodeSent(true);
            } catch (error: any) {
                await handleError(error);
            } finally {
                setLoading(false);
            }
        }
    }

    const createInvestment = async () => {
        try {
            const manager = await createInvestmentManager();
            await manager.createInvestment(confirmationUuid!.uuid);
            await navigateByName(RouteDictionary.MY_INVESTMENTS);
        } catch (error: any) {
            await handleError(error);
        }
    }

    return (
        <CommonConfirmationSubPage closePage={closePage}>
            <PrimaryHeading>
                <Heading headingType={HeadingType.H1} className="investment-confirmation__heading">
                    <span>{ t("investment_confirmation") }</span><span>ID&nbsp;{project.paymentCode}</span>
                </Heading>
            </PrimaryHeading>
            <CommonStatsContainer>
                <CommonStatBlock
                    logo={<CurrencySvg />}
                    title={t("investment_confirmation.sum")}
                    value={<Money money={project.general.loanSum} />}
                />
                <CommonStatBlock
                    logo={<CalendarSvg />}
                    title={t("investment_confirmation.term")}
                    value={`${project.general.initialTerm * 30} ${t(resolveDayTranslation(project.general.initialTerm * 30))}`}
                />
                <CommonStatBlock
                    logo={<PercentSvg />}
                    title={t("investment_confirmation.rate")}
                    value={`${project.general.interestRate}%`}
                />
            </CommonStatsContainer>
            { loading && <div className="investment-confirmation__placeholder">
                { t("investment_confirmation.create") }
            </div> }
            <div className="investment-confirmation-main">
                <div className="investment-confirmation-main__container">
                    <CommonText large>
                        { t("investment_confirmation.balance") }: <Money money={profileData.balance!} />
                    </CommonText>
                    <div>
                        <InputMoney
                            money={investmentAmount}
                            onMoneyChanged={setInvestmentAmount}
                            text={t(
                                "investment_confirmation.input",
                                { currency: CURRENT_CURRENCY_SYMBOL() }
                            )}
                            error={errors.investmentAmount !== undefined}
                        />
                        { errors.investmentAmount && <InputErrors errors={errors.investmentAmount} /> }
                    </div>
                    <div className="investment-confirmation-main__result">
                        <CommonSubText>
                            { t("investment_confirmation.final_sum") }
                        </CommonSubText>
                        <Money money={investmentAmount} className="investment-confirmation-main__result_amount" />
                    </div>
                    <div className="investment-confirmation-main__submit">
                        <PrimaryButton expanded color={hasErrors() ? PrimaryButtonColor.GRAY : PrimaryButtonColor.BLUE}>
                            <Button disabled={hasErrors()} onClick={handleSubmit}>
                                { t("investment_confirmation.invest") }
                            </Button>
                        </PrimaryButton>
                        <div className="investment-confirmation-main__disclaimer">
                            { t("investment_confirmation.confirm") }
                        </div>
                    </div>
                </div>
            </div>
            { scheduleLoading && <div>{ t("investment_confirmation.generating_schedule") }</div> }
            { schedule !== null && <div className="investment-confirmation__schedule">
                <TertiaryHeading>
                    <Heading headingType={HeadingType.H3} className="investment-confirmation__schedule-heading">
                        { t("investment_confirmation.preliminary") }
                    </Heading>
                </TertiaryHeading>
                <PaymentsSchedule schedule={schedule} />
            </div> }
            <InvestmentConfirmationModal
                confirmationUuid={confirmationUuid}
                onSuccess={createInvestment}
                active={codeSent}
                onClose={() => setCodeSent(false)}
            />
        </CommonConfirmationSubPage>
    );
}

export default InvestmentConfirmation;