import React from "react";
import {DetailedProjectSubBlocks, DetailedProjectSubPage} from "../../index";

const DetailedProjectOffers = () => {
    return (
        <DetailedProjectSubPage>
            <DetailedProjectSubBlocks>
                Detailed Project Offers
            </DetailedProjectSubBlocks>
        </DetailedProjectSubPage>
    );
}

export default DetailedProjectOffers;