import React, {AllHTMLAttributes, FC, ReactNode} from "react";
import "./DetailedProjectProgress.scoped.scss";
import {RoundProgressBar} from "../../../../common";
import {cn} from "../../../../../utils";

interface Props extends AllHTMLAttributes<HTMLDivElement> {
    percent: number;
    text: ReactNode;
}

const DetailedProjectProgress: FC<Props> = ({ percent, text, ...props }) => {
    return (
        <div {...props} className={cn("detailed-project-progress", props.className)}>
            <RoundProgressBar percentage={percent} />
            <div className="detailed-project-progress__text">
                { text }
            </div>
        </div>
    );
}

export default DetailedProjectProgress;