import React, {FC} from "react";
import {DetailedMyActiveProject} from "../../../../../../api-client";
import ActivePaymentSchedule from "../../../../../payment-schedule/ActivePaymentSchedule";
import MyProjectScheduleHeading from "../MyProjectScheduleHeading";
import {useTranslator} from "../../../../../../hooks";

interface Props {
    project: DetailedMyActiveProject;
}

const MyActiveProjectSchedule: FC<Props> = ({ project }) => {
    const t = useTranslator();
    return (
        <>
            <MyProjectScheduleHeading>
                { t("my_detailed_project.schedule") }
            </MyProjectScheduleHeading>
            <ActivePaymentSchedule schedule={project.active.paymentSchedule} />
        </>
    );
}

export default MyActiveProjectSchedule;