import React, {FC, PropsWithChildren} from "react";
import styles from './AuthHeader.module.scss';
import {LogoImage, PublicLink} from "../../../common";
import Container from "../../common/Container";

const AuthHeader: FC<PropsWithChildren> = ({ children }) => {
    return (
        <header className={styles.auth_header}>
            <Container>
                <div className={styles.auth_header__container}>
                    { children }
                    <PublicLink url="/" className={styles.auth_header__link}>
                        <LogoImage className={styles.auth_header__logo} />
                    </PublicLink>
                </div>
            </Container>
        </header>
    );
}

export default AuthHeader;