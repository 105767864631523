import React, {FC, PropsWithChildren} from "react";
import './PaginationContent.scoped.scss';
import {usePaginationContext} from "../PaginationContext";
import {useTranslator} from "../../../hooks";

interface Props {
    loading: boolean;
    loadingMessage?: string;
    noDataMessage?: string;
}

const PaginationContent: FC<PropsWithChildren<Props>> = (
    {
        loading,
        loadingMessage = "pagination.loading",
        noDataMessage = "pagination.no_data",
        children
    }) => {
    const context = usePaginationContext();
    const t = useTranslator();
    if (loading) {
        return (
            <div className="pagination-content">{ t(loadingMessage) }</div>
        )
    }
    return (
        <>
            { context.count !== 0 ? children : <div className="pagination-content">{ t(noDataMessage) }</div> }
        </>
    );
}

export default PaginationContent;