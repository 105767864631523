import React from "react";
import {PrimaryMarket} from "../../../../components/pages/market/primary-market";
import MarketSection from "../../../../components/layout/lender/MarketSection";
import MainContainer from "../../../../components/layout/common/MainContainer";
import SecondaryMarket from "../../../../components/pages/market/secondary-market/SecondaryMarket";

const Market = () => {
    return (
        <>
            <MainContainer>
                <MarketSection>
                    <PrimaryMarket />
                </MarketSection>
            </MainContainer>
            <MainContainer>
                <MarketSection>
                    <SecondaryMarket />
                </MarketSection>
            </MainContainer>
        </>
    );
}

export default Market;