import {ApiClientInterface} from "./clients";
import {SellingOffersFilter} from "../utils";
import {Collection, SellingOffer} from "./models";
import buildQuery from "../utils/router/buildQuery";

export class OfferProvider {
    constructor(private readonly apiClient: ApiClientInterface, private readonly abortSignal?: AbortSignal) {}

    public async getOffers(page: number, perPage: number, filter?: SellingOffersFilter): Promise<Collection<SellingOffer>> {
        const query = buildQuery({ page, perPage, filter });
        return this.apiClient.get(`/selling?${query}`, {}, this.abortSignal);
    }
}