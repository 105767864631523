import React, {FC, InputHTMLAttributes, ReactNode, useId} from "react";
import {InputLabel} from "../../form";
import {cn, resultIf} from "../../../../utils";
import "./CommonTextArea.scoped.scss";

export interface CommonTextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
    value: any;
    onChange: (value: any) => void;
    text: ReactNode;
    error: boolean;
}

const CommonTextarea: FC<CommonTextAreaProps> = ({ value, onChange, text, error, ...props }) => {
    const id = useId();
    const onInput = (event: any) => {
        onChange(event.target.value);
    };
    return (
        <div className="common-input__container">
            <InputLabel id={id} text={text} />
            <textarea
                {...props}
                id={id}
                className={cn("common-input", "common-textarea", props.className, resultIf(error, "common-input--error"))}
                onChange={onInput}
                value={value}
            />
        </div>
    );
}

export default CommonTextarea;