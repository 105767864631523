import React, {FC, PropsWithChildren} from "react";
import styles from "./DetailedProjectInvestBlock.module.scss";

const DetailedProjectInvestBlock: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className={styles.detailed_project_invest_block}>
            { children }
        </div>
    );
}

export default DetailedProjectInvestBlock;