import {Translations} from "../../types";

export const funding_request: Translations = {
    "funding_request.navigation.upload_documents": "Загрузка выписки",
    "funding_request.navigation.count_available": "Расчет доступной суммы",
    "funding_request.navigation.survey": "Анкета заемщика",
    "funding_request.navigation.loan_options": "Выбор условий займа",
    "funding_request.navigation.sign_documents": "Подписание документов",
    "funding_request.upload": "Загрузите",
    "funding_request.upload.more": "Загрузите ещё",
    "funding_request.upload.restriction": "один или несколько файлов (.txt до 1 мб)",
    "funding_request.upload.restriction.more": "(.txt до 1 мб)",
    "funding_request.upload.count": "Рассчитать лимит",
    "funding_request.upload.description": "Загрузите стандартные выписки по основным расчетным счетам, чтобы узнать доступную сумму займа",
    "funding_request.upload.requirements": "Требования",
    "funding_request.upload.requirements.first": "Выписка должна включать текущий месяц (должна быть сформирована по текущую дату)",
    "funding_request.upload.requirements.second": "Выписка должна быть не менее чем за 12 месяцев",
    "funding_request.upload.requirements.third": "Если вашему бизнесу менее 1 года, загрузите выписку за весь фактический срок работы",
    "funding_request.upload.recommendations": "Рекомендации",
    "funding_request.upload.several_accounts": "Если вы используете несколько счетов в одном или нескольких банках, загрузите " +
        "выписки со всех счетов для корректного вычисления лимита компании.",
    "funding_request.available_sum.declined": "Проверка отклонена",
    "funding_request.available_sum.pending": "Ожидаем проверки со стороны администратора...",
    "funding_request.available_sum.success": "Проверка прошла успешно",
    "funding_request.available_sum.preliminary_declined": "Предварительная проверка была отклонена.",
    "funding_request.available_sum.pending_more": "Ожидается проверка со стороны администратора платформы, по результатам которой вам " +
        "будет одобрена предварительная сумма заема.",
    "funding_request.available_sum.approved_more_first": "По результатам автоматического анализа транзакций по вашему счету вам предварительно одобрена " +
        "                        сумма, в пределах которой вы сможете взять заём. Обращаем ваше внимание, что на следующих шагах " +
        "                        сумма может измениться после рассмотрения заявки андеррайтером.",
    "funding_request.available_sum.approved_more_second": "Вы можете попробовать получить более высокий лимит. Для этого загрузите дополнительные выписки с " +
        "                        других счетов (в том числе, из других банков), либо зайдите в личный кабинет других банков. Ранее " +
        "                        загруженные выписки суммируются — загрузите только дополнительные выписки.",
    "funding_request.available_sum.preliminary_approved": "Предварительно одобренная сумма",
    "funding_request.survey": "Заполните анкету заёмщика",
    "funding_request.survey.fill": "Для завершения регистрации и оформления заявки на займ заполните форму.",
    "funding_request.survey.personal_data": "Персональные данные",
    "funding_request.survey.company_data": "Информация о компании",
    "funding_request.survey.company_data.phone": "Телефон",
    "funding_request.survey.company_data.description": "Подробное описание текущей деятельности",
    "funding_request.survey.company_data.business_form": "Форма ведения бизнеса",
    "funding_request.survey.company_data.activity": "Деятельность заказчиков",
    "funding_request.survey.company_data.business_address": "Фактический адрес ведения бизнеса",
    "funding_request.survey.company_data.links": "Ссылки на сайт вашей компании или профили в социальных сетях",
    "funding_request.survey.company_data.links.add": "Добавить еще одну ссылку",
    "funding_request.survey.mark": "Оценка деловой репутации",
    "funding_request.survey.mail_for_reviews": "Если у вас имеются отзывы о деловой репутации от кредитных организаций или некредитных " +
        "финансовых организаций, в которых заемщик находился/находится на обслуживании, пришлите " +
        "их по адресу",
    "funding_request.survey.reputation.positive": "Положительная",
    "funding_request.survey.reputation.negative": "Отрицательная",
    "funding_request.survey.reputation.no_reputation": "Нет оценки",
    "funding_request.survey.reputation.no_crime": "Подтверждаю отсутствие неснятой или непогашенной судимости за преступления в сфере " +
        "экономики или преступления против государственной власти, интересов государственной " +
        "службы и службы в органах местного самоуправления",
    "funding_request.survey.reputation.no_negative": "Подтверждаю отсутствие фактов (событий, действий), которые могут оказать существенное " +
        "негативное влияние на возврат займа",
    "funding_request.survey.reputation.money_source": "Укажите основной источник оборотных средств",
    "funding_request.survey.reputation.money_source.economic": "Выручка от хозяйственой деятельности",
    "funding_request.survey.reputation.money_source.borrowed": "Заемные средства",
    "funding_request.survey.reputation.money_source.other": "Другое",
    "funding_request.survey.reputation.confirm": "Подтверждение введенных данных",
    "funding_request.survey.reputation.confirmation.first": "Предоставляю",
    "funding_request.survey.reputation.confirmation.second": "согласие на обработку " +
        "персональных данных, запрос в бюро кредитных историй и проверку данных мобильного оператора",
    "funding_request.survey.send": "Отправить анкету",
    "funding_request.loan_options": "Выберите условия займа",
    "funding_request.loan_options.sum": "Сумма, :currency (Доступный лимит от :min_amount :currency до :max_amount :currency)",
    "funding_request.loan_options.term": "Срок, мес (Максимальный срок :term мес)",
    "funding_request.loan_options.promo": "Промокод (если есть)",
    "funding_request.loan_options.send_request": "Отправить запрос",
    "funding_request.loan_options.finish_registration": "Завершить регистрацию",
    "funding_request.loan_options.schedule": "График платежей",
    "funding_request.loan_options.preliminary_warning": "Внимание! График платежей предварительный",
    "funding_request.loan_options.preliminary_explanation": "С точным графиком платежей вы сможете ознакомиться в личном кабинете после " +
        "получения займа. Погасить заём можно досрочно без штрафов и комиссий.",
    "funding_request.loan_options.terms": "Условия займа",
    "funding_request.loan_options.terms.sum": "Сумма займа",
    "funding_request.loan_options.terms.commission": "Комиссия за выдачу (:percent% от суммы займа)",
    "funding_request.loan_options.terms.final_sum": "Сумма к зачислению",
    "funding_request.loan_options.terms.schedule": "Частота к погашению",
    "funding_request.loan_options.terms.term": "Срок займа",
    "funding_request.loan_options.terms.term.monthly": "Ежемесячно",
    "funding_request.loan_options.terms.term.twice_a_month": "2 раза в месяц",
    "funding_request.loan_options.terms.term.thrice_a_month": "3 раза в месяц",
    "funding_request.loan_options.terms.term.four_times_a_month": "4 раза в месяц",
    "funding_request.loan_options.terms.term.quarterly": "Ежеквартально",
    "funding_request.loan_options.terms.term.at_the_end": "В конце периода",
    "funding_request.loan_options.terms.term.not_listed": "Не указана",
    "funding_request.sign_document": "Рассмотрение заявки и подписание документов",
    "funding_request.sign_document.sms_signed": "Подписано по СМС",
    "funding_request.sign_document.approved_manager": "Одобрено менеджером",
    "funding_request.sign_document.final_review": "Заявка передана на рассмотрение",
    "funding_request.sign_document.final_review_text.first": "Срок рассмотрения в большинстве случаев 1-2 рабочих дня.",
    "funding_request.sign_document.final_review_text.second": "Положительное решение сообщит менеджер, в некоторых случаях может потребоваться уточнение деталей.",
    "funding_request.sign_document.requisites.heading_first": "Ожидается подписание",
    "funding_request.sign_document.requisites.heading_second": "валютой",
    "funding_request.sign_document.requisites.description": "Чтобы подписать документы, переведите со счета заемщика (Счет " +
        "юридического лица или индивидуального предпринимателя) платеж в размере",
    "funding_request.sign_document.requisites.description_second": "по указанным ниже реквизитам.",
    "funding_request.sign_document.requisites.one": "Один",
    "funding_request.sign_document.requisites": "Реквизиты для отправки",
    "funding_request.sign_document.requisites.purpose": "Назначение платежа: Перевод с целью подтверждения присоединения к правилам " +
        "potok.digital, подписания инвестиционного предложения № 31.28 664. НДС не облагается",
    "funding_request.sign_document.qr_code": "QR-код для отправки",
    "funding_request.sign_document.qr_code.camera": "Наведите камеру смартфона",

    "funding_request.months.nominative": "месяц",
    "funding_request.months.genitive": "месяца",
    "funding_request.months.multiple": "месяцев"
}