import React, {FC} from "react";
import {DetailedMyFundraisingProject} from "../../../../../../api-client";
import PaymentsSchedule from "../../../../../payment-schedule/PaymentsSchedule";
import MyProjectScheduleHeading from "../MyProjectScheduleHeading";
import {useTranslator} from "../../../../../../hooks";

interface Props {
    project: DetailedMyFundraisingProject;
}

const MyFundraisingProjectSchedule: FC<Props> = ({ project }) => {
    const t = useTranslator();
    return (
        <>
            <MyProjectScheduleHeading>
                { t("my_detailed_project.schedule.preliminary") }
            </MyProjectScheduleHeading>
            <PaymentsSchedule schedule={project.fundraising.paymentSchedule} />
        </>
    );
}

export default MyFundraisingProjectSchedule;