import React, {FC, PropsWithChildren, ReactNode} from "react";
import "./CommonStatBlock.scoped.scss";
import {Image} from "../../images";
import {CommonSubText, CommonText} from "../../typography";

interface Props {
    logo: ReactNode;
    title: ReactNode;
    value: ReactNode;
}

const CommonStatBlock: FC<PropsWithChildren<Props>> = ({ logo, title, value, children }) => {
    return (
        <div className="common-stat-block">
            <div className="common-stat-block__container">
                { logo }
                <div className="common-stat-block__inner">
                    <CommonSubText small className="common-stat-block__text">
                        { title }
                    </CommonSubText>
                    <CommonText large>
                        { value }
                    </CommonText>
                </div>
            </div>
            { children }
        </div>
    );
}

export default CommonStatBlock;