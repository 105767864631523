import React, {FC} from "react";
import styles from "./MyActiveProject.module.scss";
import {MyActiveProject as MyActiveProjectModel} from "../../../../../api-client";
import {CommonSubText, CommonText, Money, ProjectLink} from "../../../../common";
import MyActiveProjectPayment from "../MyActiveProjectPayment";
import {formatDate} from "../../../../../formatters";
import MyProject from "../../MyProject";
import {createMoneyFactory} from "../../../../../di";
import {RouteDictionary} from "../../../../../utils";
import {useTranslator} from "../../../../../hooks";
import {resolveMonthTranslation} from "../../../../../i18n";
import {loansScheduleTypeTranslations} from "../../../../../views/borrower/statistics/MyLoans/MyLoans";

interface Props {
    project: MyActiveProjectModel;
}

const moneyFactory = createMoneyFactory();

const MyActiveProject: FC<Props> = ({ project }) => {
    const t = useTranslator();
    const countFinalDate = (maturityDate: Date): Date => {
        const date = new Date(maturityDate);
        return new Date(date.setMonth(date.getMonth() + project.initialTerm));
    }
    return (
        <MyProject small className={styles.my_active_project}>
            <ProjectLink to={RouteDictionary.MY_PROJECT} params={{uuid: project.uuid}}>
                <MyProject.Column className={styles.my_active_project__id}>
                    <CommonText>
                        { project.paymentCode ?? t("my_loans.not_listed") }
                    </CommonText>
                </MyProject.Column>
                <MyProject.Column className={styles.my_active_project__target}>
                    <CommonText>
                        <Money money={project.targetSum} />
                    </CommonText>
                </MyProject.Column>
                <MyProject.Column className={styles.my_active_project__paid}>
                    { project.paidSum !== undefined ? <>
                        <CommonText>
                            <Money money={project.paidSum} />
                        </CommonText>
                        <CommonSubText small>
                            { (project.paidSum.amount / project.targetSum.amount) * 100 }%
                        </CommonSubText>
                    </> : <CommonText>
                        <Money money={moneyFactory.createMoney()} />
                    </CommonText> }
                </MyProject.Column>
                <MyProject.Column className={styles.my_active_project__rate}>
                    <CommonText>
                        { project.interestRate }%
                    </CommonText>
                </MyProject.Column>
                <MyProject.Column className={styles.my_active_project__term}>
                    <CommonText>
                        { project.initialTerm } { t(resolveMonthTranslation(project.initialTerm)) }
                    </CommonText>
                    <CommonSubText small>
                        { project.maturityDate !== undefined && formatDate(project.maturityDate) + "-" + formatDate(countFinalDate(project.maturityDate)) }
                    </CommonSubText>
                </MyProject.Column>
                <MyProject.Column className={styles.my_active_project__schedule}>
                    <CommonText>
                        { t(loansScheduleTypeTranslations[project.scheduleType]) }
                    </CommonText>
                </MyProject.Column>
                <MyProject.Column className={styles.my_active_project__next}>
                    <CommonText>
                        { project.nextPayment !== undefined && <MyActiveProjectPayment payment={project.nextPayment} /> }
                    </CommonText>
                </MyProject.Column>
            </ProjectLink>
        </MyProject>
    );
}

export default MyActiveProject;