import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {CompanyProvider} from "../CompanyProvider";

export class CompanyProviderFactory {
    private readonly apiClientFactory: ApiClientFactoryInterface;

    constructor(baseUrl: string, headers: Headers) {
        this.apiClientFactory = new ApiClientFactory(
            `${baseUrl}/api/v1/company`,
            headers
        )
    }

    public createCompanyProvider(abortSignal?: AbortSignal): CompanyProvider {
        return new CompanyProvider(this.apiClientFactory.createClient(), abortSignal);
    }
}