import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {createMyProjectsProvider} from "../../../../di";
import {useErrorCallback} from "../../../../hooks";
import {Loader} from "../../../../components/common";
import {DetailedMyProject} from "../../../../api-client";
import LoaderContainer from "../../../../components/loading/LoaderContainer";
import MyDetailedProject from "../../../../components/pages/my-projects/detailed/MyDetailedProject";
import MyDetailedProjectPlaceholder
    from "../../../../components/pages/my-projects/detailed/MyDetailedProjectPlaceholder/MyDetailedProjectPlaceholder";

const MyProject = () => {
    const { uuid } = useParams();
    const handleError = useErrorCallback();
    const [loading, setLoading] = useState(true);
    const [project, setProject] = useState<DetailedMyProject | undefined>(undefined);

    useEffect(() => {
        const controller = new AbortController();
        (async () => {
            if (uuid !== undefined) {
                setLoading(true);
                try {
                    const provider = await createMyProjectsProvider(controller.signal);
                    const project = await provider.getOne(uuid);
                    setProject(project);
                } catch (error: any) {
                    await handleError(error);
                } finally {
                    setLoading(false);
                }
            }
        })();
        return () => {
            controller.abort();
        }
    }, []);
    return (
        <>
            { loading && <LoaderContainer>
                <Loader />
            </LoaderContainer> }
            { !loading && project === undefined && <MyDetailedProjectPlaceholder /> }
            { !loading && project !== undefined && <MyDetailedProject project={project} /> }
        </>
    );
}

export default MyProject;