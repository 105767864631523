import React from "react";
import {LayoutLenderCabinet} from "../../layouts";
import {Outlet} from "react-router-dom";

const LenderCabinetDashboard = () => {
    return (
        <LayoutLenderCabinet>
            <Outlet />
        </LayoutLenderCabinet>
    );
}

export default LenderCabinetDashboard;