import React, {FC, PropsWithChildren} from "react";
import CommonBlockExtension from "../CommonBlockExtension";

const CommonBlockFooter: FC<PropsWithChildren> = ({ children }) => {
    return (
        <CommonBlockExtension tag="footer" underscore={false}>
            { children }
        </CommonBlockExtension>
    );
}

export default CommonBlockFooter;