import {WORD_CASE, WordCase} from "../utils";

export const resolveWordCase = (amount: number): WordCase => {
    amount = amount % 100;
    if (amount % 10 === 1 && amount !== 11) {
        return WORD_CASE.NOMINATIVE;
    }
    if (amount % 10 >= 2 && amount % 10 <= 4 && ![12, 13, 14].includes(amount)) {
        return WORD_CASE.GENITIVE;
    }
    return WORD_CASE.MULTIPLE;
}