import React, {PropsWithChildren, ReactNode} from "react";
import {OptionBox} from "../../../common";
import {OptionBoxProps} from "../../../common/selects/OptionBox/OptionBox";
import DropdownDownSvg from "../../../svg/dropdown/DropdownDownSvg";
import {ColorDictionary, getCssVariable} from "../../../../utils";
import "./AnalyticsOptionBox.scoped.scss";
import {OptionBoxContext} from "../../../common/selects/OptionBox/OptionBoxContext";
import DropdownOpener from "../../../common/utils/DropdownOpener";

interface Props<T> extends OptionBoxProps<T> {
    onSelected: (option: T) => void;
    renderSelectedOption?: (option: T) => ReactNode;
}

const AnalyticsOptionBox = <T, >({ options, selectedOption, onSelected, renderSelectedOption, renderOption }: PropsWithChildren<Props<T>>) => {
    const mappedRenderOption = (option: T) => {
        return <div className="analytics-option-box__item">
            { renderOption !== undefined ? renderOption(option) : "Placeholder" }
        </div>
    }
    return (
        <div className="analytics-option-box">
            <OptionBox options={options} selectedOption={selectedOption} renderOption={mappedRenderOption}>
                <OptionBox.Head>
                    <OptionBoxContext.Consumer>
                        {({ active, selectedOption }) => <>
                            <div className="analytics-option-box__head">
                                <span>
                                    { renderSelectedOption !== undefined ? renderSelectedOption(selectedOption) : selectedOption }
                                </span>
                                <DropdownOpener active={active}>
                                    <DropdownDownSvg color={getCssVariable(ColorDictionary.WHITE)} />
                                </DropdownOpener>
                            </div>
                        </>}
                    </OptionBoxContext.Consumer>
                </OptionBox.Head>
                <OptionBox.Content onSelected={onSelected} className="analytics-option-box__content" />
            </OptionBox>
        </div>
    );
}

export default AnalyticsOptionBox;