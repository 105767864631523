import React, {useEffect, useState} from "react";
import "./Introduction.scoped.scss";
import {useIntroductionStatus} from "../../../../hooks/useIntroductionStatus";
import {useEffectOnUpdate, useErrorCallback, useNavigateByName} from "../../../../hooks";
import {RouteDictionary} from "../../../../utils";
import {FundingRequest} from "../../../../api-client";
import FundingRequestContextProvider, {
    FundingRequestContextType,
    FundingRequestStep,
    resolveFundingRequestStep
} from "../../../../components/pages/funding-request/FundingRequestContext";
import MainGrid from "../../../../components/layout/common/MainGrid";
import IntroductionAside from "../../../../components/pages/introduction/IntroductionAside";
import SubPageMain from "../../../../components/layout/common/SubPageMain";
import {MultiStepForm} from "../../../../components/common";
import FundingRequestUpload from "../../../../components/pages/funding-request/FundingRequestUpload";
import IntroductionIdentification from "../../../../components/pages/introduction/IntroductiontIdentification";
import FundingRequestOptions from "../../../../components/pages/funding-request/FundingRequestOptions";
import FundingRequestSignDocument from "../../../../components/pages/funding-request/FundingRequestSignDocument";
import {createProjectRequestManager} from "../../../../di";
import IntroductionAvailableSum from "../../../../components/pages/introduction/IntroductionAvailableSum";

const Introduction = () => {
    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const introductionCompleted = useIntroductionStatus();
    const navigate = useNavigateByName();

    useEffect(() => {
        if (introductionCompleted) {
            navigate(RouteDictionary.MY_PROJECTS);
            return;
        }
        setInitialLoading(false);
    }, []);

    const handleError = useErrorCallback();
    const [step, setStep] = useState(FundingRequestStep.UploadDocuments);
    const [request, setRequest] = useState<FundingRequest | null>(null);
    useEffectOnUpdate(() => {
        (async () => {
            try {
                const manager = await createProjectRequestManager();
                const result = await manager.getUsersFirst();
                setRequest(result);
                setStep(resolveFundingRequestStep(result));
            } catch (error: any) {
                await handleError(error);
            } finally {
                setLoading(false);
            }
        })()
    }, [initialLoading]);

    const value: FundingRequestContextType = {
        step,
        setStep,
        request,
        setRequest
    }

    const handleUpload = () => {
        setStep(FundingRequestStep.CountAvailableSum);
    }

    return (
        <>
            { !loading && <FundingRequestContextProvider props={value}>
                <MainGrid subpage>
                    { loading ? <div>Загрузка...</div> : <>
                        <IntroductionAside />
                        <SubPageMain>
                            <MultiStepForm step={step}>
                                <FundingRequestUpload onUploaded={handleUpload} />
                                <IntroductionAvailableSum onNextStep={() => setStep(FundingRequestStep.FillSurvey)} />
                                <IntroductionIdentification />
                                <FundingRequestOptions />
                                <FundingRequestSignDocument />
                            </MultiStepForm>
                        </SubPageMain>
                    </> }
                </MainGrid>
            </FundingRequestContextProvider> }
        </>
    )
}

export default Introduction;