import React from "react";
import {Outlet} from "react-router-dom";
import MainContainer from "../../components/layout/common/MainContainer";

const LenderCabinetOther = () => {
    return (
        <MainContainer max>
            <Outlet />
        </MainContainer>
    );
}

export default LenderCabinetOther;