import React, {FC} from "react";
import {SvgProps} from "../SvgProps";
import {defaultArrowColor} from "./detaultArrowColor";
import SvgContainer from "../SvgContainer";
import {SvgContainerSize} from "../SvgContainer/SvgContainer";

const ArrowDown: FC<SvgProps> = ({ color = defaultArrowColor }) => {
    return (
        <SvgContainer size={SvgContainerSize.Small}>
            <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 11V1M5 11L9 7M5 11L1 7" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
            </svg>
        </SvgContainer>
    );
}

export default ArrowDown;