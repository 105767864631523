import React, {FC, PropsWithChildren} from "react";
import './CommonAside.scoped.scss';
import {CommonAsideLink} from "./extensions";
import {LogoImage, PublicLink} from "../../../common";
import {RouteDictionary} from "../../../../utils";
import {ChatSvg, LogoutSvg} from "../../../svg";
import CommonAsideStep from "./extensions/CommonAsideStep";
import {useTranslator} from "../../../../hooks";

type CommonAsideExtensions = {
    Step: typeof CommonAsideStep;
    Link: typeof CommonAsideLink;
}

interface Props {
    showLogo?: boolean;
}

const CommonAside: FC<PropsWithChildren<Props>> & CommonAsideExtensions = ({ showLogo= true, children }) => {
    const t = useTranslator();
    return (
        <aside className="common-aside">
            <nav className="common-aside__navigation">
                { showLogo && <div className="common-aside__logo">
                    <PublicLink url="/">
                        <LogoImage />
                    </PublicLink>
                </div> }
                <ul className="common-aside__list">
                    { children }
                </ul>
            </nav>
            <div className="common-aside__list">
                <CommonAsideLink to={RouteDictionary.CONTACT_US} icon={<ChatSvg />}>
                    { t("navigation.contact_us") }
                </CommonAsideLink>
                <CommonAsideLink to={RouteDictionary.LOGOUT} icon={<LogoutSvg />}>
                    { t("navigation.logout") }
                </CommonAsideLink>
            </div>
        </aside>
    );
}

CommonAside.Step = CommonAsideStep;
CommonAside.Link = CommonAsideLink;

export default CommonAside;