import {ApiClientInterface} from "./clients";
import {BorrowerCompany, BorrowerCompanyData, BorrowerCompanyRepresentative} from "./models";

export class BorrowerCompanyManager {
    constructor(private readonly apiClient: ApiClientInterface) {}

    public async getUserCompany(borrowerUuid: string): Promise<BorrowerCompany | null> {
        return this.apiClient.get(`/${borrowerUuid}`);
    }

    public async create(data: BorrowerCompanyData, representative: BorrowerCompanyRepresentative): Promise<BorrowerCompany> {
        return this.apiClient.post("", {data, representative});
    }
}