import React, {useEffect} from "react";
import {useOverlayState, useAppDispatch} from "../../../../hooks";
import './Overlay.scoped.scss';
import {set} from "../../../../store/overlay";
import {cn, resultIf} from "../../../../utils";
import {useLocation} from "react-router-dom";

const Overlay = () => {
    const dispatch = useAppDispatch();
    const isOverlayActive = useOverlayState();
    const location = useLocation();

    const closeOverlay = () => {
        dispatch(set(false));
    }

    useEffect(() => {
        closeOverlay();
    }, [location]);

    useEffect(() => {
        closeOverlay();
        const callback = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                closeOverlay();
            }
        }
        window.addEventListener('keyup', callback);
        return () => {
            window.removeEventListener('keyup', callback);
        }
    }, []);

    return (
        <div
            id="overlay"
            className={cn(resultIf(isOverlayActive, "active"))}
            onClick={closeOverlay}
        />
    );
}

export default Overlay;