import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useErrorCallback, useTranslator} from "../../../../hooks";
import {DetailedProject as DetailedProjectModel, Investment} from "../../../../api-client";
import {createInvestmentManager, createProjectManager} from "../../../../di";
import ContentContainer from "../../../../components/layout/common/ContentContainer";
import {Loader} from "../../../../components/common";
import Section from "../../../../components/layout/common/Section";
import {DetailedProject as DetailedProjectComponent} from "../../../../components/pages/detailed-project";

//TODO: REWORK INVESTMENT MODEL
const DetailedInvestment = () => {
    const { uuid, investmentUuid } = useParams();
    const t = useTranslator();
    const handleError = useErrorCallback();
    const [project, setProject] = useState<DetailedProjectModel | null>(null);
    const [investment, setInvestment] = useState<Investment | null>(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        (async () => {
            try {
                const projectManager = await createProjectManager();
                const investmentManager = await createInvestmentManager();
                const [project, investment] = await Promise.all([
                    projectManager.getDetailedProject(uuid!),
                    investmentManager.getDetailedInvestment(investmentUuid!)
                ]);
                setProject(project);
                setInvestment(investment);
            } catch (error: any) {
                await handleError(error);
            } finally {
                setLoading(false);
            }
        })();
    }, []);
    return (
        <ContentContainer>
            { loading && <div className="detailed-project__loader">
                <Loader />
            </div> }
            { !loading && <Section className="detailed-project-section">
                { project === null || investment === null ? <div className="detailed-project__placeholder">
                    { t("detailed_project.not_found") }
                </div> : <DetailedProjectComponent project={project} investment={investment} /> }
            </Section> }
        </ContentContainer>
    );
}

export default DetailedInvestment;