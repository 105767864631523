import React, {AllHTMLAttributes, FC, PropsWithChildren} from "react";
import styles from "./MediumText.module.scss";
import {cn, resultIf} from "../../../../utils";

interface Props extends AllHTMLAttributes<HTMLSpanElement> {
    accent?: boolean;
}

const MediumText: FC<PropsWithChildren<Props>> = ({ accent = false, children, ...props }) => {
    return (
        <span {...props} className={cn(styles.medium_text, resultIf(accent, styles.medium_text___accent), props.className)}>
            { children }
        </span>
    );
}

export default MediumText;