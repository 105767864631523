import {QueryObject} from "./buildQuery";

export function getQueryObject<T extends QueryObject = {}>(query: string): T {
    query = query.replaceAll("%5B", "[").replaceAll("%5D", "]");
    let queryObject: QueryObject = {};
    if (query === "") {
        return queryObject as T;
    }
    if (!query.startsWith("?")) {
        throw new Error("Given string does not match query parameters");
    }
    let queryParams = query.slice(1).split("&");
    queryParams.forEach((param) => {
        let [paramKey, paramValue] = param.split("=");
        const key = paramKey.replace(/\[.*]/, '');
        if (!Object.prototype.hasOwnProperty.call(queryObject, key)) {
            Object.defineProperty(queryObject, key, {
                value: paramKey !== key ? {} : paramValue,
                configurable: true,
                writable: true,
                enumerable: true
            });
        }
        if (paramKey !== key) {
            const isArray = paramKey.slice(-2) === "[]";
            const isDate = /^\d{4}-\d{2}-\d{2}$/.test(paramValue);
            const nested = paramKey.slice(key.length + 1, -1).split("][").filter((elem) => {
                return elem !== "";
            });
            const current = queryObject[key as keyof typeof queryObject];
            nested.reduce((prev, cur, index, array) => {
                const isLast = index === array.length - 1;
                const value = !isLast ? {} : (isArray ? [] : undefined);
                if (!Object.prototype.hasOwnProperty.call(prev, cur)) {
                    Object.defineProperty(prev, cur, {
                        value,
                        writable: true,
                        enumerable: true
                    });
                }
                if (isLast) {
                    if (isArray) {
                        prev[cur].push(isDate ? new Date(paramValue) : paramValue);
                    } else if (isDate) {
                        prev[cur] = new Date(paramValue);
                    } else {
                        prev[cur] = paramValue
                    }
                }
                return prev[cur];
            }, current);
        }

    });
    return queryObject as T;
}