import React, {FC, PropsWithChildren} from "react";
import {UploadedFile} from "../../../../api-client";
import {createUploadManager} from "../../../../di/apiClient";
import './FileUpload.scoped.scss';
import {sleep} from "../../../../utils";

interface Props {
    files: Array<UploadedFile>;
    onUploaded: (files: Array<UploadedFile>) => void;
    onLoading: (loading: boolean) => void;
    id: string;
    accept?: string;
    multiple?: boolean;
}

const FileUpload: FC<PropsWithChildren<Props>> = (
        {
            files,
            onUploaded,
            onLoading,
            id,
            accept= ".pdf, .jpg, .jpeg, .png, .gif, .tiff, .doc, .docx",
            multiple = true,
            children
        }
    ) => {
    const uploadFiles = async (e: { target: HTMLInputElement }) => {
        onLoading(true);
        const manager = await createUploadManager();
        const userFiles = e.target.files as FileList || [];
        if (!multiple) {
            manager.uploadFile(userFiles[0]).then((data) => {
                onUploaded([data]);
                onLoading(false);
            });
            return;
        }
        const promises: Array<Promise<UploadedFile | void>> = [];
        for (const file of userFiles) {
            promises.push(manager.uploadFile(file).then((data: UploadedFile) => {
                onUploaded([...files, data]);
            }));
        }
        Promise.all(promises).finally(() => onLoading(false));
    }
    return (
        <>
            <label htmlFor={id} className="file-upload__label">
                { children }
            </label>
            <input
                className="file-upload__input"
                id={id}
                type="file"
                accept={accept}
                multiple={multiple}
                onChange={uploadFiles}
            />
        </>
    );
}

export default FileUpload;