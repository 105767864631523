import React, {useContext} from "react";
import MoneyProgress from "../../../../progress/MoneyProgress";
import {CommonSubText, PrimaryButton, ProjectLink} from "../../../../common";
import {resolveMonthTranslation} from "../../../../../i18n";
import {useTranslator} from "../../../../../hooks";
import {DetailedProjectContext} from "../../DetailedProjectContext";
import DetailedProjectInvestBlocks from "../DetailedProjectInvest/extensions/DetailedProjectInvestBlocks";
import DetailedProjectInvestBlock from "../DetailedProjectInvest/extensions/DetailedProjectInvestBlock";
import {PrimaryButtonColor} from "../../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {RouteDictionary} from "../../../../../utils";
import {DETAILED_PROJECT_TAB} from "../DetailedProjectNavigation/DetailedProjectNavigation";
import {CommonBlock} from "../../../../common/blocks";

//TODO: ADD REAL NUMBER OF OFFERS
const LoanPaymentProjectInvestInfo = () => {
    const t = useTranslator();
    const {project} = useContext(DetailedProjectContext);
    return (
        <>
            <CommonBlock.Header>
                <MoneyProgress
                    firstPoint={{ name: t("detailed_project.invest.paid"), money: project.general.investedSum }}
                    secondPoint={{ name: t("detailed_project.invest.loan_sum"), money: project.general.loanSum }}
                />
            </CommonBlock.Header>
            <CommonBlock.Section underscore={false}>
                <DetailedProjectInvestBlocks>
                    <DetailedProjectInvestBlock>
                        <CommonSubText>
                            { t("detailed_project.invest.rate") }
                        </CommonSubText>
                        <div className="detailed-project-invest__value">
                            {project.general.interestRate}%
                        </div>
                    </DetailedProjectInvestBlock>
                    <DetailedProjectInvestBlock>
                        <CommonSubText>
                            { t("detailed_project.invest.term") }
                        </CommonSubText>
                        <div className="detailed-project-invest__value">
                            {project.general.initialTerm} { t(resolveMonthTranslation(project.general.initialTerm)) }
                        </div>
                    </DetailedProjectInvestBlock>
                    <DetailedProjectInvestBlock>
                        <CommonSubText>
                            { t("detailed_project.invest.number_of_offers") }
                        </CommonSubText>
                        <div className="detailed-project-invest__value">
                            24
                        </div>
                    </DetailedProjectInvestBlock>
                </DetailedProjectInvestBlocks>
                <div className="detailed-project-invest__submit">
                    <PrimaryButton expanded color={PrimaryButtonColor.BLUE}>
                        <ProjectLink
                            to={RouteDictionary.DETAILED_PROJECT}
                            params={{uuid: project.uuid}}
                            queryObject={{ tab: DETAILED_PROJECT_TAB.OFFERS }}
                        >
                            { t("detailed_project.invest.buy") }
                        </ProjectLink>
                    </PrimaryButton>
                </div>
            </CommonBlock.Section>
        </>
    );
}

export default LoanPaymentProjectInvestInfo;