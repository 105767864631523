import {ApiClientInterface} from "./clients";
import {Money, UuidResult} from "./models";

export class WithdrawManager {
    constructor(private readonly apiClient: ApiClientInterface) {}

    public async create(money: Money): Promise<UuidResult> {
        return this.apiClient.post("", { money });
    }

    public async cancel(uuid: string): Promise<void> {
        return this.apiClient.post(`/${uuid}/cancel`);
    }
}