import {Translations} from "../../types";

export const identification: Translations = {
    "identification.request": "Ձեր դիմումը",
    "identification.status": "Դիմումի կարգավիճակը",
    "identification": "Ներդրողի կարգավիճակի համար դիմում",
    "identification.disclaimer": "Վարկի գրանցումը և դիմումը լրացնելու համար լրացրեք ձևաթուղթը։",
    "identification.personal_data": "Անձնական տվյալներ",
    "identification.your_status": "Ձեր դիմումի կարգավիճակը",
    "identification.phone_number": "Հեռախոսահամար",
    "identification.phone_confirmed": "Հեռախոսը հաստատված է",
    "identification.passport_data": "Անձնագրային տվյալներ",
    "identification.passport_files": "Նույնականացում",
    "identification.passport_files.disclaimer": "Վերբեռնեք նշված լուսանկարները կամ փաստաթղթերի սկանավորումները, որոնք կպահանջվեն " +
        "նույնականացման համար: Առաջարկվող լուծաչափը առնվազն 1200×800px է մինչև 10 մբ:",
    "identification.confirmation": "Մուտքագրված տվյալների հաստատում",
    "identification.agree": "Համաձայնվել",
    "identification.promotion_conditions": "ներդրումները խթանելու պայմաններով",
    "identification.data_policy": "անձնական տվյալների մշակման քաղաքականության հետ",
    "identification.send": "Հայտ ներկայացնել",
    "identification.draft": "Ձեր դիմումը վերանայվում է ադմինիստրատորի կողմից...",
}