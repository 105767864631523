import React, {FC} from "react";
import "./DetailedProjectProceedingHistory.scoped.scss";
import {ProceedingHistoryItem} from "../../../../../api-client";
import {CommonSubText, Heading, Money, SecondaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import {DetailedProjectDatatable, DetailedProjectSubBlock} from "../../index";
import {formatDate} from "../../../../../formatters";
import {ProjectStatus} from "../../../../../api-client";
import {useTranslator} from "../../../../../hooks";
import {dateDiff} from "../../../../../utils";
import {resolveDayTranslation, resolveTimesTranslation} from "../../../../../i18n";

interface Props {
    proceedingHistory: Array<ProceedingHistoryItem>
}

type ValidProjectStatuses = ProjectStatus.FUND_RAISING
    | ProjectStatus.FINISHED
    | ProjectStatus.LOAN_PAYMENT
    | ProjectStatus.PAID
    | ProjectStatus.REFUNDED;

const projectStatusTranslations = {
    [ProjectStatus.FUND_RAISING]: "detailed_project.status.fundraising",
    [ProjectStatus.FINISHED]: "detailed_project.status.finished",
    [ProjectStatus.LOAN_PAYMENT]: "detailed_project.status.loan_payment",
    [ProjectStatus.PAID]: "detailed_project.status.paid",
    [ProjectStatus.REFUNDED]: "detailed_project.status.refunded",
}

const DetailedProjectProceedingHistory: FC<Props> = ({ proceedingHistory }) => {
    const t = useTranslator();
    const renderItems = () => {
        return proceedingHistory.map((item, index) => {
            const { paymentCode, status } = item;
            return <DetailedProjectDatatable.Row key={index}>
                <DetailedProjectDatatable.Text>
                    { paymentCode.split("-").shift() }
                </DetailedProjectDatatable.Text>
                <DetailedProjectDatatable.Text>
                    { t(projectStatusTranslations[status as ValidProjectStatuses]) }
                </DetailedProjectDatatable.Text>
                <DetailedProjectDatatable.Text>
                    {formatDate(new Date(item.loanDate))}
                </DetailedProjectDatatable.Text>
                <DetailedProjectDatatable.Text>
                    <Money money={item.loanSum} />
                </DetailedProjectDatatable.Text>
                <DetailedProjectDatatable.Text>
                    {item.interestRate}%
                </DetailedProjectDatatable.Text>
                <DetailedProjectDatatable.Text>
                    {dateDiff(item.initialTerm, item.loanDate)} { t(resolveDayTranslation(dateDiff(item.initialTerm, item.loanDate))) }
                </DetailedProjectDatatable.Text>
                <DetailedProjectDatatable.Text>
                    <Money money={item.principalLoan} />
                </DetailedProjectDatatable.Text>
                <DetailedProjectDatatable.Text>
                    {item.overdueCount} { t(resolveTimesTranslation(item.overdueCount)) }
                </DetailedProjectDatatable.Text>
                <DetailedProjectDatatable.Text>
                    {item.maxOverdueDays} { t(resolveDayTranslation(item.maxOverdueDays)) }
                </DetailedProjectDatatable.Text>
            </DetailedProjectDatatable.Row>
        })
    }
    return (
        <DetailedProjectSubBlock>
            <SecondaryHeading smaller>
                <Heading headingType={HeadingType.H2}>
                    { t("detailed_project.proceeding_history") }
                </Heading>
            </SecondaryHeading>
            <DetailedProjectDatatable className="detailed-project-history-datatable">
                <DetailedProjectDatatable.Row>
                    <CommonSubText>
                        { t("detailed_project.proceeding_history.id") }
                    </CommonSubText>
                    <CommonSubText>
                        { t("detailed_project.proceeding_history.status") }
                    </CommonSubText>
                    <CommonSubText>
                        { t("detailed_project.proceeding_history.date") }
                    </CommonSubText>
                    <CommonSubText>
                        { t("detailed_project.proceeding_history.sum") }
                    </CommonSubText>
                    <CommonSubText>
                        { t("detailed_project.proceeding_history.rate") }
                    </CommonSubText>
                    <CommonSubText>
                        { t("detailed_project.proceeding_history.term") }
                    </CommonSubText>
                    <CommonSubText>
                        { t("detailed_project.proceeding_history.remainder") }
                    </CommonSubText>
                    <CommonSubText>
                        { t("detailed_project.proceeding_history.delay_count") }
                    </CommonSubText>
                    <CommonSubText>
                        { t("detailed_project.proceeding_history.max_delay") }
                    </CommonSubText>
                </DetailedProjectDatatable.Row>
                { renderItems() }
            </DetailedProjectDatatable>
        </DetailedProjectSubBlock>
    );
}

export default DetailedProjectProceedingHistory;