import {Translations} from "../../types";

export const analytics: Translations = {
    "analytics.navigation.platform": "Հարթակ",
    "analytics.navigation.mkk": "IWC",
    "analytics.user": "Վերլուծություն",
    "analytics.user.error": "Սխալ է տեղի ունեցել, վերլուծությունը չի գտնվել",
    "analytics.user.navigation.volume": "Տրված վարկերի ծավալը",
    "analytics.user.navigation.yield": "Բերքատվությունը",
    "analytics.user.navigation.defaults": "Կանխադրվածներ",
    "analytics.user.navigation.penalties": "Տույժեր",
    "analytics.user.period.twelve_months": "Վերջին 12 ամիսները",
    "analytics.user.period.six_months": "Վերջին 6 ամիսները",
    "analytics.user.period.three_months": "Վերջին 3 ամիսները",
    "analytics.user.period.year_only": "Միայն :year թ",
    "analytics.user.period": "Ժամանակաշրջան",
    "analytics.user.type": "Վարկի տեսակը",
    "analytics.user.type.buyback": "հետ գնել",
    "analytics.user.volume.month": "Ամիս",
    "analytics.user.volume.issued": "Թողարկված",
    "analytics.user.volume.borrower_number": "Վարկառուների թիվը",
    "analytics.user.volume.average_rate": "Միջին դրույքաչափը",
    "analytics.user.volume.average_term": "Միջին ժամկետ",
    "analytics.user.volume.average_sum": "Միջին գումարը",
    "analytics.user.profitabilities.month": "Ամիս",
    "analytics.user.profitabilities.irr": "IRR",
    "analytics.user.profitabilities.result": "Կուտակված արդյունք",
    "analytics.user.penalties.navigation.number": "Քանակ",
    "analytics.user.penalties.navigation.sum": "Գումար",
    "analytics.user.penalties.navigation.percent": "% գումարից",
    "analytics.user.default.header.month": "Ամիս",
    "analytics.user.default.header.all": "Ընդամենը",
    "analytics.user.default.header.returned": "Վերադարձել է",
    "analytics.user.default.header.defaulted": "լռելյայն վիճակում",
    "analytics.platform_statistics": "Վիճակագրություն",
    "analytics.platform_statistics.issued_first": "Տրված վարկեր",
    "analytics.platform_statistics.issued_second": "կայքի մեջ",
    "analytics.platform_statistics.number_of_loans": "Վարկերի քանակը կայքում",
    "analytics.platform_statistics.number_of_borrowers": "Վարկառուների թիվը",
    "analytics.platform_statistics.number_of_lenders": "Ներդրողների թիվը",
    "analytics.platform_statistics.paid_first": "Վճարված",
    "analytics.platform_statistics.paid_second": "ներդրողներ",
    "analytics.platform_statistics.yield": "Ելք բոլոր ժամանակների համար",

    "analytics.user_statistics": "Статистика",
    "analytics.user_statistics.issued": "Проектов инвестировано",
    "analytics.user_statistics.invested": "Инвестированная сумма",
    "analytics.user_statistics.number_of_borrowers": "Кол-во заемщиков",
    "analytics.user_statistics.paid_first": "Получено",
    "analytics.user_statistics.yield": "Доходность за все время",
}