import React, {FC} from "react";
import styles from "./MyDetailedProject.module.scss";
import {DetailedMyProject} from "../../../../../api-client";
import MyProjectContextProvider, {MyProjectContextType} from "../MyProjectContext";
import CommonBackButton from "../../../../common/buttons/CommonBackButton";
import {Heading, PrimaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import MyProjectAside from "../aside/MyProjectAside";
import MyProjectScheduleResolver from "../schedule/MyProjectScheduleResolver";

interface Props {
    project: DetailedMyProject;
}

const MyDetailedProject: FC<Props> = ({ project }) => {
    const value: MyProjectContextType = {
        project: project
    }
    return (
        <div className={styles.my_detailed_project}>
            <MyProjectContextProvider props={value}>
                <div className={styles.my_detailed_project__main}>
                    <div className={styles.my_detailed_project__container}>
                        <CommonBackButton className={styles.my_detailed_project__back} />
                        <PrimaryHeading>
                            <Heading headingType={HeadingType.H1} className={styles.my_detailed_project__heading}>
                                ID {project.paymentCode}
                            </Heading>
                        </PrimaryHeading>
                        <MyProjectScheduleResolver />
                    </div>
                </div>
                <div className={styles.my_detailed_project__aside}>
                    <div className={styles.my_detailed_project__aside_container}>
                        <MyProjectAside />
                    </div>
                </div>
            </MyProjectContextProvider>
        </div>
    );
}

export default MyDetailedProject;