import React, {useMemo, useState} from "react";
import "./PrimaryMarket.scoped.scss";
import {useErrorCallback, useToggle, useTranslator} from "../../../../../hooks";
import {compareProjectsFilter, DataCollection, FundraisingProjectsFilter} from "../../../../../utils";
import {FundRaisingProject} from "../../../../../api-client";
import {createProjectManager} from "../../../../../di";
import {AsLink, Button, Heading, SecondaryButton, SecondaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import {SecondaryButtonColor} from "../../../../common/buttons/decorators/SecondaryButton/SecondaryButton";
import {Pagination, PaginationContent, PaginationHeader, PaginationPaginator} from "../../../../pagination";
import {SortingSvg} from "../../../../svg";
import {PrimaryMarketOffer} from "../index";
import SortingDirectionBlock from "../../../../common/utils/SortingDirectionBlock";
import FilterSvg from "../../../../svg/FilterSvg";
import ProjectsFilterModal from "../../../../modals/ProjectsFilterModal";
import MarketCount from "../../../../layout/lender/MarketCount";
import MarketHeading from "../../../../layout/lender/MarketHeading";
import MarketButtons from "../../../../layout/lender/MarketButtons";
import MarketHeader from "../../../../layout/lender/MarketHeader";

const defaultFilter: FundraisingProjectsFilter = {};

const PrimaryMarket = () => {
    const t = useTranslator();
    const handleError = useErrorCallback();
    const [loading, setLoading] = useState(false);
    const [projects, setProjects] =
        useState<DataCollection<FundRaisingProject>>(new DataCollection());
    const [filterModalOpened, toggleFilterModalOpened] = useToggle();

    const [filter, setFilter] = useState<FundraisingProjectsFilter>(defaultFilter);

    const loadData = async (page: number, perPage: number, filter?: FundraisingProjectsFilter): Promise<void> => {
        setLoading(true);
        try {
            const projectManager = await createProjectManager();
            const projects = await projectManager.getFundRaisingProjects(page, perPage, filter);
            const dataCollection = new DataCollection<FundRaisingProject>();
            dataCollection.setCollection(projects);
            setProjects(dataCollection);
        } catch (error: any) {
            await handleError(error);
        } finally {
            setLoading(false);
        }
    }

    const renderProjects = useMemo(() => {
        return projects.items.map((project) => {
            return <PrimaryMarketOffer project={project} key={project.uuid} />
        });
    }, [projects]);

    return (
        <div className="primary-market">
            <MarketHeader>
                <MarketHeading>
                    <SecondaryHeading smaller>
                        <Heading headingType={HeadingType.H2}>
                            { t("market.primary") }
                        </Heading>
                    </SecondaryHeading>
                    { !loading && <MarketCount count={projects.totalCount} /> }
                </MarketHeading>
                <MarketButtons>
                    <SecondaryButton color={SecondaryButtonColor.GRAY}>
                        <AsLink>
                            <Button onClick={toggleFilterModalOpened} className="primary-market__button primary-market__button--sorting">
                                <span>
                                    { t("market.primary.filters") }
                                </span>
                                <SortingSvg color={filterModalOpened ? "#262B39" : "#ffffff"} />
                            </Button>
                        </AsLink>
                    </SecondaryButton>
                    <SecondaryButton color={SecondaryButtonColor.GRAY}>
                        <AsLink>
                            <Button onClick={toggleFilterModalOpened} className="primary-market__button">
                                <span>
                                    { t("market.primary.filters") }
                                </span>
                                <FilterSvg />
                            </Button>
                        </AsLink>
                    </SecondaryButton>
                </MarketButtons>
            </MarketHeader>
            <Pagination
                onLoad={loadData}
                count={projects.count}
                totalCount={projects.totalCount}
                filterObject={filter}
                initialChangeFilter={(value: Partial<FundraisingProjectsFilter>) => setFilter({...filter, ...value})}
                compareFilters={compareProjectsFilter}
            >
                <PaginationContent loading={loading}>
                    <PaginationHeader className="primary-market__list-header">
                        <div>
                            { t("market.primary.company") }
                        </div>
                        <SortingDirectionBlock
                            direction={filter.loanAmountSorting}
                            handleChange={(direction) => setFilter({...filter, loanAmountSorting: direction})}
                        >
                            { t("market.primary.sum") }
                        </SortingDirectionBlock>
                        <SortingDirectionBlock
                            direction={filter.interestRateSorting}
                            handleChange={(direction) => setFilter({...filter, interestRateSorting: direction})}
                        >
                            { t("market.primary.rate") }
                        </SortingDirectionBlock>
                        <SortingDirectionBlock
                            direction={filter.initialTermSorting}
                            handleChange={(direction) => setFilter({...filter, initialTermSorting: direction})}
                        >
                            { t("market.primary.term") }
                        </SortingDirectionBlock>
                        <div>
                            { t("market.primary.schedule") }
                        </div>
                        <div>
                            { t("market.primary.collected") }
                        </div>
                    </PaginationHeader>
                    <ul className="market__list">
                        { renderProjects }
                    </ul>
                </PaginationContent>
                <PaginationPaginator />
            </Pagination>
            <ProjectsFilterModal
                filter={filter}
                onFilterChange={setFilter}
                active={filterModalOpened}
                onClose={toggleFilterModalOpened}
            />
        </div>
    );
}

export default PrimaryMarket;