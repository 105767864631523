import React, {useMemo, useState} from "react";
import "./MyFundraisingProjects.scss";
import {useErrorCallback, useTranslator} from "../../../../../hooks";
import {DataCollection, MyActiveProjectsFilter, MyFundraisingProjectsFilter} from "../../../../../utils";
import {MyFundraisingProject as MyFundraisingProjectModel} from "../../../../../api-client";
import {createMyProjectsProvider} from "../../../../../di";
import MyFundraisingProject from "../MyFundraisingProject";
import {Pagination, PaginationContent, PaginationHeader, PaginationPaginator} from "../../../../pagination";
import SortingDirectionBlock from "../../../../common/utils/SortingDirectionBlock/SortingDirectionBlock";

const defaultFilter: MyFundraisingProjectsFilter = {};

const MyFundraisingProjects = () => {
    const t = useTranslator();
    const handleError = useErrorCallback();
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState<MyFundraisingProjectsFilter>(defaultFilter);
    const [projects, setProjects]
        = useState<DataCollection<MyFundraisingProjectModel>>(new DataCollection<MyFundraisingProjectModel>());

    const loadData = async (
        page: number,
        perPage: number,
        filter: MyFundraisingProjectsFilter | undefined,
        abortSignal: AbortSignal
    ) => {
        setLoading(true);
        try {
            const provider = await createMyProjectsProvider(abortSignal);
            const results = await provider.getFundraisingList(page, perPage, filter ?? defaultFilter);
            const collection = new DataCollection<MyFundraisingProjectModel>();
            collection.setCollection(results);
            setProjects(collection);
        } catch (error: any) {
            await handleError(error);
        } finally {
            setLoading(false);
        }
    }

    const renderProjects = useMemo(() => {
        return projects.items.map((project) => {
            return <MyFundraisingProject key={project.uuid} project={project} />
        });
    }, [projects]);

    return (
        <div className="my-fundraising-projects">
            <Pagination
                onLoad={loadData}
                count={projects.count}
                totalCount={projects.totalCount}
                filterObject={filter}
                initialChangeFilter={(value: Partial<MyActiveProjectsFilter>) => setFilter({...filter, ...value})}
            >
                <PaginationHeader className="my-fundraising-projects__list-header">
                    <div>
                        { t("my_loans.id") }
                    </div>
                    <SortingDirectionBlock
                        direction={filter.targetSumSorting}
                        handleChange={(direction) => setFilter((filter) => { return {...filter, targetSumSorting: direction} })}
                    >
                        { t("my_loans.sum") }
                    </SortingDirectionBlock>
                    <SortingDirectionBlock
                        direction={filter.collectedSumSorting}
                        handleChange={(direction) => setFilter((filter) => { return {...filter, collectedSumSorting: direction} })}
                    >
                        { t("my_loans.collected") }
                    </SortingDirectionBlock>
                    <SortingDirectionBlock
                        direction={filter.interestRateSorting}
                        handleChange={(direction) => setFilter((filter) => { return {...filter, interestRateSorting: direction} })}
                    >
                        { t("my_loans.rate") }
                    </SortingDirectionBlock>
                    <SortingDirectionBlock
                        direction={filter.createdAtSorting}
                        handleChange={(direction) => setFilter((filter) => { return {...filter, createdAtSorting: direction} })}
                    >
                        { t("my_loans.created_at") }
                    </SortingDirectionBlock>
                    <div>
                        { t("my_loans.schedule_payments") }
                    </div>
                </PaginationHeader>
                <PaginationContent loading={loading}>
                    <div className="market__content">
                        <ul className="market__list">
                            { renderProjects }
                        </ul>
                    </div>
                </PaginationContent>
                <PaginationPaginator />
            </Pagination>
        </div>
    );
}

export default MyFundraisingProjects;