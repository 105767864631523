import React from "react";
import {SvgComponent} from "../SvgComponent";
import SvgContainer from "../SvgContainer";
import {SvgContainerSize} from "../SvgContainer/SvgContainer";
import {defaultSvgColorDark} from "../SvgProps";

const DropdownDownSvg: SvgComponent = ({ color = defaultSvgColorDark }) => {
    return (
        <SvgContainer size={SvgContainerSize.Small}>
            <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1L5 5L1 1" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
            </svg>
        </SvgContainer>
    );
}

export default DropdownDownSvg;