import {Translations} from "../../types";

export const detailed_project: Translations = {
    "detailed_project.not_found": "Նախագիծը չի գտնվել",
    "detailed_project.navigation.summary": "Ամփոփում",
    "detailed_project.navigation.analytics": "Վերլուծություն",
    "detailed_project.navigation.reputation": "Հեղինակություն",
    "detailed_project.navigation.documents": "Փաստաթղթերը",
    "detailed_project.navigation.offers": "Գնել վարկ",
    "detailed_project.invest.investment_created_at": "Ստեղծվել է",
    "detailed_project.invest.investment_sum": "Գումար",
    "detailed_project.invest.investment_income": "Եկամուտ",
    "detailed_project.invest.investment_cancel": "Չեղարկել",
    "detailed_project.invest.investment_sell": "Վաճառել",
    "detailed_project.invest.fundraising_warning": "Վարկի վճարումները կսկսեն գործել վարկն ամբողջությամբ հավաքելուց հետո",
    "detailed_project.invest.rate": "Գնահատել",
    "detailed_project.invest.collected": "Հավաքված",
    "detailed_project.invest.target": "Թիրախ",
    "detailed_project.invest.paid": "Վճարված",
    "detailed_project.invest.paid_sum": "Վճարման գումարը",
    "detailed_project.invest.loan_sum": "Վարկի գումար",
    "detailed_project.invest.number_of_offers": "Առաջարկների քանակը",
    "detailed_project.invest.term": "Ժամկետ",
    "detailed_project.invest.buyback": "Հետգնում",
    "detailed_project.invest.buyback_explanation": "Сама того не зная, в процессе становления идеи и реализации проекта\n" +
        "принимала активное участие Гильдия Вольных Проектировщиков, за что " +
        "ей огромное спасибо. Комрады, вы реально лучшие.",
    "detailed_project.invest.easy_exit": "Հեշտ ելք",
    "detailed_project.invest.easy_exit_explanation": "123",
    "detailed_project.invest": "Ներդրումներ",
    "detailed_project.invest.buy": "Գնել վարկ",
    "detailed_project.placeholder": "Տեղեկատվությունը չի գտնվել:",
    "detailed_project.founder.share": "Կիսվեք ընկերությունում",
    "detailed_project.founder.legal": "օր",
    "detailed_project.founder.individual": "ան",
    "detailed_project.founder.capital": "Կանոնադրական կապիտալ",
    "detailed_project.founder.registration_date": "Գրանցման ամսաթիվը",
    "detailed_project.founder.number": "Թիվ",
    "detailed_project.founder.companies_number": "Ընկերությունների թիվը",
    "detailed_project.general": "Վարկի տարբերակներ",
    "detailed_project.general.product": "Արտադրանք",
    "detailed_project.general.rate": "Գնահատել",
    "detailed_project.general.term": "Ժամկետ",
    "detailed_project.general.percents": "Տոկոսների հաշվեգրում",
    "detailed_project.general.on_remainder": "Մնացած հիմնական մնացորդի համար",
    "detailed_project.general.frequency": "Վճարման հաճախականությունը",
    "detailed_project.guarantors": "Երաշխավորներ",
    "detailed_project.guarantors.fio": "Ամբողջական անուն",
    "detailed_project.borrower_info": "Վարկառուի տվյալներ",
    "detailed_project.borrower_info.name": "Անուն",
    "detailed_project.borrower_info.registration_date": "Գրանցման ամսաթիվը",
    "detailed_project.borrower_info.address": "Հասցե",
    "detailed_project.borrower_info.okved": "OKVED",
    "detailed_project.borrower_info.description": "Գործունեության նկարագրությունը",
    "detailed_project.borrower_info.not_listed": "Չի նշված",
    "detailed_project.borrower_info.links": "Կայքի հղումներ",
    "detailed_project.borrower_info.founders": "Հիմնադիրներ և անդամներ",
    "detailed_project.borrower_info.credit_turnover": "Վարկային շրջանառություն",
    "detailed_project.borrower_info.accounting.active": "Հաշվեկշիռ (ակտիվ)",
    "detailed_project.borrower_info.accounting.passive": "Հաշվեկշիռ (պարտավորություն)",
    "detailed_project.borrower_info.accounting.tangible_non_assets": "Շոշափելի ոչ ընթացիկ ակտիվներ",
    "detailed_project.borrower_info.accounting.intangible_financial": "Ոչ նյութական ֆինանսական և այլն",
    "detailed_project.borrower_info.accounting.stocks": "Բաժնետոմսեր",
    "detailed_project.borrower_info.accounting.financial_assets": "Ֆինանսական և այլ ընթացիկ ակտիվներ",
    "detailed_project.borrower_info.accounting.balance": "Ակտիվների մնացորդը",
    "detailed_project.borrower_info.accounting.capital_and_reserves": "Կապիտալ և պահուստներ",
    "detailed_project.borrower_info.accounting.long_term_borrowings": "Երկարաժամկետ փոխառություններ",
    "detailed_project.borrower_info.accounting.short_term_borrowings": "Կարճաժամկետ փոխառություններ",
    "detailed_project.borrower_info.accounting.accounts_payable": "կրեդիտորական պարտքեր",
    "detailed_project.borrower_info.accounting.other_liabilities": "Այլ ընթացիկ պարտավորություններ",
    "detailed_project.borrower_info.accounting.balance_passive": "Պարտավորությունների մնացորդը",
    "detailed_project.borrower_info.accounting.period": "Ժամանակաշրջան",
    "detailed_project.distribution": "Գնորդների բաժնետոմսերի բաշխում",
    "detailed_project.admissions": "Ընթացիկ հաշվին մուտքերի քանակը",

    "detailed_project.reputation.credit_history": "Վարկային պատմություն",
    "detailed_project.reputation.no_delay": "Ընթացիկ ուշացում չկա",
    "detailed_project.reputation.debt_load": "Ընթացիկ պարտքի բեռը",
    "detailed_project.reputation.delays_30_days_12_months": "Վերջին 12 ամիսների ընթացքում մինչև 30 օր ուշացումների քանակը",
    "detailed_project.reputation.max_borrower_sum": "Մաքս. վարկի գումարը հաջողությամբ սպասարկվել է վարկառուի կողմից",
    "detailed_project.reputation.last_month_loans_number": "Վերջին ամսվա վարկերի և փոխառությունների քանակը",
    "detailed_project.reputation.microcredits_3_months": "Միկրովարկեր վերջին 3 ամիսների համար",

    "detailed_project.portfolio": "Վարկային պորտֆոլիո",
    "detailed_project.portfolio.mortgage": "Հիփոթեք",
    "detailed_project.portfolio.leasing": "Լիզինգ",
    "detailed_project.portfolio.bank_credit": "Բանկային վարկ",
    "detailed_project.portfolio.crowdlending": "Մարդկանց կուտակում",

    "detailed_project.arbitrations": "Արբիտրաժներ",
    "detailed_project.arbitrations.role": "Դեր",
    "detailed_project.arbitrations.claimant": "Հայցվոր",
    "detailed_project.arbitrations.defendant": "Ամբաստանյալ",
    "detailed_project.arbitrations.execution_proceedings": "Կատարողական վարույթ",
    "detailed_project.arbitrations.index": "Ցուցանիշ",
    "detailed_project.arbitrations.historical": "Պատմական",

    "detailed_project.proceeding_history": "Արտադրության պատմություն",
    "detailed_project.proceeding_history.id": "Վարկի ID",
    "detailed_project.proceeding_history.status": "Կարգավիճակ",
    "detailed_project.proceeding_history.date": "Թողարկման ամսաթիվը",
    "detailed_project.proceeding_history.sum": "Գումար",
    "detailed_project.proceeding_history.rate": "Գնահատել",
    "detailed_project.proceeding_history.term": "Ժամկետ",
    "detailed_project.proceeding_history.remainder": "Հիմնական մնացորդը",
    "detailed_project.proceeding_history.delay_count": "Ուշացումների քանակը",
    "detailed_project.proceeding_history.max_delay": "Մաքս. ուշացում",

    "detailed_project.status.fundraising": "Դրամահավաք",
    "detailed_project.status.finished": "Ավարտված",
    "detailed_project.status.loan_payment": "Վարկի վճարում",
    "detailed_project.status.paid": "Վճարված",
    "detailed_project.status.refunded": "Վերադարձվել է",

    "detailed_project.period.month": "1 ամիս",
    "detailed_project.period.three_months": "3 ամիս",
    "detailed_project.period.six_months": "6 ամիս",
    "detailed_project.period.twelve_months": "12 ամիս",
    "detailed_project.period.all_time": "Ամբողջ ժամանակ",

    "detailed_project.turnover_period.month": "վերջին ամսվա համար",
    "detailed_project.turnover_period.three_months": "միջինը 3 ամիս",
    "detailed_project.turnover_period.six_months": "միջինը 6 ամիս",
    "detailed_project.turnover_period.twelve_months": "միջինը 12 ամիս",
    "detailed_project.turnover_period.all_time": "ամբողջ ժամանակի համար",

    "detailed_project.schedule.monthly": "Ամսական",
    "detailed_project.schedule.monthly_twice": "Ամսական 2 անգամ",
    "detailed_project.schedule.monthly_thrice": "Ամսական 3 անգամ",
    "detailed_project.schedule.monthly_four_times": "Ամսական 4 անգամ",
    "detailed_project.schedule.quarterly": "Եռամսյակային",
    "detailed_project.schedule.at_the_end": "Վերջում",

    "detailed_project.invest.months.nominative": "ամիս",
    "detailed_project.invest.months.genitive": "ամիսներ",
    "detailed_project.invest.months.multiple": "ամիսներ",

    "detailed_project.days.nominative": "օր",
    "detailed_project.days.genitive": "օրեր",
    "detailed_project.days.multiple": "օրեր",

    "detailed_project.times.nominative": "ժամանակ",
    "detailed_project.times.genitive": "անգամ",
    "detailed_project.times.multiple": "անգամ",
}