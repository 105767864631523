import {Translations} from "../../types";
import {languageSelect} from "./languageSelect";
import {auth} from "./auth";
import {common} from "./common";
import {errors} from "./errors";
import {navigation} from "./navigation";
import {header} from "./header";
import {deposit} from "./deposit";
import {withdraw} from "./withdraw";
import {market} from "./market";
import {my_money} from "./my_money";
import {finances} from "./finances";
import {pagination} from "./pagination";
import {profile} from "./profile";
import {analytics} from "./analytics";
import {date} from "./date";
import {my_investments} from "./my_investments";
import {detailed_project} from "./detailed_project";
import {confirm_email} from "./confirm_email";
import {identification} from "./identification";
import {confirm_phone} from "./confirm_phone";
import {funding_request} from "./funding_request";
import {payments_schedule} from "./payments_schedule";
import {my_loans} from "./my_loans";
import {word_case} from "./word_case";
import {my_detailed_project} from "./my_detailed_project";
import {history} from "./history";
import {borrower_profile} from "./borrower_profile";
import {investment_confirmation} from "./investment_confirmation";
import {guarantor} from "./guarantor";
import {selling_confirmation} from "./selling_confirmation";
import {archive} from "./archive";

export const ru: Translations = {
    ...auth,
    ...common,
    ...date,
    ...deposit,
    ...withdraw,
    ...finances,
    ...header,
    ...languageSelect,
    ...analytics,
    ...market,
    ...my_investments,
    ...archive,
    ...detailed_project,
    ...profile,
    ...borrower_profile,
    ...investment_confirmation,
    ...selling_confirmation,
    ...my_money,
    ...my_loans,
    ...my_detailed_project,
    ...history,
    ...identification,
    ...confirm_email,
    ...confirm_phone,
    ...funding_request,
    ...navigation,
    ...payments_schedule,
    ...pagination,
    ...errors,
    ...word_case,
    ...guarantor
};
