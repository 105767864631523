import React, {useContext} from "react";
import {CommonSubText, Money} from "../../../../common";
import {formatDate} from "../../../../../formatters";
import {resolveMonthTranslation} from "../../../../../i18n";
import {useTranslator} from "../../../../../hooks";
import {DetailedProjectContext} from "../../DetailedProjectContext";
import DetailedProjectInvestBlock from "../../layout/DetailedProjectInvest/extensions/DetailedProjectInvestBlock";
import DetailedProjectInvestBlocks from "../../layout/DetailedProjectInvest/extensions/DetailedProjectInvestBlocks";

const DetailedProjectInvestmentStat = () => {
    const t = useTranslator();
    const { project, investment } = useContext(DetailedProjectContext);
    return (
        <DetailedProjectInvestBlocks>
            <DetailedProjectInvestBlock>
                <CommonSubText>
                    { t("detailed_project.invest.investment_created_at") }
                </CommonSubText>
                <div className="detailed-project-invest__value">
                    {formatDate(new Date(investment!.createdAt))}
                </div>
            </DetailedProjectInvestBlock>
            <DetailedProjectInvestBlock>
                <CommonSubText>
                    { t("detailed_project.invest.investment_sum") }
                </CommonSubText>
                <div className="detailed-project-invest__value">
                    <Money money={investment!.money} />
                </div>
            </DetailedProjectInvestBlock>
            <DetailedProjectInvestBlock>
                <CommonSubText>
                    { t("detailed_project.invest.rate") }
                </CommonSubText>
                <div className="detailed-project-invest__value">
                    {project.general.interestRate}%
                </div>
            </DetailedProjectInvestBlock>
            <DetailedProjectInvestBlock>
                <CommonSubText>
                    { t("detailed_project.invest.term") }
                </CommonSubText>
                <div className="detailed-project-invest__value">
                    {project.general.initialTerm} { t(resolveMonthTranslation(project.general.initialTerm)) }
                </div>
            </DetailedProjectInvestBlock>
        </DetailedProjectInvestBlocks>
    );
}

export default DetailedProjectInvestmentStat;