import React from "react";
import AuthFooterLogin from "../../components/layout/auth/footer/AuthFooterLogin";
import {Outlet} from "react-router-dom";
import {LayoutAuth} from "../../layouts";
import AuthHeaderRegisterUser from "../../components/layout/auth/header/AuthHeaderRegisterUser";

const RegisterUserAuth = () => {
    return (
        <LayoutAuth header={<AuthHeaderRegisterUser />} footer={<AuthFooterLogin />}>
            <Outlet />
        </LayoutAuth>
    );
}

export default RegisterUserAuth;