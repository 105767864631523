import React from "react";
import styles from "./InvestmentLanguageSelect.module.scss";
import LanguageSelect from "../../common/utils/LanguageSelect";

const InvestmentLanguageSelect = () => {
    return (
        <div className={styles.investment_language_select}>
            <LanguageSelect />
        </div>
    );
}

export default InvestmentLanguageSelect;