import React, {FC, PropsWithChildren, useEffect, useState} from "react";
import {useAppDispatch, useErrorCallback, useLoggedInStatus} from "../../hooks";
import {createProfileManager} from "../../di";
import {set} from "../../store/profile";
import {Loader} from "../../components/common";

const MainLayout: FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    const isLoggedIn = useLoggedInStatus();
    const errorCallback = useErrorCallback();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        (async () => {
            try {
                if (!isLoggedIn) {
                    await initProfile();
                }
            } catch (error: any) {
                await errorCallback(error);
            } finally {
                setLoading(false);
            }
        })()
    }, []);

    const initProfile = async () => {
        const manager = await createProfileManager();
        const profile = await manager.get();
        await dispatch(set(profile));
    }

    return (
        <>
            { loading ? <Loader /> : children }
        </>
    );
}

export default MainLayout;