import React, {FC, PropsWithChildren, useEffect, useState} from "react";
import "./LayoutLenderIdentification.scoped.scss";
import {useNavigateByName, useProfileData} from "../../hooks";
import {RouteDictionary} from "../../utils";
import {Loader} from "../../components/common";

const LayoutLenderIdentification: FC<PropsWithChildren> = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const profileData = useProfileData();
    const navigate = useNavigateByName();
    useEffect(() => {
        if (profileData.lenderVerified) {
            navigate(RouteDictionary.ANALYTICS);
            return;
        }
        setLoading(false);
    }, []);
    return (
        <>
            { loading
                ? <div className="layout-lender-identification__loader"><Loader /></div>
                : <div className="layout-lender-identification">
                { children }
            </div> }
        </>
    );
}

export default LayoutLenderIdentification;