import React, {createContext, PropsWithChildren} from "react";
import {LenderIdentification} from "../../../api-client";

export enum IdentificationStep {
    FillSurvey = 0,
    CheckStatus = 1
}

export interface IdentificationContextType {
    step: IdentificationStep;
    setStep: (step: IdentificationStep) => void;
    identification: LenderIdentification | undefined;
    setIdentification: (identification: LenderIdentification) => void;
}

type Props = {
    props: IdentificationContextType;
}

export const IdentificationContext = createContext<IdentificationContextType>({
    step: IdentificationStep.FillSurvey,
    setStep: () => {},
    identification: undefined,
    setIdentification: () => {}
});

function IdentificationContextProvider(props: PropsWithChildren<Props>) {
    const { children, ...value } = props;
    return <IdentificationContext.Provider value={value.props}>
        { children }
    </IdentificationContext.Provider>
}

export default IdentificationContextProvider;