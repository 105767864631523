import React, {PropsWithChildren} from "react";
import {ClassInjector} from "../../../../../injectors";
import './SecondaryHeading.scss';
import {ClassNameDecorator} from "../../../../../decorators";
import {cn, resultIf} from "../../../../../../utils";

interface Props {
    smaller?: boolean;
}

const SecondaryHeading: ClassNameDecorator<PropsWithChildren<Props>> = ({ smaller, children, className }) => {
    return (
        <ClassInjector classNames={cn(
            "secondary-heading",
            resultIf(
                smaller === true,
                "secondary-heading--smaller"
            )
        )}
                       oldClassName={className}
        >
            { children }
        </ClassInjector>
    );
}

export default SecondaryHeading;