import React, {FC} from "react";
import {Bar} from "react-chartjs-2";
import {BarElement, CategoryScale, Chart, ChartData, ChartOptions, LinearScale} from "chart.js";

Chart.register(CategoryScale, LinearScale, BarElement);

function createGradient(ctx: CanvasRenderingContext2D) {
    const colorStart = "#C8D8FF";
    const colorEnd = "#004AFF";
    const gradient = ctx.createLinearGradient(0, 300, 0, 0);
    gradient.addColorStop(0, colorStart);
    gradient.addColorStop(1, colorEnd);
    return gradient;
}

interface Props {
    data: Array<number>;
    labels: Array<Array<string>> | Array<string>;
    stepSize: number;
    ticksFunc: (value: number | string) => string;
}

//TODO: ADD PROPER WORDS FOR Y SCALE TICKS
const BarChart: FC<Props> = ({ data, labels, stepSize, ticksFunc }) => {
    const chartData: ChartData<'bar'> = {
        labels: labels,
        datasets: [{
            data: data,
            backgroundColor: '#F0F2F8',
            borderColor: 'black',
            borderRadius: 8,
            hoverBackgroundColor: ({ chart: { ctx } }) => {
                return createGradient(ctx);
            },
            barThickness: 24
        }]
    }
    const chartOptions: ChartOptions<"bar"> = {
        maintainAspectRatio: true,
        layout: {
            padding: 20
        },
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            }
        },
        scales: {
            y: {
                ticks: {
                    callback: ticksFunc,
                    stepSize
                },
                border: {
                    display: false
                },
                grid: {
                    lineWidth: function (context) {
                        return context?.index === 0 ? 0 : 1;
                    },
                    tickBorderDash: [8, 4]
                },
            },
            x: {
                grid: {
                    display: false,
                    lineWidth: function (context) {
                        return context?.index === 0 ? 0 : 1;
                    }
                },
                border: {
                    width: 0
                },
                ticks: {
                    maxRotation: 0,
                    minRotation: 0
                }
            }
        }
    }
    return (
        <Bar data={chartData} options={chartOptions} />
    );
}

export default BarChart;