import {Translations} from "../../types";

export const errors: Translations = {
    "errors.field_required": ":field :type для заполнения",
    "errors.fields_required": ":field обязательны для заполнения",
    "errors.passwords_do_not_match": "Пароли не совпадают",
    "errors.confirm_password": "Подтвердите пароль",
    "errors.accept_something": "Примите :document",
    "errors.field_less_than": ":field :type быть больше :amount",
    "errors.fields_less_than": ":field должны быть больше :amount",
    "errors.field_more_than": ":field :type быть меньше :amount",
    "errors.fields_more_than": ":field должны быть меньше :amount",
    "errors.incorrect_input": ":field неверно :type",
    "errors.incorrect_inputs": ":field неверно введены",
    "errors.confirm_phone": "Ваш телефон не подтвержден",
    "errors.not_number": ":field :type быть числом",
    "errors.wrong_department_code": "Неверный формат кода подразделения",
    "errors.wrong_phone": "Неверный формат номера телефона",

    "errors.fields.name": "Имя",
    "errors.fields.last_name": "Фамилия",
    "errors.fields.email": "Эл. почта",
    "errors.fields.phone": "Номер телефона",
    "errors.fields.password": "Пароль",
    "errors.fields.register_type": "Тип регистрации",
    "errors.fields.platform_rules": "Правила платформы",
    "errors.fields.privacy_policy": "Политику конфиденциальности",
    "errors.fields.sum": "Сумма",
    "errors.fields.rate": "Процент",
    "errors.fields.term": "Срок",
    "errors.fields.passport.series": "Серия",
    "errors.fields.passport.number": "Номер",
    "errors.fields.passport.issued_by": "Место выдачи",
    "errors.fields.passport.department_code": "Код подразделения",
    "errors.fields.passport.issued_at": "Дата выдачи",
    "errors.fields.passport.registration_address": "Адрес регистрации",
    "errors.fields.company.reg_number": "Рег. номер",
    "errors.fields.company.kpp": "КПП",
    "errors.fields.company.phone": "Телефон",
    "errors.fields.company.description": "Описание",
    "errors.fields.company.business_form": "Форма бизнеса",
    "errors.fields.company.activity": "Деятельность",
    "errors.fields.company.address": "Адрес",
    "errors.fields.company.site_urls": "Ссылки на сайты",
    "errors.fields.company.reputation_rating": "Рейтинг",
    "errors.fields.company.money_source": "Источник доходов",

    "errors.field_required.masculine": "обязателен",
    "errors.field_required.feminine": "обязательна",
    "errors.field_required.neuter": "обязательно",

    "errors.field_must.masculine": "должен",
    "errors.field_must.feminine": "должна",
    "errors.field_must.neuter": "должно",

    "errors.input.masculine": "введен",
    "errors.input.feminine": "введена",
    "errors.input.neuter": "введено"
};
