import React, {useEffect, useState} from "react";
import {useAppDispatch, useErrorCallback, useNavigateByName, useProfileData} from "../../../../hooks";
import ConfirmationBlock from "../../../../components/pages/confirmation/ConfirmationBlock";
import {LayoutAuth} from "../../../../layouts";
import "./ConfirmPhone.scoped.scss";
import {ConfirmSmsCodeForm} from "../../../../components/common";
import {PhoneConfirmation} from "../../../../api-client";
import {createVerificationManager} from "../../../../di";
import {setPhoneVerified} from "../../../../store/profile";
import {RouteDictionary} from "../../../../utils";
import AuthFooter from "../../../../components/layout/auth/AuthFooter";
import AuthHeaderConfirmPhone from "../../../../components/layout/auth/header/AuthHeaderConfirmPhone";

const ConfirmPhone = () => {
    const navigate = useNavigateByName();
    const handleError = useErrorCallback();
    const profileData = useProfileData();
    const dispatch = useAppDispatch();
    const [confirmation, setConfirmation] = useState<PhoneConfirmation | null>(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (profileData.phoneVerified) {
            navigate(RouteDictionary.MY_PROJECTS);
            return;
        }
        (async () => {
            try {
                const manager = await createVerificationManager();
                const confirmation = await manager.requestPhoneConfirmation(profileData.uuid!);
                setConfirmation(confirmation);
            } catch (error: any) {
                await handleError(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [profileData]);

    const handleSend = async (verificationCode: string): Promise<void> => {
        const manager = await createVerificationManager();
        await manager.confirmPhone(profileData.uuid!, verificationCode);
    }

    const handleResend = async () => {
        console.log(123);
    }

    const onSuccess = () => {
        dispatch(setPhoneVerified(true));
        navigate(RouteDictionary.FUNDING_REQUEST);
    }

    return (
        <LayoutAuth header={<AuthHeaderConfirmPhone />} footer={<AuthFooter />}>
            <ConfirmationBlock className="confirm-phone-container">
                { loading ? <div>Loading...</div> : <div>
                    <ConfirmSmsCodeForm
                        sendCallback={handleSend}
                        resendCallback={handleResend}
                        successCallback={onSuccess}
                    />
                </div> }
            </ConfirmationBlock>
        </LayoutAuth>
    );
}

export default ConfirmPhone;