import React, {FC, useState} from "react";
import {Phone} from "../../../../api-client";
import {InputPhone} from "../../../common/inputs";
import {useAppDispatch, useErrorCallback, useProfileData, useTranslator} from "../../../../hooks";
import {AsLink, Button, InputErrors, Loader} from "../../../common";
import {validatePhone} from "../../../../utils";
import {createProfileManager} from "../../../../di";
import {setPhone as setStorePhone, setPhoneVerified} from "../../../../store/profile";
import PhoneConfirmationModal from "../../../modals/PhoneConfirmationModal";
import {Errors as ValidationErrors} from "../../../../utils/types/Errors";
import "./IdentificationPhone.scoped.scss";

interface Props {
    phone: Phone;
    setPhone: (phone: Phone) => void;
    errors?: ValidationErrors;
}

const IdentificationPhone: FC<Props> = ({ phone, setPhone, errors }) => {
    const t = useTranslator();
    const handleError = useErrorCallback();
    const profileData = useProfileData();
    const dispatch = useAppDispatch();
    const [modalOpened, setModalOpened] = useState(false);
    const [loading, setLoading] = useState(false);

    const canSubmit = (): boolean => {
        return validatePhone(phone);
    }

    const needToSubmit = (): boolean => {
        return `${profileData.phone?.countryCode}${profileData.phone?.number}`
            !== `${phone.countryCode}${phone.number}` || !profileData.phoneVerified;
    }

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const manager = await createProfileManager();
            const result = await manager.addPhone(phone);
            await dispatch(setStorePhone(result));
        } catch (error: any) {
            await handleError(error);
        } finally {
            setLoading(false);
        }
        setModalOpened(true);
    }

    const onSuccess = async () => {
        await dispatch(setPhoneVerified(true));
        setModalOpened(false);
    }

    return (
        <div className="identification-phone">
            <InputPhone
                phone={phone}
                onPhoneInput={setPhone}
                text={t("identification.phone_number")}
                error={false}
                disabled={loading}
            />
            { errors && <InputErrors errors={errors} /> }
            { canSubmit() && needToSubmit() && <AsLink>
                <Button onClick={handleSubmit} className="identification-phone__submit">
                    { t("common.submit") }
                </Button>
            </AsLink> }
            { loading && <Loader /> }
            { profileData.phoneVerified && !needToSubmit() && <div>
                { t("identification.phone_confirmed") }
            </div> }
            <PhoneConfirmationModal
                active={modalOpened}
                onClose={() => setModalOpened(false)}
                onSuccess={onSuccess}
            />
        </div>
    );
}

export default IdentificationPhone;