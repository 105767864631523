import React, {useEffect, useState} from "react";
import './Identification.scoped.scss';
import IdentificationContextProvider, {
    IdentificationContextType,
    IdentificationStep
} from "../../../../components/pages/identification/IdentificationContext";
import IdentificationAside from "../../../../components/pages/identification/IdentificationAside";
import {Loader, MultiStepForm} from "../../../../components/common";
import IdentificationForm from "../../../../components/pages/identification/IdentificationForm";
import IdentificationStatus from "../../../../components/pages/identification/IdentificationStatus";
import {createIdentificationManager} from "../../../../di";
import {TIdentification} from "../../../../api-client";
import {useErrorCallback, useNavigateByName, useProfileData} from "../../../../hooks";
import {RouteDictionary} from "../../../../utils";
import MainGrid from "../../../../components/layout/common/MainGrid";
import SubPageMain from "../../../../components/layout/common/SubPageMain";
import LoaderContainer from "../../../../components/loading/LoaderContainer";

const Identification = () => {
    const profileData = useProfileData();
    const navigate = useNavigateByName();
    const [step, setStep] = useState<IdentificationStep>(IdentificationStep.FillSurvey);
    const [latestIdentification, setLatestIdentification] = useState<TIdentification | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const handleError = useErrorCallback();

    useEffect(() => {
        if (profileData.lenderVerified) {
            navigate(RouteDictionary.ANALYTICS);
            return;
        }
        (async () => {
            try {
                const manager = await createIdentificationManager();
                const latestIdentification = await manager.getLatest();
                setLatestIdentification(latestIdentification);
                resolveStep();
            } catch (error: any) {
                await handleError(error);
            } finally {
                setLoading(false);
            }
        })()
    }, []);

    const resolveStep = (): void => {
        if (profileData.hasIdentificationDraft) {
            setStep(IdentificationStep.CheckStatus);
            return;
        }
        setStep(IdentificationStep.FillSurvey);
    }

    const value: IdentificationContextType = {
        step,
        setStep,
        identification: latestIdentification,
        setIdentification: setLatestIdentification
    }

    return (
        <div className="identification">
            <IdentificationContextProvider props={value}>
                <MainGrid subpage>
                    <IdentificationAside />
                    <SubPageMain>
                        { loading ? <LoaderContainer>
                            <Loader />
                        </LoaderContainer> : <MultiStepForm step={step}>
                            <IdentificationForm />
                            <IdentificationStatus />
                        </MultiStepForm> }
                    </SubPageMain>
                </MainGrid>
            </IdentificationContextProvider>
        </div>
    );
}

export default Identification