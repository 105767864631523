import React, {useContext, useState} from "react";
import {
    BorrowerRegistrationContext,
    BorrowerRegistrationStep
} from "../BorrowerRegistrationForm/context/BorrowerRegistrationContext";
import useDebounce from "../../../../../hooks/useDebounce";
import {Company} from "../../../../../api-client";
import {createCompanyProvider} from "../../../../../di";
import {useEffectOnUpdate, useErrorCallback, useTranslator} from "../../../../../hooks";
import {DaDataCompany} from "../../../../../api-client/models/company";
import styles from "./BorrowerRegistrationCompany.module.scss";
import registerStyles from "../../register.module.scss";
import {Button, Heading, PrimaryButton, PrimaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import {CommonInput} from "../../../../common/inputs";
import CompanyAutoComplete from "../CompanyAutoComplete";
import {PrimaryButtonColor} from "../../../../common/buttons/decorators/PrimaryButton/PrimaryButton";

const BorrowerRegistrationCompany = () => {
    const t = useTranslator();
    const { input, setInput, setStep } = useContext(BorrowerRegistrationContext);
    const handleError = useErrorCallback();
    const [companyInput, setCompanyInput] = useState("");
    const debouncedCompanyInput = useDebounce(companyInput);
    const [companies, setCompanies] = useState<Array<Company>>([]);
    const [loading, setLoading] = useState(false);

    const findCompany = async (input: string, abortSignal: AbortSignal) => {
        setLoading(true);
        try {
            const provider = await createCompanyProvider(abortSignal);
            const results = await provider.getDaDataCompanies(input);
            setCompanies(results);
        } catch (error: any) {
            await handleError(error);
        } finally {
            setLoading(false);
        }
    }

    useEffectOnUpdate(() => {
        setCompanies([]);
    }, [companyInput]);

    useEffectOnUpdate(() => {
        const abortController = new AbortController();
        if (debouncedCompanyInput !== "") {
            findCompany(debouncedCompanyInput, abortController.signal);
        }
        return () => {
            abortController.abort();
        }
    }, [debouncedCompanyInput]);

    const setNewCompany = (company: DaDataCompany) => {
        setCompanies([]);
        setInput({...input, company: company});
    }

    const handleCompanySelected = () => {
        setCompanies([]);
        setStep(BorrowerRegistrationStep.EnterCredentials);
    }

    return (
        <div className={styles.enter_company}>
            <PrimaryHeading>
                <Heading headingType={HeadingType.H1} className={registerStyles.register__heading}>
                    { t("auth.register_borrower") }
                </Heading>
            </PrimaryHeading>
            <div className={styles.enter_company__input}>
                <CommonInput
                    id="company"
                    text={ t("common.company_reg_number") }
                    onChange={setCompanyInput}
                    value={companyInput}
                    error={false}
                    loading={loading}
                />
                { companies.length !== 0
                    && <CompanyAutoComplete
                        companies={companies}
                        onSelected={setNewCompany}
                    />
                }
            </div>
            { input.company !== undefined && <div className={styles.enter_company__company}>
                { t("auth.register_borrower.as") } { input.company.name }
            </div> }
            <div>
                <PrimaryButton expanded color={input.company === undefined ? PrimaryButtonColor.GRAY : PrimaryButtonColor.BLUE}>
                    <Button disabled={input.company === undefined} onClick={handleCompanySelected}>
                        { t("common.continue") }
                    </Button>
                </PrimaryButton>
            </div>
        </div>
    );
}

export default BorrowerRegistrationCompany;