import {Translations} from "../../types";

export const my_investments: Translations = {
    "my_investments": "Իմ ներդրումները",
    "my_investments.filters": "Զտիչներ",
    "my_investments.navigation.loan": "Ընթացիկ",
    "my_investments.navigation.fundraising": "Դրամահավաք",
    "my_investments.navigation.returned": "վերադարձավ",
    "my_investments.navigation.default": "լռելյայն վիճակում",
    "my_investments.navigation.selling": "Վաճառվում է",
    "my_investments.loan.company": "Ընկերություն",
    "my_investments.loan.rate": "Հայտ",
    "my_investments.loan.date": "Ներդրման ամսաթիվը",
    "my_investments.loan.return": "Վերադարձ",
    "my_investments.loan.sum": "Գումար",
    "my_investments.loan.redeemed": "Փրկված է",
    "my_investments.loan.sell": "Վաճառել վարկ",
    "my_investments.fundraising.company": "Ընկերություն",
    "my_investments.fundraising.target": "Թիրախ",
    "my_investments.fundraising.collected": "Հավաքված",
    "my_investments.fundraising.investment_sum": "Ներդրումների չափը",
    "my_investments.fundraising.rate": "Հայտ",
    "my_investments.fundraising.investment_date": "Ներդրման ամսաթիվը",
    "my_investments.fundraising.schedule": "Վճարումների ժամանակացույց",
    "my_investments.schedule.monthly": "Ամսական",
    "my_investments.schedule.monthly_twice": "Ամիսը երկու անգամ",
    "my_investments.schedule.monthly_thrice": "Ամսական երեք անգամ",
    "my_investments.schedule.four_times": "Ամսական չորս անգամ",
    "my_investments.schedule.quarterly": "Եռամսյակային",
    "my_investments.schedule.at_the_end": "Ժամանակահատվածի վերջում"
}