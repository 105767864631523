import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    FullName,
    LenderIdentificationStatus,
    Money,
    Phone, PhoneResult,
    Profile,
    Role,
    UserStatus
} from "../api-client"
import {Company} from "../api-client";

export type ProfileState = {
    uuid?: string;
    role?: Role;
    status?: UserStatus;
    email?: string;
    fullName?: FullName;
    emailVerified?: boolean;
    phone?: Phone;
    phoneVerified?: boolean;
    name?: string;
    balance?: Money;
    reservedFunds?: Money;
    paymentCode?: string;
    isLender?: boolean;
    lenderVerified?: boolean;
    lenderStatus?: LenderIdentificationStatus;
    hasIdentificationDraft?: boolean;
    isIndividual?: boolean;
    isEntrepreneur?: boolean;
    isLegalEntity?: boolean;
    isBorrower?: boolean;
    hasProjectRequests?: boolean;
    hasCompletedIntroduction?: boolean;
    company?: Company;
}

const initialState: ProfileState = {}

export const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        set: (state: ProfileState, { payload }: PayloadAction<Profile>) => {
            state.uuid = payload.uuid;
            state.role = payload.role;
            state.status = payload.status;
            state.fullName = payload.fullName;
            state.email = payload.email;
            state.emailVerified = payload.emailVerified;
            state.phone = payload.phone;
            state.phoneVerified = payload.phoneVerified;
            state.name = payload.name;
            state.balance = payload.balance;
            state.reservedFunds = payload.reservedFunds;
            state.paymentCode = payload.paymentCode;
            state.isLender = payload.lender !== undefined;
            state.lenderVerified = payload.lender?.verified;
            state.lenderStatus = payload.lender?.identificationStatus;
            state.hasIdentificationDraft = payload.lender?.hasIdentificationDraft;
            state.isIndividual = payload.status === UserStatus.INDIVIDUAL;
            state.isEntrepreneur = payload.status === UserStatus.ENTREPRENEUR;
            state.isLegalEntity = payload.status === UserStatus.LEGAL_ENTITY;
            state.isBorrower = payload.borrower !== undefined;
            state.hasProjectRequests = payload.borrower?.hasProjectRequests;
            state.hasCompletedIntroduction = payload.borrower?.hasCompletedIntroduction;
            state.company = payload.borrower?.company;
        },
        subtractBalance: (state: ProfileState, { payload }: PayloadAction<number>) => {
            if (state.balance) {
                state.balance = new Money(state.balance.amount - payload, state.balance.currencyCode);
            }
        },
        setIdentificationDraft: (state: ProfileState, { payload }: PayloadAction<boolean>) => {
            state.lenderVerified = !payload;
            state.hasIdentificationDraft = payload;
        },
        setPhone(state: ProfileState, { payload }: PayloadAction<PhoneResult>) {
            state.phone = payload.phone;
            state.phoneVerified = payload.phoneVerified;
        },
        setPhoneVerified: (state: ProfileState, { payload }: PayloadAction<boolean>) => {
            state.phoneVerified = payload;
        },
        clear: (state: ProfileState) => {
            delete state.uuid;
            delete state.paymentCode;
        }
    }
});

export const {
    set,
    setIdentificationDraft,
    subtractBalance,
    setPhone,
    setPhoneVerified,
    clear
} = profileSlice.actions;

export default profileSlice.reducer;

