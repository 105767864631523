import {Translations} from "../../types";

export const profile: Translations = {
    "profile": "Անձնագիր",
    "profile.edit": "Խմբագրել",
    "profile.cancel": "Չեղարկել",
    "profile.account": "Հաշվի մանրամասներ",
    "profile.passport": "Անձնագրային տվյալներ",
    "profile.passport.disclaimer": "Անձնագրային տվյալները փոխելիս կպահանջվի լրացուցիչ նույնականացում:" +
        "Տվյալների ստուգման պահին հարթակի ֆունկցիոնալությունն անհասանելի կլինի:",
    "profile.documents": "Փաստաթղթերը",
    "profile.save": "Պահպանել փոփոխությունները"
}