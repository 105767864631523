import React, {AllHTMLAttributes, PropsWithChildren, ReactNode, useContext} from "react";
import {OptionBoxContext, OptionBoxContextType} from "../../OptionBoxContext";
import {cn, resultIf} from "../../../../../../utils";
import './OptionBoxHead.scoped.scss';

interface Props extends AllHTMLAttributes<HTMLDivElement> {
    placeholder?: string | undefined
}

const OptionBoxHead = <T, >({ placeholder, children, ...props }: PropsWithChildren<Props>) => {
    const context: OptionBoxContextType<T> = useContext(OptionBoxContext);
    const renderSelectedOption = (): ReactNode => {
        if (children !== undefined) {
            return children;
        }
        if (context.selectedOption !== null) {
            return context.render(context.selectedOption);
        }
        return placeholder ?? "Выберите элементы из списка";
    }
    return (
        <div
            {...props}
            className={cn(
                "option-box__head",
                resultIf(context.active, "active"),
                props.className
            )}
            onClick={context.toggle}
        >
            { renderSelectedOption() }
        </div>
    );
}

export default OptionBoxHead;