import {Translations} from "../../types";

export const funding_request: Translations = {
    "funding_request.navigation.upload_documents": "Upload statements",
    "funding_request.navigation.count_available": "Calculate sum",
    "funding_request.navigation.survey": "Borrower survey",
    "funding_request.navigation.loan_options": "Choose loan option",
    "funding_request.navigation.sign_documents": "Sign documents",
    "funding_request.upload": "Upload",
    "funding_request.upload.more": "Upload more",
    "funding_request.upload.restriction": "one or more files (.txt up to 1 mb)",
    "funding_request.upload.restriction.more": "(.txt up to 1 mb)",
    "funding_request.upload.count": "Calculate limit",
    "funding_request.upload.description": "Download standard bank account statements to see available loan amounts",
    "funding_request.upload.requirements": "Requirements",
    "funding_request.upload.requirements.first": "The statement must include the current month (must be generated by the current date)",
    "funding_request.upload.requirements.second": "Statement must be at least 12 months old",
    "funding_request.upload.requirements.third": "If your business is less than 1 year old, please upload a statement for the entire actual period of operation",
    "funding_request.upload.recommendations": "Recommendations",
    "funding_request.upload.several_accounts": "If you use multiple accounts with one or more banks, please " +
        "download statements from all accounts to correctly calculate the company limit.",
    "funding_request.available_sum.declined": "Rejected",
    "funding_request.available_sum.pending": "Waiting for admin review...",
    "funding_request.available_sum.success": "Successful",
    "funding_request.available_sum.preliminary_declined": "The preliminary check was rejected.",
    "funding_request.available_sum.pending_more": "A review by the platform administrator is expected, as a result " +
        "of which you will be approved for a preliminary loan amount.",
    "funding_request.available_sum.approved_more_first": "Based on the results of automatic analysis of transactions on " +
        "your account, you have been pre-approved for the amount within which you can borrow. Please note that in the " +
        "next steps the amount may change after the application has been reviewed by the underwriter.",
    "funding_request.available_sum.approved_more_second": "You can try to get a higher limit. To do this, upload " +
        "additional statements from other accounts (including from other banks), or go to the personal account of " +
        "other banks. Previously downloaded statements are summarized - download only additional statements.",
    "funding_request.available_sum.preliminary_approved": "Amount pre-approved",
    "funding_request.survey": "Fill out the loan application",
    "funding_request.survey.fill": "To complete the registration and application for a loan, fill out the form.",
    "funding_request.survey.personal_data": "Personal data",
    "funding_request.survey.company_data": "Company data",
    "funding_request.survey.company_data.phone": "Phone",
    "funding_request.survey.company_data.description": "Detailed description of current activities",
    "funding_request.survey.company_data.business_form": "Business form",
    "funding_request.survey.company_data.activity": "Customer activities",
    "funding_request.survey.company_data.business_address": "Actual business address",
    "funding_request.survey.company_data.links": "Links to your company website or social media profiles",
    "funding_request.survey.company_data.links.add": "Add another link",
    "funding_request.survey.mark": "Business reputation assessment",
        "funding_request.survey.mail_for_reviews": "If you have reviews of goodwill from credit institutions or " +
            "non-credit financial institutions in which the borrower was / is on service, send them to",
    "funding_request.survey.reputation.positive": "Positive",
    "funding_request.survey.reputation.negative": "Negative",
    "funding_request.survey.reputation.no_reputation": "No rating",
    "funding_request.survey.reputation.no_crime": "I confirm the absence of an unexpunged or outstanding conviction " +
        "for crimes in the field of economics or crimes against state power, the interests of public " +
        "service and service in local governments",
    "funding_request.survey.reputation.no_negative": "I confirm the absence of facts (events, actions) that " +
        "may have a material negative impact on the repayment of the loan",
    "funding_request.survey.reputation.money_source": "Specify the main source of working capital",
    "funding_request.survey.reputation.money_source.economic": "Revenue from business activities",
    "funding_request.survey.reputation.money_source.borrowed": "Borrowed funds",
    "funding_request.survey.reputation.money_source.other": "Other",
    "funding_request.survey.reputation.confirm": "Confirmation of entered data",
    "funding_request.survey.reputation.confirmation.first": "I provide",
    "funding_request.survey.reputation.confirmation.second": "consent to processing personal data, a request to " +
        "the credit bureau and verification of mobile operator data",
    "funding_request.survey.send": "Send questionnaire",
    "funding_request.loan_options": "Select loan terms",
    "funding_request.loan_options.sum": "Amount, :currency (Available limit from :min_amount " +
        ":currency to :max_amount :currency)",
    "funding_request.loan_options.term": "Term, months (Maximum term - :term months)",
    "funding_request.loan_options.promo": "Promo code (if any)",
    "funding_request.loan_options.send_request": "Submit an inquiry",
    "funding_request.loan_options.finish_registration": "Finish registration",
    "funding_request.loan_options.schedule": "Payment schedule",
    "funding_request.loan_options.preliminary_warning": "Attention! Preliminary payment schedule",
    "funding_request.loan_options.preliminary_explanation": "You will be able to get acquainted with the exact payment " +
        "schedule in your personal account after receiving a loan. You can repay the loan ahead of " +
        "schedule without penalties and commissions.",
    "funding_request.loan_options.terms": "Loan terms",
    "funding_request.loan_options.terms.sum": "Loan sum",
    "funding_request.loan_options.terms.commission": "Issuance fee (:percent% of loan amount)",
    "funding_request.loan_options.terms.final_sum": "Amount to be credited",
    "funding_request.loan_options.terms.schedule": "Maturity frequency",
    "funding_request.loan_options.terms.term": "Loan term",
    "funding_request.loan_options.terms.term.monthly": "Monthly",
    "funding_request.loan_options.terms.term.twice_a_month": "2 times a month",
    "funding_request.loan_options.terms.term.thrice_a_month": "3 times a month",
    "funding_request.loan_options.terms.term.four_times_a_month": "4 times a month",
    "funding_request.loan_options.terms.term.quarterly": "Quarterly",
    "funding_request.loan_options.terms.term.at_the_end": "At the end",
    "funding_request.loan_options.terms.term.not_listed": "Not listed",
    "funding_request.sign_document": "Consideration of the application and signing of documents",
    "funding_request.sign_document.sms_signed": "Signed by SMS",
    "funding_request.sign_document.approved_manager": "Approved by manager",
    "funding_request.sign_document.final_review": "Application submitted for review",
    "funding_request.sign_document.final_review_text.first": "Processing time in most cases is 1-2 business days.",
    "funding_request.sign_document.final_review_text.second": "A positive decision will be reported by the manager, " +
        "in some cases it may be necessary to clarify the details.",
    "funding_request.sign_document.requisites.heading_first": "Signing expected",
    "funding_request.sign_document.requisites.heading_second": "currency",
    "funding_request.sign_document.requisites.description": "To sign documents, transfer from the borrower's account " +
        "(Account of a legal entity or individual entrepreneur) a payment in the amount of",
    "funding_request.sign_document.requisites.description_second": "according to the details below.",
    "funding_request.sign_document.requisites.one": "One",
    "funding_request.sign_document.requisites": "Details for sending",
    "funding_request.sign_document.requisites.purpose": "Purpose of payment: Transfer in order to confirm accession " +
        "to the potok.digital rules, signing of investment proposal No. 31.28 664. VAT exempt",
    "funding_request.sign_document.qr_code": "QR code to send",
    "funding_request.sign_document.qr_code.camera": "Point your smartphone camera",

    "funding_request.months.nominative": "month",
    "funding_request.months.genitive": "months",
    "funding_request.months.multiple": "months"
}