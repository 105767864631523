import {Translations} from "../../types";

export const market: Translations = {
    "market.primary": "Primary market",
    "market.secondary": "Secondary market",
    "market.primary.company": "Company",
    "market.primary.sum": "Sum",
    "market.primary.rate": "Rate",
    "market.primary.term": "Term",
    "market.primary.schedule": "Schedule type",
    "market.primary.collected": "Collected",
    "market.primary.invest": "Invest",
    "market.primary.filters": "Filters",
    "market.primary.filters.term": "Term, days",
    "market.primary.filters.submit": "Submit",
    "market.primary.filters.reset": "Reset",
    "market.primary.schedule.monthly": "Monthly",
    "market.primary.schedule.monthly_twice": "Twice a month",
    "market.primary.schedule.monthly_thrice": "Thrice a month",
    "market.primary.schedule.monthly_four_times": "Four times a month",
    "market.primary.schedule.quarterly": "Quarterly",
    "market.primary.schedule.at_the_end": "At the end"
}