import {Translations} from "../../types";

export const identification: Translations = {
    "identification": "Заявка на статус инвестора",
    "identification.disclaimer": "Для завершения регистрации и оформления заявки на займ заполните форму.",
    "identification.personal_data": "Персональные данные",
    "identification.request": "Ваша заявка",
    "identification.status": "Статус заявки",
    "identification.your_status": "Статус вашей заявки",
    "identification.phone_number": "Номер телефона",
    "identification.phone_confirmed": "Телефон подтвержден",
    "identification.passport_data": "Паспортные данные",
    "identification.passport_files": "Идентификация",
    "identification.passport_files.disclaimer": "Загрузите указанные фотографии или сканы документов, которые " +
        "потребуются для идентификации. Рекомендуемое разрешение не ниже 1200×800рх до 10 мб.",
    "identification.confirmation": "Подтверждение введенных данных",
    "identification.agree": "Согласен",
    "identification.promotion_conditions": "с условиями по содействию в инвестировании",
    "identification.data_policy": "с политикой обработки персональных данных",
    "identification.send": "Отправить заявку",
    "identification.draft": "Ваша заявка проверяется администратором...",
}