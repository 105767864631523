import React from "react";
import {DetailedProjectSubBlocks, DetailedProjectSubPage} from "../../index";
import DetailedProjectDocumentsList from "../DetailedProjectDocumentsList";

const DetailedProjectDocuments = () => {
    return (
        <DetailedProjectSubPage>
            <DetailedProjectSubBlocks>
                <DetailedProjectDocumentsList />
            </DetailedProjectSubBlocks>
        </DetailedProjectSubPage>
    );
}

export default DetailedProjectDocuments;