import {FullName} from "../api-client";

export const formatFullName = (fullName: FullName, hideMiddleName: boolean = false): string => {
    const middleName = formatMiddleName(fullName.middleName, hideMiddleName);
    return `${fullName.lastName} ${fullName.firstName}${middleName}`
}

function formatMiddleName(middleName: string | undefined, hide: boolean): string {
    if (middleName === undefined) {
        return "";
    }
    return hide ? ` ${middleName[0].toUpperCase()}.` : ` ${middleName}`;
}