import React, {FC} from "react";
import AnalyticsOptionBox from "../AnalyticsOptionBox";
import {ANALYTICS_PERIOD, AnalyticsPeriod} from "../../../../api-client";
import {useTranslator} from "../../../../hooks";

interface Props {
    selectedOption: AnalyticsPeriod;
    setSelectedOption: (option: AnalyticsPeriod) => void;
}

interface PeriodObject {
    period: AnalyticsPeriod;
    translation: string;
    parameters?: { [key: string]: string };
}

const periodsObjects: Array<PeriodObject> = [
    { period: ANALYTICS_PERIOD.TwelveMonths, translation: "analytics.user.period.twelve_months" },
    { period: ANALYTICS_PERIOD.SixMonths, translation: "analytics.user.period.six_months" },
    { period: ANALYTICS_PERIOD.ThreeMonths, translation: "analytics.user.period.three_months" },
    { period: ANALYTICS_PERIOD.FirstYearBefore, translation: "analytics.user.period.year_only", parameters: { year: "2020" } },
    { period: ANALYTICS_PERIOD.SecondYearBefore, translation: "analytics.user.period.year_only", parameters: { year: "2021" } },
    { period: ANALYTICS_PERIOD.ThirdYearBefore, translation: "analytics.user.period.year_only", parameters: { year: "2022" } }
];

const AnalyticsPeriodSelect: FC<Props> = ({ selectedOption, setSelectedOption}) => {
    const t = useTranslator();
    const mappedSelectedOption = periodsObjects.filter((periodObject) => {
        return periodObject.period === selectedOption;
    })[0];

    const handleSelect = (value: PeriodObject) => {
        setSelectedOption(value.period);
    }

    const renderSelectedPeriod = (period: PeriodObject) => {
        return `${t("analytics.user.period")} (${renderOption(period)})`;
    }

    const renderOption = (option: PeriodObject) => {
        return t(option.translation, option.parameters);
    }

    return (
        <AnalyticsOptionBox
            options={periodsObjects}
            onSelected={handleSelect}
            selectedOption={mappedSelectedOption}
            renderSelectedOption={renderSelectedPeriod}
            renderOption={renderOption}
        />
    );
}

export default AnalyticsPeriodSelect;