import React from "react";
import {Heading, PrimaryHeading, ProjectLink} from "../../../components/common";
import {HeadingType} from "../../../components/common/typography/headings/Heading/Heading";
import styles from "./Register.module.scss";
import Tabs from "../../../components/layout/common/Tabs";
import RegisterContainer from "../../../components/pages/register/RegisterContainer";
import {RouteDictionary} from "../../../utils";
import {useTranslator} from "../../../hooks";

const Register = () => {
    const t = useTranslator();
    return (
        <div className={styles.register}>
            <RegisterContainer>
                <PrimaryHeading>
                    <Heading headingType={HeadingType.H1}>
                        { t("auth.register") }
                    </Heading>
                </PrimaryHeading>
                <Tabs>
                    <ProjectLink
                        to={RouteDictionary.REGISTER_LENDER}
                    >
                        { t("common.lender") }
                    </ProjectLink>
                    <ProjectLink
                        to={RouteDictionary.REGISTER_BORROWER}
                    >
                        { t("common.borrower") }
                    </ProjectLink>
                </Tabs>
            </RegisterContainer>
        </div>
    );
}

export default Register;