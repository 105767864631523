import {NamedUrl} from "../types";
import {RouteDictionary} from "./RouteDictionary";

export const authRoutes: Array<NamedUrl> = [
    {
        name: RouteDictionary.REGISTER,
        url: "/register"
    },
    {
        name: RouteDictionary.REGISTER_LENDER,
        url: "/register-lender"
    },
    {
        name: RouteDictionary.REGISTER_BORROWER,
        url: "/register-borrower"
    },
    {
        name: RouteDictionary.VERIFICATION,
        url: "/verification/:code"
    },
    {
        name: RouteDictionary.LOGIN,
        url: "/login"
    },
    {
        name: RouteDictionary.LOGOUT,
        url: "/logout"
    },
    {
        name: RouteDictionary.FORGOT_PASSWORD,
        url: "/forgot-password"
    },
    {
        name: RouteDictionary.REDIRECT_AFTER_AUTH,
        url: "/redirect-after-auth"
    },
]