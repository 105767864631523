import React, {FC, useContext, useMemo, useState} from "react";
import './FundingRequestFiles.scoped.scss';
import {Button, FileUpload, Loader, PrimaryButton} from "../../../common";
import {UploadedFile} from "../../../../api-client";
import {PrimaryButtonColor} from "../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {ArrowRight} from "../../../svg";
import FundingRequestFile from "../FundingRequestFile";
import {FundingRequestContext} from "../FundingRequestContext";
import {useTranslator} from "../../../../hooks";
import {CommonBlock} from "../../../common/blocks";

interface Props {
    urls: Array<string>,
    onSubmit: (urls: Array<string>) => void;
    loadingAllFiles: boolean;
}

const FundingRequestFiles: FC<Props> = ({ urls, onSubmit, loadingAllFiles }) => {
    const t = useTranslator();
    const { request } = useContext(FundingRequestContext);
    const [files, setFiles] = useState<Array<string>>(urls);
    const [loading, setLoading] = useState(false);
    const uploadedFiles = useMemo((): Array<UploadedFile> => {
        return files.map((url): UploadedFile => {
            return {
                name: url.split("/").pop() || url,
                url: url,
                downloadLink: url
            }
        })
    }, [files]);

    const setUploadedFiles = (newFiles: Array<UploadedFile>) => {
        setFiles(newFiles.map((file) => file.url));
    }

    const handleSubmit = () => {
        const newUrls = uploadedFiles.map((file: UploadedFile) => file.url);
        onSubmit(newUrls);
    }

    const renderFiles = () => {
        return uploadedFiles.map((loadedFile, index) => {
            return <FundingRequestFile
                key={index}
                file={loadedFile}
                onRemoved={() => setFiles(files => files.filter((file) => {
                    return file !== loadedFile.url;
                }))}
            />
        })
    }
    return (
        <CommonBlock>
            <CommonBlock.Section>
                <div className="funding-request-files">
                    { loading && <div className="funding-request-files__loader">
                        <Loader />
                    </div> }
                    <div className="funding-request-files__inner">
                        { files.length > 0 && <div className="funding-request-files__list">
                            { renderFiles() }
                        </div> }
                        <div className="funding-request-files__upload">
                            <FileUpload
                                files={uploadedFiles}
                                onUploaded={setUploadedFiles}
                                onLoading={(value) => setLoading(value)}
                                id="files"
                                accept=".txt, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            >
                            <span>
                                { files.length === 0
                                    ? <>{ t("funding_request.upload") }</>
                                    : <>{ t("funding_request.upload.more") }</> }
                            </span>
                            </FileUpload>
                            &nbsp;
                            <div>
                                { files.length == 0
                                    ? <>{ t("funding_request.upload.restriction") }</>
                                    : <>{ t("funding_request.upload.restriction.more") }</>
                                }
                            </div>
                        </div>
                    </div>
                    <PrimaryButton
                        expanded
                        color={
                            files.length === 0 || request?.rejectionReason !== undefined
                                ? PrimaryButtonColor.GRAY
                                : PrimaryButtonColor.BLUE
                        }
                    >
                        <Button
                            onClick={handleSubmit}
                            disabled={files.length === 0 || request?.rejectionReason !== undefined}
                            loading={loadingAllFiles}
                        >
                            <div className="funding-request-files__submit">
                                <div>
                                    { t("funding_request.upload.count") }
                                </div>
                                <div>
                                    <ArrowRight />
                                </div>
                            </div>
                        </Button>
                    </PrimaryButton>
                </div>
            </CommonBlock.Section>
        </CommonBlock>
    );
}

export default FundingRequestFiles;