import React, {FC} from "react";
import {Company} from "../../../../../api-client";
import styles from "./CompanyAutoComplete.module.scss";
import MediumText from "../../../../common/typography/MediumText";
import {CommonSubText} from "../../../../common";

interface Props {
    companies: Array<Company>;
    onSelected: (company: Company) => void;
}

const CompanyAutoComplete: FC<Props> = ({ companies, onSelected }) => {
    const renderCompanies = () => {
        return companies.map((company) => {
            return <li
                className={styles.company_autocomplete__element}
                key={company.id}
                onClick={() => onSelected(company)}
            >
                <MediumText accent className={styles.company_autocomplete__name}>
                    { company.name }
                </MediumText>
                <div className={styles.company_autocomplete__info}>
                    <CommonSubText small className={styles.company_autocomplete__id}>
                        { company.inn }
                    </CommonSubText>
                    <CommonSubText small className={styles.company_autocomplete__address}>
                        { company.address }
                    </CommonSubText>
                </div>
            </li>
        })
    }
    return (
        <div className={styles.company_autocomplete}>
            <ul className={styles.company_autocomplete__list}>
                { renderCompanies() }
            </ul>
        </div>
    );
}

export default CompanyAutoComplete;