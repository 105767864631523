import React, {FC} from "react";
import {CommonSubText, Heading, SecondaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import {DetailedProjectDatatable, DetailedProjectSubBlock} from "../../index";
import {TranslationDictionary} from "../../../../../utils";
import {PaymentScheduleType, ProjectGeneral} from "../../../../../api-client";
import {useTranslator} from "../../../../../hooks";
import {resolveDayTranslation} from "../../../../../i18n";
import {DynamicTranslation} from "../../../../language";

const paymentScheduleTranslations: TranslationDictionary<PaymentScheduleType> = {
    [PaymentScheduleType.MONTHLY_PAYMENTS]: "detailed_project.schedule.monthly",
    [PaymentScheduleType.MONTHLY_TWO_PAYMENTS]: "detailed_project.schedule.monthly_twice",
    [PaymentScheduleType.MONTHLY_THREE_PAYMENTS]: "detailed_project.schedule.monthly_thrice",
    [PaymentScheduleType.MONTHLY_FOUR_PAYMENTS]: "detailed_project.schedule.monthly_four_times",
    [PaymentScheduleType.QUARTERLY_PAYMENTS]: "detailed_project.schedule.quarterly",
    [PaymentScheduleType.PAYMENT_AT_THE_END]: "detailed_project.schedule.at_the_end",
}

interface Props {
    general: ProjectGeneral;
}

const DetailedProjectGeneral: FC<Props> = ({ general }) => {
    const t = useTranslator();
    return (
        <DetailedProjectSubBlock>
            <SecondaryHeading smaller>
                <Heading headingType={HeadingType.H2}>
                    { t("detailed_project.general") }
                </Heading>
            </SecondaryHeading>
            <DetailedProjectDatatable className="detailed-project-summary__datatable">
                <DetailedProjectDatatable.Row>
                    <CommonSubText>
                        { t("detailed_project.general.product") }
                    </CommonSubText>
                    <DetailedProjectDatatable.Text>
                        <DynamicTranslation translations={ general.name } />
                    </DetailedProjectDatatable.Text>
                </DetailedProjectDatatable.Row>
                <DetailedProjectDatatable.Row>
                    <CommonSubText>
                        { t("detailed_project.general.rate") }
                    </CommonSubText>
                    <DetailedProjectDatatable.Text>
                        { general.interestRate }%
                    </DetailedProjectDatatable.Text>
                </DetailedProjectDatatable.Row>
                <DetailedProjectDatatable.Row>
                    <CommonSubText>
                        { t("detailed_project.general.term") }
                    </CommonSubText>
                    <DetailedProjectDatatable.Text>
                        { general.initialTerm * 30 } {t(resolveDayTranslation(general.initialTerm * 30))}
                    </DetailedProjectDatatable.Text>
                </DetailedProjectDatatable.Row>
                <DetailedProjectDatatable.Row>
                    <CommonSubText>
                        { t("detailed_project.general.percents") }
                    </CommonSubText>
                    <DetailedProjectDatatable.Text>
                        { t("detailed_project.general.on_remainder") }
                    </DetailedProjectDatatable.Text>
                </DetailedProjectDatatable.Row>
                <DetailedProjectDatatable.Row>
                    <CommonSubText>
                        { t("detailed_project.general.frequency") }
                    </CommonSubText>
                    <DetailedProjectDatatable.Text>
                        { t(paymentScheduleTranslations[general.paymentScheduleType]) }
                    </DetailedProjectDatatable.Text>
                </DetailedProjectDatatable.Row>
            </DetailedProjectDatatable>
        </DetailedProjectSubBlock>
    );
}

export default DetailedProjectGeneral;