import React, {FC} from "react";
import './DetailedProjectAnalyticsChart.scoped.scss';
import {cn, resultIf} from "../../../../../utils";
import {useMountAnimation, useTranslator} from "../../../../../hooks";
import {NetCreditTurnoverMonth} from "../../../../../api-client";
import BarChart from "../../../../chart/BarChart";
import moment from "moment/moment";

interface Props {
    months: Array<NetCreditTurnoverMonth>;
}

const DetailedProjectAnalyticsChart: FC<Props> = ({ months }) => {
    const t = useTranslator();
    const mounted = useMountAnimation();

    const chartData = months.map((entry) => {
        return entry.sum.amount / 100;
    });

    const chartLabels = months.map((entry) => {
        const momentDate = moment(entry.month);
        return [momentDate.format("MMM"), momentDate.format("YYYY")];
    });

    return (
        <div className={cn("detailed-project-analytics-chart", resultIf(mounted, "mounted"))}>
            <BarChart
                data={chartData}
                labels={chartLabels}
                stepSize={1000000}
                ticksFunc={(tickValue) => `${Math.floor(+tickValue / 1000000)} ${t("common.chart_mln")}`}
            />
        </div>
    );
}

export default DetailedProjectAnalyticsChart;