import React from "react";
import MyProjectStatus from "../MyProjectStatus";
import InformationSvg from "../../../../../svg/InformationSvg";
import {ColorDictionary, getCssVariable} from "../../../../../../utils";
import MediumText from "../../../../../common/typography/MediumText";
import {useTranslator} from "../../../../../../hooks";

const MyProjectFundraisingStatus = () => {
    const t = useTranslator();
    return (
        <MyProjectStatus>
            <InformationSvg color={getCssVariable(ColorDictionary.PRIMARY_BLUE)} />
            <MediumText>
                { t("my_detailed_project.status.fundraising") }
            </MediumText>
        </MyProjectStatus>
    );
}

export default MyProjectFundraisingStatus;