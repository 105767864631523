import React, {useContext, useMemo, useState} from "react";
import './DetailedProjectDistribution.scoped.scss';
import {Heading, SecondaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import {cn, resultIf, TranslationDictionary} from "../../../../../utils";
import {Period} from "../../../../../api-client";
import {DetailedProjectContext} from "../../DetailedProjectContext";
import DetailedProjectPlaceholder from "../../common/DetailedProjectPlaceholder";
import {ProjectInvestor} from "../../../../../api-client";
import DetailedProjectPeriodDistribution from "../DetailedProjectPeriodDistribution";
import Tabs from "../../../../layout/common/Tabs";
import {useTranslator} from "../../../../../hooks";

const sharesDistributionTranslations: TranslationDictionary<Period> = {
    [Period.OneMonth]: "detailed_project.period.month",
    [Period.ThreeMonths]: "detailed_project.period.three_months",
    [Period.SixMonths]: "detailed_project.period.six_months",
    [Period.TwelveMonths]: "detailed_project.period.twelve_months",
    [Period.AllTime]: "detailed_project.period.all_time",
}

const DetailedProjectDistribution = () => {
    const t = useTranslator();
    const { analytics } = useContext(DetailedProjectContext).project;
    if (analytics === undefined || analytics.investments.length === 0) {
        return <DetailedProjectPlaceholder />
    }
    const investments = analytics.investments;
    const [period, setPeriod] = useState<Period>(investments[0].period);

    const currentInvestors = useMemo((): Array<ProjectInvestor> => {
        const investmentPeriod = analytics.investments.filter((investment) => {
            return investment.period === period;
        })[0];
        return investmentPeriod.investors;
    }, [period]);

    const renderTabs = () => {
        return investments.map((investment, index) => {
            return <div
                key={index}
                className={cn(
                    "detailed-project-analytics__option",
                    resultIf(period === investment.period, "active")
                )}
                onClick={() => setPeriod(investment.period)}
            >
                { t(sharesDistributionTranslations[investment.period]) }
            </div>
        })
    }

    const currentDistribution = useMemo(() => {
        return <DetailedProjectPeriodDistribution investors={currentInvestors} key={period} />
    }, [currentInvestors]);
    return (
        <div className="detailed-project-distribution">
            <div className="detailed-project-analytics__header">
                <SecondaryHeading smaller>
                    <Heading headingType={HeadingType.H2}>
                        { t("detailed_project.distribution") }
                    </Heading>
                </SecondaryHeading>
                <Tabs>
                    { renderTabs() }
                </Tabs>
            </div>
            { currentDistribution }
        </div>
    );
}

export default DetailedProjectDistribution;