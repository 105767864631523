import React from "react";
import "./SuspenseLoader.scoped.scss";

const SuspenseLoader = () => {
    return (
        <div className="page-loader">
            <div className="page-loader__inner"></div>
        </div>
    );
}

export default SuspenseLoader