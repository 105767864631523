import React, {FC, PropsWithChildren} from "react";
import Container from "../../components/layout/common/Container";
import Content from "../../components/layout/common/Content";
import MainGrid from "../../components/layout/common/MainGrid";
import BorrowerNavigation from "../../components/layout/borrower/BorrowerNavigation";
import Main from "../../components/layout/common/Main";
import BorrowerLanguageSelect from "../../components/language/BorrowerLanguageSelect";
import MainContainer from "../../components/layout/common/MainContainer";

const LayoutBorrowerCabinet: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Container>
            <Content min>
                <MainGrid>
                    <BorrowerNavigation />
                    <Main min>
                        <MainContainer max>
                            <BorrowerLanguageSelect />
                            { children }
                        </MainContainer>
                    </Main>
                </MainGrid>
            </Content>
        </Container>
    );
}

export default LayoutBorrowerCabinet;