import React, {FC, ReactNode} from "react";
import "./CommonAsideStep.scoped.scss";
import {cn, resultIf} from "../../../../../../utils";
import {CommonAsideElement} from "../index";

interface Props {
    index: number;
    text: ReactNode;
    active: boolean;
    onClick: (() => void) | undefined;
    finished?: boolean;
}

const CommonAsideStep: FC<Props> = ({ index, text, active, onClick, finished = false }) => {
    return (
        <CommonAsideElement
            className={cn(
                resultIf(active, "active"),
                resultIf(finished, "finished"),
                resultIf(onClick !== undefined, "available")
            )}
            onClick={onClick}
        >
            <div className="common-aside-step">
                <div className="common-aside-step__number">
                    { index }
                </div>
                <div>
                    { text }
                </div>
            </div>
        </CommonAsideElement>
    );
}

export default CommonAsideStep;