import {Language} from "../api-client";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface LanguageState {
    language?: Language;
}

const initialState: LanguageState = {
    language: undefined
}

export const languageSlice = createSlice({
    name: "language",
    initialState,
    reducers: {
        set(state: LanguageState, { payload }: PayloadAction<Language>) {
            state.language = payload;
        }
    }
});

export const { set } = languageSlice.actions;

export default languageSlice.reducer;