import React from "react";
import {Route, Routes} from "react-router-dom";
import GuarantorIdentification from "../../../views/guarantor/GuarantorIdentification";

const GuarantorRoutes = () => {
    return (
        <Routes>
            <Route path="/:uuid" element={<GuarantorIdentification />} />
        </Routes>
    );
}

export default GuarantorRoutes;