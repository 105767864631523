import React, {PropsWithChildren} from "react";
import './AsLink.scoped.scss';
import {ClassInjector} from "../../../../injectors";
import {ClassNameDecorator} from "../../../../decorators";

const AsLink: ClassNameDecorator<PropsWithChildren> = ({ children, className }) => {
    return (
        <ClassInjector
            classNames="link-button"
            oldClassName={className}
        >
            { children }
        </ClassInjector>
    );
}

export default AsLink;