import React from "react";
import {SvgComponent} from "../SvgComponent";
import {defaultStatSvgColor} from "./defaultStatSvgColor";
import SvgContainer from "../SvgContainer";
import {SvgContainerSize} from "../SvgContainer/SvgContainer";

const ClockSvg: SvgComponent = ({ color= defaultStatSvgColor }) => {
    return (
        <SvgContainer size={SvgContainerSize.Small}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <circle cx="8" cy="8" r="7" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8 4V8H11" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_1659_32930">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgContainer>
    );
}

export default ClockSvg;