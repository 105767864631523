import {Collection} from "../../api-client";

export class DataCollection<T> {
    private _count = 0;
    private _totalCount = 0;
    private _items: Array<T> = [];

    constructor(readonly prefix = "") {}

    get count(): number {
        return this._count;
    }

    get totalCount(): number {
        return this._totalCount;
    }

    get items(): Array<T> {
        return this._items;
    }

    setCollection(collection: Collection<T>): void {
        this._count = collection.count;
        this._totalCount = collection.totalCount;
        this._items = collection.items;
    }
}