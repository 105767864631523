import React, {AllHTMLAttributes, FC, PropsWithChildren} from "react";
import styles from "./CommonPropertiesList.module.scss";
import {cn} from "../../../../utils";
import CommonPropertiesListElement from "./extensions/CommonPropertiesListElement";

interface Props extends AllHTMLAttributes<HTMLUListElement>{}

type CommonPropertiesListExtensions = {
    Element: typeof CommonPropertiesListElement;
}

const CommonPropertiesList: FC<PropsWithChildren<Props>> & CommonPropertiesListExtensions
    = ({ children , ...props}) => {
    return (
        <ul {...props} className={cn(styles.common_properties_list, props.className)}>
            { children }
        </ul>
    );
}

CommonPropertiesList.Element = CommonPropertiesListElement;

export default CommonPropertiesList;