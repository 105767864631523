import React, {FC, useMemo} from "react";
import "./PrimaryMarketOffer.module.scss";
import {FundRaisingProject, PaymentScheduleType} from "../../../../../api-client";
import {Money, ProjectLink, SecondaryButton} from "../../../../common";
import {dateDiff, RouteDictionary} from "../../../../../utils";
import {SecondaryButtonColor} from "../../../../common/buttons/decorators/SecondaryButton/SecondaryButton";
import {useTranslator} from "../../../../../hooks";
import {resolveDayTranslation} from "../../../../../i18n";
import {DynamicTranslation} from "../../../../language";
import CommonOffer from "../../CommonOffer";
import styles from "./PrimaryMarketOffer.module.scss";

interface Props {
    project: FundRaisingProject;
}

export const ScheduleTypeTranslation: Record<PaymentScheduleType, string> = {
    [PaymentScheduleType.MONTHLY_PAYMENTS]: "market.primary.schedule.monthly",
    [PaymentScheduleType.MONTHLY_TWO_PAYMENTS]: "market.primary.schedule.monthly_twice",
    [PaymentScheduleType.MONTHLY_THREE_PAYMENTS]: "market.primary.schedule.monthly_thrice",
    [PaymentScheduleType.MONTHLY_FOUR_PAYMENTS]: "market.primary.schedule.monthly_four_times",
    [PaymentScheduleType.QUARTERLY_PAYMENTS]: "market.primary.schedule.quarterly",
    [PaymentScheduleType.PAYMENT_AT_THE_END]: "market.primary.schedule.at_the_end",
}

const PrimaryMarketOffer: FC<Props> = ({ project }) => {
    const t = useTranslator();
    const collectedPercent = useMemo(() => {
        if (project.investedSum === undefined) {
            return 0;
        }
        return Math.floor(project.investedSum.amount / project.targetSum.amount * 100);
    }, [project]);

    return (
        <CommonOffer className={styles.primary_market_offer}>
            <div className={styles.primary_market_offer__name}>
                <DynamicTranslation translations={ project.name } />
            </div>
            <div className={styles.primary_market_offer__sum}>
                <Money money={project.targetSum} />
            </div>
            <div className={styles.primary_market_offer__rate}>
                { project.interestRate }%
            </div>
            <div className={styles.primary_market_offer__term}>
                { dateDiff(project.initialTerm, project.maturityDate) } { t(resolveDayTranslation(dateDiff(project.initialTerm, project.maturityDate))) }
            </div>
            <div className={styles.primary_market_offer__type}>
                { t(ScheduleTypeTranslation[project.scheduleType]) }
            </div>
            <div className={styles.primary_market_offer__collected}>
                { collectedPercent }%
            </div>
            <div className={styles.primary_market_offer__actions}>
                <SecondaryButton color={SecondaryButtonColor.BLUE}>
                    <ProjectLink to={RouteDictionary.DETAILED_PROJECT} params={{ uuid: project.uuid }}>
                        { t("market.primary.invest") }
                    </ProjectLink>
                </SecondaryButton>
            </div>
        </CommonOffer>
    );
}

export default PrimaryMarketOffer