import React, {FC, useState} from "react";
import {ModalProps} from "../Modal/Modal";
import {ConfirmSmsCodeForm, Loader} from "../../common/utils";
import {Modal} from "../index";
import {createInvestmentManager} from "../../../di";
import {UuidResult, InvestmentSmsConfirmation} from "../../../api-client";
import {useEffectOnUpdate, useErrorCallback, useTranslator} from "../../../hooks";

interface Props extends ModalProps {
    confirmationUuid: UuidResult | null;
    onSuccess: () => void;
}

const InvestmentConfirmationModal: FC<Props> = ({ confirmationUuid, active, onClose, onSuccess }) => {
    const t = useTranslator();
    const handleError = useErrorCallback();
    const [confirmation, setConfirmation] = useState<InvestmentSmsConfirmation | null>(null);
    const [loading, setLoading] = useState(true);

    useEffectOnUpdate(() => {
        if (active) {
            (async () => {
                try {
                    const manager = await createInvestmentManager();
                    const result = await manager.requestCode(confirmationUuid!.uuid);
                    setConfirmation(result);
                } catch (error: any) {
                    await handleError(error);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [active]);

    const handleSend = async (code: string) => {
        const manager = await createInvestmentManager();
        await manager.confirmCode(confirmationUuid!.uuid, code);
    }

    const handleResend = async () => {
        console.log("ADD RESEND LOGIC");
    }

    return (
        <Modal active={active} onClose={onClose}>
            { loading ? <div>
                <Loader />
            </div> : <ConfirmSmsCodeForm
                sendCallback={handleSend}
                resendCallback={handleResend}
                successCallback={onSuccess}
            /> }
        </Modal>
    );
}

export default InvestmentConfirmationModal;