export interface CompanyRequisites {
    name: string;
    regNumber: string;
    kpp: string;
    account: string;
    bank: string;
    correspondent: string;
    bik: string;
}

export const CURRENT_COMPANY_REQUISITES: CompanyRequisites = {
    name: "Armcrowd",
    regNumber: "9701046627",
    kpp: "770101001",
    account: "40702810901600004898",
    bank: "Банк",
    correspondent: "3010181020000000593",
    bik: "044525593"
}