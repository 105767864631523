import React, {useContext, useState} from "react";
import {AnalyticsUserContext} from "../../AnalyticsUserContext";
import AnalyticsPenaltiesNavigation, {
    PENALTIES_NAVIGATION_PAGE,
    PenaltiesNavigationPage
} from "../../../rows/AnalyticsPenaltiesNavigation/AnalyticsPenaltiesNavigation";
import AnalyticsPenalty from "../../../rows/penalty/AnalyticsPenalty";
import AnalyticsPenaltySum from "../../../rows/penalty/AnalyticsPenaltySum";
import AnalyticsPenaltyPercent from "../../../rows/penalty/AnalyticsPenaltyPercent";
import AnalyticsRows from "../../../rows/AnalyticsRows";
import AnalyticsPenaltyHeader from "../../../rows/penalty/AnalyticsPenaltyHeader";
import BarChart from "../../../../../chart/BarChart";
import moment from "moment/moment";
import {useTranslator} from "../../../../../../hooks";

const AnalyticsUserPenalties = () => {
    const t = useTranslator();
    const penalties = useContext(AnalyticsUserContext).analytics?.penalties;
    if (penalties === undefined) {
        return <div>Analytics is undefined (somehow)</div>
    }
    const [page, setPage] = useState<PenaltiesNavigationPage>(PENALTIES_NAVIGATION_PAGE.NUMBER);
    const renderRows = () => {
        if (page === PENALTIES_NAVIGATION_PAGE.NUMBER) {
            return penalties.map((penalty, index) => {
                return <AnalyticsPenalty penalty={penalty} key={index} />
            });
        }
        if (page === PENALTIES_NAVIGATION_PAGE.SUM) {
            return penalties.map((penalty, index) => {
                return <AnalyticsPenaltySum penalty={penalty} key={index} />
            });
        }
        return penalties.map((penalty, index) => {
            return <AnalyticsPenaltyPercent penalty={penalty} key={index} />
        });
    }

    const chartData = penalties.map((entry) => {
        return (entry.returned.sum.amount + entry.inDefault.sum.amount) / 100;
    });

    const chartHeadings = penalties.map((entry) => {
        const momentDate = moment(entry.date);
        return [momentDate.format("MMM"), momentDate.format("YYYY")];
    });

    return (
        <AnalyticsRows>
            <div className="user-analytics__chart">
                <BarChart
                    data={chartData}
                    labels={chartHeadings}
                    stepSize={1000000}
                    ticksFunc={(tickValue) => `${Math.floor(+tickValue / 1000000)} ${t("common.chart_mln")}`}
                />
            </div>
            <AnalyticsPenaltiesNavigation current={page} setCurrent={setPage} />
            <AnalyticsPenaltyHeader />
            <ul>
                { renderRows() }
            </ul>
        </AnalyticsRows>
    );
}

export default AnalyticsUserPenalties;