import React, {FC, ImgHTMLAttributes} from "react";

export interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {}

const Image: FC<ImageProps> = ({ ...props }) => {
    return (
        <img {...props} />
    );
}

export default Image;