import {useState} from "react";
import {useEffectOnUpdate} from "./useEffectOnUpdate";

function useDebounce<T>(value: T, delay: number = 500) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffectOnUpdate(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedValue(value)
        }, delay);
        return () => {
            clearTimeout(timeoutId);
        }
    }, [value, delay]);

    return debouncedValue;
}

export default useDebounce;