import {Translations} from "../../types";

export const deposit: Translations = {
    "deposit": "Ավանդ",
    "deposit.id_assigned": "ձեզ հատկացվել է ID",
    "deposit.terms": "Պլատֆորմում հնարավոր է ներդրումներ կատարել միայն այն դեպքում, եթե անհատ հաշվում կան անվճար միջոցներ",
    "deposit.transfer": "Միջոցների համալրումն իրականացվում է բացառապես ներդրողին պատկանող բանկային հաշվից Պլատֆորմի օպերատորի անվանական հաշվին փոխանցելու միջոցով:",
    "deposit.requisites": "Ռեկվիզիտներ",
    "deposit.reg_number": "Գրանցման համարը",
    "deposit.account": "C/a",
    "deposit.bik": "BIC",
    "deposit.correspondent": "Թղթակցային հաշիվ",
    "deposit.purpose": "Վճարման նպատակը",
    "deposit.agreement": "Պայմանագրով անվանական հաշվի համալրում :date, ID 160499, առանց ԱԱՀ-ի.",
    "deposit.qr": "QR-կոդ",
    "deposit.camera": "Ուղղեք ձեր սմարթֆոնի տեսախցիկը",
}