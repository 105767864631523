import React, {useEffect, useState} from "react";
import './DetailedProject.scoped.scss';
import Section from "../../../../components/layout/common/Section";
import {DetailedProject as DetailedProjectComponent} from "../../../../components/pages/detailed-project";
import {DetailedProject as DetailedProjectModel} from "../../../../api-client/models/project/DetailedProject";
import {useErrorCallback, useTranslator} from "../../../../hooks";
import {createProjectManager} from "../../../../di";
import {useParams} from "react-router-dom";
import ContentContainer from "../../../../components/layout/common/ContentContainer";
import {Loader} from "../../../../components/common";

const DetailedProject = () => {
    const { uuid } = useParams();
    const t = useTranslator();
    const handleError = useErrorCallback();
    const [project, setProject] = useState<DetailedProjectModel | null>(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        (async () => {
            try {
                const projectManager = await createProjectManager();
                const project = await projectManager.getDetailedProject(uuid!);
                setProject(project);
            } catch (error: any) {
                await handleError(error);
            } finally {
                setLoading(false);
            }
        })();
    }, []);
    return (
        <ContentContainer>
            { loading && <div className="detailed-project__loader">
                <Loader />
            </div> }
            { !loading && <Section className="detailed-project-section">
                { project === null ? <div className="detailed-project__placeholder">
                    { t("detailed_project.not_found") }
                </div> : <DetailedProjectComponent project={project} /> }
            </Section> }
        </ContentContainer>
    );
}

export default DetailedProject;