import React, {lazy} from "react";
import {Outlet, Route, Routes} from "react-router-dom";
import InvestmentConfirmation from "../../../../views/lender/market/InvestmentConfirmation";
import Loadable from "../../../loading/Loadable";
import DetailedInvestment from "../../../../views/lender/market/DetailedInvestment";
import SellConfirmation from "../../../../views/lender/market/SellConfirmation";

const LazyDetailedProject = Loadable(lazy(() => import("../../../../views/lender/market/DetailedProject")));

const DetailedProjectRoutes = () => {
    return (
        <>
            <Routes>
                <Route element={<LazyDetailedProject />}>
                    <Route index element={<></>} />
                    <Route path="confirmation" element={<InvestmentConfirmation />} />
                </Route>
                <Route path="investment/:investmentUuid" element={<DetailedInvestment />}>
                    <Route path="sell" element={<SellConfirmation />} />
                </Route>
            </Routes>
            <Outlet />
        </>
    );
}

export default DetailedProjectRoutes;