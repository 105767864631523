import React, {FC} from "react";
import './DropdownArrowImage.scoped.scss';
import {cn, resultIf} from "../../../../utils";
import DropdownDownSvg from "../../../svg/dropdown/DropdownDownSvg";

interface Props {
    active: boolean;
}

const DropdownArrowImage: FC<Props> = ({ active }) => {
    return (
        <div
            className={cn(
                "dropdown-arrow-image",
                resultIf(active, "active")
            )}
        >
            <DropdownDownSvg />
        </div>
    );
}

export default DropdownArrowImage;