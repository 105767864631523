import React, {AllHTMLAttributes, FC} from "react";
import styles from "./CommonInvestment.module.scss";
import {cn} from "../../../../utils";
import MyInvestmentLink from "../MyInvestmentLink";
import CommonListEntry from "../../../common/blocks/CommonListEntry";

interface Props extends AllHTMLAttributes<HTMLLIElement> {
    linkTo: { projectUuid: string; investmentUuid: string }
}

const CommonInvestment: FC<Props> = ({ linkTo, ...props }) => {
    return (
        <CommonListEntry {...props} className={cn(styles.common_investment, props.className)}>
            <MyInvestmentLink uuid={linkTo.projectUuid} investmentUuid={linkTo.investmentUuid}>
                { props.children }
            </MyInvestmentLink>
        </CommonListEntry>
    );
}

export default CommonInvestment;