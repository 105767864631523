import React from "react";
import AuthFooter from "../../AuthFooter";
import {RouteDictionary} from "../../../../../utils";
import {ProjectLink} from "../../../../common";
import "./AuthFooterLogin.scss";
import {useTranslator} from "../../../../../hooks";

const AuthFooterLogin = () => {
    const t = useTranslator();
    return (
        <AuthFooter>
            <ProjectLink to={RouteDictionary.REGISTER} className="auth_footer_login__link">
                { t("auth.create_account") }
            </ProjectLink>
        </AuthFooter>
    );
}

export default AuthFooterLogin;