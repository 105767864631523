export const concatenateQueries = (queryString: string, ...other: string[]): string => {
    let reduced = other.reduce((accum, current) => {
        if (current === "") {
            return accum;
        }
        if (current.charAt(0) === "?") {
            current = current.substring(1);
        }
        return `${accum}&${current}`;
    }, queryString);
    if (reduced.charAt(0) === "&") {
        return reduced.substring(1);
    }
    return reduced;
}