import {NamedUrl} from "../types";
import {RouteDictionary} from "./RouteDictionary";

export const borrowerRoutes: Array<NamedUrl> = [
    {
        name: RouteDictionary.CONFIRM_PHONE,
        url: "/confirm-phone"
    },
    {
        name: RouteDictionary.INTRODUCTION,
        url: "/introduction"
    },
    {
        name: RouteDictionary.BORROWER_PROFILE,
        url: "/profile"
    },
    {
        name: RouteDictionary.MY_PROJECTS,
        url: "/my-projects"
    },
    {
        name: RouteDictionary.MY_PROJECTS_ACTIVE,
        url: "/my-projects/active"
    },
    {
        name: RouteDictionary.MY_PROJECTS_FUNDRAISING,
        url: "/my-projects/fundraising"
    },
    {
        name: RouteDictionary.MY_PROJECTS_REFUNDED,
        url: "/my-projects/refunded"
    },
    {
        name: RouteDictionary.MY_PROJECTS_MODERATED,
        url: "/my-projects/moderated"
    },
    {
        name: RouteDictionary.MY_PROJECT,
        url: "/my-projects/:uuid"
    },
    {
        name: RouteDictionary.MY_HISTORY,
        url: "/my-history"
    },
    {
        name: RouteDictionary.FUNDING_REQUEST,
        url: "/request"
    },
    {
        name: RouteDictionary.PROJECT_REQUEST,
        url: "/request/:uuid"
    }
]