import React from "react";
import MyProjectStatus from "../MyProjectStatus";
import ApprovedSvg from "../../../../../svg/status/ApprovedSvg";
import {CommonText} from "../../../../../common";
import {useTranslator} from "../../../../../../hooks";

const MyProjectFinalReviewApprovedStatus = () => {
    const t = useTranslator();
    return (
        <MyProjectStatus>
            <ApprovedSvg />
            <CommonText>
                { t("my_detailed_project.status.final_review_approved") }
            </CommonText>
        </MyProjectStatus>
    );
}

export default MyProjectFinalReviewApprovedStatus;