import {RefObject, useEffect, useRef, useState} from "react";
import {useEffectOnUpdate} from "./useEffectOnUpdate";

export const useDropdown = (active: boolean, initialHeight: number = 0): [RefObject<HTMLDivElement>, number] => {
    const contentElement = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(initialHeight);

    const setActualHeight = () => {
        setHeight(active ? contentElement.current!.clientHeight : initialHeight);
    }

    useEffectOnUpdate(() => {
        setActualHeight()
    }, [active]);

    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            if (active) {
                setHeight(entries[0].contentRect.height);
            }
        });
        observer.observe(contentElement.current!);
        return () => { contentElement.current && observer.unobserve(contentElement.current) }
    }, [active]);

    return [
        contentElement, height
    ]
}