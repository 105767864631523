import React, {FC, PropsWithChildren} from "react";

const ConfirmationBlockText: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="confirmation-block__text">
            { children }
        </div>
    );
}

export default ConfirmationBlockText;