import React, {FC, useState} from "react";
import {useTranslator} from "../../../../hooks";
import {cn} from "../../../../utils";
import {Button, SecondaryButton} from "../../buttons";
import styles from "./LanguageSelect.module.scss";
import {ButtonProps} from "../../buttons/Button/Button";
import {SecondaryButtonColor} from "../../buttons/decorators/SecondaryButton/SecondaryButton";
import LanguageSelectModal from "../../../modals/LanguageSelectModal";

const LanguageSelect: FC<ButtonProps> = ({ ...props }) => {
    const [modalOpened, setModalOpened] = useState(false);
    const t = useTranslator();
    return (
        <>
            <SecondaryButton color={SecondaryButtonColor.BLUE}>
                <Button
                    {...props}
                    className={cn(styles.language_select, props.className)}
                    onClick={() => setModalOpened(true)}
                >
                <span>
                    { t("language_select.short") }
                </span>
                </Button>
            </SecondaryButton>
            <LanguageSelectModal active={modalOpened} onClose={() => setModalOpened(false)} />
        </>
    );
}

export default LanguageSelect;