import React, {FC} from "react";
import styles from "./ArchivedProject.module.scss";
import {ArchivedProject as ArchivedProjectModel} from "../../../../api-client";
import CommonListEntry from "../../../common/blocks/CommonListEntry";
import {useTranslator} from "../../../../hooks";
import {Money} from "../../../common";

interface Props {
    project: ArchivedProjectModel
}

const ArchivedProject: FC<Props> = ({ project }) => {
    const t = useTranslator();
    return (
        <CommonListEntry className={styles.archived_project}>
            <div className={styles.archived_project__id}>
                { project.paymentCode ?? t("archive.not_listed") }
            </div>
            <div className={styles.archived_project__name}>
                { project.name }
            </div>
            <div className={styles.archived_project__term}>
                { project.initialTerm }
            </div>
            <div className={styles.archived_project__rate}>
                { project.interestRate ?? t("archive.not_listed") }
            </div>
            <div className={styles.archived_project__amount}>
                <Money money={project.targetSum} />
            </div>
        </CommonListEntry>
        // <li className={styles.archived_project}>
        //     <div className={styles.archived_project__id}>
        //
        //     </div>
        //     <div className={styles.archived_project__id}>
        //
        //     </div>
        // </li>
    );
}

export default ArchivedProject;