import React, {FC, PropsWithChildren} from "react";
import styles from "./MarketButtons.module.scss";

const MarketButtons: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className={styles.market_buttons}>
            { children }
        </div>
    );
}

export default MarketButtons;