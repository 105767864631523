import React from "react";
import {Outlet} from "react-router-dom";
import LayoutBorrowerIntroduction from "../../layouts/LayoutBorrowerIntroduction";

const BorrowerIntroductionDashboard = () => {
    return (
        <LayoutBorrowerIntroduction>
            <Outlet />
        </LayoutBorrowerIntroduction>
    );
}

export default BorrowerIntroductionDashboard;