import React, {FC} from "react";
import {Payment} from "../../../../../api-client";
import styles from "./MyActiveProjectPayment.module.scss";
import {cn, ColorDictionary, resultIf} from "../../../../../utils";
import {formatDate} from "../../../../../formatters";
import InformationSvg from "../../../../svg/InformationSvg";

interface Props {
    payment: Payment;
}

const MyActiveProjectPayment: FC<Props> = ({ payment }) => {
    return (
        <div className={cn(styles.my_loan_payment, resultIf(payment.overdue, styles.my_loan_payment___overdue))}>
            <div>
                { formatDate(payment.date) }
            </div>
            { payment.overdue && <InformationSvg color={ColorDictionary.ERROR} /> }
        </div>
    );
}

export default MyActiveProjectPayment;