import React, {FC, PropsWithChildren} from "react";
import "./CommonAsideElement.scoped.scss";
import {cn} from "../../../../../../utils";

interface Props {
    className?: string;
    onClick?: (() => void);
}

const CommonAsideElement: FC<PropsWithChildren<Props>> = ({ className, onClick, children }) => {
    return (
        <li
            className={cn(
                "common-aside-element",
                className
            )}
            onClick={onClick}
        >
            { children }
        </li>
    );
}

export default CommonAsideElement;