import React, {FC} from "react";
import styles from "./PaymentsSchedule.module.scss";
import {PaymentSchedule} from "../../../api-client";
import {CommonSubText} from "../../common/typography";
import Money from "../../common/utils/Money";
import sumMoney from "../../../utils/money/sumMoney";
import {CommonText} from "../../common";
import {useTranslator} from "../../../hooks";

interface Props {
    schedule: PaymentSchedule;
}

const PaymentsSchedule: FC<Props> = ({ schedule }) => {
    const t = useTranslator();
    const renderRows = () => {
        return schedule.payments.map((payment, index) => {
            return <li className={styles.payments_list__element} key={payment.uuid}>
                <CommonText>
                    { index + 1 }
                </CommonText>
                <CommonText>
                    <Money money={sumMoney(payment.interest, payment.principal)} />
                </CommonText>
                <CommonText>
                    <Money money={payment.principal} />
                </CommonText>
                <CommonText>
                    <Money money={payment.interest} />
                </CommonText>
            </li>
        })
    }
    return (
        <ul className={styles.payments_list}>
            <li className={styles.payments_list__element}>
                <CommonSubText small>
                    { t("payments_schedule.number") }
                </CommonSubText>
                <CommonSubText small>
                    { t("payments_schedule.sum") }
                </CommonSubText>
                <CommonSubText small>
                    { t("payments_schedule.debt") }
                </CommonSubText>
                <CommonSubText small>
                    { t("payments_schedule.percents") }
                </CommonSubText>
            </li>
            { renderRows() }
        </ul>
    );
}

export default PaymentsSchedule;